import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { companyProfileInfoForUser } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import { ListPosPaymentSummaryAPI, ListPosShifReportAPI, ListPosShifReportSalesAPI, ListPosShifReportWalletAPI } from './ReportAPI';

const PosShiftReportPrint = (props) => {
    const { branch, shift } = props;
    const companyProfileData = useSelector(
        (state) => state?.companyProfileSlice?.userValue
    );

    const shiftSalesReport = useSelector(
        (state) => state.posReportshiftSlice.shiftReportSale);
    const decimalPosition = localStorage.getItem("decimalPosition");

    const shiftReportpayment = useSelector(
        (state) => state.posReportshiftSlice.shiftReporPayment);

    const shiftReportwallet = useSelector(
        (state) => state.posReportshiftSlice.shiftReportWalletPay);

    const productSummmary = useSelector(
        (state) => state.posReportshiftSlice.shiftReport);

    const paymentSummmary = useSelector(
        (state) => state.posReportshiftSlice.shiftReporPaymentSummary);

    const formatDate = (dateTimeString) => {
        return dateTimeString?.split(' ')[0]; // Split the string by space and return the first part (the date)
    };


    return (
        <div className="print-main-div" style={{ backgroundColor: "white" }}>
            <div className='header-div' style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                    <img src={companyProfileData?.companyLogo} alt="" style={{ height: "62px" }} />
                </div>
                <div style={{ textAlignLast: "center" }}>
                    <h1 style={{ fontSize: "medium", margin: "0" }}>Shift Report</h1>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <p style={{ margin: "0", fontSize: "small" }}>{branch?.branchName}</p>
                        <div style={{ borderLeft: "1px solid black", height: "19px", marginInline: "10px" }}></div>
                        <p style={{ margin: "0", fontSize: "small" }}>{shift?.SHIFID}</p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <p style={{ margin: "0", fontSize: "small" }}>{"start - " + formatDate(shiftSalesReport?.shiftData?.startDate)}</p>
                        <p style={{ margin: "0", paddingInline: "10px", fontSize: "small" }}>To</p>
                        <p style={{ margin: "0", fontSize: "small" }}>{" end - " + formatDate(shiftSalesReport?.shiftData?.endDate)}</p>
                    </div>
                </div>
                <div>
                    <h1 style={{ fontSize: "small", margin: "0" }}>companyName</h1>
                    <p style={{ margin: "0", fontSize: "smaller" }}>{companyProfileData?.companyName}</p>
                    <p style={{ margin: "0", fontSize: "smaller" }}>{companyProfileData?.companyAddress}</p>
                    <p style={{ margin: "0", fontSize: "smaller" }}>{companyProfileData?.companyContact}</p>
                </div>
            </div>
            {/* sales table */}
            <div className="new-global-table-container" style={{ paddingTop: "0 42px", overflow: "visible",maxHeight:"none" }}>
                <div className="button-div" style={{ padding: "20px 0 0 0px" }}>
                    <button style={{ backgroundColor: "rgb(150 148 148)" }} >
                        SALES
                    </button>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Order Reff</th>
                            <th>Customer</th>
                            <th>Mobile</th>
                            <th>Delivery Charge</th>
                            <th>Order Amount</th>
                            <th>Paid Amount</th>
                            <th>Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {shiftSalesReport?.sale !== undefined ? shiftSalesReport?.sale?.map((r, i) => (
                            <tr key={i} style={{ backgroundColor: i % 2 === 0 ? "white" : "#e9e7e7" }}>
                                <td style={{ border: "none", height: "29px" }}>{formatDate(r.orderDate)}</td>
                                <td style={{ border: "none", height: "29px" }}>{r.name}</td>
                                <td style={{ border: "none", height: "29px" }}>{r.customerName}</td>
                                <td style={{ border: "none", height: "29px" }}>{r.mobileNo}</td>
                                <td style={{ border: "none", height: "29px" }}>{r.deliveryCharge}</td>
                                <td style={{ border: "none", height: "29px" }}>{r.amtTotal}</td>
                                <td style={{ border: "none", height: "29px" }}>{r.paidAmt}</td>
                                <td style={{ border: "none", height: "29px" }}>{r.amountDue}</td>
                            </tr>
                        )) : (
                            <tr>
                                <td colSpan={8}>
                                    NO DATA
                                </td>
                            </tr>
                        )}
                    </tbody>
                    <tfoot>
                        <tr style={{ backgroundColor: "rgb(150 148 148)" }}>
                            <td colSpan={5} style={{ textAlignLast: "end", paddingInline: "10px" }}>TOTAL</td>
                            <td style={{ textAlignLast: "center" }}>{shiftSalesReport?.sale?.reduce((acc, current) => acc + Number(current?.amtTotal || 0), 0)?.toFixed(decimalPosition)}</td>
                            <td style={{ textAlignLast: "center" }}>{shiftSalesReport?.sale?.reduce((acc, current) => acc + Number(current?.paidAmt || 0), 0)?.toFixed(decimalPosition)}</td>
                            <td style={{ textAlignLast: "center" }}>{shiftSalesReport?.sale?.reduce((acc, current) => acc + Number(current?.amountDue || 0), 0)?.toFixed(decimalPosition)}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            {/* Payment Table */}
            <div className="new-global-table-container" style={{ paddingTop: "0 42px", overflow: "visible",maxHeight:"none" }}>
                <div className="button-div" style={{ padding: "20px 0 0 0px" }}>
                    <button style={{ backgroundColor: "rgb(150 148 148)" }} >
                        PAYMENT
                    </button>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Payment Reff</th>
                            <th>Order Reff</th>
                            <th>Customer</th>
                            <th>Payment Method</th>
                            <th> Amount</th>

                        </tr>
                    </thead>
                    <tbody>
                        {shiftReportpayment?.sale !== undefined ? shiftReportpayment?.sale?.map((r, i) => (
                            <tr key={i} style={{ backgroundColor: i % 2 === 0 ? "white" : "#e9e7e7" }}>
                                <td style={{ border: "none", height: "29px" }}>{formatDate(r.paymentDate)}</td>
                                <td style={{ border: "none", height: "29px" }}>{r.name}</td>
                                <td style={{ border: "none", height: "29px" }}>{r.orderName}</td>
                                <td style={{ border: "none", height: "29px" }}>{r.customerName}</td>
                                <td style={{ border: "none", height: "29px" }}>{r.type}</td>
                                <td style={{ border: "none", height: "29px" }}>{r.amount}</td>

                            </tr>
                        )) : (
                            <tr>
                                <td colSpan={6} >NO DATA</td>
                            </tr>
                        )}
                    </tbody>
                    <tfoot>
                        <tr style={{ backgroundColor: "rgb(150 148 148)" }}>
                            <td colSpan={5} style={{ textAlignLast: "end", paddingInline: "75px" }}>TOTAL</td>
                            <td style={{ textAlignLast: "center" }}>{shiftReportpayment?.sale?.reduce((acc, current) => acc + Number(current?.amount || 0), 0)?.toFixed(decimalPosition)}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>

            {/* wallet */}

            <div className="new-global-table-container" style={{ paddingTop: "0 42px", overflow: "visible",maxHeight:"none" }}>
                <div className="button-div" style={{ padding: "20px 0 0 0px" }}>
                    <button style={{ backgroundColor: "rgb(150 148 148)" }} >
                        WALLET
                    </button>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Wallet Reff</th>
                            <th>Order Reff</th>
                            <th>Customer</th>
                            <th>Payment Method</th>
                            <th> Amount</th>

                        </tr>
                    </thead>
                    <tbody>
                        {shiftReportwallet?.sale !== undefined ? shiftReportwallet?.sale?.map((r, i) => (
                            <tr key={i} style={{ backgroundColor: i % 2 === 0 ? "white" : "#e9e7e7" }}>
                                <td style={{ border: "none", height: "29px" }}>{formatDate(r.paymentDate)}</td>
                                <td style={{ border: "none", height: "29px" }}>{r.name}</td>
                                <td style={{ border: "none", height: "29px" }}>{r.orderName}</td>
                                <td style={{ border: "none", height: "29px" }}>{r.customerName}</td>
                                <td style={{ border: "none", height: "29px" }}>{r.type}</td>
                                <td style={{ border: "none", height: "29px" }}>{r.amount}</td>

                            </tr>
                        )) : (
                            <tr>
                                <td colSpan={6}>NO DATA</td>
                            </tr>
                        )}
                    </tbody>
                    <tfoot>
                        <tr style={{ backgroundColor: "rgb(150 148 148)" }}>
                            <td colSpan={5} style={{ textAlignLast: "end", paddingInline: "10px" }}>TOTAL</td>
                            <td style={{ textAlignLast: "center" }}>{shiftReportwallet?.sale?.reduce((acc, current) => acc + Number(current?.amount || 0), 0)?.toFixed(decimalPosition)}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>

            {/* Product summary */}
            <div className="new-global-table-container" style={{ paddingTop: "0 42px", overflow: "visible",maxHeight:"none" }}>
                <div className="button-div" style={{ padding: "20px 0 0 0px" }}>
                    <button style={{ backgroundColor: "rgb(150 148 148)" }} >
                        PRODUCT SUMMARY
                    </button>
                </div>
                <table>
                    <thead>
                        <tr>

                            <th>Product</th>
                            <th>Qty</th>
                            <th> Amount</th>

                        </tr>
                    </thead>
                    <tbody>
                        {productSummmary?.sale !== undefined ? productSummmary?.sale?.map((r, i) => (
                            <tr key={i} style={{ backgroundColor: i % 2 === 0 ? "white" : "#e9e7e7" }}>

                                <td style={{ border: "none", height: "29px" }}>{r.productName}</td>
                                <td style={{ border: "none", height: "29px" }}>{r.totalQty}</td>
                                <td style={{ border: "none", height: "29px" }}>{r.amount}</td>

                            </tr>
                        )) : (
                            <tr>
                                <td colSpan={3}>NO DATA</td>
                            </tr>
                        )}
                    </tbody>
                    <tfoot>
                        <tr style={{ backgroundColor: "rgb(150 148 148)" }}>
                            <td colSpan={2} style={{ textAlignLast: "end", paddingInline: "10px" }}>TOTAL</td>
                            <td style={{ textAlignLast: "center" }}>{productSummmary?.sale?.reduce((acc, current) => acc + Number(current?.amount || 0), 0)?.toFixed(decimalPosition)}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>

            {/* Payment summary */}
            <div className="new-global-table-container" style={{ paddingTop: "0 42px", overflow: "visible",maxHeight:"none" }}>
                <div className="button-div" style={{ padding: "20px 0 0 0px" }}>
                    <button style={{ backgroundColor: "rgb(150 148 148)" }} >
                        PAYMENT SUMMARY
                    </button>
                </div>
                <table>
                    <thead>
                        <tr>

                            <th>Payment Method</th>

                            <th> Amount</th>

                        </tr>
                    </thead>
                    <tbody>
                        {paymentSummmary?.sale !== undefined ? paymentSummmary?.sale?.map((r, i) => (
                            <tr key={i} style={{ backgroundColor: i % 2 === 0 ? "white" : "#e9e7e7" }}>

                                <td style={{ border: "none", height: "29px" }}>{r.type}</td>

                                <td style={{ border: "none", height: "29px" }}>{r.amount}</td>

                            </tr>
                        )) : (
                            <tr>
                                <td colSpan={2}>NO DATA</td>
                            </tr>
                        )}
                    </tbody>
                    <tfoot>
                        <tr style={{ backgroundColor: "rgb(150 148 148)" }}>
                            <td style={{ textAlignLast: "end", paddingInline: "10px" }}>TOTAL</td>
                            <td style={{ textAlignLast: "center" }}>{paymentSummmary?.sale?.reduce((acc, current) => acc + Number(current?.amount || 0), 0)?.toFixed(decimalPosition)}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    )
}

export default PosShiftReportPrint