import React, { useEffect, useState } from 'react'
import { FilePicker } from '../../../../Single Components/filePicker';
import { Autocomplete, Dialog, IconButton, TextField, Tooltip } from '@mui/material';
import { viewAllAllowedBranchesAPICall } from '../../../../../API/Settings/Company Settings/companySettingsAPI';
import { getAllTaxSettingsListAPICall } from "../../../../../API/Settings/TaxSettings/taxSettingsAPI";
import "./reward.css"
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { SingleViewRewardAPI, createRewardAPI, editedRewardAPI } from '../GeneralAPI';

const CreateReward = () => {
    let navigateTo = useNavigate();

    const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
    const userRole = useSelector((state) => state.userRoleSlice.value)
    const userInfo = useSelector((state) => state.loginResponseSlice.value);
    const allowedBranchList = useSelector((state) => state.allAllowedBranchesSlice.value);
    const [addReward, setAddReward] = useState(false)
    const [validationAlert, setValidationAlert] = useState({});
    const [selectedConditionIndex, setSelectedConditionIndex] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    const allTaxList = useSelector((state) => state.taxSettingsSlice.value);
    const singleView = useSelector((state) => state.generalSlice.singleViewRewards);
    const singleViewById = localStorage?.getItem("row_id")
    //    console.log(singleViewById);
    const removeCondition = (indexToRemove) => {
        if (formData.condition !== undefined) {
            const updatedConditions = formData.condition.filter((_, index) => index !== indexToRemove);
            setFormData(prevState => ({
                ...prevState,
                condition: updatedConditions
            }));
        }
    };

    const [conditionInitial, setConditionInitial] = useState({
        minPurchaseAmt: "",
        // tax: null,
        points: ""
    });


    const FormInitial = {
        name: '',
        branch: [],
        isEdit: false,
        isActive: true,
        condition: []
    }
    const [formData, setFormData] = useState(FormInitial)
    const [image, setImage] = useState()

    // onChange
    const getFormPostInfo = (key) => (e, newValue) => {
        const { value } = e.target;
        setValidationAlert({});
        setFormData({
            ...formData,
            [key]: value !== 0 ? value : newValue,
        });
    };

    const branchOnchange = (e, newValue) => {
        setValidationAlert({});
        setFormData({ ...formData, branch: newValue })
    }

    const imageOnchange = (e) => {
        let file = e.target.files[0];
        setImage(file)
    }
    // condition adding
    const conditionOnchange = (key) => (e, newValue) => {
        setValidationAlert({});
        // Get the new value from the event
        const { value } = e.target;
        // Update the corresponding property in the conditionInitial object
        setConditionInitial((prevState) => ({
            ...prevState,
            [key]: value !== 0 ? value : newValue
        }));
    };



    const addNewCondition = () => {
        setIsEditing(false); // Set editing state to false
        // setConditionInitial({ minPurchaseAmt: "", tax: null, points: "" });
        setAddReward(true); // Open the dialog
    };

    const saveCondition = () => {
        // Validate input fields here
        const isDuplicate = formData.condition.some(item => item.minPurchaseAmt === conditionInitial.minPurchaseAmt);

    if (isDuplicate) {
        // If it's a duplicate, show validation alert or handle it accordingly
        setValidationAlert({
            ...validationAlert,
            minPurchaseAmt: 'Minimum purchase amount already exists!',
          });
        return;
    }

        if (isEditing && selectedConditionIndex !== null) {
            // If editing an existing condition
            const updatedConditions = [...formData.condition];
            updatedConditions[selectedConditionIndex] = conditionInitial;
            setFormData(prevState => ({ ...prevState, condition: updatedConditions }));
        } else {
            // If adding a new condition
            setFormData(prevState => ({
                ...prevState,
                condition: [...prevState.condition, conditionInitial]
            }));
        }

        // Reset input fields and close the dialog
        setConditionInitial({ minPurchaseAmt: "", points: "" });
        setIsEditing(false); // Reset editing state
        setAddReward(false); // Close the dialog
    };


    const clearSelectedCondition = () => {
        setSelectedConditionIndex(null);
        setConditionInitial({
            minPurchaseAmt: "",
            // tax: null,
            points: ""
        });
    };

    const editCondition = (index) => {
        if (!formData?.isEdit) {
            setSelectedConditionIndex(index);
            setConditionInitial(formData.condition[index]);
            setIsEditing(true); // Set editing state to true
            setAddReward(true); // Open the dialog
        }

    };

    const DocumentFormData = new FormData();
    DocumentFormData.append("name", formData?.name)
    DocumentFormData.append("file", image)
    DocumentFormData.append("allowBranches", JSON.stringify(formData?.branch?.map((obj) => obj?._id) || []))

    DocumentFormData.append("conditions", JSON.stringify(formData?.condition))
    DocumentFormData.append("isActive", formData?.isActive)


    console.log(formData);

    // for (let pair of DocumentFormData.entries()) {
    //     console.log(pair[0] + ', ' + pair[1]);
    // }
    const submitBtnClicked = () => {
        if (formData?.name === '' || formData?.name === undefined) {
            setValidationAlert({
                ...validationAlert,
                name: "fill this field !!",
            });
        }
        else if (formData?.branch?.length === 0 || formData?.branch === undefined) {
            setValidationAlert({
                ...validationAlert,
                branch: "fill this field !!",
            });
        }

        else {
            createRewardAPI(DocumentFormData)
        }
    }
    //edit
    const editOnClick = () => {
        if (formData.isEdit) {

            setFormData({ ...formData, isEdit: false });
        } else {

            if (!formData.name) {

                setValidationAlert({
                    ...validationAlert,
                    name: "Fill this field !!",
                });
            } else if (!formData.branch || formData.branch.length === 0) {

                setValidationAlert({
                    ...validationAlert,
                    branch: "Fill this field !!",
                });
            } else {

                const draftFn = () => {
                    setFormData({ ...formData, isEdit: true });
                };

                const taxForbackend = formData.condition.map((obj) => {
                    return {
                        minPurchaseAmt: obj.minPurchaseAmt,
                        // includedPrice: obj.tax === "Tax Included" ? true : false,
                        points: obj.points
                    }
                })
                const DocumentFormData = new FormData();
                DocumentFormData.append("name", formData?.name)
                DocumentFormData.append("file", image)
                DocumentFormData.append("allowBranches", JSON.stringify(formData?.branch?.map((obj) => obj?._id) || []))

                DocumentFormData.append("conditions", JSON.stringify(taxForbackend))
                DocumentFormData.append("isActive", formData?.isActive)
                DocumentFormData.append("_id", singleViewById)


                // const body={
                //     name:formData?.name,
                //     branch:formData?.branch,
                //     conditions:formData?.condition,
                //     imageUrl:formData?.image,
                //    _id:singleViewById,
                //    isActive:formData?.isActive

                // }
                editedRewardAPI(
                    DocumentFormData, draftFn);
            }
        }
    };
    //singleView
    useEffect(() => {
        if (singleView !== undefined) {
            setFormData({
                ...formData,
                isEdit: true,

                name: singleView.name,
                branch: allowedBranchList !== undefined ? allowedBranchList?.filter(item => singleView?.allowBranches.some(branch => item._id === branch.branchId)) : [],
                isActive: singleView.isActive,
                condition: singleView.conditions?.map((r) => {
                    return {
                        minPurchaseAmt: r?.minPurchaseAmt,
                        // tax: r.includedPrice === true ? "(Tax Included)" : "(Tax Excluded)",
                        points: r.points
                    }
                })

            });
            setImage(singleView?.imageUrl)
        }
    }, [singleView, allowedBranchList]);

    console.log(formData);

    //   console.log(singleView);
    console.log(singleView?.imageUrl);

    useEffect(() => {
        if (singleViewById !== undefined) {
            SingleViewRewardAPI({ _id: singleViewById });
        }
    }, [])


    useEffect(() => {

        userRole === "user" && viewAllAllowedBranchesAPICall({ employeeId: userInfo?.data?._id });
        getAllTaxSettingsListAPICall();
    }, [])
    return (
        <div className="global-page-parent-container">
            <div
                className="justify-space-between global-white-bg-container"
                style={{ padding: "0 4%" }}
            >
                <h3 style={{ style: "17px 0px 15px 29px" }}>Rewards</h3></div>
            <div
                className="global-white-bg-container"
                style={{ padding: "0 2%" }}
            >
                <div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ alignContent: "center" }}>

                            <Tooltip title="Back">
                                <IconButton onClick={() => {
                                    navigateTo(
                                        "/userdashboard/pointOfSales/general/Rewards"
                                    );
                                }}>
                                    <i className="bi bi-chevron-left" style={{ fontSize: '30px', color: 'black', cursor: 'pointer' }}></i>
                                </IconButton>
                            </Tooltip>

                            {singleView !== undefined && (
                                <Tooltip title="Edit">
                                    <IconButton onClick={editOnClick}>
                                        <i
                                            class={formData?.isEdit ?
                                                "bi bi-pencil-square edit-icon1"
                                                :
                                                "bi bi-check2-square edit-icon"
                                            }
                                        ></i>
                                    </IconButton>
                                </Tooltip>
                            )}
                            {singleView === undefined && (
                                <Tooltip title="Save">
                                    <IconButton onClick={submitBtnClicked}>
                                        <i className="bi bi-floppy" style={{ color: 'black' }}></i>
                                    </IconButton>
                                </Tooltip>
                            )}
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <h3>Active</h3>
                            <input
                                style={{ marginRight: "50px" }}
                                className="toggle-checkbox"
                                type="checkbox"

                                checked={formData.isActive}
                                onChange={() => setFormData({ ...formData, isActive: !formData.isActive })}
                                disabled={formData?.isEdit}

                            />
                        </div>

                    </div>
                    <hr className="global-hr" />
                </div>
                <div style={{ padding: "16px 0px 37px 0px", display: "flex" }}>
                    <div style={{ width: "73%" }} >

                        <div className="new-global-single-input" style={{ width: "90%", padding: "1px 1px 20px 13px" }}>
                            <TextField
                                value={formData?.name}
                                onChange={getFormPostInfo("name")}
                                id="outlined-basic"
                                label="Name*"
                                variant="outlined"
                                type="text"
                                focused
                                disabled={formData?.isEdit}
                            />
                            <p className="doc-validation-alert" style={{ top: '67%' }}>{validationAlert?.name}</p>
                        </div>

                        <div className="new-global-single-input auto-complete-new" style={{ width: "90%", padding: "1px 1px 20px 13px" }}>
                            <Autocomplete
                                multiple
                                disablePortal
                                options={userRole === "admin" ? allBranchesList : allowedBranchList || []}
                                getOptionLabel={(option) => option?.branchName}
                                placeholder='Branch'
                                sx={{ width: 300 }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Branch" focused placeholder='' />
                                )}
                                value={formData?.branch}
                                onChange={branchOnchange}
                                disabled={formData?.isEdit}


                            />
                            <p className="doc-validation-alert" style={{ top: '67%' }}>{validationAlert?.branch}</p>
                        </div>

                    </div>
                    <div>
                        <div className="global-single-input " style={{ width: "100%" }}>
                            <p>Image Upload</p>
                            <FilePicker
                                id="image"
                                uploadImageUrl={image}
                                setImage={image}
                                onChange={imageOnchange}
                                disabled={formData?.isEdit}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="global-white-bg-container" style={{ padding: "0 2%", height: "52vh" }}>
                <div style={{ display: "flex", alignItems: "center", gap: "81px" }}>
                    <h3 style={{ margin: "5px", padding: "18px 1px 18px 19px" }}>Condition</h3>

                    <button className='add-button' onClick={addNewCondition} disabled={formData?.isEdit} >ADD</button>
                </div>
                <div style={{ width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "space-between" }} >


                    {formData?.condition?.map((item, index) => (
                        <div className='reward-box' key={index} onClick={() => editCondition(index)} >
                            <div>
                                <div style={{ display: "flex" }}>
                                    <p>Min. Purchase Amount</p>
                                    {/* <p style={{ paddingLeft: "10px", fontWeight: "600" }}>{item.tax}</p> */}

                                </div>
                                <p style={{ fontWeight: "600", marginTop: "0" }}>{item.minPurchaseAmt}</p>
                            </div>

                            <div>
                                <div style={{ display: "flex" }}>
                                    <p>Points</p>

                                    <button style={{ border: "none", height: '4vh', paddingTop: "16px" }} disabled={formData?.isEdit}>
                                        <DeleteIcon onClick={() => removeCondition(index)} color="error"

                                        /></button>
                                </div>
                                <p style={{ fontWeight: "600", marginTop: "0" }}>{item.points}</p>
                            </div>


                        </div>
                    ))}



                </div>
            </div>

            <Dialog open={addReward} maxWidth="lg">

                <div className="add-department-dialog-container">
                    <h3>Add Reward Points</h3>
                    <hr className="global-hr" />
                    <div style={{ display: "flex" }}
                    >
                        <div className="new-global-single-input" style={{ width: "100%", padding: "1px 1px 20px 13px", margin: "14px 0 0 0" }}>
                            <TextField
                                value={conditionInitial?.minPurchaseAmt}
                                onChange={conditionOnchange("minPurchaseAmt")}
                                id="outlined-basic"
                                label="Min. Purchase Amount*"
                                variant="outlined"
                                type="number"
                                focused
                            // disabled={formValues?.isEdit}
                            />
                        </div>
                <p className="doc-validation-alert" style={{ top: '45%',right:"6%" }}>{validationAlert?.minPurchaseAmt}</p>

                        {/* <div className="new-global-single-input auto-complete-new" style={{ width: "26%", padding: "1px 1px 20px 13px", margin: "14px 0 0 0" }}>
                            <Autocomplete
                                options={['Inclusive Tax', 'Exclusive Tax']}
                                sx={{ width: 300 }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Tax" focused placeholder='' />
                                )}
                                value={conditionInitial?.tax || ''}
                                onChange={conditionOnchange("tax")}
                            />

                        </div> */}

                    </div>
                    <div className="new-global-single-input" style={{ width: "100%", padding: "1px 1px 20px 13px", margin: "0px" }}>
                        <TextField
                            value={conditionInitial?.points}
                            onChange={conditionOnchange("points")}
                            id="outlined-basic"
                            label="Points*"
                            variant="outlined"
                            type="number"
                            focused
                        // disabled={formValues?.isEdit}
                        />
                    </div>
                    <div style={{ display: "flex", justifyContent: "end" }}>
                        <button className="cancel-button" onClick={() => {
                            setAddReward(false);

                        }}>cancel</button>
                        <button className='save-button' onClick={saveCondition}>Save</button>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default CreateReward