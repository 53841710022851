import {
  Autocomplete,
  Dialog,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  createFilterOptions,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import cancel from "../../../../Assets/statusRibbon/cancel.png";
import Po from "../../../../Assets/statusRibbon/purchaseorder.png";
import done from "../../../../Assets/statusRibbon/done.png";
import uploadFile from "../../../../Assets/Images/Icon feather-upload.png";
import { CloseOutlined } from "@mui/icons-material";
import logoImage from "../../../../Assets/Images/Icon ionic-logo-buffer.png";
import { useDispatch, useSelector } from "react-redux";
import { vendorListApi, viewVendorListAPICall } from "../../Accounts/Vendor/API/vendorListAPICall";
import {
  companyProfileInfoForUser,
  viewAllAllowedBranchesAPICall,
  viewAllBranchesAPICall,
} from "../../../../API/Settings/Company Settings/companySettingsAPI";
import {
  getAllNewProductList,
} from "../../Accounts/Vendor/API/vendorBillAPI";
import { getAllTaxSettingsListAPICall } from "../../../../API/Settings/TaxSettings/taxSettingsAPI";
import {
  filterUomByPurchseUomAPICall,
  filterUomByPurchseUomEditAPICall,
  viewUOMListAPICall,
  viewUOMSubListAPICall,
} from "./UnitOfMeasurment/unitOfMeasurmentAPICall";
import { getCurrentTime, today } from "../../../../Js/Date";
import {
  findObjFromList,
  roundNumericFields,
  taxCalculationForLines,
} from "../../../../Js/generalFunctions";
import { useNavigate } from "react-router-dom";
import {
  GenerateRfqName,
  PoCancelAPiCall,
  productSearchApi,
  resetToDraftApiCall,
} from "../../../../API/Purchase Manager/RFQ/ConfirmRfqApiCall";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import {
  NewUpdatePOAPiCall,
  SavePurchaseOrderNewApiCall,
  confirmPurchasOrderNewApiCall,
 
} from "../../../../API/Purchase Manager/Purchase Order/PurchaseOrder";
import { viewAllCurrencyUpdated } from "../../../../API/Settings/Currency/CurrencyAPI";
import { viewMultiCurrencyConfiguration } from "../../../../API/Purchase Manager/configuration";
import { poHistoryAPICall, viewPurchaseOrderSingleView } from "../../../../API/Purchase Manager/NewPurchaseOrder/PurchaseOrder";

import grn from "../../../../Assets/SVG/grn.svg";
import vendor from "../../../../Assets/SVG/vendor bill.svg";
import { LoadingForm } from "../../../Single Components/LoadingForm";
import PaymentTerms from "../../components/Dialog/paymentTerms/PaymentTerms";
import purchasehistoryicon from "../../../../Assets/SVG/Icon material-history.svg"
import { PurchaseHistory } from "../../Accounts/Vendor/purchaseWPO/PurchaseHistory";
import { setFilterActive } from "../../../Single Components/listSlice/filterCategorySlice";
import { TableVirtuoso } from "react-virtuoso";
import { setErrorSnack, setSnackMsg } from "../../Sales/Billing/Slice/billingScreenSlice";

export const CreateNewPurchaseOrder1 = () => {
  const dispatch = useDispatch();
  const {branchDetails,currencyDetails} = useSelector((state) => state.mainDashBoardSlice)

  const paymentTermsList = useSelector(
    (state) => state.vendorsListSlice?.paymentTermsList
  );

  const userInfo = useSelector((state) => state.loginResponseSlice.value);
  const userRole=useSelector((state)=>state.userRoleSlice.value)
  const allowedBranchList = useSelector((state) => state.allAllowedBranchesSlice.value);


  const userBranchId = localStorage.getItem("branchId");
  const PurchaseOrderSingleList = useSelector(
    (state) => state?.RfqSlice?.purchaseOrderSingleView
  );
  const singleViewID = localStorage.getItem("singleViewById");
  const poHistoryData =useSelector((state)=>state.RfqSlice.poHistory)
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );
  const decimalPosition = Number(localStorage.getItem("decimalPosition"));
  const allTaxList = useSelector((state) => state.taxSettingsSlice.value);
  const allActiveTaxList = allTaxList?.filter(
    (item) => item?.taxType === "Purchase"
  );
  const allNewProductList = useSelector(
    (state) => state.vendorBillSlice.NewProductList
  );
  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );

  const currencyActive = currencyListUpdated?.filter(
    (obj) => obj?.status === true
  );
  const UomList = useSelector(
    (state) => state.unitOfMeasurmentSlice.apiResList
  );

  let navigateTo = useNavigate();
  const uomSubList = useSelector(
    (state) => state.unitOfMeasurmentSlice.subUomList
  );
  const MultiCurrencyView = useSelector(
    (state) => state.ConfigurationSlice.MultiCurrency
  );
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  const VendorList = useSelector((state) => state.vendorsListSlice.apiResList);
  const addRowData = ["Add Header","Add Line"];
  const [RfqEdit, setRfqEdit] = useState(false);
  const [isActiveTable, setIsActiveTable] = useState("invoiceLines");
  const [addRowInput, setAddRowInput] = useState("Add Line");
  const [selectedTaxAmtCalculated, setSelectedTaxAmtCalculated] = useState([]);
  const [loadingForm,setLoadingForm]=useState(false)


  const [editClicked, setEditClicked] = useState(false);
  const [hideSaveBtn, sethideSaveBtn] = useState(false);
  const [tableData, setTableData] = useState([
    {
      type: "lines",
      id: Date.now(),
      product: null,
      description: "",
      uom: null,
      qty: "",
      unitPrice: "",
      discount: "",
      tax: [],
      amount: "",
      inclusive: "",
    },
  ]);



  const [filteredTaxOptions, setFilteredTaxOptions] = useState(
    tableData?.map(() => allActiveTaxList || [])
  );


  const [rowIndex, setRowIndex] = useState(null);

  const [disabledInput, setDisabledInput] = useState(false);
  const [image, setImage] = useState([]);
  const [imageDisplay, setImageDisplay] = useState([]);
  const [AlertSnack, setAlertSnack] = useState(false);
  const [logView, setLogView] = useState(false);
  const containerRef = useRef(null);
  const [validationAlert, setValidationAlert] = useState({});
  const [resetToDraftShowBtn, setResetToDraftShowBtn] = useState(false);
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
  });
  const [showpurchaseOrderConfirm, setshowpurchaseOrderConfirm] =
    useState(false);
  const [showAllBtn, setShowAllBtn] = useState(false);

  const [savePurchaseOrderDetails, setsavePurchaseOrderDetails] = useState({});
  const [handlePaymentTermsbtn, setHandlePaymentTermsbtn] = useState("");
  const [openPurchaseHistory,setOpenPurchaseHistory]=useState(false)
  const [rowId,setRowId]=useState()
  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      message: "",
    });
  };

  const closeAlertSnackbar = () => {
    setAlertSnack(false);
  };

  //constructor formData
  const formData = new FormData();

  const [log, setLog] = useState([]);

  const formInitialState = {
    name: "###",
    vendor: null,
    branch: branchDetails,
    quotationDate: today,
    billDate: "",
    paymentReference: "",
    accountingDate: "",
    journal: null,
    product: null,
    Description: "",
    currency: currencyDetails,
    account: null,
    qty: "",
    // uom: "",
    uom: null,
    unitPrice: "",
    taxes: null,
    discount: "",
    subTotal: "",
    dueDate: "",
    visibleOnly: false,
    itemTotal: 0,
    taxAmt: 0,
    payterms: "",
    expireDate: "",
    customerNote: "",
    termsAndConditions: "",
    deliveryTerms: "",
    debitNoteCount: "",
    grnCount: "",
    vendorCount: "",
    status: "",
    lpoNo:"",
  };

  const [formValues, setFormValues] = useState(formInitialState);
  const [generatedName, setGeneratedName] = useState("###");

  const productMenuClick = () => {
    document.getElementById("productMenuBtn").classList.add("active");
    document.getElementById("departmentMenuBtn").classList.remove("active");
    setIsActiveTable("invoiceLines");
  };

  const departmentMenuClick = () => {
    document.getElementById("productMenuBtn").classList.remove("active");
    document.getElementById("departmentMenuBtn").classList.add("active");
    setIsActiveTable("others");
  };

  const [totalsInfo, setTotalsInfo] = useState({
    subTotal: 0.0,
    inclusiveTax: 0.0,
    exclusiveTax: 0.0,
    total: 0.0,
  });
  const [addRowScroll, setAddRowScroll] = useState(false);
  const addRow = () => {
    if (addRowInput === "Add Header") {
      setTableData([
        ...tableData,
        { type: "header", id: Date.now(), value: "" },
      ]);
    } else if (addRowInput === "Add Line") {
      let lastRow=tableData[tableData.length-1]
      if(lastRow?.product===null){
        dispatch(setSnackMsg("Fill all the required fields in the last row !!"))
        dispatch(setErrorSnack(true))
      }else{
      setTableData([
        ...tableData,
        {
          type: "lines",
          id: Date.now(),
          product: null,
          description: "",
          uom: null,
          qty: "",
          unitPrice: "",
          discount: "",
          tax: [],
          amount: "",
          inclusive: "",
        },
      ]);
    }
  }
    setAddRowScroll(!addRowScroll);
  };



  const handleDelete = (index) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };

  const changeImage = (e) => {
    setImage([...image, e.target.files[0]]);
    setImageDisplay([...imageDisplay, URL.createObjectURL(e.target.files[0])]);
  };

  const removeImageFn = (i) => {
    image.splice(i, 1);
    imageDisplay.splice(i, 1);
    setImage([...image]);
    setImageDisplay([...imageDisplay]);
  };

  const clickLogView = () => {
    setLogView(!logView);
  };

  const getFormInfo = (key) => (e, newValue) => {
    const { value } = e.target;
    const keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      setValidationAlert({});
      setFormValues({
        ...formValues,
        [key]: newValue !== null ? newValue : null,
      });
    } else {
      setValidationAlert({});
      setFormValues({
        ...formValues,
        [key]:
          value !== 0 && value !== undefined
            ? value
            : newValue !== null
            ? newValue
            : null,
      });
    }
  };

  // handleInputChange

  const handleInputChange = (id, field) => (e, newValue) => {
    const { value } = e.target;
    const updatedData = tableData.map((row, index) => {
      if (index === id) {
        if (field === "product") {
          setRowIndex(index);
          let customerTax=newValue?.vendorTax || []
          let taxObjects=allTaxList?.filter(obj => customerTax.includes(obj._id))

          let selectedTaxType=null
          if(taxObjects.length!==0){
            selectedTaxType=taxObjects[0]?.includedPrice
          }  
          const newFilteredTaxOptions =selectedTaxType !== null? allActiveTaxList?.filter((option) => option.includedPrice === selectedTaxType)
              : allActiveTaxList;
          filteredTaxOptions[index] = newFilteredTaxOptions;

          return {
            ...row,
            [field]: newValue,
            description: newValue?.productName || "",
            uom: findObjFromList("_id", uomSubList, "purchaseuom", newValue),
            qty:newValue !== null ? 1 : "",
            unitPrice: newValue?.cost || 0,
            discount: newValue !== null ? 0 : "",
            tax:taxObjects,
            amount:newValue?.cost,
            inclusive: selectedTaxType !== null ? selectedTaxType : "",
          };
        } else if (field === "tax") {
          const selectedTaxId =
            newValue?.length !== 0 ? newValue[0]?.includedPrice : undefined;
          const newFilteredTaxOptions =
            selectedTaxId !== undefined
              ? allActiveTaxList.filter(
                  (option) => option.includedPrice === selectedTaxId
                )
              : allActiveTaxList;
          filteredTaxOptions[index] = newFilteredTaxOptions;
          return {
            ...row,
            tax: newValue,
            inclusive: selectedTaxId !== undefined ? selectedTaxId : "",
          };
        } else if (field === "qty") {
          let totalAmt = 0;
          let totalItemAmt =
            Number(value)?.toFixed(decimalPosition) * Number(row?.unitPrice);
          let discount = (Number(row?.discount) / 100) * totalItemAmt;

          totalAmt = totalItemAmt - discount;
          return { ...row, [field]: e.target.value, amount: totalAmt };
        } else if (field === "unitPrice") {
          let totalAmt = 0;
          let totalItemAmt =
            Number(value)?.toFixed(decimalPosition) * Number(row?.qty);
          let discount = (Number(row?.discount) / 100) * totalItemAmt;

          totalAmt = totalItemAmt - discount;
          return { ...row, [field]: e.target.value, amount: totalAmt };
        } else if (field === "discount") {
          let totalAmt = 0;
          let totalItemAmt = Number(row?.qty) * Number(row?.unitPrice);
          let discount =
            (Number(value)?.toFixed(decimalPosition) / 100) * totalItemAmt;

          totalAmt = totalItemAmt - discount;
          return { ...row, [field]: e.target.value, amount: totalAmt };
        } else {
          return { ...row, [field]: value === 0 ? newValue : e.target.value };
        }
      }
      return row;
    });
    calculateTax(updatedData)
  };


   // tax calculated from general function
  const calculateTax = (array) => {
    let taxUpdatedTable = taxCalculationForLines(array)
    setTableData(taxUpdatedTable)
  }
  //handleMouseEnter
  const handleMouseEnter = () => {
    const updatedArray = roundNumericFields(tableData, [
      "qty",
      "unitPrice",
      "discount",
      "amount",
    ]);
    setTableData(updatedArray);
  };
  // Navigator
  const navigateToGrn = () => {
    navigateTo("/userdashboard/purchaseManager/GoodsReceivedNote/add");
  };

  const PurchaseOrder_ConfirmBody = {
    purchaseOrderId: savePurchaseOrderDetails?._id || singleViewID,
    postDate: `${today} ${getCurrentTime()}`,
  };

  // confirmPurchaseOrderbtnFun
  const confirmPurchaseOrderBtnFun = () => {
    confirmPurchasOrderNewApiCall(
      PurchaseOrder_ConfirmBody,
      setSnackBarStates,
      snackBarStates,
      navigateToGrn,setLoadingForm
    );
  };

  // Save BtnFun
  const SavePurchaseOrderFun = (key) => () => {
    dispatch( setFilterActive(false))
    formData.append("supplierId", formValues.vendor?._id);
    formData.append("branchId", formValues.branch?.storeCode);
    formData.append("quotationDate", formValues.quotationDate);
    formData.append("currency", formValues?.currency?._id);
    //ProductInfo
    formData.append("customerNote", formValues?.customerNote);
    formData.append("termsAndConditions", formValues?.termsAndConditions);
    formData.append(
      "payTerms",
      formValues?.payterms === "" ? '' : formValues?.payterms?._id
    );
    formData.append(
      "expiryDate",
      formValues?.expireDate === "" ? null : formValues?.expireDate
    );
    formData.append("deliveryTerms", formValues?.deliveryTerms);
    formData.append("postDate", `${today} ${getCurrentTime()}`);
    formData.append("total", totalsInfo?.total?.toFixed(decimalPosition));
    formValues?.lpoNo!=="" && formData.append("LpoNo", formValues?.lpoNo);
   
    image.forEach((file, index) => {
      formData.append("file", file);
    });
    formData.append("rfqType", 2); //1 for adding rfq and 2 for purchase order
    const productInfo = tableData?.filter((obj)=>obj.product!==null).map((item) => {
      if (item.type === "header") {
        return {
          line_type: item.type,
          label: item?.value,
        };
      } else {
        return {
          line_type: item.type,
          _id: item.product?._id,
          mainCatgeoryId: item?.product?.mainCatgeoryId,
          product: item?.product?.productName,
          label: item?.description,
          uomName: item?.product?.uomName,
          uom: item?.product?.uom,
          purchaseUomName: item?.uom?.uomName,
          purchaseuom: item?.uom?._id,
          qty: Number(item?.qty),
          posCat: item?.product?.poscat,
          unitPrice: Number(item?.unitPrice),
          cost: "",
          discount: item?.discount,
          includedPrice: item?.tax[0] ? item?.tax[0]?.includedPrice : null,
          taxes: item?.taxSplitted,
          taxIdList:item?.taxIdList,
          prodType:item?.prodType || item?.product?.prodType,
          tracking:item?.tracking || item?.product?.tracking

        };
      }
    });

    formData.append("productInfo", JSON.stringify(productInfo));

    if (key === "draft") {
      if (
        formValues?.vendor?._id === null ||
        formValues?.vendor?._id === undefined
      ) {
        setValidationAlert({
          ...validationAlert,
          vendor: "fill this field !!",
        });
      } else if (
        formValues.branch?.storeCode === null ||
        formValues.branch?.storeCode === undefined
      ) {
        setValidationAlert({
          ...validationAlert,
          branch: "fill this field !!",
        });
      } else if (
        formValues?.quotationDate === null ||
        formValues?.quotationDate === undefined ||
        formValues?.quotationDate === ""
      ) {
        setValidationAlert({
          ...validationAlert,
          quotationDate: "fill this field !!",
        });
      } else if (
        formValues?.currency?._id === null ||
        formValues?.currency?._id === undefined
      ) {
        setValidationAlert({
          ...validationAlert,
          currency: "fill this field !!",
        });
      } else {
        SavePurchaseOrderNewApiCall(
          formData,
          setSnackBarStates,
          snackBarStates,
          setshowpurchaseOrderConfirm,
          setsavePurchaseOrderDetails,
          sethideSaveBtn,
          setDisabledInput,setLoadingForm
        );
      }
    } else if (key === "edit") {
      formData.append(
        "_id",
        PurchaseOrderSingleList?._id || savePurchaseOrderDetails?._id
      );
      NewUpdatePOAPiCall(
        formData,
        setSnackBarStates,
        snackBarStates,
        setEditClicked,
        setDisabledInput,
        setshowpurchaseOrderConfirm,
        setsavePurchaseOrderDetails,setLoadingForm
      );
    }
  };
  //console.log(formValues?.vendorid);
  const grnCountOne = () => {
    localStorage.removeItem("singleViewById");
    localStorage.setItem("singleViewPoById", formValues?.grnid);
    localStorage.setItem("sequence", PurchaseOrderSingleList?.sequence);
   navigateTo("/userdashboard/purchaseManager/GoodsReceivedNote/SingleView");
//console.log(PurchaseOrderSingleList?.sequence);
  };

  const grnList = () => {
    localStorage.removeItem("sequence")
    navigateTo("/userdashboard/purchaseManager/GoodsReceivedNote");
  };
  const vendorCountOne = () => {
    localStorage.removeItem("singleViewById");
    localStorage.setItem("singleVendorById", formValues?.vendorid);
    navigateTo("/userdashboard/accounts/vendor/SingleView");
  };
  const vendorList = () => {
    navigateTo("/userdashboard/accounts/vendor/vendorBills");
  };

  //edit Btn fun
  const editBtnFun = () => {
    setEditClicked(true);
    setDisabledInput(false);
    setshowpurchaseOrderConfirm(false);
  };

  //resetToDraft
  const resetToDraft = () => {
    resetToDraftApiCall(
      savePurchaseOrderDetails,
      setSnackBarStates,
      snackBarStates,
      setsavePurchaseOrderDetails,
      setResetToDraftShowBtn,
      setShowAllBtn,setLoadingForm
    );
  };

  //purchaseOrderCancelBtn
  const purchaseOrderCancelBtn = () => {
    PoCancelAPiCall(
      savePurchaseOrderDetails,
      setSnackBarStates,
      snackBarStates,
      setsavePurchaseOrderDetails,
      setShowAllBtn,
      setResetToDraftShowBtn,setLoadingForm
    );
  };

  const openPurchaseHistoryFn=(item)=>{
    setRowId(item);
    setOpenPurchaseHistory(true)
  }

  



  //singleView of PO (start)
  useEffect(() => {
    if (PurchaseOrderSingleList !== undefined  && VendorList!==undefined && allBranchesList!==undefined) {
      setGeneratedName(PurchaseOrderSingleList?.sequence);
      setLog(PurchaseOrderSingleList?.log || []);
      setFormValues({
        ...formValues,
        vendor: findObjFromList(
          "name",
          VendorList,
          "supplierName",
          PurchaseOrderSingleList
        ),
        branch: findObjFromList(
          "_id",
          allBranchesList,
          "branchId",
          PurchaseOrderSingleList
        ),
        quotationDate: PurchaseOrderSingleList?.quotationDate,
        currency: findObjFromList(
          "_id",
          currencyListUpdated,
          "currency",
          PurchaseOrderSingleList
        ),
        name: PurchaseOrderSingleList?.sequence,
        customerNote: PurchaseOrderSingleList?.customerNote,
        termsAndConditions: PurchaseOrderSingleList?.termsAndConditions,
        payterms: findObjFromList(
          "_id",
          paymentTermsList,
          "paymentTerms",
          PurchaseOrderSingleList
        ),
        expireDate: PurchaseOrderSingleList?.expiryDate,
        deliveryTerms: PurchaseOrderSingleList?.deliveryTerms,

        vendorid:
          PurchaseOrderSingleList?.vendorBillCount !== undefined &&
          PurchaseOrderSingleList?.vendorBillCount[0]?._id,
        vendorCount:
          PurchaseOrderSingleList?.vendorBillCount !== undefined &&
          PurchaseOrderSingleList?.vendorBillCount[0]?.totalCount,

        grnid:
          PurchaseOrderSingleList?.grnCount !== undefined &&
          PurchaseOrderSingleList?.grnCount[0]?._id,
        grnCount:
          PurchaseOrderSingleList?.grnCount !== undefined &&
          PurchaseOrderSingleList?.grnCount[0]?.totalCount,
        status: PurchaseOrderSingleList?.type,
        lpoNo:PurchaseOrderSingleList?.LpoNo||"",
      });

      setshowpurchaseOrderConfirm(true);
      sethideSaveBtn(true);
      setDisabledInput(true);

      if (PurchaseOrderSingleList?.type === "DONE") {
        setShowAllBtn(true);
      }
    }else{
      setFormValues(formInitialState)
    }
  }, [PurchaseOrderSingleList,VendorList,uomSubList,allBranchesList,branchDetails]);

  useEffect(() => {
    if (PurchaseOrderSingleList !== undefined && allTaxList!==undefined && allNewProductList!==undefined) {
      const updatedArray = PurchaseOrderSingleList?.productInfo?.map((r, i) => {

        if (r?.line_type === "Line" || r?.line_type === "lines") {
          
          let taxesId=r?.taxIdList || []
          let taxObjects = allTaxList?.filter(obj => taxesId.some(taxObj => taxObj._id === obj?._id));
          const newFilteredTaxOptions=r?.includedPrice !== null? allActiveTaxList?.filter((option) => option.includedPrice === r?.includedPrice)
              : allActiveTaxList;
          filteredTaxOptions[i] = newFilteredTaxOptions;

          return {
            type: r?.line_type,
            product: findObjFromList("_id", allNewProductList, "_id", r),
            description: r?.label,
            uom: findObjFromList("_id", uomSubList, "purchaseuom", r),
            qty: r?.qty,
            unitPrice: r?.unitPrice,
            discount: r?.discount,
            tax: taxObjects,
            amount:
              r?.qty * r?.unitPrice -
              (r?.qty * r?.unitPrice * r?.discount) / 100,
            inclusive: r?.includedPrice,
          };
        } else {
          return {
            type: r?.line_type,
            header: r?.label,
          };
        }
      });
      calculateTax(updatedArray);
    }
  }, [PurchaseOrderSingleList?.productInfo, allNewProductList,allTaxList,uomSubList]);

  // GenerateRFQ and PO name(same Name)
  useEffect(() => {
    if (formValues?.branch !== null && PurchaseOrderSingleList===undefined) {
      GenerateRfqName(formValues?.branch, setGeneratedName);
    }else{
      setGeneratedName("###")
    }
  }, [formValues?.branch]);




  useEffect(() => {
    setTotalsInfo({
      ...totalsInfo,
      subTotal: tableData?.reduce(
        (sum, item) => sum + Number(item?.amount || 0),
        0
      ),
      inclusiveTax: tableData?.reduce(
        (sum, item) => sum + Number(item?.inclusiveTax || 0),
        0
      ),
      exclusiveTax: tableData?.reduce(
        (sum, item) => sum + Number(item?.exclusiveTax || 0),
        0
      ),
      total:
        tableData?.reduce((sum, item) => sum + Number(item?.amount || 0), 0) +
        tableData?.reduce(
          (sum, item) => sum + Number(item?.exclusiveTax || 0),
          0
        ),
    });
  }, [tableData]);

  useEffect(() => {
    if (containerRef.current) {
      setTimeout(() => {
      containerRef.current.scrollIntoView({index:tableData.length,behavior:"smooth",align:"end",behaviour:"smooth"});
      },0);
    }
  }, [addRowScroll]);

  //useEffect
  useEffect(() => {
    userRole==="user"&& viewAllAllowedBranchesAPICall({ employeeId: userInfo?.data?._id });

    viewMultiCurrencyConfiguration();
    getAllTaxSettingsListAPICall();
    viewUOMSubListAPICall();
    if (singleViewID) {
      viewPurchaseOrderSingleView({ _id: singleViewID });
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const shouldShowAlert = !disabledInput;
      if (shouldShowAlert) {
        event.preventDefault();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [disabledInput]);

  const filterOptions = createFilterOptions({
    stringify: (option) => option.productName + option.partNo,
  });

  

 

  return (
    <div className="global-page-parent-container">
      <div
        className="new-global-white-bg-container"
        style={{ padding: "0 2%" }}
      >
        <div className="new-global-white-bg-icon-container">
          <div className="new-global-white-bg-icon-container-left">
        
          <Tooltip title="Back">
              <IconButton
                onClick={() => {
                  if (disabledInput === false) {
                    const userConfirmed = window.confirm(
                      "Changes you made may not be saved."
                    );

                    if (userConfirmed) {
                      navigateTo(
                        "/userdashboard/purchaseManager/NewPurchaseOrder"
                      );
                    }
                  } else {
                    navigateTo(
                      "/userdashboard/purchaseManager/NewPurchaseOrder"
                    );
                  }
                }}
              >
                <i class="bi bi-chevron-left"></i>
              </IconButton>
            </Tooltip>
            {showAllBtn === false && (
              <>
                {RfqEdit ? (
                  <Tooltip title="Edit">
                    <IconButton>
                      <i class="bi bi-pencil-square edit-icon1"></i>
                    </IconButton>
                  </Tooltip>
                ) : hideSaveBtn ? (
                  <Tooltip title="Edit">
                    <IconButton>
                      {editClicked ? (
                        <i
                          class="bi bi-floppy save-icon"
                          style={{ fontSize: "1.5rem" }}
                          onClick={SavePurchaseOrderFun("edit")}
                        ></i>
                      ) : (
                        <i
                          class="bi bi-pencil-square edit-icon1"
                          onClick={editBtnFun}
                        ></i>
                      )}
                    </IconButton>
                  </Tooltip>
                ) : (
                  ""
                )}

                {hideSaveBtn ? (
                  ""
                ) : (
                  <Tooltip title="Save">
                    <IconButton onMouseEnter={handleMouseEnter}>
                      <i
                        class="bi bi-floppy save-icon"
                        onClick={SavePurchaseOrderFun("draft")}
                      ></i>
                    </IconButton>
                  </Tooltip>
                )}

                {showpurchaseOrderConfirm && (
                  <>
                    <button
                      className="btn btn-post"
                      style={{
                        color: "#131010",
                        backgroundColor: "white",
                        border: "2px solid black",
                      }}
                      onClick={purchaseOrderCancelBtn}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-post"
                      onClick={confirmPurchaseOrderBtnFun}
                    >
                      Confirm
                    </button>
                  </>
                )}
              </>
            )}
            {resetToDraftShowBtn && (
              <button className="btn btn-post" onClick={resetToDraft}>
                Reset To draft
              </button>
            )}
          </div>

          <div className="status-container">
            {savePurchaseOrderDetails?.type === "PURCHASE ORDER" && (
              <img src={Po} alt="" />
            )}
            {savePurchaseOrderDetails?.type === "CANCEL" && (
              <img src={cancel} alt="" />
            )}

            {formValues?.status === "DONE" && <img src={done} alt="" />}
          </div>
          {PurchaseOrderSingleList !== undefined &&
            formValues?.status === "DONE" && (
              <div className="po-btn-container">
                {formValues?.vendorCount >= 1 && (
                  <div
                    className="po-debit-button"
                    style={{ backgroundColor: " #f7e0d8" }}
                    onClick={
                      formValues?.vendorCount === 1
                        ? vendorCountOne
                        : vendorList
                    }
                  >
                    <img src={vendor} alt={""}></img>
                    <p>Vendor bill</p>
                    <p>{formValues?.vendorCount}</p>
                  </div>
                )}
                {formValues?.grnCount >= 1 && (
                  <div
                    className="po-debit-button"
                    style={{ backgroundColor: " #cdf3e0" }}
                    onClick={formValues?.grnCount === 1 ? grnCountOne : grnList}
                  >
                    <img src={grn} alt={""}></img>
                    <p>GRN</p>
                    <p>{formValues?.grnCount}</p>
                  </div>
                )}
              </div>
            )}
        </div>
        <hr className="global-hr" />
        <h4 className="global-name">PurchaseOrder / {generatedName}</h4>
        <div className="new-global-input-container">
          <div className="new-global-single-input ">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={VendorList || []}
              getOptionLabel={(option) => option?.name}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  onChange={(e) => vendorListApi({ search: e.target.value })}
                  {...params}
                  label="Vendor*"
                  focused
                />
              )}
              onChange={getFormInfo("vendor")}
              value={formValues?.vendor}
              disabled={disabledInput}
            />
            <p className="doc-validation-alert">{validationAlert?.vendor}</p>
          </div>
          <div className="new-global-single-input">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={userRole==="admin" ? allBranchesList: allowedBranchList || []}
              getOptionLabel={(option) =>
                `${option?.storeCode}-${option?.branchName}`
              }
              isOptionEqualToValue={(option, value) => option._id === value._id}

              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Branch*" focused />
              )}
              onChange={getFormInfo("branch")}
              value={formValues.branch}
              disabled={disabledInput}
            />

            <p className="doc-validation-alert">{validationAlert?.branch}</p>
          </div>
          <div className="new-global-single-input">
            <TextField
              id="outlined-basic"
              label="Quotation Date*"
              variant="outlined"
              type="date"
              focused
              disabled={disabledInput}
              value={formValues?.quotationDate}
              onChange={getFormInfo("quotationDate")}
            />
            <p className="doc-validation-alert">
              {validationAlert?.quotationDate}
            </p>
          </div>
          <div className="new-global-single-input">
            {MultiCurrencyView !== undefined &&
            MultiCurrencyView?.isMultiCurrency ? (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={currencyActive || []}
                getOptionLabel={(option) => option?.name}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Currency*" focused />
                )}
                value={formValues?.currency}
                onChange={getFormInfo("currency")}
                disabled={disabledInput}
              />
            ) : (
              <TextField
                value={formValues?.currency?.name || ""}
                sx={{ caretColor: "transparent" }}
                className="disabled-input"
                id="outlined-basic"
                label="Currency*"
                variant="outlined"
                type="text"
                focused
                title="Multi Currency not enabled"
              />
            )}

            <p className="doc-validation-alert">{validationAlert?.currency}</p>
          </div>
          <div
              className="new-global-single-input "
              style={{ width: "22%" }}
            >
              <div className="" style={{ position: "relative" }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={paymentTermsList || []}
                  getOptionLabel={(option) => option?.paymentTerm}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Payment Terms*" focused />
                  )}
                  value={formValues?.payterms || null}
                  onChange={getFormInfo("payterms")}
                  disabled={disabledInput}
                />
                {!disabledInput && (
                  <button
                    onClick={() => {
                      setFormValues((prev) => ({
                        ...prev,
                        payterms: null,
                      }));
                      setHandlePaymentTermsbtn(!handlePaymentTermsbtn);
                    }}
                    className="add-row-btn autocomplete-inside-plusbtn"
                  >
                    +
                  </button>
                )}
              </div>
            </div>
            <div className="new-global-single-input">
              <TextField
                value={formValues?.lpoNo}
                onChange={getFormInfo("lpoNo")}
                id="outlined-basic"
                label="LPO No."
                variant="outlined"
                type="text"
                focused
                disabled={disabledInput}
              />
            </div>
            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label="Expire Date*"
                variant="outlined"
                type="date"
                focused
                value={formValues?.expireDate}
                onChange={getFormInfo("expireDate")}
                disabled={disabledInput}
              />
            </div>
        </div>
      </div>
      <div className="new-global-white-bg-container">
        <div
          className="rewards-add-rewards-ctn-top-menu accounts-table-toggle"
          style={{ marginBottom: "1%" }}
        >
          <div
            className="tab-menu-button active"
            id="productMenuBtn"
            onClick={productMenuClick}
          >
            <Typography fontSize={"0.9rem"} fontWeight={600}>
              Invoice Lines
            </Typography>
          </div>
          <div
            className="tab-menu-button"
            id="departmentMenuBtn"
            onClick={departmentMenuClick}
          >
            <Typography fontSize={"0.9rem"} fontWeight={600}>
              Other Info
            </Typography>
          </div>
        </div>
        {isActiveTable === "invoiceLines" ? (
          <>
            <TableVirtuoso
                    ref={containerRef}
                    className="global-product-table"
                    style={{ height: tableData?.length === 1 ? "10vh" : tableData?.length < 5 ? `${(tableData?.length) * 7}vh` : "25vh", maxHeight: "24vh" }}
                    data={tableData}
                    fixedHeaderContent={() => (
                  <tr>
                    <th>Product</th>
                    <th>Description</th>
                    <th>UOM</th>
                    <th>Qty</th>
                    <th>Unit Price</th>
                    <th>Discount</th>
                    <th>Tax</th>
                    <th>Amount</th>
                  </tr>
                    )}

                 itemContent={(index, item) => (
                    <>
                      {item.type === "header" ? (
                        <>
                          <td colSpan="8">
                            <input
                              type="text"
                              //  value={item.value}
                              onChange={handleInputChange(index, "value")}
                            />
                          </td>
                          <td style={{ border: "none", width: "4%" }}>
                            <IconButton
                              onClick={() => handleDelete(index)}
                              style={{ height: "30px" }}
                            >
                              <i
                                className="bi bi-trash3"
                                style={{ color: "#db0000", fontSize: "1.3rem" }}
                              ></i>
                            </IconButton>
                          </td>
                        </>
                      ) : (
                        <>
                          <td style={{ minWidth: "180px" }}>
                            <div className="product-table-auto-complete">
                              <Autocomplete
                                options={allNewProductList || []}
                                style={{ maxHeight: "100px" }}
                                getOptionLabel={(option)=>option?.productName}

                                renderOption={(props, option) => (
                                  <li {...props} key={option._id}>
                                    <span>{option.productName}</span>
                                  </li>
                                )}
                               
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseEnter}
                                  />
                                )}
                                disabled={disabledInput}
                                value={item?.product}
                                onChange={handleInputChange(index, "product")}
                                filterOptions={filterOptions}
                              />
                            </div>
                          </td>
                          <td style={{ minWidth: "100px" }}>
                            <div className="global-product-table-input-icon">
                                 {
                                    item?.product !== null && 
                                    <img onClick={()=>openPurchaseHistoryFn(item)} src={purchasehistoryicon} alt="" title="Purchase History"/>
                                    
                                  }
                                   <input
                                     type="text"
                                     onChange={handleInputChange(index, "description")}
                                    value={item?.description}
                                    onMouseEnter={handleMouseEnter}
                                   onMouseLeave={handleMouseEnter}
                            />
                            </div>
                           
                          </td>
                          <td style={{  minWidth: "50px"  }}> 
                            <div className="product-table-auto-complete">
                              <Autocomplete
                               className="uom-autocomplete"
                                options={uomSubList?.filter((obj)=>obj?.parentCatId === (item?.product?.parentUomId)) || []}
                                style={{ maxHeight: "100px" }}
                                getOptionLabel={(option) => option?.uomName}
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseEnter}
                                  />
                                )}
                                disabled={disabledInput}
                                value={item?.uom}
                                onChange={handleInputChange(index, "uom")}
                              />
                            </div>
                          </td>
                          <td style={{ maxWidth: "90px" }}>
                            <input
                              type="text"
                              onChange={handleInputChange(index, "qty")}
                              value={item?.qty}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseEnter}
                              disabled={disabledInput}
                              onFocus={(e)=>e.target.select()}
                            />
                          </td>
                          <td style={{ maxWidth: "90px" }}>
                            <input
                              type="text"
                              onChange={handleInputChange(index, "unitPrice")}
                              value={item?.unitPrice}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseEnter}
                              disabled={disabledInput}
                              onFocus={(e)=>e.target.select()}
                            />
                          </td>
                          <td style={{width: "30px"}}>
                            <input
                              type="text"
                              onChange={handleInputChange(index, "discount")}
                              value={item?.discount}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseEnter}
                              onFocus={(e)=>e.target.select()}
                              disabled={disabledInput}
                            />
                          </td>
                          <td style={{maxWidth:"245px",minWidth:item?.tax.length===0?"90px":item?.tax.length===1?"200px":`265px` }}>

                            <div className="product-table-auto-complete">
                              <Autocomplete
                                className="multiple-tax-autocomplete"
                                multiple
                                options={filteredTaxOptions[index] || []}
                                getOptionLabel={(option) => option?.taxName}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                value={item.tax}
                                disabled={disabledInput}
                                onChange={handleInputChange(index, "tax")}
                              />
                            </div>
                          </td>
                          <td style={{ width:`${String(item?.amount).length * 9}px`,minWidth:"40px"}}>
                            <input
                              type="text"
                              value={item?.amount}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseEnter}
                              disabled={disabledInput}
                            />
                          </td>
                          {
                          !disabledInput &&  
                          <td style={{ border: "none", width: "4%" }}>
                            <IconButton
                              onClick={() => handleDelete(index)}
                              style={{ height: "30px" }}
                              disabled={disabledInput}
                            >
                              <i
                                className="bi bi-trash3"
                                style={{ color: "#db0000", fontSize: "1.3rem" }}
                              ></i>
                            </IconButton>
                          </td>}
                        </>
                      )}
                    </>
                  )}
              />
            {
            !disabledInput &&  
            <div className="add-row-btn-container">
              <div
                className="global-single-input auto-complete"
                style={{ width: "76%" }}
              >
                <Autocomplete
                  options={addRowData || []}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => <TextField {...params} />}
                  value={addRowInput}
                  disabled={disabledInput}
                  onChange={(e, newValue) => setAddRowInput(newValue)}
                />
              </div>
              <button
                onClick={() => addRow()}
                className="add-row-btn"
                disabled={disabledInput}
              >
                +
              </button>
            </div>}
          </>
        ) : (
          <div className="new-global-input-container">
            <div className="new-global-single-input" style={{ width: "35%" }}>
              <p>Delivery Terms*</p>
              <textarea
                name=""
                id=""
                cols="50"
                rows="7"
                onChange={getFormInfo("deliveryTerms")}
                value={formValues?.deliveryTerms}
                disabled={disabledInput}
              ></textarea>
            </div>
          </div>
        )}
      </div>

      {isActiveTable === "invoiceLines" && (
        <div
          className="new-global-white-bg-container"
          style={{
            display: "flex",
            flexWrap: "wrap",
            padding: "8px 2%",
          }}
        >
          <div className="new-global-single-input" style={{ width: "35%" }}>
            <p>Customer Notes</p>
            <textarea
              name=""
              id=""
              cols="50"
              rows="5"
              onChange={getFormInfo("customerNote")}
              value={formValues?.customerNote}
              disabled={disabledInput}
            ></textarea>
          </div>

          <div className="new-global-single-input" style={{ width: "35%" }}>
            <p>Terms & Conditions</p>
            <textarea
              name=""
              id=""
              cols="50"
              rows="5"
              disabled={disabledInput}
              onChange={getFormInfo("termsAndConditions")}
              value={formValues?.termsAndConditions}
            ></textarea>
          </div>
          <div
            className="new-global-single-input"
            style={{ maxWidth: "250px" }}
          >
            <div className="subtotal-ctn">
              <div className="subtotal-ctn-left">
                <p>Sub Total</p>
                {totalsInfo?.inclusiveTax !== 0 && <p>Inclusive Tax</p>}

                {totalsInfo?.exclusiveTax !== 0 && <p>Exclusive Tax</p>}
              </div>
              <div className="subtotal-ctn-right">
                <p>{totalsInfo?.subTotal?.toFixed(decimalPosition)}</p>
                {totalsInfo?.inclusiveTax !== 0 && (
                  <p>{totalsInfo?.inclusiveTax?.toFixed(decimalPosition)}</p>
                )}
                {totalsInfo?.exclusiveTax !== 0 && (
                  <p>{totalsInfo?.exclusiveTax?.toFixed(decimalPosition)}</p>
                )}
              </div>
            </div>
            <hr
              className="global-hr"
              style={{ width: "80%", marginLeft: "25%" }}
            />
            <div className="subtotal-ctn">
              <div className="subtotal-ctn-left">
                <p style={{ color: "#000", fontWeight: "600" }}>Total</p>
              </div>
              <div className="subtotal-ctn-right">
                <p>{totalsInfo?.total?.toFixed(decimalPosition)} </p>
              </div>
            </div>
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", width: "60%" }}
          >
            <div className="file-picker-new">
              <input type="file" id="fileUpload" onChange={changeImage} />
              <label htmlFor="fileUpload">
                <img src={uploadFile} alt="" />
                Upload File
              </label>
            </div>
            <div style={{ display: "flex", margin: "0 0 0 22px" }}>
              {imageDisplay?.map((r, i) => (
                <div className="uploaded-image-list">
                  <img src={r} alt="" />
                  <div className="image-remove-icon-new">
                    <IconButton onClick={() => removeImageFn(i)}>
                      <CloseOutlined
                        sx={{ color: "#fd0301", fontSize: "0.9rem" }}
                      />
                    </IconButton>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <div
        className="new-global-white-bg-container new-log-container"
        style={{ padding: "4px" }}
      >
        <div
          style={{ padding: "8px", cursor: "pointer" }}
          onClick={clickLogView}
        ></div>
        <div
          className={logView ? "pointer-up" : "pointer"}
          onClick={clickLogView}
          style={{ cursor: "pointer" }}
        ></div>
        {logView && (
          <div className="single-log-container">
            {log?.map((r, i) => (
              <div className="single-log-item">
                <div className="single-log-item-line-image">
                  <hr className="single-log-item-line" />
                  <div className="single-log-item-image">
                    <img src={logoImage} alt="" />
                  </div>
                  <hr className="single-log-item-line" />
                </div>
                <p className="heading">{r?.status}</p>
                <p className="name">{r?.empId}</p>
                <p className="date-time">{r?.date}</p>
                <p className="date-time">{r?.time}</p>
              </div>
            ))}
          </div>
        )}

      </div>
      <PaymentTerms
        handleDialog={handlePaymentTermsbtn}
        setHandleDialog={setHandlePaymentTermsbtn}
      />
      <SuccessSnackbar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />

      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />

      <ErrorSnackbar
        style={{ top: "8%", left: "34%", height: "20px", width: "35%" }}
        open={AlertSnack}
        handleClose={closeAlertSnackbar}
        message={"Select Expiry Date !! on other info!!"}
      />
      <LoadingForm loading={loadingForm}/>
      <Dialog open={openPurchaseHistory} maxWidth="auto" onClose={()=>setOpenPurchaseHistory(false)}>
         <div>
            <PurchaseHistory
               closeDialog={()=>setOpenPurchaseHistory(false)}
               item={rowId}
               historyApiCall={poHistoryAPICall}
               name="Purchase Order"
               response={poHistoryData}
               orderno={generatedName}
               type="purchase"
               formValues={formValues?.vendor}
            />
         </div>
      </Dialog>

    </div>
  );
};
