import React, { useEffect, useState } from 'react'
import "./posShift.css"
import ReactToPrint from 'react-to-print'
import { IconButton, Tooltip } from '@mui/material'
import { useSelector } from 'react-redux';
import CategorySearchAndFilter from "../../../../Single Components/CategorySearchandFilter"
import CustomPagination from '../../../../Single Components/CustomPagination';
import { useNavigate } from 'react-router-dom';
import { ListPosShiftAPI } from '../GeneralAPI';
import { getCurrentTime } from '../../../../../Js/Date';
function PosShift() {
  
  const navigate=useNavigate()
  const [FormDataInfo, setFormDataInfo] = useState([])
  const userRole = useSelector((state) => state.userRoleSlice.value);

    const handleDataFromChild = (data) => {
        setFormDataInfo(data)
        console.log(data);
    };
    const [currentPage, setCurrentPage] = useState(1);
 
    const handlePageChange = (page) => {
    
      setCurrentPage(page);
    };

const shiftList=useSelector((state)=>state.generalSlice.shiftList)
const totalPages = shiftList?.pages; 


    const singleRowClick=(row)=>{
      localStorage.setItem("singleViewId",row._id)
      navigate("/userdashboard/pointOfSales/general/shiftSingleView")
    }
    useEffect(()=>{
      if (FormDataInfo.length !== 0) {
      ListPosShiftAPI({
        fromDate: FormDataInfo.fromDate === '' ? undefined : `${FormDataInfo.fromDate} ${getCurrentTime()}`,
        endDate: FormDataInfo.toDate === '' ? undefined : `${FormDataInfo.toDate} ${getCurrentTime()}`,
        branchId: FormDataInfo?.branchList?.length === 0 ? undefined:FormDataInfo?.branchList,
        status: FormDataInfo?.status?.length === 0 ? undefined : FormDataInfo?.status[0],
        search: FormDataInfo?.search===''? undefined : FormDataInfo?.search,
        orderType: FormDataInfo?.Ordertype ==='' ? undefined : FormDataInfo?.Ordertype,
        index:currentPage-1})}
    },[FormDataInfo,currentPage])

    return (
    <div className='global-page-parent-container'>
        <div
        style={{padding:"0 1%"}}
        className="justify-space-between global-white-bg-container">
            <h3 style={{margin:"0"}}>Shift</h3>
        
        <div className="justify-center" style={{gap:"10px",width:"70%",justifyContent:"flex-end"}}>
        <ReactToPrint
          trigger={() => (
            <IconButton className="printer-div" style={{color: "#fff",backgroundColor:"#002995",borderRadius:"0"}}>
              <i class="bi bi-printer"></i>
            </IconButton>
          )}
        //   content={() => paymentsPrint}
        />
        <Tooltip title="Download">
          <IconButton style={{backgroundColor: "#ffd2d2", color: "#002995",borderRadius:"0"}}>
            <i class="bi bi-arrow-down-circle"></i>
          </IconButton>
        </Tooltip>
        <CategorySearchAndFilter
            statusOptions={['Active', 'Ended']}
            onData={handleDataFromChild}
            isStatus={true}
            // isType={true}
            isOrderType={true}
            isBranch={userRole==="admin"}
        />
        </div>
        </div>

        
        <div className="global-white-bg-container" style={{paddingTop:"0"}}>
            <div className="justify-center" style={{justifyContent:"flex-end"}}>
                <CustomPagination
                 
                 currentPage={currentPage}
                 totalPages={totalPages}
                 onPageChange={handlePageChange}
                
               />
            </div>
            <div className="new-global-table-container">
            <table>
            <thead>
              <tr>
               
                <th>Shift No</th>
                <th>Branch</th>
                <th>Employee</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
                {shiftList?.list!==undefined ?
                   shiftList?.list?.map((r,i)=>(
                        <tr key={i} onClick={()=>singleRowClick(r)}>
                            
                            <td>{r.shiftNo}</td>
                            <td>{r.branchName}</td>
                            <td>{r.employee}</td>
                            <td>{r.startDate}</td>
                            <td>{r.endDate}</td>
                            <td className="table-data-box">
                                {r?.status === "DRAFT" ? (
                                    <span className="draft"> DRAFT</span>
                                ) :r?.status === "Active" ? (
                                    <span className="post"> Active</span>
                                ) : r?.status === "Ended" ? (
                                    <span className="end">ENDED</span>
                                ) : (
                                    <span>{r?.status}</span>
                                )}
                            </td>
                        </tr>
                    )):
                    (<tr>
                      <td colSpan={6}>NO DATA</td>
                      </tr>
                      )
                }
            </tbody>
            </table>
            </div>
        </div>
    </div>
  )
}

export default PosShift