import { createSlice } from "@reduxjs/toolkit";

export const filterButtonSlice=createSlice({
    name:"filterButtonSlice",
    initialState:{
        orderTypeOptions:[],
        filteredOrderType:null,
        statusOptions:[],
        filteredStatus:null,
        applyBtnClicked:false,
    },
    reducers:{
        setOrderTypeOptions:(state,action)=>{
            state.orderTypeOptions=action.payload
        },
        setStatusOptions:(state,action)=>{
            state.statusOptions=action.payload
        },
        setFilteredOrderType:(state,action)=>{
            state.filteredOrderType=action.payload
        },
        setFilteredStatus:(state,action)=>{
            state.filteredStatus=action.payload
        },
        setApplyBtnClicked:(state,action)=>{
            state.applyBtnClicked=action.payload
        }
        
    }
})

export const { setOrderTypeOptions,
    setStatusOptions, setFilteredOrderType, setFilteredStatus,setApplyBtnClicked
        }=filterButtonSlice.actions

export default filterButtonSlice.reducer