import { Autocomplete, Dialog, IconButton, TextField, Tooltip } from '@mui/material';

import React, { useEffect, useState } from 'react'
import { FilePicker } from '../../../../Single Components/filePicker';
import SuccessSnackbar from '../../../../Single Components/SnackBars/SuccessSnackbar';
import ErrorSnackbar from '../../../../Single Components/SnackBars/ErrorSnackbar';
import { useDispatch, useSelector } from 'react-redux';
import countryList from "../../../../../Assets/JSON/countryStates.json"
import "../../../../Modules/SalesNew/SalesOrders/SalesCustomers/salescustomers.css";
import { ViewCustomerGroupListApiCall, ViewPaymentTermsListApiCall, addCustomerGroupApiCall, addPaymentTermsApicall, createVendorAPICall, editVendorAPICall } from '../../../Accounts/Vendor/API/vendorListAPICall';
import { useNavigate } from 'react-router-dom';
import { findObjFromList } from '../../../../../Js/generalFunctions';
import { LoadingForm } from '../../../../Single Components/LoadingForm';
import { getVendorListSingleViewAPIcall } from '../../../Accounts/Vendor/API/vendorListAPICall';
import PaymentTerms from '../../../components/Dialog/paymentTerms/PaymentTerms';
import { viewAllAllowedBranchesAPICall } from '../../../../../API/Settings/Company Settings/companySettingsAPI';
import { setFilterActive } from '../../../../Single Components/listSlice/filterCategorySlice';

export const SalesCustomers = ({ isVendor, isCustomer, isSales ,isPurchase}) => {
  const dispatch = useDispatch();
  let navigateTo = useNavigate();
  const paymentTermsList = useSelector((state) => state.vendorsListSlice?.paymentTermsList);
  const allCustomerList = useSelector((state) => state.vendorsListSlice?.CustomerGroupList)
  const allBranchesList = useSelector((state) => state?.allBranchesSlice?.value);
  const allowedBranchList = useSelector((state) => state.allAllowedBranchesSlice.value);
  
  const editedData = useSelector((state) => state.vendorsListSlice.rowData);
  const {branchDetails} = useSelector((state) => state.mainDashBoardSlice)
  const userInfo = useSelector((state) => state.loginResponseSlice.value);
  const userRole=useSelector((state)=>state.userRoleSlice.value)
  const singleViewId = localStorage.getItem("SingleViewId")

  const countryWithState = countryList.countries;
  const [editClicked, setEditClicked] = useState(false);
  const [editClickedId, setEditClickedId] = useState(false)
  const [vendorImg, setVendorImg] = useState(null)
  const [loadingForm,setLoadingForm]=useState(false)


  const formInitialState = {
    name: "",
    address: "",
    street: "",
    city: "",
    country: null,
    state: null,
    taxId: "",
    phone: "",
    mobile: "",
    email: "",
    paymentTerms: null,
    customerGroup: null,
    supplierCurrency: "",
    contactPerson: "",
    mobilePhoneNo: "",
    companyCode: "",
    branch: branchDetails,
    toggleChecked: true,
    visibleOnly: false,
    isVendor: isVendor,
    isCustomer: isCustomer,
    addPaymentTerms: "",
    addCustomerGroup: ""
  }
  const [formValues, setFormValues] = useState(formInitialState);
  const [isModify, setisModify] = useState(false);


  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
  });

  const [validationAlert, setValidationAlert] = useState({});
  const [paymentTermsDialog, setPaymentTermsDialog] = useState(false);
  const [customerGroupDialog, setCustomerGroupDialog] = useState(false);

  const payloadFormData = new FormData();
  payloadFormData.append("file", vendorImg !== null ? vendorImg : undefined)
  payloadFormData.append("name", formValues.name)
  payloadFormData.append("country", formValues.country?.country)
  payloadFormData.append("state", formValues.state)
  payloadFormData.append("city", formValues.city)
  payloadFormData.append("streetName", formValues.street)
  payloadFormData.append("zipCode", formValues.address)
  payloadFormData.append("taxId", formValues.taxId)

  payloadFormData.append("isVendor", formValues?.isVendor)
  payloadFormData.append("isCustomer", formValues?.isCustomer)


  payloadFormData.append("mobileNo", formValues.mobile)
  payloadFormData.append("email", formValues.email)

  payloadFormData.append("payTerm", formValues?.paymentTerms?._id)
  payloadFormData.append("customerGroup", formValues?.customerGroup?._id)

  payloadFormData.append("supplierCurrency", formValues.supplierCurrency)
  payloadFormData.append("contactPerson", formValues.contactPerson)
  payloadFormData.append("contactPersonMobile", formValues.mobilePhoneNo)
  payloadFormData.append("companyCode", formValues.companyCode)
  payloadFormData.append("branchId", formValues.branch?._id)
  payloadFormData.append("phone", formValues.phone)
  payloadFormData.append("status", formValues?.toggleChecked)





  const postFunction = () => {
      isCustomer && (isSales?navigateTo("/userdashboard/sales/orders/customers") :navigateTo("/userdashboard/accounts/customers/customers"))
      isVendor && (isPurchase? navigateTo("/userdashboard/purchaseManager/vendor/vendors") :navigateTo("/userdashboard/accounts/vendor/vendors"))
  }

    //backButtonFunction
    const backButtonFunction = () => {
      postFunction()
    }

  //submitApiClick
  const submitApiClick = (key) => () => {
    dispatch( setFilterActive(false))
    if (formValues?.name === "") {
      setValidationAlert({ ...validationAlert, name: "enter a name" })
    }
    else if (formValues.mobile === "") {
      setValidationAlert({ ...validationAlert, mobile: "fill this field" })
    }
    else {
      if (key === "create") {
        createVendorAPICall(payloadFormData, postFunction, setSnackBarStates, snackBarStates,setLoadingForm)
      }
      else if(key === 'edit'){
        payloadFormData.append("_id", editClickedId)
        editVendorAPICall(payloadFormData, postFunction, setSnackBarStates, snackBarStates,setLoadingForm)
      }
    }

  }

  //closeSnackbar
  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates, success: false,
      error: false,
      message: "",
    })
  }


  //getFormInfo
  const getFormInfo = (key) => (e, newValue) => {
    const { value } = e.target;
    setValidationAlert({})
    if (key === "country") {
      setFormValues({ ...formValues, country: newValue, state: null })
    }
    else {
      setFormValues({ ...formValues, [key]: value !== 0 ? value : newValue })
    }
  }

  //PaymentTermsbtn
  const PaymentTermsbtn = () => {
    setPaymentTermsDialog(true);
  }
  //CustomerGroupBtn
  const CustomerGroupBtn = () => {
    setCustomerGroupDialog(true)
  }

  //addCustomerGroupSubmitBtn
  const addCustomerGroupSubmitBtn = () => {
    addCustomerGroupApiCall({
      customerGroup: formValues?.addCustomerGroup
    },
      setSnackBarStates, snackBarStates, setisModify, isModify, clearInput,setLoadingForm
    )
  }

  //ClearInput feilds
  const clearInput = () => {
    setFormValues({
      ...formValues, addPaymentTerms: "",
      addCustomerGroup: ""
    })
    setPaymentTermsDialog(false);
    setCustomerGroupDialog(false);
  }




  //viewAllBranchsApiCall & ViewPaymentTermsList
  useEffect(() => {
    ViewPaymentTermsListApiCall();
    ViewCustomerGroupListApiCall();
    userRole==="user"&& viewAllAllowedBranchesAPICall({ employeeId: userInfo?.data?._id });

    singleViewId && getVendorListSingleViewAPIcall({_id: singleViewId})
  }, [])




  //edit

  useEffect(() => {
    if (editedData !== undefined) {

      setEditClicked(true)
      setFormValues({
        ...formValues,
        name: editedData?.name,
        visibleOnly: true,
        address: editedData?.zipCode,
        street: editedData?.streetName,
        city: editedData?.city,
        country: findObjFromList("country", countryWithState, "country", editedData),
        state: editedData?.state,
        taxId: editedData?.gst,
        phone:editedData?.phone,
        mobile: editedData?.mobileNo,
        email: editedData?.email,
        paymentTerms:findObjFromList("_id",paymentTermsList,"payTerm",editedData),
        customerGroup:findObjFromList("_id",allCustomerList,"customerGroup",editedData),
        supplierCurrency: editedData?.supplierCurrency,
        contactPerson: editedData?.contactPerson,
        mobilePhoneNo: editedData?.contactPersonMobile,
        companyCode: editedData?.companyCode,
        branch: findObjFromList("_id", allBranchesList, "branchId", editedData),
        toggleChecked: editedData?.status,

        isVendor: editedData?.isVendor,
        isCustomer: editedData?.isCustomer,

      })
      setEditClickedId(editedData?._id);
      setVendorImg(editedData?.imageUrl);

    } else {
      setEditClicked(false)
      setFormValues(formInitialState)
    }
  }, [editedData, allBranchesList]);


  //edituseEffect End

  


  return (
    <div className="global-page-parent-container">
      <div className="global-white-bg-container justify-space-between" style={{ padding: "3px 1%" }}>
        <div>
          <Tooltip title="Back">
            <IconButton onClick={backButtonFunction}>
              <i class="bi bi-chevron-left"></i>
            </IconButton>
          </Tooltip>
          {
            editClicked ?
              <IconButton
                onClick={
                  formValues?.visibleOnly === true ?
                    () => setFormValues({
                      ...formValues, visibleOnly: false
                    }) : submitApiClick("edit")}
              >
                {formValues?.visibleOnly === true ?
                  <i class="bi bi-pencil-square edit-icon1"></i> :
                  <i class="bi bi-check2-square edit-icon" style={{ fontSize: "1.5rem" }}></i>}
              </IconButton>
              :
              <IconButton
                onClick={submitApiClick("create")}
              >
                <i class="bi bi-floppy save-icon"></i>
              </IconButton>
          }
        </div>
      </div>
      <div className="white-bg-two-section">
        <div className="global-white-bg-container sales-customer-left">
          <div className='single-container'>
            <div className="global-single-input" style={{ paddingBottom: "3%" }}>
              <p>Image Upload</p>
              <FilePicker
                id="addVendorImage"
                uploadImageUrl={vendorImg}
                setImage={setVendorImg}
                onChange={(e) => {
                  setVendorImg(e.target.files[0]);
                }}
              />
              <p className="doc-validation-alert">{validationAlert?.image}</p>
            </div>

            <div className="new-global-single-input" style={{ width: "45%", justifyContent: "end" }}>
              <TextField
                id="outlined-basic"
                label="Name*"
                variant="outlined"
                type="text"
                onChange={getFormInfo("name")}
                value={formValues?.name}
                disabled={formValues?.visibleOnly}
                focused

              />
              <p
                style={{ top: "45%" }}
                className="doc-validation-alert">{validationAlert?.name}</p>
            </div>

          </div>
          <div className='single-container'>

            <div className="new-global-single-input auto-complete-new" style={{ width: "45%" }}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={countryWithState || []}
                getOptionLabel={(option) => option?.country}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Country*" focused />
                )}
                value={formValues?.country}
                onChange={getFormInfo('country')}
                disabled={formValues?.visibleOnly}
              />
              <p className="doc-validation-alert">{validationAlert?.country}</p>
            </div>


            <div className="new-global-single-input auto-complete-new" style={{ width: "45%" }}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={formValues?.country?.states || []}
                getOptionLabel={(option) => option}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="State*" focused />
                )}
                value={formValues.state}
                onChange={getFormInfo("state")}
                disabled={formValues?.visibleOnly}
              />
              <p className="doc-validation-alert">{validationAlert?.state}</p>
            </div>

          </div>
          <div className='single-container'>
            <div className="new-global-single-input" style={{ width: "45%" }}>
              <TextField
                id="outlined-basic"
                label="City*"
                variant="outlined"
                type="text"
                focused
                value={formValues?.city}
                onChange={getFormInfo('city')}
                disabled={formValues?.visibleOnly}
              />
              <p className="doc-validation-alert">{validationAlert?.city}</p>
            </div>



            <div className="new-global-single-input" style={{ width: "45%" }}>
              <TextField
                id="outlined-basic"
                label="Street*"
                variant="outlined"
                type="text"
                focused
                value={formValues?.street}
                onChange={getFormInfo('street')}
                disabled={formValues?.visibleOnly}
              />
              <p className="doc-validation-alert">{validationAlert?.street}</p>
            </div>

          </div>
          <div className='single-container'>



            <div className="new-global-single-input" style={{ width: "45%" }}>
              <TextField
                id="outlined-basic"
                label="ZipCode*"
                variant="outlined"
                type="text"
                focused
                value={formValues?.address}
                onChange={getFormInfo("address")}
                disabled={formValues?.visibleOnly}
              />
              <p className="doc-validation-alert">{validationAlert?.address}</p>
            </div>


            <div className="new-global-single-input" style={{ width: "45%" }}>
              <TextField
                id="outlined-basic"
                label="TRN No*"
                variant="outlined"
                type="text"
                focused
                value={formValues?.taxId}
                onChange={getFormInfo('taxId')}
                disabled={formValues?.visibleOnly}
              />
              <p className="doc-validation-alert">{validationAlert?.taxId}</p>
            </div>

          </div>




          <div className='single-container'>
            <div className="global-single-input" style={{ width: "45%" }}>
              <p>Is Vendor</p>
              <input
                className="toggle-checkbox"
                type="checkbox"
                checked={formValues?.isVendor}
                onChange={(e) => setFormValues({
                  ...formValues, isVendor: e.target.checked
                })}
                disabled={formValues?.visibleOnly}
              />

            </div>
            <div className="global-single-input" style={{ width: "45%" }}>
              <p>Is Customer</p>
              <input
                className="toggle-checkbox"
                type="checkbox"
                checked={formValues?.isCustomer}
                onChange={(e) => setFormValues({
                  ...formValues, isCustomer: e.target.checked
                })}
                disabled={formValues?.visibleOnly}
              />

            </div>
          </div>

        </div>


        <div className="global-white-bg-container sales-customer-left">
          <div className='single-container'>
            <div className="new-global-single-input" style={{ width: "45%" }}>
              <TextField
                id="outlined-basic"
                label="Mobile*"
                variant="outlined"
                type="Number"
                focused
                value={formValues?.mobile}
                onChange={getFormInfo('mobile')}
                disabled={formValues?.visibleOnly}
              />
              <p className="doc-validation-alert">{validationAlert?.mobile}</p>
            </div>



            <div className="new-global-single-input" style={{ width: "45%" }}>
              <TextField
                id="outlined-basic"
                label="Email*"
                variant="outlined"
                type="email"
                focused
                value={formValues?.email}
                onChange={getFormInfo("email")}
                disabled={formValues?.visibleOnly}
              />
              <p className="doc-validation-alert">{validationAlert?.email}</p>
            </div>

          </div>
          <div className='single-container'>



            <div className="new-global-single-input auto-complete-new" style={{ width: "45%" }}>

              <div className='' style={{ position: "relative" }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={paymentTermsList || []}
                  getOptionLabel={(option) => option?.paymentTerm}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Payment Terms*" focused />
                  )}
                  value={formValues.paymentTerms}
                  onChange={getFormInfo("paymentTerms")}
                  disabled={formValues?.visibleOnly}

                />
                <button onClick={PaymentTermsbtn} className="add-row-btn autocomplete-inside-plusbtn">
                  +
                </button>
              </div>

            </div>



            <div className="new-global-single-input" style={{ width: "45%" }}>
              <TextField
                id="outlined-basic"
                label="Supplier Currency*"
                variant="outlined"
                type="text"
                focused
                value={formValues.supplierCurrency}
                onChange={getFormInfo("supplierCurrency")}
                disabled={formValues?.visibleOnly}
              />
              <p className="doc-validation-alert">{validationAlert?.supplierCurrency}</p>
            </div>

          </div>
          <div className='single-container'>


            <div className="new-global-single-input" style={{ width: "45%" }}>
              <TextField
                id="outlined-basic"
                label="Contact Person"
                variant="outlined"
                type="text"
                focused
                value={formValues.contactPerson}
                onChange={getFormInfo("contactPerson")}
                disabled={formValues?.visibleOnly}
              />
              <p className="doc-validation-alert">{validationAlert?.contactPerson}</p>
            </div>



            <div className="new-global-single-input" style={{ width: "45%" }}>
              <TextField
                id="outlined-basic"
                label="Contact Person Mobile"
                variant="outlined"
                type="text"
                focused
                value={formValues.mobilePhoneNo}
                onChange={getFormInfo("mobilePhoneNo")}
                disabled={formValues?.visibleOnly}
              />
              <p className="doc-validation-alert">{validationAlert?.mobilePhoneNo}</p>
            </div>

          </div>
          <div className='single-container'>




            <div className="new-global-single-input" style={{ width: "45%" }}>
              <TextField
                id="outlined-basic"
                label="Company Code"
                variant="outlined"
                type="text"
                focused
                value={formValues.companyCode}
                onChange={getFormInfo("companyCode")}
                disabled={formValues?.visibleOnly}
              />
              <p className="doc-validation-alert">{validationAlert?.companyCode}</p>
            </div>



            <div className="new-global-single-input auto-complete-new" style={{ width: "45%" }}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={userRole==="admin" ? allBranchesList: allowedBranchList || []}
                getOptionLabel={(option) => `${option?.storeCode}-${option?.branchName}`}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Branch" focused />
                )}
                value={formValues.branch}
                onChange={getFormInfo("branch")}
                disabled={formValues?.visibleOnly}
              />
              <p className="doc-validation-alert">{validationAlert?.branch}</p>
            </div>



          </div>

          <div className='single-container'>
            <div className="new-global-single-input auto-complete-new" style={{ width: "45%" }}>
              <div className='' style={{ position: "relative" }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={allCustomerList || []}
                  getOptionLabel={(option) => option?.customerGroup}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Customer Group*" focused />
                  )}

                  value={formValues?.customerGroup}
                  onChange={getFormInfo("customerGroup")}
                  disabled={formValues?.visibleOnly}
                />
                <button onClick={CustomerGroupBtn} className="add-row-btn autocomplete-inside-plusbtn">
                  +
                </button>
              </div>
            </div>

            <div className="new-global-single-input" style={{ width: "45%" }}>
              <TextField
                id="outlined-basic"
                label="Phone"
                variant="outlined"
                type="Number"
                focused
                value={formValues?.phone}
                onChange={getFormInfo("phone")}
                disabled={formValues?.visibleOnly}
              />
              <p className="doc-validation-alert">{validationAlert?.phone}</p>
            </div>

          </div>

          <div className='new-global-single-input' style={{ width: "100%" }}>

            <div className="global-single-input">
              <p>Active / Inactive</p>
              <input
                className="toggle-checkbox"
                checked={formValues?.toggleChecked}
                onChange={(e) => setFormValues({ ...formValues, toggleChecked: e.target.checked })}
                disabled={formValues?.visibleOnly}
                type="checkbox"

              />
            </div>

          </div>
        </div>

      </div>

      <PaymentTerms handleDialog={paymentTermsDialog} setHandleDialog={setPaymentTermsDialog} />
      
      <Dialog
        open={customerGroupDialog}
        onClose={() => setCustomerGroupDialog(false)}
        maxWidth="lg"
      >

        <div className="payment-terms-dialog-container">
          <div className="new-global-single-input" style={{ width: "95%" }}>
            <TextField
              id="outlined-basic"
              label="Customer Group"
              variant="outlined"
              type="text"
              focused
              value={formValues?.addCustomerGroup}
              onChange={getFormInfo('addCustomerGroup')}

            />
            <p className="doc-validation-alert">{validationAlert?.addCustomerGroup}</p>
          </div>
          <hr className="global-hr" />
          <div className='paymentterms-btns'>
            <button
              onClick={addCustomerGroupSubmitBtn}
              className="btn btn-primary shift-end-password-btn"
              style={{ height: "2rem" }}
            >
              Submit
            </button>
            <button
              onClick={() => setCustomerGroupDialog(false)}
              className="paymentterms-cancelbtn"
              style={{ height: "2rem" }}
            >
              Cancel
            </button>
          </div>

        </div>

      </Dialog>
      <SuccessSnackbar open={snackBarStates.success} handleClose={closeSnackbar} message={snackBarStates.message} />
      <ErrorSnackbar open={snackBarStates.error} handleClose={closeSnackbar} message={snackBarStates.message} />
      <LoadingForm loading={loadingForm}/>

    </div>
  )
}
