import { Autocomplete, Dialog, IconButton, TextField } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { useDispatch, useSelector } from 'react-redux';
import { setErrorSnack, setSnackMsg } from '../../../Sales/Billing/Slice/billingScreenSlice';
import DeleteIcon from '@mui/icons-material/Delete';
import { findObjFromList, roundNumericFields } from '../../../../../Js/generalFunctions';
import { setBatchPopup, store_table_batch_row_data } from './inventoryBatchSlice';
import "./inventoryBatch.css"
const AddBatchPopup = (props) => {

  const {isSales,setTableData,tableData,grn,taxCalculate}=props
  const {batchDataPopup,batchTableRowData,batchTableRowIndex,batchListOfProduct}=useSelector((state)=>state.inventoryBatchSlice)

  const decimalPosition=localStorage.getItem("decimalPosition")

  const containerRef=useRef(null)
  const dispatch=useDispatch()

  const closeDialog=()=>{
    dispatch(setBatchPopup(false))
    dispatch(store_table_batch_row_data(null))
  }
  const formDataInitial={
    product:null,
    orderQty:0,
    doneQty:0,
  }
  const [formValues,setFormValues]=useState(formDataInitial)

  const tableDataInitial = {
    batchNo:isSales?null:"",
    expirationDate:"",
    qty:"",
    uom:batchTableRowData?.uom?._id
  };
  
  const [tableArray,setTableArray]=useState([tableDataInitial])
  const [addRowScroll,setAddRowScroll]=useState(false)

  const addRow=()=>{
    let tableDataLength=tableArray.length
    let lastLineData=tableArray[tableDataLength-1]
    if(tableDataLength!==0 && (lastLineData.batchNo===null || lastLineData.batchNo==="" || lastLineData.expirationDate==="" || lastLineData.qty==="")){
      dispatch(setSnackMsg("Fill the required fields in the last row !!"))
      dispatch(setErrorSnack(true))
    }else{
      setTableArray([...tableArray,tableDataInitial])
      setAddRowScroll(!addRowScroll);
    }
  }

  const handleInputChange=(id,field)=>(e,newValue)=>{
    const { value } = e.target;
    const updatedData = tableArray.map((row, index) => {
      if (index === id) {
          return { ...row, [field]: newValue!==undefined ? newValue : value };
      }
      return row;
    });
    setTableArray(updatedData)

    let doneQty=updatedData.reduce((sum,item)=>sum+Number(item.qty===""?0:item.qty),0)
    setFormValues({...formValues,doneQty})
  }

  const handleDelete = (index) => {
    const updatedTableData = [...tableArray];
    updatedTableData.splice(index, 1);
    setTableArray(updatedTableData);
    let doneQty=updatedTableData.reduce((sum,item)=>sum+Number(item.qty===""?0:item.qty),0)
    setFormValues({...formValues,doneQty})
  };

  const handleMouseEnter = () => {
    const updatedArray = roundNumericFields(tableArray, [
      "qty"
    ]);
    setTableArray(updatedArray);
    let doneQty=updatedArray.reduce((sum,item)=>sum+Number(item.qty===""?0:item.qty),0)
    setFormValues({...formValues,doneQty})
  };

  const clickSaveBtn=()=>{
    let orderQty=formValues?.orderQty?.toFixed(decimalPosition)
    let doneQty=formValues?.doneQty?.toFixed(decimalPosition)
    
    let duplicateTable=[...tableData]
    let obj={...duplicateTable[batchTableRowIndex]}

    if(!grn){
      let totalAmt = 0;
          let totalItemAmt =
          Number(doneQty)?.toFixed(decimalPosition) * Number(obj?.unitPrice);
          let discount = (Number(obj?.discount) / 100) * totalItemAmt;
          totalAmt = totalItemAmt - discount;
          obj.amount=totalAmt
    }
    obj.batchList=tableArray?.map((item)=>({...item,qty:Number(item.qty),batchNo:isSales?item.batchNo?._id:item?.batchNo}))
    grn?obj.recievedQuantity=Number(doneQty):obj.qty=Number(doneQty)
    
    obj.doneQty=Number(doneQty)

    duplicateTable[batchTableRowIndex]=obj

    let tableDataLength=tableArray.length
    let lastLineData=tableArray[tableDataLength-1]
    if(tableDataLength!==0 && (lastLineData.batchNo===null || lastLineData.batchNo==="" || lastLineData.expirationDate==="" || lastLineData.qty==="")){
      dispatch(setSnackMsg("Fill the required fields in the last row !!"))
      dispatch(setErrorSnack(true))
    }
    else if(grn && Number(doneQty)>Number(orderQty) || Number(doneQty)<0){
      dispatch(setSnackMsg(Number(doneQty)<0?"Done qty can't be minus value":"Done Quantity can't be greater than order quantity !!"))
      dispatch(setErrorSnack(true))
    }else{
      grn?setTableData(duplicateTable):taxCalculate(duplicateTable)
    }
  }
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [addRowScroll]);
  useEffect(()=>{
    if(batchTableRowData!==null){
      let doneQty=batchTableRowData?.batchList?.reduce((sum,item)=>sum+Number(item.qty===""?0:item.qty),0)
      setFormValues({...formValues,
        orderQty:batchTableRowData?.orderQuantity || Number(batchTableRowData?.demandQty) || 0,
        product:(typeof batchTableRowData?.product ==="string")?(batchTableRowData?.product):(batchTableRowData?.product?.productName||null),
        doneQty:doneQty || 0
      })

      let tableInfo=batchTableRowData?.batchList?.map((item)=>(
        {
          batchNo:isSales?findObjFromList("_id",batchListOfProduct,"batchNo",item):item?.batchNo,
          expirationDate:item?.expirationDate,
          qty:item?.qty,
          uom:item?.uom,
        }
      ))
      setTableArray((tableInfo?.length===0 || !tableInfo)?[tableDataInitial]:tableInfo)
    }
  },[batchTableRowData,batchListOfProduct])

  return (
    <Dialog open={batchDataPopup} maxWidth="lg" onClose={closeDialog}>
        <div className='batch-save-popup-ctn'>
            <div className="batch-save-popup-topbar justify-space-betweeen">
              <h4>Batch</h4>
              <div 
              style={{gap:"10px"}}
              className="justify-center">
                  <button 
                  onClick={clickSaveBtn}
                  className="btn btn-post">
                    SAVE
                  </button>

                  <IconButton onClick={closeDialog}>
                        <HighlightOffOutlinedIcon
                          sx={{
                            color: "#f93737",
                            fontSize: "30px",
                            fontWeight: "400",
                          }}
                        />
                      </IconButton>
              </div>
            </div>

            <div className="batch-save-popup-body flex-column">
              <div className="batch-product-info">
                 <div className='flex-column'>
                    <p>Product</p>
                    <p>Order Qty</p>
                   {grn && <p>Done Qty</p>}
                 </div>       
                 <div className='flex-column'>
                    <p>: {formValues?.product} </p>
                    <p>: {grn?(formValues?.orderQty?.toFixed(decimalPosition)):(formValues?.doneQty?.toFixed(decimalPosition))}</p>
                    {grn &&<p>: {formValues?.doneQty?.toFixed(decimalPosition)}</p>}
                 </div>       
              </div>
              <div className="global-product-table" onMouseLeave={handleMouseEnter} onBlur={handleMouseEnter}>
                  <table>
                    <thead>
                      <tr>
                        <th>Lot Number</th>
                        <th>Expiry Date</th>
                        <th>Qty</th>
                        <th>uom</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>

                    <tbody>
                      {tableArray?.map((item, index) => (
                        <tr
                          key={index}
                          ref={
                            index === tableArray?.length - 1
                              ? containerRef
                              : null
                          }
                        >
                              <td style={{ minWidth: "180px" }}>
                               {
                               isSales?
                               <div className="product-table-auto-complete">
                                  <Autocomplete
                                    options={batchListOfProduct || []}
                                    getOptionLabel={(option)=>option?.batchNo}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder='Enter Lot Number'
                                      />
                                    )}
                                    value={item?.batchNo}
                                    onChange={handleInputChange(
                                      index,
                                      "batchNo"
                                    )}
                                  />
                                </div>
                              :  
                              <input
                                  type="text"
                                  onChange={handleInputChange(index, "batchNo")}
                                  value={item?.batchNo}
                                  placeholder='Enter Lot Number'

                                />
                              }
                              </td>
                              <td style={{ maxWidth: "180px" }}>
                                <input
                                  type="date"
                                  onChange={handleInputChange(index, "expirationDate")}
                                  value={item?.expirationDate}
                                />
                              </td>
                              <td style={{ minWidth: "95px" }}>
                                <input
                                  type="number"
                                  onChange={handleInputChange(index, "qty")}
                                  value={item?.qty}
                                  onFocus={(e)=>e.target.select()}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  readOnly
                                  defaultValue={batchTableRowData?.uom?.uomName}
                                />
                              </td>

                              <td style={{  width: "4%" }}>
                                <IconButton
                                  onClick={() => handleDelete(index)}
                                  style={{ height: "30px" }}
                                >
                                  <DeleteIcon color='error' fontSize='small'/>
                                </IconButton>
                              </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <p 
                onClick={addRow}
                className="add-line">Add Line
                </p>
            </div>
        </div>
    </Dialog>
  )
}

export default AddBatchPopup