import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import store from "../../../../../Redux/store";
// import { get_branch_transfer_single_view } from './BranchTransferSlice';
import { Skeleton } from "@mui/material";
import { CustomerPaymentFilterAPICall, viewCustomerPaymentListAPICall } from "./customerPaymentAPI";
import { get_customer_payment_row_data_list } from "./customerPaymentSlice";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import { companyProfileInfoForUser } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import { viewAllCurrencyUpdated } from "../../../../../API/Settings/Currency/CurrencyAPI";
import { filterObjFromList } from "../../../../../Js/generalFunctions";
import { getCurrentTime } from "../../../../../Js/Date";
import CustomPagination from "../../../../Single Components/CustomPagination";
import axios from "axios";
import { setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";

export const CustomerPayments = () => {
  let navigate = useNavigate();

  const {currencyDetails} = useSelector((state) => state.mainDashBoardSlice)

  const [symbol, setSymbol] = useState(null);
  const customerPaymentList = useSelector(
    (state) => state.customerPaymentSlice.apiResList
  );

  const [FormDataInfo, setFormDataInfo] = useState([])
  const [selectedBranch, setSelectedBranch] = useState(null);

  const filtereDataOptions = useSelector((state) => state.listCategory);


  const createCustomerPayment = () => {
    navigate("/userdashboard/accounts/customer/payments/create");
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = customerPaymentList?.pages;

  useEffect(() => {

    setSymbol(currencyDetails?.symbol || "");
  }, [currencyDetails]);


  useEffect(() => {
    localStorage.removeItem("singleViewById");

    store.dispatch(
      get_customer_payment_row_data_list({
        customerPaymentSingleRowData: undefined,
      })
    );

  }, []);

  const handleClickRow = (row) => {
    localStorage.setItem("singleViewById", row._id);
    store.dispatch( setFilterActive(window.location.pathname))
    navigate("/userdashboard/accounts/customer/payments/create");
  };
  useEffect(() => {
    
    if (FormDataInfo.length !== 0) {
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData && 
      CustomerPaymentFilterAPICall({
        fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
        toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
        branchList: FormDataInfo.branchList,
        type: [1],
        status: FormDataInfo.status,
        search: FormDataInfo.search,
        index: currentPage - 1,
      }, setIsLoading);
    }
  
  }, [FormDataInfo, currentPage]);


  return (
    <div className="global-page-parent-container">
      {/* table */}
      <div className="global-white-bg-container">
        <div className="justify-space-between"
          style={{ gap: "0 12px",  display: 'flex', justifyContent: 'space-between', alignItems: 'end'}}
        >
            {FormDataInfo?.branchList?.length > 0 && (
              <div style={{ marginLeft: '1%' }}>
               <h3>Branch name: 
                  {selectedBranch?.storeCode||filtereDataOptions?.filterCategorylist?.branchList?.storeCode}
                  -{selectedBranch?.branchName||filtereDataOptions?.filterCategorylist?.branchList?.branchName}</h3>
              </div>
            )} 
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap:'9px',
              width: FormDataInfo?.branchList?.length ? '72%' : '100%'
            }}>
          <CategorySearchandFilter
            statusOptions={['COMPLETED', 'DRAFT',"RETURNED"]}
            onData={handleDataFromChild}
            isStatus={true}
            onBranchSelect={handleBranchSelect}
          />
          <button
            onClick={createCustomerPayment}
            className="create-button-blue"
          >
            Create
          </button>
          </div>
        </div>
        <div className="create-button-blue-container">
          <h3>Payments</h3>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setFormDataInfo={setFormDataInfo}
          />
        </div>
        <div className="new-global-table-container">
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Payment Type</th>
                <th>Customer</th>
                <th>Total Amount</th>
                <th>Created By</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={7}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : customerPaymentList?.list !== undefined &&
                customerPaymentList?.list?.length !== 0 ? (
                customerPaymentList?.list
                  ?.slice(0)

                  .map((r, i) => (
                    <tr
                      key={i}
                      onClick={() => handleClickRow(r)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{r?.paymentDate}</td>
                      <td>{r?.name}</td>
                      <td>{r?.TYPE}</td>
                      <td>{r?.customer}</td>
                      <td>{symbol}&nbsp;{r?.totalAmount}</td>
                      <td>{r?.createdBy}</td>
                      <td className="table-data-box">
                        {r?.status === "POSTED" ? (
                          <span className="post"> POSTED</span>
                        ) : r?.status === "DRAFT" ? (
                          <span className="draft"> DRAFTED</span>
                        ) : r?.status === "RETURNED" ? (
                          <span className='return'> RETURN</span>
                        ) :(
                          "---"
                        )}
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan={7}>No Data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
