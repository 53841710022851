import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
  createFilterOptions,
} from "@mui/material";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import React, { useEffect, useRef, useState } from "react";
import postRibbon from "../../../../../Assets/statusRibbon/post.png";
import draftRibbon from "../../../../../Assets/statusRibbon/draft.png";
import returned from "../../../../../Assets/statusRibbon/return.png";
import paidRibbon from "../../../../../Assets/statusRibbon/paid.png";
import uploadFile from "../../../../../Assets/Images/Icon feather-upload.png";
import { CloseOutlined } from "@mui/icons-material";
import logoImage from "../../../../../Assets/Images/Icon ionic-logo-buffer.png";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentTime, today } from "../../../../../Js/Date";
import { useNavigate } from "react-router-dom";
import "./customerInvoice.css"
import {
  findObjFromList,
  roundNumericFields,
  taxCalculationForLines,
} from "../../../../../Js/generalFunctions";
import { journalListApiCall } from "../../../../../API/AccountUpdated/journalAPI";
import { viewMultiCurrencyConfiguration } from "../../../../../API/Purchase Manager/configuration";
import {
  viewUOMSubListAPICall,
} from "../../../Purchase Manager/Pages/UnitOfMeasurment/unitOfMeasurmentAPICall";
import { getAllTaxSettingsListAPICall } from "../../../../../API/Settings/TaxSettings/taxSettingsAPI";
import { chartOfAccountListApiCall } from "../../../../../API/AccountUpdated/chartOfAccountAPI";
import SuccessSnackbar from "../../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
import {
  customerAdvancePayAPI,
  customerInvoiceRegisterPaymentAPICall,
  customerInvoiceSingleViewAPICall,
  draftCustomerInvoiceAPICall,
  editCustomerInvoiceAPICall,
  generateCustomerInvoiceNameAPICall,
  postCustomerInvoiceAPICall,
  returnCustomerInvoiceAPI
} from "./customerInvoiceAPI";
import { LoadingForm } from "../../../../Single Components/LoadingForm";
import PaymentTerms from "../../../components/Dialog/paymentTerms/PaymentTerms";
import { setErrorSnack, setSnackMsg } from "../../../Sales/Billing/Slice/billingScreenSlice";
import Swal from "sweetalert2";
import { viewAllAllowedBranchesAPICall } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import { setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";
import { TableVirtuoso } from "react-virtuoso";

export const CreateCustomerInvoice = () => {

  const {userDetails,branchDetails,currencyDetails} = useSelector((state) => state.mainDashBoardSlice)
  const dispatch=useDispatch()
  
  const allActiveEmpList = useSelector(
    (state) => state.allActiveEmployeeSlice.value
  );
  const numToWords = require("num-words");
  const singleRowId = localStorage.getItem("singleViewById");

  const decimalPosition = Number(localStorage.getItem("decimalPosition"));

  const editedData = useSelector((state) => state.customerInvoiceSlice.rowData);

  const userInfo = useSelector((state) => state.loginResponseSlice.value);
  const userRole=useSelector((state)=>state.userRoleSlice.value)
  const allowedBranchList = useSelector((state) => state.allAllowedBranchesSlice.value);

  const customerList = useSelector((state) => state.allCustomerListSlice.value);
  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );
  const allNewProductList = useSelector(
    (state) => state.vendorBillSlice.NewProductList
  );

  const uomSubList = useSelector(
    (state) => state.unitOfMeasurmentSlice.subUomList
  );
  const chartOfAccountList = useSelector(
    (state) => state.chartOfAccountsSlice.value
  );


  const configurationList = useSelector(
    (state) => state.glConfigurationSlice.apiResList
  );
  const accountsList = chartOfAccountList?.filter(
    (item) => item?.type === "Income"
  );
  const allTaxList = useSelector((state) => state.taxSettingsSlice.value);
  const allTaxListSalesType = allTaxList?.filter(
    (item) => item?.taxType === "Sales" 
  );

  const companyProfileData = useSelector(
    (state) => state.companyProfileSlice.userValue
  );
  const journalList = useSelector((state) => state.journalSlice.value);
  const journalSalesList = journalList?.filter(
    (item) => item?.type === "sales"
  );
  const MultiCurrencyView = useSelector(
    (state) => state.ConfigurationSlice.MultiCurrency
  );
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  const paymentTermsList = useSelector(
    (state) => state.vendorsListSlice?.paymentTermsList
  );

  

  let navigateTo = useNavigate();
  const [alertDialog, setAlertDialog] = useState(false);
  const addRowData = ["Add Header","Add Line" ];
  const [isActiveTable, setIsActiveTable] = useState("invoiceLines");
  const [addRowInput, setAddRowInput] = useState("Add Line");
  const [creditNoteName, setCreditNoteName] = useState("###");
  const [editDataId, setEditDataId] = useState("");
  const [alertPopup, setAlertPopup] = useState(false);
  const [handlePaymentTermsbtn, setHandlePaymentTermsbtn] = useState("");
  const [totalsInfo, setTotalsInfo] = useState({
    subTotal: 0.0,
    inclusiveTax: 0.0,
    exclusiveTax: 0.0,
    total: 0.0,
    roundOff:0?.toFixed(decimalPosition)
  });

  const tableDataInitial = {
    type: "lines",
    id: Date.now(),
    product: null,
    label: "",
    account: null,
    uom: null,
    qty: "",
    unitPrice: "",
    discount: "",
    tax: [],
    amount: "",
    inclusive: "",
    header: "",
  };
  const [tableData, setTableData] = useState([tableDataInitial]);

  const [filteredTaxOptions, setFilteredTaxOptions] = useState(
    tableData?.map(() => allTaxListSalesType || [])
  );

  const [rowIndex, setRowIndex] = useState(null);

  const [image, setImage] = useState([]);
  const [imageDisplay, setImageDisplay] = useState([]);

  const [logView, setLogView] = useState(false);
  const [isLoading, setIsLoading] = useState("");
  const [editClicked, setIsEditClicked] = useState(false);
  const containerRef = useRef(null);
  const [validationAlert, setValidationAlert] = useState({});
  const [registerPayment, setRegisterPayment] = useState(false);
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
    alert: false,
  });
  const [loadingForm, setLoadingForm] = useState(false);

  const [paymentTypeList, setPaymentTypeList] = useState([]);

  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      alert: false,
      message: "",
    });
  };
  const [log, setLog] = useState([]);

  const formInitialState = {
    customer: null,
    branch: branchDetails,
    employee: userDetails,
    invoiceReference: "",
    invoiceDate: today,
    paymentReference: "",
    accountingDate: today,
    journal: findObjFromList("_id",journalSalesList,"salesJournal",configurationList?.sales),
    currency: currencyDetails,
    orderDate: null,

    customerNotes: "",
    termsAndConditions: "",

    isEdit: false,
    status: "",
    paymentStatus: false,
    key: "",
    userPermission: false,
    paymentTerms: null,
    lpoNo:"",

  };

  const [formValues, setFormValues] = useState(formInitialState);


  const paymentFormValuesInitialState = {
    date: today,
    paymentType: null,
    type: null,
    paymentReference: formValues?.name,
    chequeDate: "",
    chequeNo: "",
    amount: "",
    amountInWords: "",
  };

  const [paymentForm, setPaymentForm] = useState(paymentFormValuesInitialState);
  const clearForm = () => {
    setFormValues(formInitialState);
    setTableData([tableDataInitial]);
    setEditDataId("");
  };
  const productMenuClick = () => {
    document.getElementById("productMenuBtn").classList.add("active");
    document.getElementById("departmentMenuBtn").classList.remove("active");
    setIsActiveTable("invoiceLines");
  };

  const departmentMenuClick = () => {
    document.getElementById("productMenuBtn").classList.remove("active");
    document.getElementById("departmentMenuBtn").classList.add("active");
    setIsActiveTable("others");
  };
  const [addRowScroll, setAddRowScroll] = useState(false);
  const addRow = () => {
    if (addRowInput=== "Add Header") {
      setTableData([
        ...tableData,
        { type: "header", id: Date.now(), header: "" },
      ]);
    } else if (addRowInput === "Add Line") {
      let lastRow=tableData[tableData.length-1]
      if(lastRow?.product===null){
        dispatch(setSnackMsg("Fill all the required fields in the last row !!"))
        dispatch(setErrorSnack(true))
      }else{
      setTableData([
        ...tableData,
        {
          type: "lines",
          id: Date.now(),
          product: null,
          label: "",
          account: null,
          uom: null,
          qty: "",
          unitPrice: "",
          discount: "",
          tax: [],
          amount: "",
          inclusive: "",
        },
      ]);
    }
  }
    setAddRowScroll(!addRowScroll);
  };

  useEffect(() => {
    if (containerRef.current) {
      setTimeout(() => {
        containerRef.current.scrollIntoView({index:tableData.length,behavior:"smooth",align:"end",behaviour:"smooth"});
        },0);
    }
  }, [addRowScroll]);

  const handleDelete = (index) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };

  const changeImage = (e) => {
    setImage([...image, e.target.files[0]]);
    setImageDisplay([...imageDisplay, URL.createObjectURL(e.target.files[0])]);
  };

  const removeImageFn = (i) => {
    image.splice(i, 1);
    imageDisplay.splice(i, 1);
    setImage([...image]);
    setImageDisplay([...imageDisplay]);
  };

  const clickLogView = () => {
    setLogView(!logView);
  };

  //getFormInfo
  const getFormInfo = (key) => (e, newValue) => {
    const { value } = e.target;
    const keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      setValidationAlert({});
      setFormValues({
        ...formValues,
        [key]: newValue !== null ? newValue : null,
      });
    } else {
      setValidationAlert({});
      setFormValues({
        ...formValues,
        [key]:
          value !== 0 && value !== undefined
            ? value
            : newValue !== null
            ? newValue
            : null,
      });
    }
  };

  const getPaymentForm = (key) => (e, newValue) => {
    const { value } = e.target;
    setValidationAlert({});
    if (key === "amount") {
      if (editedData?.amountDue > value) {
        setPaymentForm({ ...paymentForm, amount: value });
      } else {
        setPaymentForm({ ...paymentForm, amount: editedData?.amountDue });
      }
    } else {
      setPaymentForm({
        ...paymentForm,
        [key]:
          value !== 0 && value !== undefined  ? value : newValue !== null? newValue: null,
      });
    }
  };

  // handleInputChange
  const handleInputChange = (id, field) => (e, newValue) => {
    const { value } = e.target;
    const updatedData = tableData.map((row, index) => {
      if (index === id) {
        if (field === "product") {
          setRowIndex(index);
          let customerTax=newValue?.customerTax || []
          let taxObjects=allTaxList?.filter(obj => customerTax.includes(obj._id))

          let selectedTaxType=null
          if(taxObjects.length!==0){
            selectedTaxType=taxObjects[0]?.includedPrice
          }  
          const newFilteredTaxOptions =selectedTaxType !== null? allTaxListSalesType?.filter((option) => option.includedPrice === selectedTaxType)
              : allTaxListSalesType;
          filteredTaxOptions[index] = newFilteredTaxOptions;
          return {
            ...row,
            [field]: newValue,
            label: newValue?.productName || "",
            uom: findObjFromList("_id", uomSubList, "uom", newValue),
            qty: 1,
            unitPrice: newValue?.salesPrice || "",
            discount: newValue !== null ? 0 : "",
            tax:taxObjects,
            account:
              newValue !== null
                ? findObjFromList(
                    "_id",
                    accountsList,
                    "incomeAccount",
                    configurationList?.sales
                  )
                : null,
            amount: newValue !== null ? newValue?.salesPrice : "",
            inclusive: selectedTaxType !== null ? selectedTaxType : "",
          };
        } else if (field === "tax") {
          const selectedTaxId =
            newValue?.length !== 0 ? newValue[0]?.includedPrice : undefined;
          const newFilteredTaxOptions =
            selectedTaxId !== undefined
              ? allTaxListSalesType.filter(
                  (option) => option.includedPrice === selectedTaxId
                )
              : allTaxListSalesType;
          filteredTaxOptions[index] = newFilteredTaxOptions;
          return {
            ...row,
            tax: newValue,
            inclusive: selectedTaxId !== undefined ? selectedTaxId : "",
          };
        } else if (field === "qty") {
          let totalAmt = 0;
          let totalItemAmt =
            Number(value)?.toFixed(decimalPosition) * Number(row?.unitPrice);
          let discount = (Number(row?.discount) / 100) * totalItemAmt;

          totalAmt = totalItemAmt - discount;
          return { ...row, [field]: e.target.value, amount: totalAmt };
        } else if (field === "unitPrice") {
          let totalAmt = 0;
          let totalItemAmt =
            Number(value)?.toFixed(decimalPosition) * Number(row?.qty);
          let discount = (Number(row?.discount) / 100) * totalItemAmt;

          totalAmt = totalItemAmt - discount;
          return { ...row, [field]: e.target.value, amount: totalAmt };
        } else if (field === "discount") {
          let totalAmt = 0;
          let totalItemAmt = Number(row?.qty) * Number(row?.unitPrice);
          let discount =
            (Number(value)?.toFixed(decimalPosition) / 100) * totalItemAmt;

          totalAmt = totalItemAmt - discount;
          return { ...row, [field]: e.target.value, amount: totalAmt };
        } else {
          return { ...row, [field]:  newValue !== undefined ? newValue : e.target.value };
        }
      }
      return row;
    });

    calculateTax(updatedData)

  };
  const calculateTax=(array)=>{
    let taxUpdatedTable=taxCalculationForLines(array)
    setTableData(taxUpdatedTable)
  }

  const handleMouseEnter = () => {
    const updatedArray = roundNumericFields(tableData, [
      "qty",
      "unitPrice",
      "discount",
      "amount",
    ]);
    setTableData(updatedArray);
  };

  const tableDataToBackend = tableData?.map((item) =>
    item?.type === "lines"
      ? {
          line_type: item?.type,
          _id: item?.product?._id,
          accountId: item?.account?._id,
          mainCatgeoryId: item?.product?.mainCatgeoryId || item?.mainCatgeoryId,
          product: item?.product?.productName,
          label: item?.label,
          uomName: item?.uom?.uomName,
          purchaseUomName: item?.product?.purchaseUomName || item?.purchaseUomName,
          purchaseuom: item?.product?.purchaseuom || item?.purchaseuom,
          cost: item?.product?.cost,
          uom: item?.uom?._id,
          qty: Number(item?.qty),
          unitPrice: Number(item?.unitPrice),
          discount: Number(item?.discount),
          includedPrice: item?.inclusive === "" ? null : item?.inclusive,
          taxes: item?.taxSplitted,
          taxIdList:item?.taxIdList,
          prodType:item?.prodType || item?.product?.prodType
        }
      : {
          line_type: item?.type,
          label: item?.header,
        }
  );

  const formData = new FormData();
  formValues?.employee !== null && formData.append("salesPerson", formValues?.employee?._id)
  /*required*/ formData.append("vendorId", formValues?.customer?._id);
  /*required*/ formData.append("branchId", formValues?.branch?._id);
  formValues?.invoiceReference !== "" &&
    formData.append("invoiceRef", formValues?.invoiceReference);
    const invoiceDate = formValues?.invoiceDate === today ? `${formValues?.invoiceDate} ${getCurrentTime()}` : `${formValues?.invoiceDate} 00:00:00`;
   formData.append("invoiceDate", invoiceDate);

  /*required*/
  //  formData.append("invoiceDate", `${formValues?.invoiceDate} ${getCurrentTime()}`);
  formValues?.paymentReference !== "" &&
  formData.append("paymentRef", formValues?.paymentReference);
    if (formValues?.accountingDate !== "") {
      const accountingDate = formValues?.accountingDate === today ? `${formValues?.accountingDate} ${getCurrentTime()}` : `${formValues?.accountingDate} 00:00:00`;
      formData.append("accountingDate", accountingDate);
  }
  
  // formValues?.accountingDate !== "" &&
  /*required*/ formData.append("journals", formValues?.journal?._id);
  /*required*/ formData.append("currency", formValues?.currency?._id);
  formValues?.paymentTerms !== null && formData.append("paymentTerms", formValues?.paymentTerms?._id)

  // formValues?.paymentTerms!=="" && formData.append("paymentTerms", formValues?.paymentTerms);
  formValues?.lpoNo!=="" && formData.append("LpoNo", formValues?.lpoNo);
 
  /*required*/
  /*required*/ formData.append(
    "total",
    totalsInfo?.total?.toFixed(decimalPosition)
  );
  formData.append("roundOff",Number(totalsInfo?.roundOff)?.toFixed(decimalPosition));
  /*required*/ formData.append("taxableAmount", 0);
  /*required*/ formData.append("tax", 0);
  /*required*/ formData.append("amountDue", 0);
  image.length !== 0 &&
    image.forEach((file, index) => {
      formData.append("file", file);
    });
  formValues?.termsAndConditions !== "" &&
    formData.append("termsAndCondition", formValues?.termsAndConditions);
  formValues?.customerNotes !== "" &&
    formData.append("customerNotes", formValues?.customerNotes);
  /*required*/ formData.append("postDate", `${today} ${getCurrentTime()}`);
  // tobe changed
  /*required*/ formData.append("deliveryNoteId", null);

  const handleDialogAgree = () => {
    submitBtnClicked(formValues.key, true)();
  };

  const submitBtnClicked = (key, permission) => () => {
    dispatch( setFilterActive(false))
    if (formValues?.customer === null || formValues?.customer === undefined ) {
      setValidationAlert({
        ...validationAlert,
        customer: "fill this field !!",
      });
    } else if ( 
      formValues?.branch === null ||
      formValues?.branch === undefined
    ) {
      setValidationAlert({ ...validationAlert, branch: "fill this field !!" });
    } 
    else if ( 
      formValues?.invoiceReference === " "  
    ) {
      setValidationAlert({ ...validationAlert, invoiceReference: "fill this field !!" });
    } 
    else if (
      formValues?.invoiceDate === "" ||
      formValues?.invoiceDate === undefined
    ) {
      setValidationAlert({
        ...validationAlert,
        invoiceDate: "fill this field !!",
      });
    } else if (
      formValues?.accountingDate === "" ||
      formValues?.accountingDate === undefined
    ) {
      setValidationAlert({
        ...validationAlert,
        accountingDate: "fill this field !!",
      });
    } else if (
      formValues?.journal === null ||
      formValues?.journal === undefined
    ) {
      setValidationAlert({ ...validationAlert, journal: "fill this field !!" });
    } else if (
      formValues?.currency === null ||
      formValues?.currency === undefined
    ) {
      setValidationAlert({
        ...validationAlert,
        currency: "fill this field !!",
      });
    } 
    else if ( formValues?.invoiceReference === "") {
      setValidationAlert({
        ...validationAlert,
        invoiceReference: "fill this field !!",
      });
    } 
    else {
      if (key === "post") {
        let draftFn = () => {
          setFormValues({
            ...formValues,
            isEdit: true,
            status: "POSTED",
            paymentStatus: false,
          });
          setPaymentForm({ ...paymentForm, amount: totalsInfo?.total });
        };
        let postFormData = new FormData();
        postFormData.append("_id", editDataId);
        postFormData.append("postDate", `${today} ${getCurrentTime()}`);
        if (tableDataToBackend.length > 0) {
          postCustomerInvoiceAPICall(
            postFormData,
            draftFn,
            setSnackBarStates,
            snackBarStates,
            setLoadingForm
          );
        } else {
          setSnackBarStates({
            error: true,
            message: "can't post customer invoice without lines",
          });
        }
      }
      if (key === 'return') {
        returnCustomerInvoiceAPI({
          id: singleRowId,
          postDate: `${today} ${getCurrentTime()}`
        }, setAlertPopup, setSnackBarStates)
      }

      if (key === "draft") {
        const draftFn = () => {
          setFormValues({ ...formValues, isEdit: true, status: "DRAFT" });
        };

        const isAccountIdEmpty = tableDataToBackend.some(
          (item) => !item.accountId
        );

        if (!isAccountIdEmpty) {
          formData.append("productInfo", JSON.stringify(tableDataToBackend));
          draftCustomerInvoiceAPICall(
            formData,
            draftFn,
            setSnackBarStates,
            snackBarStates,
            setEditDataId,
            setLoadingForm
          );
        } else {
          setFormValues((prev) => ({ ...prev, key: "draft" }));
          setAlertDialog(true);

          if (permission) {
            const validData = tableDataToBackend.filter(
              (res) => res.product && res.accountId
            );
            formData.append("productInfo", JSON.stringify(validData));

            draftCustomerInvoiceAPICall(
              formData,
              draftFn,
              setSnackBarStates,
              snackBarStates,
              setEditDataId,
              setLoadingForm
            );

            setAlertDialog(false);
          }
        }
      } else if (key === "edit") {
        formData.append("_id", editDataId);
        let draftFn = () => {
          setFormValues({ ...formValues, isEdit: true, status: "DRAFT" });
          setIsEditClicked(false);
        };

        const isAccountIdEmpty = tableDataToBackend.some(
          (item) => !item.accountId
        );

        if (!isAccountIdEmpty) {
          formData.append("productInfo", JSON.stringify(tableDataToBackend));
          editCustomerInvoiceAPICall(
            formData,
            draftFn,
            setSnackBarStates,
            snackBarStates,
            setLoadingForm
          );
        } else {
          setFormValues((prev) => ({ ...prev, key: "edit" }));
          setAlertDialog(true);

          if (permission) {
            const validData = tableDataToBackend.filter(
              (res) => res.product && res.accountId
            );
            formData.append("productInfo", JSON.stringify(validData));

            editCustomerInvoiceAPICall(
              formData,
              draftFn,
              setSnackBarStates,
              snackBarStates,
              setLoadingForm
            );
            setAlertDialog(false);
          }
        }
      }
    }
  };

  const clickRegisterPayment = () => {
    setRegisterPayment(true);
  };

  const bodyToPayment = {
    customerInvoiceId: editedData?._id,
    paymentTypeId: paymentForm?.paymentType?._id,
    chequeDate:
      paymentForm?.type === "Cheque" ? paymentForm?.chequeDate : undefined,
    chequeNo: paymentForm?.chequeNo,
    type: paymentForm?.type === "Manual" ? 1 : 2,
    totalAmount: Number(paymentForm?.amount),
    paymentDate: paymentForm?.date,
    amountInWords: numToWords(Math.floor(paymentForm?.amount)),
    branchId: paymentForm?.paymentType?.branchId,
    orderDate: today,
  };

  const registerPaymentClick = () => {
    if (paymentForm?.paymentType === null) {
      setValidationAlert({
        ...validationAlert,
        paymentType: "Fill This Field",
      });
    } else if (paymentForm?.type === null) {
      setValidationAlert({
        ...validationAlert,
        paymentFormType: "Fill This Field",
      });
    } else if (
      paymentForm?.type === "Cheque" &&
      paymentForm?.chequeDate === ""
    ) {
      setValidationAlert({
        ...validationAlert,
        paymentFormCheckDate: "Fill This Field",
      });
    } else if (
      paymentForm?.chequeNo === "" ||
      paymentForm?.chequeNo === null ||
      paymentForm?.chequeNo === undefined
    ) {
      setValidationAlert({
        ...validationAlert,
        paymentFormCheckNo: "Fill This Field",
      });
    } else {
      customerInvoiceRegisterPaymentAPICall(
        bodyToPayment,
        setRegisterPayment,
        setSnackBarStates,
        snackBarStates,
        setLoadingForm
      );
    }
  };

  const filterOptions = createFilterOptions({
    stringify: (option) => option.productName + option.partNo,
  });


  const roundOffOnChange=(e)=>{
    const {value}=e.target
    let roundOffLimit=configurationList?.roundingLimit
    if(value==="-" ||Number(value) >= -(roundOffLimit) && Number(value) <= (roundOffLimit)){
      setTotalsInfo({...totalsInfo,roundOff:value})
    }else{
       dispatch(setSnackMsg(`Maximum allowed round off is :${roundOffLimit}`))
       dispatch(setErrorSnack(true))
    }
  }

  // advance payments api call
  const paymentAdvanceClick=(list)=>()=>{

    let requestBody={
      case: 1,
      paymentId: list?._id,
      billId: editedData?._id
    }

    let postFn=()=>{
      customerInvoiceSingleViewAPICall({ _id: singleRowId });
    }
    Swal.fire({
      text: "Amount will be redeemed from customer !!",
      icon: 'warning',
      confirmButtonText: 'Add',
      showCancelButton: true,
      customClass:{
          cancelButton:'sweet-alert-cancel-button',
          confirmButton:'sweet-alert-submit-button',
          }
      }).then((result)=>{
          if(result.isConfirmed){
            customerAdvancePayAPI(requestBody,postFn)
          }
      })

      
  }
  useEffect(() => {
    if (singleRowId === null && configurationList !== undefined && journalList!==undefined) {
      setFormValues({
        ...formValues,
        journal: findObjFromList(
          "_id",
          journalSalesList,
          "salesJournal",
          configurationList?.sales
        ),
      });
    }
  }, [configurationList,journalList]);

  useEffect(() => {
    if (journalList !== undefined) {
      let stockValuationAccList = journalList?.filter(
        (item) => item.type === "bank" || item.type === "cash"
      );
      setPaymentTypeList(stockValuationAccList);
    }
  }, [journalList]);

  useEffect(() => {

    journalListApiCall();
    viewMultiCurrencyConfiguration();
    chartOfAccountListApiCall();
    getAllTaxSettingsListAPICall();
    viewUOMSubListAPICall();
    if (singleRowId) {
      customerInvoiceSingleViewAPICall({ _id: singleRowId });
    }
    userRole==="user"&& viewAllAllowedBranchesAPICall({ employeeId: userInfo?.data?._id });
  }, []);

  useEffect(() => {
    userRole==="user"&& viewAllAllowedBranchesAPICall({ employeeId: userInfo?.data?._id });
    let subTotal=tableData?.reduce((sum, item) => sum + Number(item?.amount || 0),0)
    let inclusiveTax=tableData?.reduce((sum, item) => sum + Number(item?.inclusiveTax || 0),0)
    let exclusiveTax=tableData?.reduce((sum, item) => sum + Number(item?.exclusiveTax || 0),0)
    let roundedValue=Number(totalsInfo.roundOff)?.toFixed(decimalPosition)
    setTotalsInfo({
      ...totalsInfo,
      subTotal:subTotal,
      inclusiveTax:inclusiveTax,
      exclusiveTax:exclusiveTax,
      total:subTotal+exclusiveTax+Number(roundedValue)
    });

  }, [tableData,totalsInfo.roundOff]);

  useEffect(() => {
    if (editedData === undefined) {
      generateCustomerInvoiceNameAPICall({
        branchId: formValues?.branch?._id,
        invoiceDate: formValues?.invoiceDate,
      },setCreditNoteName);
    }
  }, [formValues?.invoiceDate, formValues?.branch]);

  // edit
  useEffect(() => {
    if (editedData !== undefined && allActiveEmpList!==undefined && journalList!==undefined) {
      setEditDataId(editedData?._id);
      setCreditNoteName(editedData?.seqName || editedData?.name);
      setTotalsInfo({...totalsInfo,roundOff:editedData?.rounding?.toFixed(decimalPosition) || 0?.toFixed(decimalPosition)})

      setFormValues({
        ...formValues,
        isEdit: true,
        status: editedData?.status,
        employee: findObjFromList("_id",allActiveEmpList,"salesPerson",editedData),
        customer:editedData?.customerAddress,
        branch: findObjFromList(
          "_id",
          allBranchesList,
          "branchId",
          editedData
        ),
        invoiceReference: editedData?.invoiceRef || "",
        paymentReference: editedData?.paymentRef || "",
        journal: findObjFromList(
          "_id",
          journalSalesList,
          "journals",
          editedData
        ),
        currency:
          MultiCurrencyView !== undefined &&
          MultiCurrencyView?.isMultiCurrency === true
            ? findObjFromList(
                "_id",
                currencyListUpdated,
                "currency",
                editedData
              )
            : findObjFromList(
                "_id",
                currencyListUpdated,
                "currency",
                companyProfileData
              ),
        accountingDate: editedData?.accountingDate || "",
        invoiceDate: editedData?.invoiceDate || "",
        customerNotes: editedData?.customerNotes || "",
        termsAndConditions: editedData?.termsAndCondition || "",
        paymentStatus: editedData?.paymentStatus,
        lpoNo:editedData?.LpoNo || "",
        paymentTerms:editedData?.paymentTerms || "",

        paymentTerms:findObjFromList(
          "_id",
          paymentTermsList,
          "paymentTerms",
          editedData
        )
      });
      setLog(editedData?.log);
      setPaymentForm({
        ...paymentForm,
        amount: editedData?.amountDue,
      });
    } else {
      clearForm();
    }
  }, [
    editedData,
    allActiveEmpList,
    allBranchesList,
    chartOfAccountList,
    currencyDetails,
    chartOfAccountList,
  ]);

  useEffect(() => {
    if (editedData !== undefined && allTaxList!==undefined) {
      const updatedArray = editedData?.productInfo?.map((r, i) => {

        if (r?.line_type === "Line" || r?.line_type === "lines") {

          let taxesId=r?.taxIdList || []
          let taxObjects = allTaxList?.filter(obj => taxesId.some(taxObj => taxObj._id === obj?._id));

          const newFilteredTaxOptions=r?.includedPrice !== null? allTaxListSalesType?.filter((option) => option.includedPrice === r?.includedPrice)
              : allTaxListSalesType;
          filteredTaxOptions[i] = newFilteredTaxOptions;

          return {
            type: r?.line_type,
            product: r?.prodObj,
            label: r?.label,
            account: findObjFromList(
              "_id",
              chartOfAccountList,
              "accountId",
              r
            ),
            uom: findObjFromList("_id", uomSubList, "uom", r),
            qty: r?.qty,
            unitPrice: r?.unitPrice,
            discount: r?.discount,
            tax: taxObjects,
            amount:
              r?.qty * r?.unitPrice -
              (r?.qty * r?.unitPrice * r?.discount) / 100,

            inclusive: r?.includedPrice,
            prodType:r?.prodType
          };
        } else {
          return {
            type: r?.line_type,
            header: r?.label,
          };
        }
      });
      calculateTax(updatedArray)
    }
  }, [editedData?.productInfo,uomSubList,allTaxList]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const shouldShowAlert = !formValues?.isEdit;
      if (shouldShowAlert) {
        event.preventDefault();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formValues?.isEdit]);

  return (
    <div className="global-page-parent-container">
      <div
        className="new-global-white-bg-container"
        style={{ padding: "0 2%" }}
      >
        <div className="new-global-white-bg-icon-container">
          {isLoading ? (
            <Skeleton
              sx={{ marginTop: "1%" }}
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={40}
            />
          ) : (
            <div
              className="new-global-white-bg-icon-container-left"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Tooltip
                title="Back"
                onClick={() => {
                  if (formValues?.isEdit === false) {
                    const userConfirmed = window.confirm(
                      "Changes you made may not be saved."
                    );

                    if (userConfirmed) {
                      navigateTo(
                        "/userdashboard/accounts/customers/customerInvoices"
                      );
                    }
                  } else {
                    navigateTo(
                      "/userdashboard/accounts/customers/customerInvoices"
                    );
                  }
                }}
              >
                <IconButton>
                  <i class="bi bi-chevron-left"></i>
                </IconButton>
              </Tooltip>
              {formValues?.status === "DRAFT" && (
                <Tooltip title="Edit">
                  <IconButton
                    onClick={
                      editClicked
                        ? submitBtnClicked("edit")
                        : () => {
                            setFormValues({ ...formValues, isEdit: false });
                            setIsEditClicked(true);
                          }
                    }
                    onMouseEnter={handleMouseEnter}
                  >
                    <i
                      class={
                        editClicked
                          ? "bi bi-floppy save-icon"
                          : "bi bi-pencil-square edit-icon1"
                      }
                    ></i>
                    {/* <i class="bi bi-check2-square edit-icon"></i> */}
                  </IconButton>
                </Tooltip>
              )}

              {formValues?.status === "" && (
                <Tooltip title="Save">
                  <IconButton
                    onMouseEnter={handleMouseEnter}
                    onClick={submitBtnClicked("draft")}
                  >
                    <i class="bi bi-floppy save-icon"></i>
                  </IconButton>
                </Tooltip>
              )}

              {formValues?.status === "DRAFT" && !editClicked && (
                <>
                <button
                  onClick={submitBtnClicked("post")}
                  className="btn btn-post"
                >
                  Post
                </button>
                <button
              className="btn btn-primary"
              style={{
                backgroundColor: "#eb4034",
                height: "32px",
                width: "15%",
              }}
              onClick={() => setAlertPopup(true)}
            >
              Return
            </button>
                </>
              )}
              {formValues?.status === "POSTED" &&
                formValues?.paymentStatus === false && (
                  <button
                    onClick={clickRegisterPayment}
                    className="btn btn-post"
                  >
                    Register Payment
                  </button>
                )}
            </div>
          )}
          <div className="status-container">
            {!isLoading && formValues?.status === "DRAFT" && (
              <img src={draftRibbon} alt="" />
            )}
            {!isLoading && formValues?.status === "POSTED" && (
              <img src={postRibbon} alt="" />
            )}
              {!isLoading && formValues?.status === "RETURNED" && (
              <img src={returned} alt="" />
            )}
            {!isLoading &&
              formValues?.status === "PAID" &&
              formValues?.paymentStatus === true && <img src={paidRibbon} />}
          </div>
        </div>
        <hr className="global-hr" />
        <h4 className="global-name">{!isLoading && creditNoteName}</h4>
        {isLoading ? (
          <Skeleton
            sx={{ margin: "1% 0" }}
            variant="rectangular"
            animation="wave"
            width={"100%"}
            height={150}
          />
        ) : (
          <div className="new-global-input-container">
            <div className="new-global-single-input ">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={customerList || []}
                getOptionLabel={(option) => option?.name}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Customer*"
                    focused
                  />
                )}
                onChange={getFormInfo("customer")}
                value={formValues?.customer}
                disabled={formValues?.isEdit}
              />
              <p className="doc-validation-alert">
                {validationAlert?.customer}
              </p>
            </div>

            <div className="new-global-single-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={userRole==="admin" ? allBranchesList: allowedBranchList || []}

                getOptionLabel={(option) =>
                  `${option?.storeCode}-${option?.branchName}`
                }
              isOptionEqualToValue={(option, value) => option._id === value._id}

                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Branch*" focused />
                )}
                onChange={getFormInfo("branch")}
                value={formValues?.branch}
                disabled={formValues?.isEdit}
              />
              <p className="doc-validation-alert">{validationAlert?.branch}</p>
            </div>
            <div className="new-global-single-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={allActiveEmpList || []}
                getOptionLabel={(option) => 
                  option?.staff_name
                }
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Sales Person" focused />
                )}
                onChange={getFormInfo("employee")}
                value={formValues?.employee}
                disabled={formValues?.isEdit}
              />
              <p className="doc-validation-alert">{validationAlert?.employee}</p>
            </div>
            <div className="new-global-single-input">
              <TextField
                id="outlined-basic"
                label="Invoice Refrence"
                variant="outlined"
                type="text"
                focused
                value={formValues?.invoiceReference}
                onChange={getFormInfo("invoiceReference")}
                disabled={formValues?.isEdit}
              />
              <p className="doc-validation-alert">{validationAlert?.invoiceReference}</p>
            </div>
            <div className="new-global-single-input">
              <TextField
                value={formValues?.invoiceDate}
                onChange={getFormInfo("invoiceDate")}
                id="outlined-basic"
                label="Invoice Date*"
                variant="outlined"
                type="date"
                focused
                disabled={formValues?.isEdit}
              />
              <p className="doc-validation-alert">
                {validationAlert?.invoiceDate}
              </p>
            </div>
            <div className="new-global-single-input">
              <TextField
                value={formValues?.paymentReference}
                onChange={getFormInfo("paymentReference")}
                id="outlined-basic"
                label="Payment Reference"
                variant="outlined"
                type="text"
                focused
                disabled={formValues?.isEdit}
              />
            </div>
            <div className="new-global-single-input">
              <TextField
                value={formValues?.accountingDate}
                onChange={getFormInfo("accountingDate")}
                id="outlined-basic"
                label="Accounting Date*"
                variant="outlined"
                type="date"
                focused
                disabled={formValues?.isEdit}
              />
              <p className="doc-validation-alert">
                {validationAlert?.accountingDate}
              </p>
            </div>
            <div className="new-global-single-input">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={journalSalesList || []}
                getOptionLabel={(option) => option?.journalName}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Journal*" focused />
                )}
                value={formValues?.journal}
                onChange={getFormInfo("journal")}
                disabled={formValues?.isEdit}
              />
              <p className="doc-validation-alert">{validationAlert?.journal}</p>
            </div>
            <div className="new-global-single-input">
              {MultiCurrencyView !== undefined &&
              MultiCurrencyView?.isMultiCurrency ? (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={currencyListUpdated || []}
                  getOptionLabel={(option) => option?.name}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Currency*" focused />
                  )}
                  value={formValues?.currency}
                  onChange={getFormInfo("currency")}
                  disabled={formValues?.isEdit}
                />
              ) : (
                <TextField
                  value={formValues?.currency?.name || ""}
                  sx={{ caretColor: "transparent" }}
                  className="disabled-input"
                  id="outlined-basic"
                  label="Currency*"
                  variant="outlined"
                  type="text"
                  focused
                  title="Multi Currency not enabled"
                  disabled={formValues?.isEdit}
                />
              )}
              <p className="doc-validation-alert">
                {validationAlert?.currency}
              </p>
            </div>
            <div
              className="new-global-single-input "
              style={{ width: "22%" }}
            >
              <div className="" style={{ position: "relative" }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={paymentTermsList || []}
                  getOptionLabel={(option) => option?.paymentTerm}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Payment Terms*" focused />
                  )}
                  value={formValues?.paymentTerms}
                  onChange={getFormInfo("paymentTerms")}
                  disabled={formValues?.isEdit}
                />
                {!formValues.isEdit && (
                  <button
                    onClick={() => {
                      setFormValues((prev) => ({
                        ...prev,
                        paymentTerms: null,
                      }));
                      setHandlePaymentTermsbtn(!handlePaymentTermsbtn);
                    }}
                    className="add-row-btn autocomplete-inside-plusbtn"
                  >
                    +
                  </button>
                )}
              </div>
            </div>
            <div className="new-global-single-input">
              <TextField
                value={formValues?.lpoNo}
                onChange={getFormInfo("lpoNo")}
                id="outlined-basic"
                label="LPO No."
                variant="outlined"
                type="text"
                focused
                disabled={formValues?.isEdit}
                />
            </div>
          </div>
        )}
      </div>

      <div className="new-global-white-bg-container">
        {isLoading ? (
          <Skeleton
            sx={{ marginTop: "1%" }}
            variant="rectangular"
            animation="wave"
            width={"100%"}
            height={110}
          />
        ) : (
          <>
            <div
              className="rewards-add-rewards-ctn-top-menu accounts-table-toggle"
              style={{ marginBottom: "1%" }}
            >
              <div
                className="tab-menu-button active"
                id="productMenuBtn"
                onClick={productMenuClick}
              >
                <Typography fontSize={"0.9rem"} fontWeight={600}>
                  Invoice Lines
                </Typography>
              </div>
              <div
                className="tab-menu-button"
                id="departmentMenuBtn"
                onClick={departmentMenuClick}
              >
                <Typography fontSize={"0.9rem"} fontWeight={600}>
                  Other Info
                </Typography>
              </div>
            </div>
            {isActiveTable === "invoiceLines" ? (
              <>
                <TableVirtuoso
                    ref={containerRef}
                    className="global-product-table"
                    style={{ height: tableData?.length === 1 ? "10vh" : tableData?.length < 5 ? `${(tableData?.length) * 7}vh` : "25vh", maxHeight: "24vh" }}
                    data={tableData}
                    fixedHeaderContent={() => (
                      <tr>
                        <th>Product</th>
                        <th>Label</th>
                        <th>Account</th>
                        <th>UOM</th>
                        <th>Qty</th>
                        <th>Unit Price</th>
                        <th>Discount</th>
                        <th>Tax</th>
                        <th>Amount</th>
                        <th style={{ backgroundColor: "#fff" }}></th>
                      </tr>
                   
                  )}

               
                  itemContent={(index, item) => (
                        <>
                          {item.type === "header" ? (
                            <>
                              <td colSpan="9">
                                <input
                                  type="text"
                                  value={item.header}
                                  onChange={handleInputChange(index, "header")}
                                  onMouseEnter={handleMouseEnter}
                                  onMouseLeave={handleMouseEnter}
                                  disabled={formValues?.isEdit}
                                />
                              </td>
                             {
                              (formValues?.status === "" || editClicked )&&  
                             <td style={{ border: "none", width: "4%" }}>
                                <IconButton
                                  onClick={() => handleDelete(index)}
                                  style={{ height: "30px" }}
                                  disabled={formValues?.isEdit}
                                >
                                  <i
                                    className="bi bi-trash3"
                                    style={{
                                      color: "#db0000",
                                      fontSize: "1.3rem",
                                    }}
                                  ></i>
                                </IconButton>
                              </td>}
                            </>
                          ) : (
                            <>
                              <td style={{ minWidth: "180px" }}>
                                <div className="product-table-auto-complete">
                                  <Autocomplete
                                    options={allNewProductList || []}
                                    style={{ maxHeight: "100px" }}
                                    getOptionLabel={(option)=>option?.productName}
                                    isOptionEqualToValue={(option, value) => option._id === value._id}
                                    
                                    renderOption={(props, option) => (
                                      <li {...props} key={option._id}>
                                        <span>{option.productName}</span>
                                      </li>
                                    )}
                                   filterOptions={filterOptions}
                                   renderInput={(params) => (
                                      <TextField
                                        {...params}
                                      />
                                    )}
                                    disabled={formValues?.isEdit}
                                    value={item?.product}
                                    onChange={handleInputChange(
                                      index,
                                      "product"
                                    )}
                                  />
                                </div>
                              </td>
                              <td style={{  width:`${String(item?.label).length * 10}px`,minWidth:"90px" }}>
                                <input
                                  type="text"
                                  onChange={handleInputChange(index, "label")}
                                  value={item?.label}
                                  disabled={formValues?.isEdit}
                                  onMouseEnter={handleMouseEnter}
                                  onMouseLeave={handleMouseEnter}
                                />
                              </td>
                              <td style={{ minWidth: "120px" }}>
                                <div className="product-table-auto-complete">
                                  <Autocomplete
                                    options={accountsList || []}
                                    style={{ maxHeight: "100px" }}
                                    getOptionLabel={(option) =>
                                      option?.accountName
                                    }
                                    disabled={formValues?.isEdit}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseEnter}
                                      />
                                    )}
                                    value={item?.account}
                                    onChange={handleInputChange(
                                      index,
                                      "account"
                                    )}
                                  />
                                </div>
                              </td>
                              <td style={{ minWidth: "50px" }}>
                                <div className="product-table-auto-complete">
                                  <Autocomplete
                                  className="uom-autocomplete"
                                    options={uomSubList?.filter((obj)=>obj?.parentCatId === (item?.product?.parentUomId)) || []}
                                    style={{ maxHeight: "100px" }}
                                    getOptionLabel={(option) => option?.uomName}
                                    isOptionEqualToValue={(option, value) => option._id === value._id}
                                  
                                    disabled={formValues?.isEdit}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseEnter}
                                      />
                                    )}
                                    value={item?.uom}
                                    onChange={handleInputChange(index, "uom")}
                                  />
                                </div>
                              </td>
                              <td style={{ width:`${String(item?.qty).length * 9}px`,minWidth:"40px"}}>
                                <input
                                  type="text"
                                  style={{ textAlign: "right" }}
                                  onChange={handleInputChange(index, "qty")}
                                  value={item?.qty}
                                  disabled={formValues?.isEdit}
                                  onMouseEnter={handleMouseEnter}
                                  onFocus={(e)=>e.target.select()}
                                  onMouseLeave={handleMouseEnter}
                                />
                              </td>
                              <td style={{ maxWidth: "80px" }}>
                                <input
                                  type="text"
                                  style={{ textAlign: "right" }}
                                  onChange={handleInputChange(
                                    index,
                                    "unitPrice"
                                  )}
                                  value={item?.unitPrice}
                                  disabled={formValues?.isEdit}
                                  onMouseEnter={handleMouseEnter}
                                  onFocus={(e)=>e.target.select()}
                                  onMouseLeave={handleMouseEnter}
                                />
                              </td>
                              <td style={{ maxWidth: "80px" }}>
                                <input
                                  type="text"
                                  style={{ textAlign: "right" }}
                                  onChange={handleInputChange(
                                    index,
                                    "discount"
                                  )}
                                  value={item?.discount}
                                  disabled={formValues?.isEdit}
                                  onMouseEnter={handleMouseEnter}
                                  onMouseLeave={handleMouseEnter}
                                  onFocus={(e)=>e.target.select()}
                                />
                              </td>
                              <td style={{maxWidth:"245px",minWidth:item?.tax.length===0?"90px":item?.tax.length===1?"200px":`265px`}}>
                                <div className="product-table-auto-complete">
                                  <Autocomplete
                                  className="multiple-tax-autocomplete"
                                    multiple
                                    options={filteredTaxOptions[index] || []}
                                    getOptionLabel={(option) => option?.taxName}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                      />
                                    )}
                                    value={item.tax}
                                    onChange={handleInputChange(index, "tax")}
                                    disabled={formValues?.isEdit}
                                  />
                                </div>
                              </td>
                              <td style={{ width:`${String(item?.amount).length * 9}px`,minWidth:"40px"}}>
                                <input
                                  type="text"
                                  style={{ textAlign: "right" }}
                                  value={item?.amount}
                                  disabled={formValues?.isEdit}
                                  onMouseEnter={handleMouseEnter}
                                  onMouseLeave={handleMouseEnter}
                                  readOnly
                                />
                              </td>
                              {
                                (formValues?.status === "" || editClicked )&&  
                                
                                <td style={{ border: "none", width: "4%" }}>
                                <IconButton
                                  onClick={() => handleDelete(index)}
                                  style={{ height: "30px" }}
                                  disabled={formValues?.isEdit}
                                >
                                  <i
                                    className="bi bi-trash3"
                                    style={{
                                      color: "#db0000",
                                      fontSize: "1.3rem",
                                    }}
                                  ></i>
                                </IconButton>
                              </td>}
                            </>
                          )}
                        </>
                      )}
                   />
               {
                (formValues?.status === "" || editClicked )&&  
               <div className="add-row-btn-container">
                  <div
                    className="global-single-input auto-complete add-line-autocomplete"
                    style={{ width: "76%" }}
                  >
                    <Autocomplete
                      options={addRowData || []}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => <TextField {...params} />}
                      value={addRowInput}
                      onChange={(e, newValue) => setAddRowInput(newValue)}
                      disabled={formValues?.isEdit}
                    />
                  </div>
                  <button
                    disabled={formValues?.isEdit}
                    onClick={() => addRow()}
                    className="add-row-btn"
                  >
                    +
                  </button>
                </div>}
              </>
            ) : (
              <div
                className="new-global-input-container"
                style={{ flexDirection: "column" }}
              >
                <div className="new-global-single-input">
                  <TextField
                    id="outlined-basic"
                    label="Order Date*"
                    variant="outlined"
                    type="date"
                    focused
                    disabled={formValues?.isEdit}
                    value={formValues?.orderDate}
                    onChange={getFormInfo("orderDate")}
                  />
                </div>

                <div className="global-product-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Account</th>
                        <th>Partner</th>
                        <th>Employee</th>
                        <th>Branch</th>
                        <th>Debit</th>
                        <th>Credit</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <input type="text" />
                        </td>
                        <td>
                          <input type="text" />
                        </td>
                        <td>
                          <input type="text" />
                        </td>
                        <td>
                          <input type="text" />
                        </td>
                        <td>
                          <input type="text" />
                        </td>
                        <td>
                          <input type="text" />
                        </td>
                      </tr>

                      <tr>
                        <td style={{ border: "0" }} colSpan={3}></td>
                        <td style={{ border: "0", textAlign: "end" }}>
                          Total :
                        </td>
                        <td style={{ border: "0", textAlign: "start" }}>
                          0.00
                        </td>
                        <td style={{ border: "0", textAlign: "start" }}>
                          0.00
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      {isActiveTable === "invoiceLines" && (
        <div
          className="new-global-white-bg-container"
          style={{
            display: "flex",
            flexWrap: "wrap",
            padding: "8px 2%",
          }}
        >
          <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  className="new-global-single-input"
                  style={{ width: "35%" }}
                >
                  <p>Customer Notes</p>
                  <textarea
                    value={formValues?.customerNotes}
                    onChange={getFormInfo("customerNotes")}
                    disabled={formValues?.isEdit}
                    name=""
                    id=""
                    cols="50"
                    rows="5"
                  ></textarea>
                </div>

                <div
                  className="new-global-single-input"
                  style={{ width: "35%" }}
                >
                  <p>Terms & Conditions</p>
                  <textarea
                    value={formValues?.termsAndConditions}
                    onChange={getFormInfo("termsAndConditions")}
                    disabled={formValues?.isEdit}
                    name=""
                    id=""
                    cols="50"
                    rows="5"
                  ></textarea>
                </div>

                <div
                  className="new-global-single-input"
                  style={{ maxWidth: "250px" }}
                  onMouseLeave={()=>setTotalsInfo({...totalsInfo,roundOff:Number(totalsInfo?.roundOff)?.toFixed(decimalPosition)})}
                >
                  <div className="subtotal-ctn">
                    <div className="subtotal-ctn-left">
                      <p>Sub Total</p>
                      {totalsInfo?.inclusiveTax !== 0 && <p>Inclusive Tax</p>}

                      {totalsInfo?.exclusiveTax !== 0 && <p>Exclusive Tax</p>}
                      {totalsInfo?.exclusiveTax === 0 &&
                        totalsInfo?.inclusiveTax === 0 && <p>Tax</p>}
                    </div>
                    <div className="subtotal-ctn-right">
                      <p>{totalsInfo?.subTotal?.toFixed(decimalPosition)}</p>
                      {totalsInfo?.inclusiveTax !== 0 && (
                        <p>
                          {totalsInfo?.inclusiveTax?.toFixed(decimalPosition)}
                        </p>
                      )}
                      {totalsInfo?.exclusiveTax !== 0 && (
                        <p>
                          {totalsInfo?.exclusiveTax?.toFixed(decimalPosition)}
                        </p>
                      )}
                      {totalsInfo?.exclusiveTax === 0 &&
                        totalsInfo?.inclusiveTax === 0 && <p>0.00</p>}
                    </div>
                  </div>
                  <hr
                    className="global-hr"
                    style={{ width: "80%", marginLeft: "25%" }}
                  />
                  <div className="subtotal-ctn">
                    <div className="subtotal-ctn-left">
                      <p style={{ color: "#000", fontWeight: "600" }}>Total</p>
                      {
                     configurationList?.isRoundingEnabled &&
                     <p >Round Off</p>}
                    </div>
                    <div className="subtotal-ctn-right">
                      <p>{totalsInfo?.total?.toFixed(decimalPosition)} </p>
                      {
                      configurationList?.isRoundingEnabled &&  
                      <input 
                      type="number"
                      onFocus={(e)=>e.target.select()}
                      value={totalsInfo?.roundOff}
                      disabled={formValues?.isEdit}
                      onChange={roundOffOnChange}
                      onMouseLeave={()=>setTotalsInfo({...totalsInfo,roundOff:Number(totalsInfo?.roundOff)?.toFixed(decimalPosition)})}
                      
                      />}
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ width: "100%", display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "75%",
                  }}
                >
                  <div className="file-picker-new">
                    <input
                      disabled={formValues?.isEdit}
                      type="file"
                      id="fileUpload"
                      onChange={changeImage}
                    />
                    <label htmlFor="fileUpload">
                      <img src={uploadFile} alt="" />
                      Upload File
                    </label>
                  </div>
                  <div style={{ display: "flex", margin: "0 0 0 22px" }}>
                    {imageDisplay?.map((r, i) => (
                      <div className="uploaded-image-list">
                        <img src={r} alt="" />
                        <div className="image-remove-icon-new">
                          <IconButton
                            disabled={formValues?.isEdit}
                            onClick={() => removeImageFn(i)}
                          >
                            <CloseOutlined
                              sx={{ color: "#fd0301", fontSize: "0.9rem" }}
                            />
                          </IconButton>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {editedData !== undefined && (
                  <div className="payment-info-amounts-ctn">
                    {editedData?.paymentsDone?.map((r, i) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          style={{
                            margin: "0",
                            fontSize: "0.8rem",
                            color: "rgb(111 109 109)",
                          }}
                        >
                          {r?.name}
                        </p>
                        <p
                          style={{
                            margin: "0",
                            fontSize: "0.8rem",
                            color: "#000",
                            fontWeight: 600,
                          }}
                        >
                          {r?.totalAmount?.toFixed(decimalPosition)}
                        </p>
                      </div>
                    ))}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        style={{
                          margin: "1% 0",
                          fontSize: "0.8rem",
                          color: "rgb(111 109 109)",
                        }}
                      >
                        Amount Due
                      </p>
                      <p
                        style={{
                          margin: "1% 0",
                          fontSize: "0.8rem",
                          color: "#000",
                          fontWeight: 600,
                        }}
                      >
                        {editedData?.amountDue?.toFixed(decimalPosition)}
                      </p>
                    </div>
                    {
                      formValues?.status === "POSTED" &&
                    editedData?.advancePayments && editedData?.advancePayments.length!==0 && editedData?.amountDue>0 &&

                    <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        style={{
                          margin: "1% 0",
                          fontSize: "0.8rem",
                          fontWeight:"600",
                          borderBottom:"1px solid rgb(182,182,182)"
                        }}
                      >
                        Advance Payments
                      </p>
                    </div>

                    {
                      editedData?.advancePayments?.map((list,i)=>(
                        <div
                        key={i}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        style={{
                          margin: "1% 0",
                          fontSize: "0.8rem",
                          color: "rgb(111 109 109)",
                        }}
                      >
                        {list?.name}
                      </p>
                      <p
                        style={{
                          margin: "1% 0",
                          fontSize: "0.8rem",
                          color: "#000",
                          fontWeight: 600,
                        }}
                      >
                        {list?.advBalanceAmt?.toFixed(decimalPosition)}
                      </p>

                      <button 
                      onClick={paymentAdvanceClick(list)}
                      className="advance-payments-add-btn justify-center">Add</button>
                    </div>
                      ))
                    }
                    </>
                    }
                  </div>
                )}
              </div>
            
        </div>
      )}

      {formValues?.status !== "" && log.length !== 0 && (
        <div
          className="new-global-white-bg-container new-log-container"
          style={{ padding: "4px" }}
        >
          <div
            style={{ padding: "8px", cursor: "pointer" }}
            onClick={clickLogView}
          ></div>
          <div
            className={logView ? "pointer-up" : "pointer"}
            onClick={clickLogView}
            style={{ cursor: "pointer" }}
          ></div>
          {logView && (
            <div className="single-log-container">
              {log?.map((r, i) => (
                <div className="single-log-item">
                  <div className="single-log-item-line-image">
                    <hr className="single-log-item-line" />
                    <div className="single-log-item-image">
                      <img src={logoImage} alt="" />
                    </div>
                    <hr className="single-log-item-line" />
                  </div>
                  <p className="heading">{r?.status}</p>
                  <p className="name">{r?.empId}</p>
                  <p className="date-time">{r?.date}</p>
                  <p className="date-time">{r?.time}</p>
                </div>
              ))}
            </div>
          )}

          {/* <div className='single-log-container-scroll-line'></div> */}
        </div>
      )}

      {/* register payment */}
      <Dialog open={registerPayment} onClose={() => setRegisterPayment(false)}>
        <div className="payment-popup">
          <h4>Register Payment</h4>
          <hr className="global-hr" />

          <div className="global-input-field-container">
            <div className="global-single-input">
              <p>Date</p>
              <input
                type="date"
                value={paymentForm.date}
                onChange={getPaymentForm("date")}
              />
              <p className="doc-validation-alert">{validationAlert?.date}</p>
            </div>
            <div className="global-single-input auto-complete">
              <p>Payment Type</p>
              <Autocomplete
                options={paymentTypeList || []}
                getOptionLabel={(option) => option?.journalName}
                renderInput={(params) => (
                  <TextField {...params} placeholder="select payment type" />
                )}
                value={paymentForm.paymentType}
                onChange={getPaymentForm("paymentType")}
              />
              <p className="doc-validation-alert">
                {validationAlert?.paymentType}
              </p>
            </div>
            <div className="global-single-input auto-complete">
              <p>Type</p>
              <Autocomplete
                options={["Manual", "Cheque"]}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField {...params} placeholder="select type" />
                )}
                value={paymentForm.type}
                onChange={getPaymentForm("type")}
              />
              <p className="doc-validation-alert">
                {validationAlert?.paymentFormType}
              </p>
            </div>
            {paymentForm?.type === "Cheque" && (
              <>
                <div className="global-single-input">
                  <p>Cheque Date</p>
                  <input
                    type="date"
                    value={paymentForm.chequeDate}
                    onChange={getPaymentForm("chequeDate")}
                  />
                  <p className="doc-validation-alert">
                    {validationAlert?.paymentFormCheckDate}
                  </p>
                </div>
              </>
            )}
            <div className="global-single-input">
              <p>
                {paymentForm?.type === "Cheque"
                  ? "Cheque No."
                  : "Transaction No"}
              </p>
              <input
                type="text"
                value={paymentForm.chequeNo}
                onChange={getPaymentForm("chequeNo")}
              />
              <p className="doc-validation-alert">
                {validationAlert?.paymentFormCheckNo}
              </p>
            </div>
            <div className="global-single-input">
              <p>Amount</p>
              <input
                type="text"
                value={paymentForm.amount}
                onChange={getPaymentForm("amount")}
              />
              <p className="doc-validation-alert">{validationAlert?.amount}</p>
            </div>
          </div>
          <hr className="global-hr" />
          <div>
            <button onClick={registerPaymentClick} className="btn btn-primary">
              Post
            </button>
            <button
              className="btn btn-secondary-outlined"
              onClick={() => setRegisterPayment(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Dialog>
    
       <Dialog
        open={alertPopup}
        onClose={() => {
          alertPopup();
        }}
        maxWidth="lg"
      >
        <div style={{ textAlign: "center", paddingTop: "24px" }}>
          {" "}
          <ReportProblemOutlinedIcon
            sx={{ color: " #d50808", fontSize: "xx-large" }}
          />
        </div>
        <div
          className="shift-end-button-password-container"
          style={{ width: "25vw", padding: "2% 4%" }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              margin: "2% 0",
              alignSelf: "center",
              paddingBottom: "14px",
            }}
          >
            Are you sure you want to return
          </div>
          <hr
            style={{
              backgroundColor: "#bfbfc3",
              width: "100%",
              height: "2px",
              marginBottom: "27px",
            }}
          ></hr>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              style={{ height: "28px", width: "28%" }}
              className="btn btn-primary"
              onClick={submitBtnClicked("return")}
            >
              Yes
            </button>
            <button
              onClick={() => setAlertPopup(false)}
              style={{ height: "28px", width: "28%" }}
              className="btn btn-secondary-outlined"
            >
              No
            </button>
          </div>
        </div>
      </Dialog>

      {/* alert */}
      <Dialog
        // fullScreen={fullScreen}
        open={alertDialog}
        onClose={() => setAlertDialog(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {`Are you sure you want to save without including the lines? `}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Some lines are missing account. These lines will be removed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setAlertDialog(false)}>
            Disagree
          </Button>
          <Button onClick={() => handleDialogAgree()} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessSnackbar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />

      <LoadingForm loading={loadingForm} />
       <PaymentTerms handleDialog={handlePaymentTermsbtn} setHandleDialog={setHandlePaymentTermsbtn} />
    </div>
  );
};
