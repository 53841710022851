import React,{useState,useEffect} from "react";
import "../../css/Single Components/filePicker.css";
import uploadImage from "../../Assets/Images/upload-image.png";
import videoThumb from "../../Assets/Images/video thumb.png";
import { IconButton } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
export const FilePicker = (props) => {
  const { id, setImage,uploadImageUrl,onChange, type,disabled,styleImg,position,right } = props;
  const [imageURL, setImageURL] = useState();
  const [disabledCheck,setDisabledCheck]=useState(false);
  useEffect(()=>{
    if(disabled==="true"||disabled){
      setDisabledCheck(true)
    }
    else{
      setDisabledCheck(false)
    }
  },[disabled])
  // console.log(disabled);  
  useEffect(() => {
    let imageUrl = undefined;
    if (typeof uploadImageUrl === "string") {
      imageUrl = uploadImageUrl;
    } else if (
      uploadImageUrl !== undefined &&
      uploadImageUrl !== null
    ) {
      imageUrl = URL.createObjectURL(uploadImageUrl);
      console.log(imageUrl);
    }
    setImageURL(imageUrl);
  }, [uploadImageUrl]);

  const clickCloseImage = () => {
    setImageURL(undefined);
    setImage(null);
  };

  
  return (
    <div className="custom-image-parent-container" style={{position:position,right:right}}>
      {imageURL === undefined && (
        <div className="file-picker-container">
          <input disabled={disabledCheck}
            type="file"
            id={id}
            onChange={onChange}
            // accept="image/png, image/gif, image/jpeg, video/mp4"
          />
          <label htmlFor={id} disabled={disabledCheck}>
            <img
              
              src={type === "video" ? videoThumb : uploadImage}
              alt="imageUpload Icon"
            />
          </label>
        </div>
      )}
      {imageURL !== undefined && (
        <div className="image-preview-container">
          <IconButton
            onClick={clickCloseImage}
            sx={{ border: "1px solid #fd0301", padding: "0" }}
            className="closeIcon-image"
            title="Remove Image"
            disabled={disabledCheck}
          >
            <CloseOutlined sx={{ color: "#fd0301" }} />
          </IconButton>
          <img src={imageURL} alt="Preview" />
        </div>
      )}
    </div>
  );
};
