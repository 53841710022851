import React, { useEffect, useState } from 'react'
import './InvoiceMarginReport.css'
import { IconButton, Skeleton, Tooltip } from '@mui/material';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import ReactToPrint from "react-to-print";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useLocation, useNavigate } from 'react-router-dom';
import { InvoiceMarginReportSingleViewAPIcall } from './GeneralReportAPI';
import { useSelector } from 'react-redux';
import { filterObjFromList } from '../../../../../Js/generalFunctions';

function InvoiceMarginReportSingleView() {
    let navigate = useNavigate();
    let location=useLocation()

    const invoiceSingleListData = useSelector((state) => state?.generalReport?.InvoiceMarginReportSingleList)
    console.log(invoiceSingleListData,'ffffff')
    const currencyListUpdated = useSelector(
        (state) => state.viewUpdatedCurrency.value
    );
    const companyProfileData = useSelector(
        (state) => state?.companyProfileSlice?.userValue)

    const [FormDataInfo, setFormDataInfo] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [symbol, setSymbol] = useState(null);

    const decimalPosition = localStorage.getItem("decimalPosition");
    const singleViewId = localStorage.getItem("invoiceSingleViewId")

    const handleDataFromChild = (data) => {
        setFormDataInfo(data);
    };

    useEffect(() => {
        let currencyObj = filterObjFromList("_id", currencyListUpdated, "currency", companyProfileData)
        setSymbol(currencyObj?.symbol)
    }, [companyProfileData, currencyListUpdated])


    useEffect(() => {
        if (FormDataInfo.length !== 0) {
            InvoiceMarginReportSingleViewAPIcall({
                _id: singleViewId,
                search: FormDataInfo?.search,

            })
        }
    }, [FormDataInfo, singleViewId])


    return (
        <div className="in-or-out-container" style={{margin:'0'}}>
            <div className="in-or-out-report-wrapper" style={{backgroundColor:"unset",padding:"5px"}}>
                <div className="sub-nav-head" style={{backgroundColor:"white",paddingInline:"10px"}}>
                <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "0.8em",
            }}
          >
           
            <div
              style={{
                background: "unset",
                borderRadius: "0",
                maxWidth: "20em",
                cursor:'pointer',
                padding:"0"
              }}
              className="path-name-wrapper"
              onClick={() => {
                navigate(
                  "/userdashboard/accounts/report/generalReport/InvoiceMarginReport"
                );
                localStorage.removeItem("invoiceSingleViewId");
              }}
            >
              <h3 className="path-name" style={{ color: "black",paddingRight:"0" }}>
              Invoice Margin Report &gt;
              </h3>
            </div>
            <div
              className="path-name-wrapper"
              style={{
                background: "unset",
                maxWidth: "17em",
                transition: "max-width 1s ease-in-out",
              }}
            >
              <h3 className="path-name" style={{ color: "black" }}>
                {location?.state?.invoiceNo}
              </h3>
            </div>
          </div>
                    {/* <div>
                        <div className="path-name-wrapper" style={{ backgroundColor: '#c3d4f7', maxWidth: "17em" }}>
                            <h3 style={{ color: '#06066f' }} className="path-name">Invoice Margin Report</h3>
                        </div>
                    </div> */}
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        width:'62%'
                    }}>
     <Tooltip title="Share">
              <IconButton>
                <i class="bi bi-share" style={{ backgroundColor: "#9797e9", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
              </IconButton>
            </Tooltip>

            <ReactToPrint
              trigger={() => (
                <IconButton className="printer-div" style={{ padding: "1px" }}>
                  <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
                </IconButton>
              )}
            //   content={() => paymentsPrintNew}
            />

            <Tooltip title="Download">
              <IconButton >
                <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
              </IconButton>
            </Tooltip>

                        <CategorySearchandFilter
                            onData={handleDataFromChild}
                            isDate={false}
                            isBranch={false}
                            isFilter={false}

                        />
                    
                    </div>
                </div>
                <div className="invoice-table-container" style={{width:"100%",marginTop:"5px",marginLeft:"0",height:"85vh"}}>
                    <div className="invoice-main-div">
                      
                        <table style={{paddingTop:"5px"}}>
                            <thead>
                                <tr>
                                    <th>PRODUCT</th>
                                    <th>QTY</th>
                                    <th>UNIT PRICE</th>
                                    <th>NET SALES</th>
                                    <th>NET COST</th>
                                    <th>PROFIT</th>
                                    <th>PROFIT MARGIN %</th>
                                </tr>
                            </thead>
                            <tbody>

                                {isLoading ? (
                                    [...Array(10)].map((r, i) => (
                                        <tr key={i}>
                                            <td colSpan={8}>
                                                <Skeleton
                                                    variant="rectangular"
                                                    animation="wave"
                                                    width={"100%"}
                                                    height={40}
                                                />
                                            </td>
                                        </tr>
                                    ))
                                ) : invoiceSingleListData?.report !== undefined && invoiceSingleListData?.report?.length !== 0 ? (
                                    invoiceSingleListData?.report
                                        ?.slice(0)
                                        ?.map((r, i) => (
                                            <tr key={i}>
                                                <td>{r?.productName}</td>
                                                <td>{r?.qty?.toFixed(decimalPosition)}</td>
                                                <td>{symbol}&nbsp;{r?.unitPrice?.toFixed(decimalPosition)}</td>
                                                <td>{symbol}&nbsp;{r?.netSale?.toFixed(decimalPosition)}</td>
                                                <td>{symbol}&nbsp;{r?.cost?.toFixed(decimalPosition)}</td>
                                                <td>{symbol}&nbsp;{r?.profit?.toFixed(decimalPosition)}</td>
                                                <td>{r?.profitMargin?.toFixed(decimalPosition)}</td>
                                            </tr>
                                        ))
                                ) : (
                                    <tr>
                                        <td style={{ textAlign: 'center' }} colSpan={7}>No Data</td>
                                    </tr>
                                )}
                            </tbody>
                            <tr>
                                <td style={{ fontWeight: 'bold' }} colSpan={3} >Total</td>
                                <td style={{ fontWeight: 'bold' }}>{symbol}&nbsp;{invoiceSingleListData?.total !== undefined && invoiceSingleListData?.total?.netSale?.toFixed(decimalPosition)}</td>
                                <td style={{ fontWeight: 'bold' }}>{symbol}&nbsp;{invoiceSingleListData?.total !== undefined && invoiceSingleListData?.total?.cost?.toFixed(decimalPosition)}</td>
                                <td style={{ fontWeight: 'bold' }}>{symbol}&nbsp;{invoiceSingleListData?.total !== undefined && invoiceSingleListData?.total?.profit?.toFixed(decimalPosition)}</td>
                                <td style={{ fontWeight: 'bold' }}>{invoiceSingleListData?.total !== undefined && invoiceSingleListData?.total?.profitMargin?.toFixed(decimalPosition)}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            {/* <LoadingForm loading={isLoading} /> */}
        </div>
    )
}

export default InvoiceMarginReportSingleView