import { Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { CostingMethod } from './CostingMethod'
import { DiscountConfig } from './DiscountConfig'
import { TaxConfig } from './TaxConfig'
import { CurrencyConfig } from './CurrencyConfig'
import "../../../../../css/Offer/offerListed.css"
export const ConfigurationPurchase = () => {
  const [isActive,setIsActive]=useState("costingMethodMenuBtn")
  const costingMethodClick=()=>{
    document.getElementById("costingMethodMenuBtn").classList.add("active")
    // document.getElementById("discountConfigMenuBtn").classList.remove("active")
    // document.getElementById("taxConfigMenuBtn").classList.remove("active")
    document.getElementById("CurrencyConfigMenuBtn").classList.remove("active")
    setIsActive("costingMethodMenuBtn")
  }

  const discountConfigClick=()=>{
    document.getElementById("costingMethodMenuBtn").classList.remove("active")
    // document.getElementById("discountConfigMenuBtn").classList.add("active")
    // document.getElementById("taxConfigMenuBtn").classList.remove("active")
    document.getElementById("CurrencyConfigMenuBtn").classList.remove("active")
    setIsActive("discountConfigMenuBtn")
  }

  const taxConfigClick=()=>{
    document.getElementById("costingMethodMenuBtn").classList.remove("active")
    // document.getElementById("discountConfigMenuBtn").classList.remove("active")
    // document.getElementById("taxConfigMenuBtn").classList.add("active")
    document.getElementById("CurrencyConfigMenuBtn").classList.remove("active")
    setIsActive("taxConfigMenuBtn")
  }

  const CurrencyConfigClick =()=>{
    document.getElementById("costingMethodMenuBtn").classList.remove("active")
    // document.getElementById("discountConfigMenuBtn").classList.remove("active")
    // document.getElementById("taxConfigMenuBtn").classList.remove("active")
    document.getElementById("CurrencyConfigMenuBtn").classList.add("active")
    setIsActive("CurrencyConfigMenuBtn")

  }

  return (
    <div className="global-page-parent-container">
       <p className="breadcrumb">Purchase Manager &gt; Configuration</p>
       <div className="offer-listed-container">
         <div className="offer-listed-container-top">
         <div className="rewards-add-rewards-ctn-top-menu">
            <div
              className="tab-menu-button active"
              id="costingMethodMenuBtn"
              onClick={costingMethodClick}
             
            >
              <Typography fontSize={"0.9rem"} fontWeight={600}>
                Costing Method
              </Typography>
            </div>
            {/* <div
              className="tab-menu-button"
              id="discountConfigMenuBtn"
              onClick={discountConfigClick}
             
            >
              <Typography fontSize={"0.9rem"} fontWeight={600}>
                Discount Configuration
              </Typography>
            </div>
            <div
              className="tab-menu-button"
              id="taxConfigMenuBtn"
              onClick={taxConfigClick}
             
            >
              <Typography fontSize={"0.9rem"} fontWeight={600}>
                Tax Configuration
              </Typography>
            </div> */}

            <div
              className="tab-menu-button"
              id="CurrencyConfigMenuBtn"
              onClick={CurrencyConfigClick}
            >
              <Typography fontSize={"0.9rem"} fontWeight={600}>
               Currency Configuration
              </Typography>
            </div>


          </div>

         </div>

         {
          isActive === "costingMethodMenuBtn"?
           <CostingMethod/>
          :isActive === "discountConfigMenuBtn"?
          <DiscountConfig/>
          :isActive ==="taxConfigMenuBtn"?
          <TaxConfig/>
          :isActive ==="CurrencyConfigMenuBtn" ?
          <CurrencyConfig/>
          :undefined
         }
       </div>
    </div>
  )
}

