import React from "react";
import "./dashboardCardStyle.css";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import { cardTypes } from "../../util/AccountDashboardUtils";
const DashboardCard = ({ type, showData, label,currency }) => {
  return (
    <div
      className={`dash-card-ctr ${
        type === cardTypes.LARGE && "large-dash-cntr"
      }`}
    >
      <div
        className={`multiple-cntr ${
          (type === cardTypes.MEDIUM || type === cardTypes.SMALL) &&
          " dash-cntr-same"
        } `}
      >
        <span
          className={`dash-name ${
            type === cardTypes.SMALL && " dash-text-transform"
          }`}
        >
          {label || ""}
        </span>

        <span className="dash-amt dash-loss">
          {type === cardTypes.SMALL ? (`${showData?.amount || 0} ${showData?.count||""} `) : `${currency||""} ${showData?.amount?.toFixed(2)||""}`}
        </span>
      </div>
      {type === cardTypes.LARGE && (
        <div>
          <div>
            <span className="sub-amt dash-profit">{`${currency} `}</span>
            <CallReceivedIcon color="success" sx={{ fontSize: "10px" }} />
          </div>
          <div>
            <span className="sub-amt dash-loss">{`${currency} `}</span>
            <CallMadeIcon color="error" sx={{ fontSize: "10px" }} />
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardCard;
