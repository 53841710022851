import {Skeleton } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { GRNFilterAPICall, getGRNpoViewAPICall} from "./grnListingAPI";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import store from "../../../../../Redux/store";
import {get_grn_list_row_data_list } from "./grnListingSlice";
import { convertDateFormat} from "../../../../../Js/Date";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import CustomPagination from "../../../../Single Components/CustomPagination";
import axios from "axios";
import { setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";

export const GrnListing1 = () => {
  const filtereDataOptions = useSelector((state) => state.listCategory);
  let navigateTo = useNavigate();
  const [path, setPath] = useState(false);


  const tableList = useSelector(
    (state) => state.goodsReceivedNoteUpdatedSlice.apiResList
  );
  const grnList = useSelector((state) => state.goodsReceivedNoteUpdatedSlice.grnViewList)


  const [filteredArray, setFilteredArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const GRNpoId = localStorage.getItem("singleViewByIdgrn")
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = filteredArray?.pages;

  const [selectedHeading, setSelectedHeading] = useState("GRN");
  const [selectedBranch, setSelectedBranch] = useState(null);

  const handleClickRow = (row) => {
    localStorage.removeItem("singleViewPoById");
    localStorage.setItem("singleViewById", row?._id);
    localStorage.setItem("sequence", row?.purchaseOrderName)
    store.dispatch( setFilterActive(window.location.pathname))
    store.dispatch(get_grn_list_row_data_list({ grnListSingleRowData: row }));
    navigateTo("/userdashboard/purchaseManager/GoodsReceivedNote/SingleView")
  };
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };
  useEffect(() => {
    if (GRNpoId !== null) {
      grnList !== undefined && setFilteredArray(grnList)
      setPath(true)
    }
    else {
      setPath(false)
      localStorage.removeItem("singleViewById");
      GRNpoId === null && setFilteredArray(tableList);
    }

  }, [grnList, tableList])

  useEffect(() => {
    if (GRNpoId) {
      getGRNpoViewAPICall({ purchaseId: GRNpoId }, setIsLoading);

    } else {
     // viewGrnUpdatedListAPICall({ branchId: branchId === "null" ? null : branchId }, setIsLoading);

    }
  }, [GRNpoId]);

  const changeColor = (heading) => {
    setSelectedHeading(heading);
  };


  const handleBackpage = () => {
    navigateTo("/userdashboard/purchaseManager/NewPurchaseOrder")
  }
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };

  useEffect(() => {
    // const source = axios.CancelToken.source();
    if (FormDataInfo.length !== 0) {
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData &&
      GRNFilterAPICall({
        fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
        toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
        location: FormDataInfo.location,
        branchList: FormDataInfo.branchList,
        status: FormDataInfo.status,
        search: FormDataInfo.search,
        index: currentPage - 1,
      }, setIsLoading);
    };
    // return () => {
    //   source.cancel('Request canceled by cleanup');
    // };
  }, [FormDataInfo, currentPage]);

  return (
    <>
      <div className="global-page-parent-container">
        <div className="global-white-bg-container">
          {path && (
            <div className="first-main-container-div">
              <div className="first-main-heading-div" id="headingDiv">
                <p
                  style={{
                    marginLeft: "14px",
                    color: selectedHeading === "Purchase Order" ? "black" : "gray",
                  }}
                  onClick={() => { changeColor("Purchase Order"); handleBackpage() }}
                >
                  Purchase Order
                </p>
                <p
                  style={{
                    color: selectedHeading === "GRN" ? "black" : "gray",
                  }}
                >
                  GRN
                </p>

              </div>
            </div>
          )}
          <div className="justify-space-between"
            style={{ gap: "0 12px", display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}
          >
             {FormDataInfo?.branchList?.length > 0 && (
              <div style={{ marginLeft: '1%' }}>
                <h3>Branch name: {selectedBranch?.storeCode}-{selectedBranch?.branchName}</h3>
              </div>
            )}
             <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: FormDataInfo?.branchList?.length ? '72%' : '100%'
            }}>
            <CategorySearchandFilter
              statusOptions={['Complete', 'Drafted', 'Returned']}
              onData={handleDataFromChild}
              isLocation={true}
              isStatus={true}
              setFormDataInfo={setFormDataInfo}
              onBranchSelect={handleBranchSelect}
            />
            </div>
          </div>
          <div
            className="create-button-blue-container"
          >
            <h3>{"Goods Received Note"}</h3>
            <CustomPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>

        </div>

        <div className="new-global-table-container">
          <table>
            <thead>
              <tr>
                <th>No</th>
                <th>Name</th>
                <th>Date</th>
                <th>Branch</th>
                <th>Location</th>
                <th>Vendor</th>
                <th>Created By</th>
                <th>Status</th>

              </tr>
            </thead>
            <tbody>
              {isLoading === true ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={9}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : filteredArray?.list?.length !== 0 ? (
                filteredArray?.list
                  ?.slice(0)
                  
                  ?.map((r, i) => (
                    <tr onClick={() => handleClickRow(r)} key={i}>
                      <td>{i + 1}</td>
                      <td>{r?.name}</td>
                      <td>{convertDateFormat(r?.date)}</td>
                      <td>{r?.branchId}</td>
                      <td>{r?.locationName}</td>
                      <td>{r?.supplierName}</td>
                      <td>{r?.CREATEDBY}</td>
                      <td className="table-data-box">
                        {r?.status === "POSTED" ? (
                          <span className="post"> POSTED</span>
                        ) : r?.status === "DRAFTED" 
                                         
                         ? (
                          <span className="draft"> DRAFTED</span>
                        ) : r?.status === "Returned" ? (
                          <span className="return">RETURN</span>
                        ) : (
                          "---"
                        )}
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan={7}>No data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
