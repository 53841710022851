import { Autocomplete, IconButton, TextField, Tooltip } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import ReactToPrint from 'react-to-print'
import { viewAllAllowedBranchesAPICall, viewAllBranchesAPICall } from '../../../../API/Settings/Company Settings/companySettingsAPI'
import { useSelector } from 'react-redux'
import { viewShiftAPICall } from '../../../../API/Settings/Shift/viewShiftAPI'
import { listAggregatorsAPI } from '../Configuration/Aggregator/aggregatorAPI'
import { ListAggregatorAPI } from './ReportAPI'
import Template from "./PosAggregatePrint"

const PosAggregatorReport = () => {
    let paymentsPrint = useRef();

    const decimalPosition = localStorage.getItem("decimalPosition");

    const userRole = useSelector((state) => state.userRoleSlice.value)
    const userInfo = useSelector((state) => state.loginResponseSlice.value);
    const allowedBranchList = useSelector((state) => state.allAllowedBranchesSlice.value);
    const allShiftList = useSelector((state) => state.viewShiftSlice.value);
    const aggregatorList = useSelector((state) => state.aggregatorSlice.aggregatorList)

    const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
    const aggregatorReportList = useSelector((state) => state.posReportshiftSlice.aggregatorReport);
    console.log(aggregatorReportList);
    const [branch, setBranch] = useState(null);
    const [shift, setShift] = useState(null);
    const [aggregator, setAggregator] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [fromDate, setFromDate] = useState(null);

    const branchOnchange = (e, newValue) => {
        setBranch(newValue)
        setShift(null)
        viewShiftAPICall({ branchId: newValue?._id });
    }
    const submitButtonClick = () => {
        let body = {
            branchId: branch?._id ||"",
            shiftId: shift?._id,
            aggregatorId: aggregator?._id,
            startDate: fromDate,
            endDate: toDate
        }


        ListAggregatorAPI(body)
    }
    useEffect(() => {
        userRole === "user" && viewAllAllowedBranchesAPICall({ employeeId: userInfo?.data?._id });
        listAggregatorsAPI({ branchId: userInfo?.branchPk })
        viewAllBranchesAPICall();
    }, [])
    return (
        <div className="global-page-parent-container" style={{ position: "relative" }}>
            <div className="global-white-bg-container Header" style={{ display: "flex", justifyContent: "space-between", padding: "0px 30px 0 25px", alignItems: "center" }}>
                <p>Aggregator Report</p>
                <div>

                    <ReactToPrint
                        trigger={() => (
                            <IconButton className="printer-div" style={{ padding: "1px" }}>
                                <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>

                            </IconButton>
                        )}
                        content={() => paymentsPrint}
                    />

                    <Tooltip title="Download">
                        <IconButton>
                            <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
                        </IconButton>
                    </Tooltip>
                </div>
            </div>

            <div className="global-white-bg-container " style={{ display: "flex", alignItems: "center" }}>
                <div className="new-global-single-input auto-complete-new">
                    <Autocomplete
                        options={userRole === "admin" ? allBranchesList : allowedBranchList || []}
                        getOptionLabel={(option) => option?.branchName}
                        renderInput={(params) => (
                            <TextField {...params} label="Branch" focused variant='outlined' />
                        )}
                        value={branch}
                        onChange={branchOnchange}
                    />
                </div>

                <div className="new-global-single-input auto-complete-new">
                    <Autocomplete
                        options={(allShiftList?.filter(obj => obj.branchId === branch?._id) || []).reverse()}

                        getOptionLabel={(option) => option?.SHIFID}
                        renderInput={(params) => (
                            <TextField {...params} label="Shift" focused variant='outlined' />
                        )}
                        value={shift}
                        onChange={(e, newValue) => setShift(newValue)}
                    />
                </div>
                <div className="new-global-single-input auto-complete-new">
                    <Autocomplete
                        options={aggregatorList || []}
                        getOptionLabel={(option) => option?.name}
                        renderInput={(params) => (
                            <TextField {...params} label="Aggregator" focused variant='outlined' />
                        )}
                        value={aggregator}
                        onChange={(e, newValue) => setAggregator(newValue)}
                    />
                </div>


                <div className="new-global-single-input" style={{ width: '15%' }}>
                    <TextField
                        id="outlined-basic"
                        placeholder="From"
                        onChange={(e, newValue) => setFromDate(e.target.value)}
                        value={fromDate}
                        variant="outlined"
                        type="date"
                        focused
                        label="From Date"
                    />
                </div>



                <div className="new-global-single-input" style={{ width: '15%' }}>
                    <TextField
                        id="outlined-basic"
                        placeholder="From"
                        onChange={(e, newValue) => setToDate(e.target.value)}
                        value={toDate}
                        variant="outlined"
                        type="date"
                        focused
                        label="To Date"
                    />
                </div>

                <button
                    onClick={submitButtonClick}
                    className='btn btn-primary' style={{ marginLeft: "25px" }}>Submit</button>

            </div>
            {aggregatorReportList !== undefined && (
                <div className="new-global-table-container" style={{ paddingTop: "0 42px" }}>
                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Order Reff</th>
                                <th>Customer</th>
                                <th>Aggregator</th>
                                <th>Mobile</th>
                                <th>Delivery Charge</th>
                                <th>Discount </th>
                                <th>Commission </th>
                                <th>Order Amount</th>
                                <th>Paid Amount</th>
                                <th>Balance</th>
                            </tr>
                        </thead>

                        <tbody>
                            {aggregatorReportList?.report?.map((item, index) => (

                                <tr key={index} style={{ backgroundColor: index % 2 === 0 ? "white" : "rgb(214 212 212)" }}>
                                    <td style={{ border: "none" }}>{item.date}</td>
                                    <td style={{ border: "none" }}>{item.orderRef}</td>
                                    <td style={{ border: "none" }}>{item.customer}</td>
                                    <td style={{ border: "none" }}>{item.aggregator}</td>
                                    <td style={{ border: "none" }}>{item.mobile}</td>
                                    <td style={{ border: "none" }}>{item.deliveryCharge}</td>
                                    <td style={{ border: "none" }}>{item.discount}</td>
                                    <td style={{ border: "none" }}>{item.commAmt?.toFixed(decimalPosition)}</td>
                                    <td style={{ border: "none" }}>{item.orderAmount?.toFixed(decimalPosition)}</td>
                                    <td style={{ border: "none" }}>{item.paidAmount?.toFixed(decimalPosition)}</td>
                                    <td style={{ border: "none" }}>{item.balance?.toFixed(decimalPosition)}</td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr style={{ backgroundColor: "#aba8a8" }}>
                                <td colSpan={5}> </td>
                                <td style={{ textAlign: "center", border: "none" }}>{aggregatorReportList?.total?.deliveryCharge?.toFixed(decimalPosition)}</td>
                                <td style={{ textAlign: "center", border: "none" }}>{aggregatorReportList?.total?.discount}</td>
                                <td style={{ textAlign: "center", border: "none" }}>{aggregatorReportList?.total?.commAmt?.toFixed(decimalPosition)}</td>
                                <td style={{ textAlign: "center", border: "none" }}>{aggregatorReportList?.total?.orderAmount?.toFixed(decimalPosition)}</td>
                                <td style={{ textAlign: "center", border: "none" }}>{aggregatorReportList?.total?.paidAmount?.toFixed(decimalPosition)}</td>
                                <td style={{ textAlign: "center", border: "none" }}>{aggregatorReportList?.total?.balance?.toFixed(decimalPosition)}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            )}
            <div style={{ display: "none" }}>
                <div ref={(el) => (paymentsPrint = el)}>
                    <Template aggregatorReportList={aggregatorReportList} branch={branch} />
                </div>
            </div>
        </div>
    )
}

export default PosAggregatorReport