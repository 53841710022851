import axios from "axios"
import { HEADERS } from "../../../../../API/UrlAndPaths"
import store from "../../../../../Redux/store"
import { setSubmitLoading, setTableLoadingGlobal } from "../../../../../Redux/Dashboard/mainDashBoardSlice"
import { setBatchPopup, storeBatchListOfProduct, store_batch_list, store_batch_single_data, store_table_batch_row_data, store_table_batch_row_index } from "./inventoryBatchSlice"



export const getInventoryBatchAPICall=async(body)=>{
    store.dispatch(setTableLoadingGlobal(true))
    await axios.post("readymade/viewBatch",body,HEADERS)
     .then((res)=>{
        if(res.status===200){
                store.dispatch(setTableLoadingGlobal(false))
                store.dispatch(store_batch_list(res.data))
            }
        })
      .catch((err)=>{
        store.dispatch(setTableLoadingGlobal(false))
        store.dispatch(store_batch_list(false))
      })
}

export const viewBatchInfoById=async(body)=>{
  store.dispatch(setSubmitLoading(true))
  await axios.post("readymade/viewBatchById",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
        store.dispatch(setSubmitLoading(false))
        store.dispatch(store_batch_single_data(res.data))
        
      }
    })
    .catch((err)=>{
      store.dispatch(setSubmitLoading(false))
      store.dispatch(store_batch_single_data(null))

  })
}

export const batchListOfProductAPI=async(body)=>{
  await axios.post("sales/batchListByProdId",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
        store.dispatch(storeBatchListOfProduct(res.data))
      }
    })
    .catch((err)=>{
      store.dispatch(storeBatchListOfProduct([]))
  })
}
// general funciton to open and store data

export const openBatchDialog=(rowData,index)=>{
  store.dispatch(setBatchPopup(true))
  store.dispatch(store_table_batch_row_data(rowData))
  store.dispatch(store_table_batch_row_index(index))
}