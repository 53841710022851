
export const flowChartElement = {
    bill:'BILL',
    invoice:'INVOICE',
    delivery:'DELIVERY',
    grn:'GOODS RECEIVED NOTE',
    payment:'PAYMENT',
    purchaseOrder:'PURCHASE ORDER',
    purchaseWpo:'PURCHASE WPO',
    quotation:'QUOTATION',
    receipt:'RECEIPT',
    rfq:'REQUEST FOR QUOTATION',
    salesOrder:'SALES ORDER',
    swso:'SWSO',
}

export const chartTypes = {
    purchase:'PURCHASE',
    sales:'SALES',
}