import React, { useEffect, useState } from 'react'
import '../Settings/posSettings.css'
import { Autocomplete, IconButton, TextField, Tooltip } from '@mui/material'
import { useSelector } from 'react-redux'
import { chartOfAccountListApiCall } from '../../../../../API/AccountUpdated/chartOfAccountAPI'
import { journalListApiCall } from '../../../../../API/AccountUpdated/journalAPI'
import { postPosSettingsAPICall, viewPosSettingsAPICall } from './posSettingsAPI'
import SuccessSnackbar from '../../../../Single Components/SnackBars/SuccessSnackbar'
import ErrorSnackbar from '../../../../Single Components/SnackBars/ErrorSnackbar'
import { findObjFromList } from '../../../../../Js/generalFunctions'
import { errorSnackMsg } from '../../../../Custom Hooks/SnackkBarUtilities'
export const PosSettings = () => {
    const chartOfAccountList = useSelector((state) => state.chartOfAccountsSlice.value)
    const journalList = useSelector((state) => state.journalSlice.value)
    const posSettingsList=useSelector((state)=>state.posSettingSlice.apiResList);

    const {currencyDetails}=useSelector((state) => state.mainDashBoardSlice)
    const receivableList = chartOfAccountList?.filter(item => item.type === "Receivables");
    const incomeAccList = chartOfAccountList?.filter(item => item.type === "Income");
    const expenseAccList = chartOfAccountList?.filter(item =>item.type === "Cost of revenue");
    const bankAndCashAccList=chartOfAccountList?.filter(item =>item.type === "Bank and cash");
    const currentAssetAccList=chartOfAccountList?.filter(item =>item.type === "Current asset");
    const typeCashAndBankJournalList=journalList?.filter(item=>item.type === "cash" || item.type ==="bank")
    const typesalesInJournalList = journalList?.filter(item=>item.type === 'sales')

    const [snackBarStates,setSnackBarStates]=useState({
      success:false,
      error:false,
      message:"",
    })
   const [validationAlert,setValidationAlert]=useState({})
    const [formValues,setFormValues] = useState({
      posAccounts:false,
      posReceivable:null,
      posIncome:null,
      posDiscount:null,
      posBankCharges:null,
      cashGainOrLoss:null,
      deliveryChargeAccount:null,
      posJournal:null,

      pettyCash:false,
      pettyCashJournal:null,

      cashInOrOut:false,
      cashInOrOutAccount:null,

      activeWallet:false,
      walletjournal:null,

      activeLoyalty:false,
      loyaltyJournal:null,
      negativeStock:false,
      points:"",
      money:""
    })
    const closeSnackbar=()=>{
      setSnackBarStates({...snackBarStates,success:false,
                              error:false,
                              message:"",})
  }
  const getFormInfo = (key) => (e, newValue) => {
    setFormValues({...formValues,[key]:newValue===undefined?e.target.value:newValue})
    setValidationAlert({})
}
const checkboxChange=(key)=>(e)=>{
  setFormValues({ ...formValues, [key]: e.target.checked })
}

const payload={
  posStatus:formValues?.posAccounts,
  posAccounts:formValues?.posAccounts===false?null:{
    posReceivableAccount:formValues?.posReceivable?._id,
    posIncomeAccount:formValues?.posIncome?._id,
    posDiscountAccount:formValues?.posDiscount?._id,
    posBankChargesAccount:formValues?.posBankCharges?._id,
    // cashGainOrLossAccount:formValues?.cashGainOrLoss?._id,
    deliveryChargeAccount:formValues?.deliveryChargeAccount?._id,
    posJournalAccount:formValues?.posJournal?._id


  },
  cashInOutStatus:formValues?.cashInOrOut,
  cashInOut:{
    cashInOutAccount:formValues?.cashInOrOut === true?formValues?.cashInOrOutAccount?._id:null
  },
  pettyCashStatus:formValues?.pettyCash,
  pettyCash:{
    PettycashJournel:formValues?.pettyCash === true?formValues?.pettyCashJournal?._id:null
  },
  activeWalletStatus:formValues?.activeWallet,
  activeWallet:{
    activeWalletJournel:formValues?.activeWallet===true?formValues?.walletjournal?._id:null
  },
  activeLoyaltyStatus:formValues?.activeLoyalty,
  activeLoyalty:{
    activeLoyaltyJournel:formValues?.activeLoyalty===true?formValues?.loyaltyJournal?._id:null
  },
  isNegativeStock:formValues?.negativeStock,
  points:formValues?.points,
  money:formValues?.money
}
const saveBtnClick=()=>{
  let message="Select this field"
  if(formValues?.posAccounts===true && formValues?.posReceivable===null){
    setValidationAlert({...validationAlert,receivableAcc:message})
  }
  else if(formValues?.posAccounts===true && formValues?.posIncome===null){
    setValidationAlert({...validationAlert,posIncome:message})
  }
  else if(formValues?.posAccounts===true && formValues?.posDiscount===null){
    setValidationAlert({...validationAlert,posDiscount:message})
  }
  else if(formValues?.posAccounts===true && formValues?.posBankCharges === null){
    setValidationAlert({...validationAlert,posBankCharges:message})
  }
  // else if(formValues?.posAccounts===true && formValues?.cashGainOrLoss === null){
  //   setValidationAlert({...validationAlert,cashGainOrLoss:message})
  // }
  else if(formValues?.posAccounts===true && formValues?.deliveryChargeAccount === null){
    setValidationAlert({...validationAlert,deliveryCharge:message})
  }
  else if(formValues?.posAccounts===true && formValues?.posJournal === null){
    setValidationAlert({...validationAlert,posJournal:message})
    errorSnackMsg("Some fields are required !!")
  }
  else if(formValues?.pettyCash===true && formValues?.pettyCashJournal === null){
    setValidationAlert({...validationAlert,pettyCashAccount:message})
    errorSnackMsg("Some fields are required !!")
  }
  else if(formValues?.cashInOrOut===true && formValues?.cashInOrOutAccount === null){
    setValidationAlert({...validationAlert,cashInOrOutAccount:message})
    errorSnackMsg("Some fields are required !!")
  }
  else if(formValues?.activeWallet===true && formValues?.walletjournal === null){
    setValidationAlert({...validationAlert,walletjournal:message})
    errorSnackMsg("Some fields are required !!")
  }
  else if(formValues?.activeLoyalty===true && formValues?.loyaltyJournal === null){
    setValidationAlert({...validationAlert,loyaltyJournal:message})
    errorSnackMsg("Some fields are required !!")
  }
  else if(formValues?.activeLoyalty===true && (formValues?.points === "" || formValues?.money === "")){
    setValidationAlert({...validationAlert,rewardPoints:"These fields are required"})
    errorSnackMsg("Some fields are required !!")
  }
  else{
    postPosSettingsAPICall(payload,setSnackBarStates,snackBarStates)
  }
 

}
useEffect(()=>{
  posSettingsList !== undefined &&
  setFormValues({
    ...formValues,
    posAccounts:posSettingsList?.posStatus,
    posReceivable:findObjFromList("_id",chartOfAccountList,"posReceivableAccount",posSettingsList?.posAccounts),
    posIncome:findObjFromList("_id",chartOfAccountList,"posIncomeAccount",posSettingsList?.posAccounts),
    posDiscount:findObjFromList("_id",chartOfAccountList,"posDiscountAccount",posSettingsList?.posAccounts),
    posBankCharges:findObjFromList("_id",chartOfAccountList,"posBankChargesAccount",posSettingsList?.posAccounts),
    cashGainOrLoss:findObjFromList("_id",chartOfAccountList,"cashGainOrLossAccount",posSettingsList?.posAccounts),
    deliveryChargeAccount:findObjFromList("_id",chartOfAccountList,"deliveryChargeAccount",posSettingsList?.posAccounts),

    posJournal:findObjFromList("_id",journalList,"posJournalAccount",posSettingsList?.posAccounts),

    pettyCash:posSettingsList?.pettyCashStatus,
    pettyCashJournal:findObjFromList("_id",journalList,"PettycashJournel",posSettingsList?.pettyCash),
    cashInOrOut:posSettingsList?.cashInOutStatus,
    cashInOrOutAccount:findObjFromList("_id",chartOfAccountList,"cashInOutAccount",posSettingsList?.cashInOut),
    activeWallet:posSettingsList?.activeWalletStatus,
    walletjournal:findObjFromList("_id",journalList,"activeWalletJournel",posSettingsList?.activeWallet),
    activeLoyalty:posSettingsList?.activeLoyaltyStatus,
    loyaltyJournal:findObjFromList("_id",journalList,"activeLoyaltyJournel",posSettingsList?.activeLoyalty),
    negativeStock:posSettingsList?.isNegativeStock,
    points:posSettingsList?.points || "",
    money:posSettingsList?.money || "",

  })
},[posSettingsList,chartOfAccountList,journalList])

    useEffect(() => {
      chartOfAccountListApiCall()
      journalListApiCall()
      viewPosSettingsAPICall()
  }, [])


  return (
    <div className="global-page-parent-container">
         <div className="global-white-bg-container">
                        <>
                            <Tooltip title="Save">
                                <IconButton 
                                onClick={saveBtnClick}
                                >
                                    <i class="bi bi-floppy"></i>
                                </IconButton>
                            </Tooltip>
                        </>
            </div>
      <div   className="new-global-white-bg-container" style={{maxHeight: "calc(100vh - 156px)",overflowY:"auto"}}>
        {/* 1 */}
          <div className='checkbox-and-dropdown-container'>
            <div className='pos-settings-checkbox-container'>
              <input 
                type="checkbox" 
                id="checkboxDropDown"
                value={formValues?.posAccounts}
                onChange={checkboxChange("posAccounts")}
                checked={formValues?.posAccounts===true}
                />
              <label htmlFor="checkboxDropDown">POS Accounts</label>
            </div>
           {
            formValues?.posAccounts===true &&
           
            <>
               <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
                 <div className="pos-settings-dropdown-container auto-complete-new">
                 <p>POS Receivable</p>
                        <Autocomplete
                            options={receivableList || []}
                            getOptionLabel={(option) => option?.accountName}
                            renderInput={(params) => (<TextField {...params} />)}
                            value={formValues?.posReceivable}
                            onChange={getFormInfo("posReceivable")}
                           
                        />
                        
                    </div>
                    <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.receivableAcc}</p>
               </div>
        
               <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
                <div className='pos-settings-dropdown-container auto-complete-new'>
                <p>POS Income</p>
                <Autocomplete
                            options={incomeAccList || []}
                            getOptionLabel={(option) => option?.accountName}
                            renderInput={(params) => (<TextField {...params} />)}
                            value={formValues?.posIncome}
                            onChange={getFormInfo("posIncome")}
                          
                        />
             
            </div>
            <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.posIncome}</p>
               </div>
               <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
            <div className='pos-settings-dropdown-container auto-complete-new'>
              <p>POS Discount</p>
               <Autocomplete
                            options={expenseAccList || []}
                            getOptionLabel={(option) => option?.accountName}
                            renderInput={(params) => (<TextField {...params} />)}
                            value={formValues?.posDiscount}
                            onChange={getFormInfo("posDiscount")}
                         
                        />
             
            </div>
            <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.posDiscount}</p>
               </div>
               <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
            <div className='pos-settings-dropdown-container auto-complete-new'>
              <p>POS Bank Charges</p>
               <Autocomplete
                            options={expenseAccList || []}
                            getOptionLabel={(option) => option?.accountName}
                            renderInput={(params) => (<TextField {...params} />)}
                            value={formValues?.posBankCharges}
                            onChange={getFormInfo("posBankCharges")}
                           
                        />
             
            </div>
            <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.posBankCharges}</p>
               </div>
               {/* <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
            <div className='pos-settings-dropdown-container auto-complete-new'>
              <p>Cash Gain or Loss</p>
               <Autocomplete
                            options={expenseAccList || []}
                            getOptionLabel={(option) => option?.accountName}
                            renderInput={(params) => (<TextField {...params} />)}
                            value={formValues?.cashGainOrLoss}
                            onChange={getFormInfo("cashGainOrLoss")}
                           
                        />
             
            </div>
            <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.cashGainOrLoss}</p>
               </div> */}
               
               <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
            <div className='pos-settings-dropdown-container auto-complete-new'>
              <p>Delivery Charge</p>
               <Autocomplete
                            options={expenseAccList || []}
                            getOptionLabel={(option) => option?.accountName}
                            renderInput={(params) => (<TextField {...params} />)}
                            value={formValues?.deliveryChargeAccount}
                            onChange={getFormInfo("deliveryChargeAccount")}
                            
                        />
             
            </div>
            <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.deliveryCharge}</p>
               </div>

               <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
            <div className='pos-settings-dropdown-container auto-complete-new'>
              <p>Pos journal</p>
               <Autocomplete
                            options={typesalesInJournalList || []}
                            getOptionLabel={(option) => option?.journalName}
                            renderInput={(params) => (<TextField {...params} />)}
                            value={formValues?.posJournal}
                            onChange={getFormInfo("posJournal")}
                            
                        />
             
            </div>
            <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.posJournal}</p>
               </div>
            </>
            }
          </div>
          {/* 2 */}
          <div className='checkbox-and-dropdown-container'>
            <div className='pos-settings-checkbox-container'>
              <input 
                 type="checkbox" 
                 id="PettyCash" 
                 value={formValues?.pettyCash}
                 onChange={checkboxChange("pettyCash")}
                 checked={formValues?.pettyCash === true}
                 />
              <label htmlFor="PettyCash">Petty Cash</label>
            </div>
            {
              formValues?.pettyCash === true &&
              <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
              <div className='pos-settings-dropdown-container auto-complete-new'>
                <p>Petty Cash Journal</p>
                 <Autocomplete
                              options={typeCashAndBankJournalList || []}
                              getOptionLabel={(option) => option?.journalName}
                              renderInput={(params) => (<TextField {...params} />)}
                              value={formValues?.pettyCashJournal}
                              onChange={getFormInfo("pettyCashJournal")}
                             
                          />
               
              </div>
              <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.pettyCashAccount}</p>
              </div>
            }
           
          
          </div>
          {/* 3 */}
          <div className='checkbox-and-dropdown-container'>
            <div className='pos-settings-checkbox-container'>
              <input 
                type="checkbox" 
                id="CashInOrOut"
                value={formValues?.cashInOrOut}
                onChange={checkboxChange("cashInOrOut")}
                checked={formValues?.cashInOrOut === true}
                />
              <label htmlFor="CashInOrOut">Cash In Or Out</label>
            </div>
            {
              formValues?.cashInOrOut === true &&
             <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
            <div className='pos-settings-dropdown-container auto-complete-new'>
              <p>Cash In Or Out Account</p>
              <Autocomplete
               disablePortal
               id="combo-box-demo"
               options={bankAndCashAccList||[]}
               getOptionLabel={(option) => option?.accountName}
               renderInput={(params) => <TextField {...params} 
              
               />}
              
               value={formValues?.cashInOrOutAccount}
               onChange={getFormInfo("cashInOrOutAccount")}
              
              />
             
            </div>
            <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.cashInOrOutAccount}</p>
            </div>
            }
            
          
          </div>
          {/* 4 */}
          <div className='checkbox-and-dropdown-container'>
            <div className='pos-settings-checkbox-container'>
              <input 
                 type="checkbox" 
                 id="ActiveWallet"
                 value={formValues?.activeWallet}
                 onChange={checkboxChange("activeWallet")}
                 checked={formValues?.activeWallet===true}
                 />
              <label htmlFor="ActiveWallet">Active Wallet</label>
            </div>
            {
              formValues?.activeWallet===true &&
             <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
            <div className='pos-settings-dropdown-container auto-complete-new'>
              <p>Wallet Journal</p>
              <Autocomplete
               disablePortal
               id="combo-box-demo"
               options={typeCashAndBankJournalList||[]}
               getOptionLabel={(option) => option?.journalName}
               renderInput={(params) => <TextField {...params} 
               />}
               value={formValues?.walletjournal}
               onChange={getFormInfo("walletjournal")}
              
              
              />
             
            </div>
            <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.walletjournal}</p>
             </div>
            }
            
         
          
          </div>
          {/* 5 */}
          <div className='checkbox-and-dropdown-container'>
            <div className='pos-settings-checkbox-container'>
              <input 
                type="checkbox" 
                id="ActiveLoyalty"
                value={formValues?.activeLoyalty}
                onChange={checkboxChange("activeLoyalty")}
                checked={formValues?.activeLoyalty===true}
                />
              <label htmlFor="ActiveLoyalty">Active Loyalty</label>
            </div>
            {
              formValues?.activeLoyalty===true &&
              <>
              <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
            <div className='pos-settings-dropdown-container auto-complete-new'>
              <p>Loyalty Journal</p>
              <Autocomplete
               disablePortal
               id="combo-box-demo"
               options={typeCashAndBankJournalList||[]}
               getOptionLabel={(option) => option?.journalName}
               renderInput={(params) => <TextField {...params} 
              
               />}
              
               value={formValues?.loyaltyJournal}
               onChange={getFormInfo("loyaltyJournal")}
              
              />
             
            </div>
            <p style={{margin:"0",color:"red",fontSize:"0.7rem",textAlign:"end"}}>{validationAlert?.loyaltyJournal}</p>
              </div>
              <div style={{display:"flex",flexDirection:"column",width:"90%"}}>
            <div className='pos-settings-dropdown-container auto-complete-new'>
              <p>Reward Point</p>
              <div className="justify-center" style={{width:"100%",justifyContent:"start"}}>
                <div className="global-single-input">
                  <p style={{marginBottom:"0"}}>Point</p>
                  <input 
                  type="number" 
                  value={formValues?.points}
                  onChange={getFormInfo("points")}
                  onFocus={(e)=>e.target.select()}
                  />
                </div>
                <span style={{alignSelf:"end",margin:"0 8px",marginBottom:"10px"}}>=</span>
                <div className="global-single-input">
                  <p style={{marginBottom:"0"}}>{currencyDetails?.symbol || "Company currency"}</p>
                  <input
                   type="number" 
                   value={formValues?.money}
                   onChange={getFormInfo("money")}
                   onFocus={(e)=>e.target.select()}
                   />
              <p className='doc-validation-alert' style={{textWrap:"nowrap",left:"0"}}>{validationAlert?.rewardPoints}</p>

                </div>
              </div>
            </div>
              </div>
              </>
            }
            
         
          
          </div>
          {/* 6 */}
          <div className='checkbox-and-dropdown-container'>
            <div className='pos-settings-checkbox-container'>
              <input 
                type="checkbox" 
                id="negativeStock"
                value={formValues?.negativeStock}
                onChange={checkboxChange("negativeStock")}
                checked={formValues?.negativeStock===true}
                />
              <label htmlFor="negativeStock">Negative Stock</label>
            </div>
          </div>

       </div>

       <SuccessSnackbar open={snackBarStates.success} handleClose={closeSnackbar} message={snackBarStates.message}/>
       <ErrorSnackbar open={snackBarStates.error} handleClose={closeSnackbar} message={snackBarStates.message}/>
    </div>
  )
}

