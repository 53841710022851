import axios from "axios"
import { HEADERS } from "../../../../../API/UrlAndPaths"
import store from "../../../../../Redux/store"
import { setPageIndexForViewOrder, setPaginationForViewOrder, setTableLoading, storeHeldOrders, storeOrdersList } from "../Slice/viewOrdersBillingSlice"
import { setTableLoadingGlobal } from "../../../../../Redux/Dashboard/mainDashBoardSlice"
import { setBillLoading, setErrorSnack, setOrderIdInPOS, setSingleOrderInfo, setSnackMsg, setSuccessSnack } from "../Slice/billingScreenSlice"

export const listOrdersForBillingAPI=async(body)=>{


    store.dispatch(setTableLoading(true))
    await axios.post("pos/listPosOrders",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(storeOrdersList(res.data))
                store.dispatch(setTableLoading(false))
                store.dispatch(setPaginationForViewOrder(false))
                store.dispatch(setPageIndexForViewOrder(0))
            }
        })
        .catch((err)=>{
             store.dispatch(storeOrdersList([]))
            store.dispatch(setTableLoading(false))
        })
}
export const listOrdersPaginationAPI=async(body)=>{
    const isPagination = store.getState().viewOrdersBillingSlice.pagination;

    store.dispatch(setTableLoadingGlobal(true))
    await axios.post("pos/listPosOrders",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                const existingProductsList = store.getState().viewOrdersBillingSlice.ordersList;
                const updatedOrdersList = existingProductsList.concat(res.data);


                // Remove duplicates from the updatedProductsList
                const uniqueOrderList = updatedOrdersList.filter((product, index, self) => (
                    index === self.findIndex((p) => (
                    p._id === product._id 
                    ))
                ));
                store.dispatch(storeOrdersList(uniqueOrderList))
                store.dispatch(setTableLoadingGlobal(false))
                store.dispatch(setPaginationForViewOrder(false))
            }
        })
        .catch((err)=>{
            err.response.status===404 && store.dispatch(setPaginationForViewOrder(isPagination && 'max'))
            store.dispatch(setTableLoadingGlobal(false))
        })
}
export const listHeldOrders=async(body)=>{

    store.dispatch(setTableLoading(true))
    await axios.post("pos/listPosOrders",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(setTableLoading(false))
                store.dispatch(storeHeldOrders(res.data))
            }
        })
        .catch((err)=>{
            store.dispatch(setTableLoading(false))
            store.dispatch(storeHeldOrders([]))
        })
}


export const deleteBookingOrder=async(body,clearFn)=>{
    store.dispatch(setBillLoading(true))
    await axios.post("pos/deleteBookingOrder",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                clearFn()
                store.dispatch(setOrderIdInPOS(null))                
                store.dispatch(setSingleOrderInfo(null))                
                store.dispatch(setBillLoading(false))                    
                store.dispatch(setSnackMsg("Booking Cancelled"))
                store.dispatch(setSuccessSnack(true))           
            }
        })
        .catch((err)=>{
            let message=err.response.status===500?"Internal Server Error !!":err.response.data||"Network Error !!"
            store.dispatch(setSnackMsg(message))
            store.dispatch(setBillLoading(false))                
            store.dispatch(setErrorSnack(true))
        })
}
export const cancelOngoingOrderApiCall=async(body,clearFn)=>{
    store.dispatch(setBillLoading(true))
    await axios.post("pos/cancelOngoingOrders",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                clearFn()
                store.dispatch(setOrderIdInPOS(null))                
                store.dispatch(setSingleOrderInfo(null))                
                store.dispatch(setBillLoading(false))                    
                store.dispatch(setSnackMsg("Order Cancelled"))
                store.dispatch(setSuccessSnack(true))           
            }
        })
        .catch((err)=>{
            let message=err.response.status===500?"Internal Server Error !!":err.response.data||"Network Error !!"
            store.dispatch(setSnackMsg(message))
            store.dispatch(setBillLoading(false))                
            store.dispatch(setErrorSnack(true))
        })
}