import { IconButton, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ReactToPrint from 'react-to-print'
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter'
import CustomPagination from '../../../../Single Components/CustomPagination'
import { ChequeRegisterReportAPIcall } from './GeneralReportAPI'
import { today } from '../../../../../Js/Date'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const ChequeRegisterReport = () => {
  const navigate = useNavigate()

  const chequeList = useSelector((state) => state.generalReport.chequeRegiter)

  const [FormDataInfo, setFormDataInfo] = useState([])
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = chequeList?.pages;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  }
  const singleView = (r) => {
    localStorage.setItem("singleViewById", r?._id)
    navigate("/userdashboard/accounts/customer/payments/create", { state: { isCheque: true } });
  }
  const handleDataFromChild = (data) => {
    setFormDataInfo(data);
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch);
  };

  useEffect(() => {
    ChequeRegisterReportAPIcall({ currentDate: today })
  }, [])
  return (
    <div className="global-page-parent-container" style={{ position: "relative",margin:"0",padding:"5px" }}>
      <div className="global-white-bg-container Header" style={{ display: "flex", justifyContent: "space-between", padding: "0px 30px 0 25px", alignItems: "center" }}>
       <p>Cheque Register Report</p>
        <div style={{ display: "flex" }}>
         
          <Tooltip title="Share">
            <IconButton>
              <i class="bi bi-share" style={{ backgroundColor: "#9797e9", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
            </IconButton>
          </Tooltip>

          <ReactToPrint
            trigger={() => (
              <IconButton className="printer-div" style={{ padding: "1px" }}>
                <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
              </IconButton>
            )}
          //   content={() => paymentsPrintNew}
          />

          <Tooltip title="Download">
            <IconButton>
              <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
            </IconButton>
          </Tooltip>

          <CategorySearchandFilter
            onData={handleDataFromChild}
            onBranchSelect={handleBranchSelect}
          // isCustomer={true}
          // isJournal={true}
          />
        </div>
      </div>
      <div className="global-white-bg-container " style={{ alignItems: "center",height:"85vh" }}>
        <div className="justify-center" style={{ justifyContent: "flex-end" }}>
          <CustomPagination

            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}

          />
        </div>
        <div className="invoice-table-container">

          <table>
            <thead>
              <tr>
                <th style={{ textAlignLast: "center" }}>Customer</th>
                <th style={{ textAlignLast: "center" }}>Branch</th>
                <th style={{ textAlignLast: "center" }}>Payment Date</th>
                <th style={{ textAlignLast: "center" }}> Reff</th>
                <th style={{ textAlignLast: "center" }}>Payment Method</th>
                <th style={{ textAlignLast: "center" }}>Cheque Number</th>
                <th style={{ textAlignLast: "center" }}>Cheque Date</th>
                <th style={{ textAlignLast: "center" }}>Amount</th>
                <th style={{ textAlignLast: "center" }}>Status</th>
              </tr>
            </thead>
            <tbody>
              {chequeList?.report?.map((item, index) => (
                <tr key={index} onClick={() => singleView(item)}>
                  <td>{item.customer}</td>
                  <td>{item.branch}</td>
                  <td>{item.paymentDate}</td>
                  <td>{item.paymentRef}</td>
                  <td>{item.paymentMethod}</td>
                  <td>{item.chequeNo}</td>
                  <td>{item.chequeDate}</td>
                  <td>{item.totalAmount}</td>
                  <td>{item.status}</td>

                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th colSpan={8}>Total</th>
                <th>{chequeList?.total?.totalAmount}</th>
              </tr>
            </tfoot>
          </table>
        </div>

      </div>
    </div>
  )
}

export default ChequeRegisterReport