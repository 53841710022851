import { createSlice } from "@reduxjs/toolkit";

export const billingScreenSlice=createSlice({
    name:"billingScreenSlice",
    initialState:{
        singleOrderInfo:null,
        cartInfo:[],
        orderIdForPos:null,
        successSnack:false,
        errorSnack:false,
        snackMsg:"",
        dialogOpen:null,
        billLoading:false,
        cartToBackend:null,
        updatedOrder:false,
        branchId:null,
        categoryLoading:false,
        offerMenuLoading:false,
        selectedPOSCategory:null,
        pageIndex:0,
        pagination:false,
        offerListCategory:[],
        offerProductsList:[],
        cartObj:null,
        cartModified:false,
        totalsInfo:null,
        paymentMode:null
    },
    reducers:{
        setOrderIdInPOS:(state,action)=>{
            state.orderIdForPos=action.payload
        },
        setSingleOrderInfo:(state,action)=>{
            state.singleOrderInfo=action.payload
        },
        setCartArray:(state,action)=>{
            state.cartInfo=action.payload
        },
        setSuccessSnack:(state,action)=>{
            state.successSnack=action.payload
        },
        setErrorSnack:(state,action)=>{
            state.errorSnack=action.payload
        },
        setSnackMsg:(state,action)=>{
            state.snackMsg=action.payload
        },
        setBillLoading:(state,action)=>{
            state.billLoading=action.payload
        },
        setDialogBox:(state,action)=>{
            state.dialogOpen=action.payload
        },
        setCartTOBackend:(state,action)=>{
            state.cartToBackend=action.payload
        },
        setUpdateOrder:(state,action)=>{
            state.updatedOrder=action.payload
        },
        setPOSBranchId:(state,action)=>{
            state.branchId=action.payload
        },
        setCategoryLoading:(state,action)=>{
            state.categoryLoading=action.payload
        },
        setOfferMenuLoading:(state,action)=>{
            state.offerMenuLoading=action.payload
        },
        setSelectedPosCat:(state,action)=>{
            state.selectedPOSCategory=action.payload
        },
        setPageIndex:(state,action)=>{
            state.pageIndex=action.payload
        },
        setPagination:(state,action)=>{
            state.pagination=action.payload
        },
        storeOfferCategory:(state,action)=>{
            state.offerListCategory=action.payload
        },
        storeOfferProductList:(state,action)=>{
            state.offerProductsList=action.payload
        },
        setCartObj:(state,action)=>{
            state.cartObj=action.payload
        },
        setCartModified:(state,action)=>{
            state.cartModified=action.payload
        },
        setTotalsInfo:(state,action)=>{
            state.totalsInfo=action.payload
        },
        setPaymentMode:(state,action)=>{
            state.paymentMode=action.payload
        },
    }
})

export const {setOrderIdInPOS,
    setSuccessSnack,
    setErrorSnack,
    setSnackMsg,
    setDialogBox,
    setBillLoading,
    setCartTOBackend,
    setSingleOrderInfo,
    setUpdateOrder,
    setPOSBranchId,
    setCategoryLoading,
    setOfferMenuLoading,
    setSelectedPosCat,
    setPageIndex,
    setPagination,
    setCartArray,
    storeOfferCategory,
    storeOfferProductList,setCartObj,setCartModified,setTotalsInfo,setPaymentMode
}=billingScreenSlice.actions

export default billingScreenSlice.reducer