import { Autocomplete, IconButton, Skeleton, TextField, Tooltip } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import ReactToPrint from 'react-to-print'
import { listShiftSummaryReportAPI } from './ReportsAPI/posShiftSummaryReportAPI'
import { useSelector } from 'react-redux'
import { convertDateFormat, today } from '../../../../Js/Date'
import { viewAllAllowedBranchesAPICall, viewAllBranchesAPICall } from '../../../../API/Settings/Company Settings/companySettingsAPI'
import "./posBillingReports.css"
import { findSumOfObjkeys } from '../../../../Js/generalFunctions'
import Template from "./PosSummaryReportPrint"

const PosShiftSummaryReport = () => {
    let paymentsPrint = useRef();
const {tableLoading}=useSelector((state)=>state.mainDashBoardSlice)
const {reportsList}=useSelector((state)=>state.posShiftSummaryReportSlice)
const userInfo = useSelector((state) => state.loginResponseSlice.value);
const userRole=useSelector((state)=>state.userRoleSlice.value)

const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
const allowedBranchList = useSelector((state) => state.allAllowedBranchesSlice.value);

const decimalPosition=localStorage.getItem("decimalPosition")
const userDefaultBranch={
    _id:userInfo?.branchPk,
    branchName:userInfo?.branchName
}
const formValuesInitial={
    branch:userRole==="user"?userDefaultBranch :null,
    fromDate:today,
    toDate:today
}
const [formValues,setFormValues]=useState(formValuesInitial)

const [grandTotals,setGrandTotals]=useState({})
const handleInputChange=(key)=>(e,newValue)=>{
    setFormValues({...formValues,[key]:newValue===undefined?e.target.value:newValue})
}

const submitButtonClick=()=>{
    let body={
        branchId:formValues.branch?._id || null,
        startDate:formValues?.fromDate===""?undefined:`${formValues?.fromDate} 00:00:00`,
        endDate:formValues?.toDate===""?undefined:`${formValues?.toDate} 23:59:59`
    }
    listShiftSummaryReportAPI(body)
}

// initial api calls
useEffect(()=>{
    viewAllAllowedBranchesAPICall({employeeId: userInfo?.data?._id }) 
    submitButtonClick()
},[])

useEffect(()=>{
if(reportsList.length!==0){
    let sum=findSumOfObjkeys(reportsList,"totalAmtTotal","totalPaidAmt","totalDueAmount")
    setGrandTotals(sum)
}else{
    setGrandTotals({})
}
},[reportsList])
    return (
        <div className="global-page-parent-container" style={{position:"relative"}}>
            <div className="global-white-bg-container Header" style={{ display: "flex", justifyContent: "space-between", padding: "0px 30px 0 25px", alignItems: "center" }}>
                <p>Shift Summary Report</p>
                <div>

                    <ReactToPrint
                        trigger={() => (
                            <IconButton className="printer-div" style={{ padding: "1px" }}>
                                <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
                           
                            </IconButton>
                        )}
                        content={() => paymentsPrint}
                    />

                    <Tooltip title="Download">
                        <IconButton>
                            <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
                        </IconButton>
                    </Tooltip>
                </div>

            </div>

            <div className="global-white-bg-container " style={{ display: "flex", alignItems: "center" }}>
                <div className="new-global-single-input auto-complete-new">
                    <Autocomplete
                        options={userRole === "admin" ? allBranchesList : allowedBranchList || []}
                        getOptionLabel={(option) => option?.branchName}
                        renderInput={(params) => (
                            <TextField {...params} label="Branch" focused variant='outlined' />
                        )}
                        value={formValues?.branch}
                        onChange={handleInputChange("branch")}
                    />
                </div>


                <div className="new-global-single-input" style={{ width: '21%' }}>
                    <TextField
                        id="outlined-basic"
                        placeholder="From"
                        onChange={handleInputChange("fromDate")}
                        value={formValues?.fromDate}
                        variant="outlined"
                        type="date"
                        focused
                        label="From Date"
                    />
                </div>



                <div className="new-global-single-input" style={{ width: '21%' }}>
                    <TextField
                        id="outlined-basic"
                        placeholder="From"
                        onChange={handleInputChange("toDate")}
                        value={formValues?.toDate}
                        variant="outlined"
                        type="date"
                        focused
                        label="To Date"
                    />
                </div>

                <button
                onClick={submitButtonClick}
                className='btn btn-primary'>Submit</button>

            </div>

            <div className='global-white-bg-container shift-report-table-container'>

                {
                
                 tableLoading?
                 <div className="new-global-table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Order Date</th>
                                <th>Order Ref</th>
                                <th>Customer</th>
                                <th>Mobile</th>
                                <th>Order Amount</th>
                                <th>Paid Amount</th>
                                <th>Bank</th>
                                <th>Cash</th>
                                <th>Credit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                            [...Array(10)].map((_,i)=>(
                            <tr key={i}>
                                <td colspan={15}>
                                    <Skeleton variant="rectangular"
                                    animation="wave"
                                    width={"100%"}
                                    height={40} />
                                </td>
                            </tr>
                            ))    
                        }
                        </tbody>
                    </table>
                 </div>
                 :
                 reportsList?.length!==0?
                 reportsList?.map((list,i)=>(
                     <>
                         <div className="single-view-left-ctn" style={{ width: "60%", minWidth: "fit-content", maxWidth: "550px", padding: "16px", margin: "1%", marginBottom: "0", backgroundColor: "rgb(248 248 248)" }}>
                             <h3 style={{ margin: "0", cursor: "pointer", fontSize: "larger" }}>{formValues.branch?.branchName || "-NIL-"}</h3>
                             <div className="justify-space-between" style={{ display: "flex" }}>
                                 <div style={{ display: "flex", gap: "10px" }}>
                                     <div className="single-view-info-ctn-left flex-column">
                                         <p style={{ margin: "1px" }}>Shift</p>
                                         <p style={{ margin: "1px" }}>Employee</p>
                                     </div>
                                     <div className="single-view-info-ctn-right flex-column">
                                         <p style={{ margin: "1px" }}>SHIFT {list?.shift?.shiftId}</p>
                                         <p style={{ margin: "1px" }}>{list?.shift?.empName}</p>
                                     </div>
                                 </div>
                                 <div style={{ display: "flex", gap: "10px" }}>
                                     <div className="single-view-info-ctn-left flex-column">
                                         <p style={{ margin: "1px" }}>Start Date</p>
                                         <p style={{ margin: "1px" }}>End Date</p>
                                     </div>
                                     <div className="single-view-info-ctn-right flex-column">
                                         <p style={{ margin: "1px" }}>{list?.shift?.startDate}</p>
                                         <p style={{ margin: "1px" }}>{list?.shift?.endDate}</p>
                                     </div>
                                 </div>
                             </div>
                         </div>

                         <div className="new-global-table-container" style={{ paddingTop: "0 42px" }}>
                             <table>
                                 <thead >
                                     <tr>
                                         <th>Order Date</th>
                                         <th>Order Reff</th>
                                         <th>Customer</th>
                                         <th>Mobile</th>
                                         <th>Order Amount</th>
                                         <th>Paid Amount</th>
                                         <th>Credit</th>

                                     </tr>
                                 </thead>
                                 <tbody>
                                     {list?.orders?.length!==0?
                                     list?.orders?.map((item, i) => (
                                         <tr key={i} style={{ backgroundColor: i % 2 === 0 ? "white" : "#e9e7e7" }}>
                                             <td style={{ border: "none", height: "29px" }}>{convertDateFormat(item?.orderDate)}</td>
                                             <td style={{ border: "none", height: "29px" }}>{item?.name}</td>
                                             <td style={{ border: "none", height: "29px" }}>{item?.customerName}</td>
                                             <td style={{ border: "none", height: "29px" }}>{item?.custMobile}</td>
                                             <td style={{ border: "none", height: "29px" }}>{item?.amtTotal?.toFixed(decimalPosition)}</td>
                                             <td style={{ border: "none", height: "29px" }}>{item?.paidAmt?.toFixed(decimalPosition)}</td>
                                             <td style={{ border: "none", height: "29px" }}>{item?.amountDue?.toFixed(decimalPosition)}</td>
                                         </tr>
                                     ))
                                    :
                                    <tr>
                                        <td colspan={7}>No Records found</td>
                                    </tr>
                                    }
                                 </tbody>
                                 <tfoot>
                                     <tr style={{ backgroundColor: "#dfd9d9" }}>
                                         <td colSpan={4} style={{ textAlignLast: "end" }}>TOTAL</td>
                                         <td style={{ textAlignLast: "center" }}>{list?.totalAmtTotal?.toFixed(decimalPosition)}</td>
                                         <td style={{ textAlignLast: "center" }}>{list?.totalPaidAmt?.toFixed(decimalPosition)}</td>
                                         <td style={{ textAlignLast: "center" }}>{list?.totalDueAmount?.toFixed(decimalPosition)}</td>
                                     </tr>
                                 </tfoot>
                             </table>
                         </div>
                     </>
                 ))   
                : 
                <div className="center-div">
                    No Records
                </div>
                }
            </div>

            {
            Object.keys(grandTotals).length!==0 &&    
            <div className="new-global-table-container report-totalbar">
                <table>
                    <thead style={{display:"none"}}>
                        <tr>
                            <th>Order Date</th>
                            <th>Order Reff</th>
                            <th>Customer</th>
                            <th>Mobile</th>
                            <th>Order Amount</th>
                            <th>Paid Amount</th>
                            <th>Credit</th>

                        </tr>
                    </thead>
                    <tfoot>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>GRAND TOTAL</td>
                            <td>{Number(grandTotals?.totalAmtTotal)?.toFixed(decimalPosition)}</td>
                            <td>{Number(grandTotals?.totalPaidAmt)?.toFixed(decimalPosition)}</td>
                            <td>{Number(grandTotals?.totalDueAmount)?.toFixed(decimalPosition)}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>}
            <div style={{display:"none"}}>
  <div ref={(el) => (paymentsPrint = el)}>
    <Template formValues={formValues} grandTotals={grandTotals}/>
  </div>
</div>
        </div>
    )
}

export default PosShiftSummaryReport