import axios from "axios";
import store from "../../../../../Redux/store";
import { HEADERS, HEADER_FORM_DATA } from "../../../../../API/UrlAndPaths";
import {
  generate_sales_w_so_name,
  get_salesWOSO_history,
  get_sales_w_so_list,
  get_sales_w_so_row_data_list,
} from "./SalesWSOSlice";
import { setBatchPopup } from "../../../Inventory/Products/Batch/inventoryBatchSlice";
import { setSubmitLoading } from "../../../../../Redux/Dashboard/mainDashBoardSlice";
import { setErrorSnack, setSnackMsg, setSuccessSnack } from "../../../Sales/Billing/Slice/billingScreenSlice";

// view sales wso

export const viewSalesWSOAPICall = async (body, setIsLoading) => {
  setIsLoading !== undefined && setIsLoading(true);
  await axios
    .post("account/viewsaleswpo", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(get_sales_w_so_list({ salesWSOData: res.data }));
        setIsLoading !== undefined && setIsLoading(false);
      }
    })
    .catch((err) => {
      store.dispatch(get_sales_w_so_list({ salesWSOData: undefined }));
      setIsLoading !== undefined && setIsLoading(false);
    });
};
export const SalesWSOFilterAPICall =(body,setIsLoading)=>{
  setIsLoading!==undefined&&setIsLoading(true)
  axios.post("account/searchsalesWSo",body,{
    headers: {
      'Content-Type': 'application/json',
    },
   
  })
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_sales_w_so_list({salesWSOData:res.data}))
      setIsLoading!==undefined&&setIsLoading(false)
    }
  })
  .catch((err)=>{
    store.dispatch(get_sales_w_so_list({salesWSOData:undefined}))
    setIsLoading!==undefined&&setIsLoading(false)
  })

}
// generate sales wso draft name 

export const generateSalesWSONameAPICall = (body,setName) => {
  axios
    .post("account/generatesaleswponumber", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setName(res.data?.prefix)

        store.dispatch(generate_sales_w_so_name({ salesWSOName: res.data }));
      }
    })
    .catch((err) => {
      setName("###")

      store.dispatch(generate_sales_w_so_name({ salesWSOName: undefined }));
    });
};

// draft sales wso api call

export const draftSalesWSOAPICall = async (
  body,
  postFn,
  setState,
  state,
  setEditDataId,
  setLoadingForm
) => {
  setLoadingForm!==undefined && setLoadingForm(true)
  await axios
    .post("account/draftsaleswpo", body, HEADER_FORM_DATA)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(setBatchPopup(false))
        localStorage.setItem("singleViewById",res.data?._id);
        setState({ ...state, message: "Sales W/SO Drafted", success: true });
        postFn();
        setEditDataId(res.data?._id);
        salesWoSoSingleViewApi({_id:res.data?._id})
        setLoadingForm!==undefined && setLoadingForm(false)
      }
    })
    .catch((err) => {
      setLoadingForm!==undefined && setLoadingForm(false)
      if(err.response.status === 500){
        setState({ ...state, message: "Internal Server error !", error: true });
      }
      else{
        setState({ ...state, message: err?.response?.data, error: true });
       }
    });
};
// edit sales wso api call

export const editSalesWSOAPICall = async (body, postFn, setState, state,setLoadingForm) => {
  console.log("first")
  setLoadingForm!==undefined && setLoadingForm(true)
  await axios
    .put("account/editsaleswpo", body, HEADER_FORM_DATA)
    .then((res) => {
      if (res.status === 200) {
        setState({ ...state, message: "Sales W/SO Edited", success: true });
        postFn();
        setLoadingForm!==undefined && setLoadingForm(false)
      }
    })
    .catch((err) => {
      setLoadingForm!==undefined && setLoadingForm(false)
      if(err.response.status === 500){
        setState({ ...state, message: "Internal Server error !", error: true });
      }
      else{
        setState({ ...state, message:err?.response?.data, error: true });
      }
    });
};

export const salesWSOBatchEditAPICAll = async (
  body
) => {
  store.dispatch(setSubmitLoading(true))
  await axios
  .put("account/editsaleswpo", body, HEADER_FORM_DATA)
  .then((res) => {
    if (res.status === 200) {
      
      store.dispatch(setBatchPopup(false))
      store.dispatch(setSubmitLoading(false))
      store.dispatch(setSnackMsg("Batch Info updated"))
      store.dispatch(setSuccessSnack(true))
      localStorage.setItem("singleViewById",res.data._id)
      }
    })
    .catch((err) => {
      store.dispatch(setSubmitLoading(false))
      store.dispatch(setSnackMsg(err.response.status===500?"Internal Server error !":err?.response?.data || "Network connection failed"))
      store.dispatch(setErrorSnack(true))
    });
};
// post sales wso api call

export const postSalesWSOAPICall = async (body, postFn, setState, state,setLoadingForm) => {
  setLoadingForm!==undefined && setLoadingForm(true)
  await axios
    .post("account/postsaleswpo", body, HEADER_FORM_DATA)
    .then((res) => {
      if (res.status === 200) {
        setState({ ...state, message: "Sales W/SO Posted", success: true });
        postFn(); 
        salesWoSoSingleViewApi({_id:res.data._id})
        setLoadingForm!==undefined && setLoadingForm(false)
      }
    })
    .catch((err) => {
      setLoadingForm!==undefined && setLoadingForm(false)
      if(err.response.status === 500){
        setState({ ...state, message: "Internal Server error!", error: true });
      }
      else{
        setState({ ...state, message:err?.response?.data, error: true });
      }
    });
};

// sales woso single view api
export const salesWoSoSingleViewApi = async (body) => {
  await axios
    .post("account/viewsalesWpoAcById", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          get_sales_w_so_row_data_list({ salesWSOsingleData: res.data })
        );
      }
    })
    .catch((err) => {
      // setState((prev)=>({...prev,message:"Something went wrong !!",error:true}))
    });
};
  //post registered payment 
  export const salesWsoRegisterPaymentPostAPICall = (
    body,registerPymentCancelBtn,setSnackBarStates,setLoadingForm
  ) => {
    setLoadingForm!==undefined && setLoadingForm(true)
    axios
      .post("account/registerPaymentFromSWSO", body, HEADERS)
      .then((res) => {
        if (res.status === 200) {
          salesWoSoSingleViewApi({_id:body.swsoId})
            setSnackBarStates((prev)=>({...prev,message: "payment registered", success: true }))
            registerPymentCancelBtn()
            setLoadingForm!==undefined && setLoadingForm(false)
        }
      })
      .catch((err) => {
        setLoadingForm!==undefined && setLoadingForm(false)
        if(err.response.status === 500){
          setSnackBarStates((prev)=>({ ...prev, message: "Internal Server error !", error: true }));

        }
        else{
          setSnackBarStates((prev)=>({ ...prev, message:err?.response?.data, error: true }));

        }
      });
  };

    // sales woso history 
    export const saleswosoHistoryAPICall=(body,setLoadingForm)=>{
      setLoadingForm!==undefined && setLoadingForm(true)
     axios.post("purchase/saleswpoHistory",body,HEADERS)
     .then((res)=>{
      if(res.status===200){
        store.dispatch(get_salesWOSO_history({salesWSOHistoryData:res.data}))
        setLoadingForm!==undefined && setLoadingForm(false)
      }
     })
     .catch((err)=>{
      store.dispatch(get_salesWOSO_history({salesWSOHistoryData:undefined}))
      setLoadingForm!==undefined && setLoadingForm(false)
     })
    }
  
