import React, { useEffect } from "react";
import "./posReaderPreviewStyle.css";
import DownloadSvg from "./components/icon/DownloadSvg";
import PrintSvg from "./components/icon/PrintSvg";

import textileErpImg from "../../../../Assets/Images/textile-erp-img.png";
import restaurantErpImg from "../../../../Assets/Images/restaurant-erp-img.png";
import supermarketErpImg from "../../../../Assets/Images/supermarket-erp-img.png";
import autopartsErpImg from "../../../../Assets/Images/autoparts-erp-img.png";
import PosReceiptTemplate from "../../components/template/posReceipt/PosReceiptTemplate";
import { switch_to_billing_screen } from "../../Sales/Billing/newBillingSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Template1 from "../../SalesNew/SalesOrders/SalesQuotation/Template1";

const PosReaderPreview = () => {
  const staticData = [
    {
      name: "TEXTILE ERP",
      url: textileErpImg,
      description: `Optimize your textile manufacturing processes with our Textile ERP,
      tailored to meet the unique demands of the textile industry.`,
    },
    {
      name: "RESTAURANT ERP",
      url: restaurantErpImg,
      description: `Enhance the performance of your restaurant with our Restaurant ERP.
      Effortlessly manage orders, track inventory, handle billing. and maintain
      customer relationships to ensure smooth operations and outstanding service-`,
    },
    {
      name: "SUPERMARKET ERP",
      url: supermarketErpImg,
      description: `EYE-T ERP An Innovative software is a comprehensive solution that
      integrates all the aspects of your supermarket business, such as
      procurement, inventory, warehouse, sales, accounting, marketing, and more.`,
    },
    {
      name: "AUTOPARTS ERP",
      url: autopartsErpImg,
      description: `Enhance the perforr-nance of vour restaurant with our Restaurant E Ettorttosstv manage orders.
      inventory. handle billing and maintain customar relationships to ensure smooth
      operations and outstanding service.`,
    },
  ];
  let componentRef = React.createRef();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(switch_to_billing_screen({ billingBoolean: true }));
  }, []);

  const onProductClick = () => {
    window.open("https://eyeterp.ae/", "_blank");
  };

  return (
    <div className="pos-reader-preview-container">
      <div className="pos-reader-wrapper">
        {/* <--download file--> */}
        <div>
          <div className="r-download-container">
            <div>
              <span className="r-amount-h">
                {"AED"} &nbsp;
                {"11,200"}
              </span>
            </div>
            <div>
              <button className="r-btn-download">
                <DownloadSvg /> <span>DOWNLOAD</span>
              </button>
              <button className="r-btn-print">
                <PrintSvg /> <span>PRINT</span>
              </button>
            </div>
          </div>
          <div className="r-list-sale-product">
            {staticData.map((res, i) => (
              <div
                onClick={() => onProductClick()}
                className="r-product-container"
              >
                <div className="r-p-img">
                  <img src={res.url} alt="" />
                </div>
                <div className="r-p-content">
                  <span className="r-content-head">{res.name}</span>
                  <span className="r-content-body">{res.description}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* <--pos-receipt-preview--> */}
        <div>
          <div className="r-preview-pos-receipt">
            <PosReceiptTemplate receiptData={[]} ref={componentRef} />
            {/* <Template1 data={"data"} formValues={"formValues"} tax={"tax"} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PosReaderPreview;
