import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  billType: "DINEIN",
  customer: null,
  defaultCustomer: null,
  btnClicked: null,
  aggregator: null,
  customerWallet: null,
  searchBarInput:null,
  waiter:null,
  aggregatorRef:null,
  customerSingleInfo:null
};

export const topBarSliceBilling = createSlice({
  name: "topBarSliceBilling",
  initialState,

  reducers: {
    setBillType: (state, action) => {
      state.billType = action.payload;
    },
    setCustomer: (state, action) => {
      state.customer = action.payload;
    },
    setDefaultCustomer: (state, action) => {
      state.defaultCustomer = action.payload;
    },
    setBtnSelected:(state,action)=>{
      state.btnClicked=action.payload;
    },
    setAggregator:(state,action)=>{
      state.aggregator=action.payload;
    },
    setAggregatorRef:(state,action)=>{
      state.aggregatorRef=action.payload;
    },
    setCustomerWallet:(state,action)=>{
      state.customerWallet=action.payload;
    },
    setSearchBarInput:(state,action)=>{
      state.searchBarInput=action.payload;
    },
    setOrderWaiter:(state,action)=>{
      state.waiter=action.payload;
    },
    setSingleCusInfo:(state,action)=>{
      state.customerSingleInfo=action.payload;
    },
    resetTopBarStates: () => initialState 
  }
});

export const {setBillType,setCustomer,setSingleCusInfo,setOrderWaiter,setBtnSelected,setDefaultCustomer,setAggregatorRef,setAggregator,setCustomerWallet,resetTopBarStates,setSearchBarInput} = topBarSliceBilling.actions;

export default topBarSliceBilling.reducer;
