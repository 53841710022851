import React, { useEffect, useRef } from "react";
import "../../../../../css/Report/TrailBalanace.css";
import { Datepicker } from "../../../../Single Components/Datepicker";
import { IconButton, Skeleton, TextField, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { GeneralLedgerListAPI, TrailBalananceListAPI } from "./TrailBalanceAPI";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCurrentTime, previousDate, today } from "../../../../../Js/Date";
import { companyProfileInfoForUser } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
// import filterObjFromList from "../../../../../Js/generalFunctions"
import { viewAllCurrencyUpdated } from "../../../../../API/Settings/Currency/CurrencyAPI";
import { filterObjFromList } from "../../../../../Js/generalFunctions";
import ReactToPrint from "react-to-print";
import * as XLSX from "xlsx";

import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import { setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";
import store from "../../../../../Redux/store";
import { LoadingForm } from "../../../../Single Components/LoadingForm";

export const GeneralLedgerNw = () => {
  const ExcelJS = require("exceljs");
  const userInfo = useSelector((state) => state.loginResponseSlice.value);
  const filtereDataOptions = useSelector((state) => state.listCategory);
  let componentRef = useRef();

  const configurationList = useSelector(
    (state) => state.glConfigurationSlice.apiResList
  );
  const [filteredData, setFilteredData] = useState({});

  const handleFilterChange = (updatedItems) => {
    setFilteredData(updatedItems);
  };
  let navigate = useNavigate();
  // const [selectedHeading, setSelectedHeading] = useState("General Ledger Report");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [symbol, setSymbol] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [FormDataInfo, setFormDataInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const trailBalanceList = useSelector(
    (state) => state.trailbalance.generalLedgerData
  );
  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );
  // const downloadList = () => {
  //   const workbook = new ExcelJS.Workbook();
  //   const sheet = workbook.addWorksheet("Genereal Ledger");
  //   sheet.properties.defaultRowHeight = 20;

  //  // Header row styling
  //  const headerRow = sheet.getRow(1);
  //  headerRow.fill = {
  //    type: "pattern",
  //    pattern: "solid",
  //    fgColor: { argb: "#1d1da7" },
  //  };
  //  headerRow.font = {
  //    size: 15,
  //    bold: true,
  //    color: { argb: "#ffffff" },
  //  };

  //  sheet.columns = [
  //   { header: "Particular", key: "account", width: 50 },
  //   { header: "Debit", key: "debitTotal", width: 20 },
  //   { header: "Credit", key: "creditTotal", width: 20 },
  //   { header: "Balance", key: "balance", width: 20 },
  // ];

  //   trailBalanceList?.journalEntryList?.forEach((item) => {
  //     const row = sheet.addRow({
  //       account: item?.account,
  //       debitTotal: `${symbol} ${item.debitTotal?.toFixed(2)}`,
  //       creditTotal: `${symbol} ${item?.creditTotal?.toFixed(2)}`,
  //       balance:` ${symbol} ${item?.balance?.toFixed(2)}`,
  //     });
  
  //     // Set background color for all rows except the header row
  //     if (row.number !== 1) {
  //       row.fill = {
  //         type: "pattern",
  //         pattern: "solid",
  //         fgColor: { argb: "#c3d4f7" }, // Background color for all rows except header
  //       };
  //     }
  //       // Add white borders to each cell in the row
  //   row.eachCell((cell) => {
  //     cell.border = {
  //       top: { style: "thin", color: { argb: "#FFFFFF" } },
  //       left: { style: "thin", color: { argb: "#FFFFFF" } },
  //       bottom: { style: "thin", color: { argb: "#FFFFFF" } },
  //       right: { style: "thin", color: { argb: "#FFFFFF" } },
  //     };
  //   });
  
  //   });
  //     // Add white borders to each column
  // sheet.columns.forEach((column) => {
  //   column.eachCell((cell) => {
  //     cell.border = {
  //       top: { style: "thin", color: { argb: "#FFFFFF" } },
  //       left: { style: "thin", color: { argb: "#FFFFFF" } },
  //       bottom: { style: "thin", color: { argb: "#FFFFFF" } },
  //       right: { style: "thin", color: { argb: "#FFFFFF" } },
  //     };
  //   });
  // });
  
  //   // Add row for totals
  //   const totalRow = sheet.addRow([
  //     "Total",
  //     `${symbol} ${trailBalanceList?.debitSubtotal.toFixed(2)}`, 
  //     `${symbol} ${trailBalanceList?.creditSubtotal.toFixed(2)}`,
  //     `${symbol} ${trailBalanceList?.openingBalanceSubtotal.toFixed(2)}`,
  // ]);
  //   totalRow.font = { bold: true };
  //   totalRow.fill = {
  //     type: "pattern",
  //     pattern: "solid",
  //     fgColor: { argb: "#1d1da7" }, // Background color same as header row
  //   };
  //   totalRow.eachCell((cell) => {
  //     cell.font = { bold: true, color: { argb: "#ffffff" } };
  //   });
 
  // workbook.xlsx.writeBuffer().then(function (data) {
  //   const blob = new Blob([data], {
  //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //   });
  //   const url = window.URL.createObjectURL(blob);
  //   const anchor = document.createElement("a");
  //   anchor.href = url;
  //   anchor.download = "General Ledger.xlsx";
  //   anchor.click();
  //   window.URL.revokeObjectURL(url);
  // });
    
  // }
  const formatFilterInfo = (filters) => {
    const parts = [];
  
    if (filters?.fromDate && filters?.toDate) {
      parts.push(`As of ${filters?.fromDate} to ${filters?.toDate}`);
    }
    if (filters?.partner) {
      parts.push(`Partner: ${filters?.partner?.name}`);
    }
    if (filters?.accountType) {
      parts.push(`Account Type: ${filters?.accountType?.label}`);
    }
    if (filters?.branchList) {
      parts.push(`Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`);
    }
    if (filters?.status) {
      parts.push(`Status: ${filters?.status}`);
    }
    if (filters?.createdBy) {
      parts.push(`Employee: ${filters?.createdBy?.staff_name}`);
    }
    
    return parts; // Return the array directly
  };
  
//working 
const downloadList = () => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("General Ledger");
  sheet.properties.defaultRowHeight = 20;

  // Add header section
  const titleRow = sheet.addRow(["GENERAL LEDGER"]);
  titleRow.font = { size: 18, bold: true };
  sheet.mergeCells('A1:D1');
  titleRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };

  // Add additional header information
  const filterParts = formatFilterInfo(filteredData) || [];

  // Ensure filterParts is an array and iterate over it
  if (Array.isArray(filterParts)) {
    filterParts.forEach((part, index) => {
      const row = sheet.addRow([part]);
      row.font = { size: 13, bold: true };
      sheet.mergeCells(`A${index + 2}:D${index + 2}`);
      row.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
    });
  }

  // Add an empty row between header and table
  sheet.addRow([]);

  // Header row styling
  const headerRow = sheet.addRow(["Particular", "Debit", "Credit", "Balance"]);
  const headerFill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FF1d1da7" }, // Use full hex code with "FF" prefix for better compatibility
  };
  const headerFont = {
    size: 15,
    bold: true,
    color: { argb: "FFFFFFFF" }, // White font color
  };

  headerRow.eachCell((cell) => {
    cell.fill = headerFill;
    cell.font = headerFont;
    cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Ensure header text is centered
  });

  sheet.columns = [
    { header: "Particular", key: "account", width: 50 },
    { header: "Debit", key: "debitTotal", width: 20 },
    { header: "Credit", key: "creditTotal", width: 20 },
    { header: "General Ledger", key: "balance", width: 20 },
  ];

  trailBalanceList?.journalEntryList?.forEach((item) => {
    const row = sheet.addRow({
      account: item?.account,
      debitTotal: ` ${item.debitTotal?.toFixed(2)}`,
      creditTotal: ` ${item?.creditTotal?.toFixed(2)}`,
      balance: ` ${item?.balance?.toFixed(2)}`,
    });

    const rowFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFC3D4F7" }, // Use full hex code with "FF" prefix for better compatibility
    };
    
    row.eachCell((cell, colNumber) => {
      cell.fill = rowFill;
      cell.border = {
        top: { style: "thin", color: { argb: "FFFFFFFF" } },
        left: { style: "thin", color: { argb: "FFFFFFFF" } },
        bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
        right: { style: "thin", color: { argb: "FFFFFFFF" } },
      };
      if (colNumber === 4) { // Assuming the balance column is the 4th column
        const value = parseFloat(item?.balance);
        if (value < 0) {
          cell.font = { color: { argb: "FFFF0000" } }; // Red color for negative values
        }
      }
    });
  });

  // Add row for totals
  const totalRow = sheet.addRow([
    "Total",
    `${symbol} ${trailBalanceList?.debitSubtotal.toFixed(2)}`,
    `${symbol} ${trailBalanceList?.creditSubtotal.toFixed(2)}`,
    `${symbol} ${trailBalanceList?.openingBalanceSubtotal.toFixed(2)}`,
  ]);

  totalRow.eachCell((cell) => {
    cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
    cell.fill = headerFill;
    cell.border = {
      top: { style: "thin", color: { argb: "FFFFFFFF" } },
      left: { style: "thin", color: { argb: "FFFFFFFF" } },
      bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
      right: { style: "thin", color: { argb: "FFFFFFFF" } },
    };
  });

  workbook.xlsx.writeBuffer().then(function (data) {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = "General Ledger.xlsx";
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};



  //attempt 1 -fail
  // const downloadList = () => {
  //   const workbook = new ExcelJS.Workbook();
  //   const sheet = workbook.addWorksheet("General Ledger");
  //   sheet.properties.defaultRowHeight = 20;
  
  //   // Add header section
  //   const titleRow = sheet.addRow(["GENERAL LEDGER"]);
  //   titleRow.font = { size: 18, bold: true };
  //   sheet.mergeCells('A1:D1'); // Merge the correct range for the title
  
  //   // Add additional header information
  //   const dateRow = sheet.addRow([`As of ${new Date().toLocaleDateString()}`]);
  //   dateRow.font = { size: 17, bold: true };
  //   sheet.mergeCells('A2:D2');
  
  //   // Add an empty row between header and table
  //   sheet.addRow([]);
  
  //   // Header row styling
  //   const headerRow = sheet.addRow(["Particular", "Debit", "Credit", "Balance"]);
  //   for (let col = 1; col <= 4; col++) {
  //     const cell = headerRow.getCell(col);
  //     cell.fill = {
  //       type: "pattern",
  //       pattern: "solid",
  //       fgColor: { argb: "#1d1da7" },
  //     };
  //     cell.font = {
  //       size: 15,
  //       bold: true,
  //       color: { argb: "#ffffff" },
  //     };
  //   }
  
  //   sheet.columns = [
  //     { header: "Particular", key: "account", width: 50 },
  //     { header: "Debit", key: "debitTotal", width: 20 },
  //     { header: "Credit", key: "creditTotal", width: 20 },
  //     { header: "Balance", key: "balance", width: 20 },
  //   ];
  
  //   trailBalanceList?.journalEntryList?.forEach((item) => {
  //     const row = sheet.addRow({
  //       account: item?.account,
  //       debitTotal: `${symbol} ${item.debitTotal?.toFixed(2)}`,
  //       creditTotal: `${symbol} ${item?.creditTotal?.toFixed(2)}`,
  //       balance: `${symbol} ${item?.balance?.toFixed(2)}`,
  //     });
  
  //     // Set background color for columns A to D
  //     if (row.number !== 4) { // Adjust to reflect the actual data rows
  //       for (let col = 1; col <= 4; col++) {
  //         row.getCell(col).fill = {
  //           type: "pattern",
  //           pattern: "solid",
  //           fgColor: { argb: "#c3d4f7" },
  //         };
  //       }
  //     }
  
  //     // Add white borders to each cell in columns A to D
  //     for (let col = 1; col <= 4; col++) {
  //       const cell = row.getCell(col);
  //       cell.border = {
  //         top: { style: "thin", color: { argb: "#FFFFFF" } },
  //         left: { style: "thin", color: { argb: "#FFFFFF" } },
  //         bottom: { style: "thin", color: { argb: "#FFFFFF" } },
  //         right: { style: "thin", color: { argb: "#FFFFFF" } },
  //       };
  //     }
  //   });
  
  //   // Add row for totals
  //   const totalRow = sheet.addRow([
  //     "Total",
  //     `${symbol} ${trailBalanceList?.debitSubtotal.toFixed(2)}`,
  //     `${symbol} ${trailBalanceList?.creditSubtotal.toFixed(2)}`,
  //     `${symbol} ${trailBalanceList?.openingBalanceSubtotal.toFixed(2)}`,
  //   ]);
  
  //   // Style the total row cells in columns A to D
  //   for (let col = 1; col <= 4; col++) {
  //     const cell = totalRow.getCell(col);
  //     cell.font = { bold: true, color: { argb: "#ffffff" } };
  //     cell.fill = {
  //       type: "pattern",
  //       pattern: "solid",
  //       fgColor: { argb: "#1d1da7" },
  //     };
  //     cell.border = {
  //       top: { style: "thin", color: { argb: "#FFFFFF" } },
  //       left: { style: "thin", color: { argb: "#FFFFFF" } },
  //       bottom: { style: "thin", color: { argb: "#FFFFFF" } },
  //       right: { style: "thin", color: { argb: "#FFFFFF" } },
  //     };
  //   }
  
  //   workbook.xlsx.writeBuffer().then(function (data) {
  //     const blob = new Blob([data], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //     });
  //     const url = window.URL.createObjectURL(blob);
  //     const anchor = document.createElement("a");
  //     anchor.href = url;
  //     anchor.download = "General Ledger.xlsx";
  //     anchor.click();
  //     window.URL.revokeObjectURL(url);
  //   });
  // };

  //attempt 2
  // const downloadList = () => {
  //   const workbook = new ExcelJS.Workbook();
  //   const sheet = workbook.addWorksheet("General Ledger");
  //   sheet.properties.defaultRowHeight = 20;

  //   // Add header section
  //   const titleRow = sheet.addRow(["GENERAL LEDGER"]);
  //   titleRow.font = { size: 18, bold: true };
  //   sheet.mergeCells('A1:D1'); // Correct merge for the title row

  //   // Add an empty row
  //   sheet.addRow([]);

  //   // Add additional header information
  //   const dateRow = sheet.addRow([`As of ${new Date().toLocaleDateString()}`]);
  //   dateRow.font = { size: 17, bold: true };
  //   sheet.mergeCells('A3:D3'); // Correct merge for the date row

  //   // Add an empty row between header and table
  //   sheet.addRow([]);

  //   // Header row styling
  //   const headerRow = sheet.addRow(["Particular", "Debit", "Credit", "Balance"]);
  //   for (let col = 1; col <= 4; col++) {
  //     const cell = headerRow.getCell(col);
  //     cell.fill = {
  //       type: "pattern",
  //       pattern: "solid",
  //       fgColor: { argb: "#1d1da7" },
  //     };
  //     cell.font = {
  //       size: 15,
  //       bold: true,
  //       color: { argb: "#ffffff" },
  //     };
  //   }

  //   sheet.columns = [
  //     { header: "Particular", key: "account", width: 50 },
  //     { header: "Debit", key: "debitTotal", width: 20 },
  //     { header: "Credit", key: "creditTotal", width: 20 },
  //     { header: "Balance", key: "balance", width: 20 },
  //   ];

  //   trailBalanceList?.journalEntryList?.forEach((item) => {
  //     const row = sheet.addRow({
  //       account: item?.account,
  //       debitTotal: `${symbol} ${item.debitTotal?.toFixed(2)}`,
  //       creditTotal: `${symbol} ${item?.creditTotal?.toFixed(2)}`,
  //       balance: `${symbol} ${item?.balance?.toFixed(2)}`,
  //     });

  //     // Set background color for columns A to D
  //     if (row.number !== 4) { // Adjust to reflect the actual data rows
  //       for (let col = 1; col <= 4; col++) {
  //         row.getCell(col).fill = {
  //           type: "pattern",
  //           pattern: "solid",
  //           fgColor: { argb: "#c3d4f7" },
  //         };
  //       }
  //     }

  //     // Add white borders to each cell in columns A to D
  //     for (let col = 1; col <= 4; col++) {
  //       const cell = row.getCell(col);
  //       cell.border = {
  //         top: { style: "thin", color: { argb: "#FFFFFF" } },
  //         left: { style: "thin", color: { argb: "#FFFFFF" } },
  //         bottom: { style: "thin", color: { argb: "#FFFFFF" } },
  //         right: { style: "thin", color: { argb: "#FFFFFF" } },
  //       };
  //     }
  //   });

  //   // Add row for totals
  //   const totalRow = sheet.addRow([
  //     "Total",
  //     `${symbol} ${trailBalanceList?.debitSubtotal.toFixed(2)}`,
  //     `${symbol} ${trailBalanceList?.creditSubtotal.toFixed(2)}`,
  //     `${symbol} ${trailBalanceList?.openingBalanceSubtotal.toFixed(2)}`,
  //   ]);

  //   // Style the total row cells in columns A to D
  //   for (let col = 1; col <= 4; col++) {
  //     const cell = totalRow.getCell(col);
  //     cell.font = { bold: true, color: { argb: "#ffffff" } };
  //     cell.fill = {
  //       type: "pattern",
  //       pattern: "solid",
  //       fgColor: { argb: "#1d1da7" },
  //     };
  //     cell.border = {
  //       top: { style: "thin", color: { argb: "#FFFFFF" } },
  //       left: { style: "thin", color: { argb: "#FFFFFF" } },
  //       bottom: { style: "thin", color: { argb: "#FFFFFF" } },
  //       right: { style: "thin", color: { argb: "#FFFFFF" } },
  //     };
  //   }

  //   workbook.xlsx.writeBuffer().then(function (data) {
  //     const blob = new Blob([data], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //     });
  //     const url = window.URL.createObjectURL(blob);
  //     const anchor = document.createElement("a");
  //     anchor.href = url;
  //     anchor.download = "General Ledger.xlsx";
  //     anchor.click();
  //     window.URL.revokeObjectURL(url);
  //   });
  // };
  
  // const downloadList = () => {
  //   const worksheet = XLSX.utils.table_to_sheet(document.getElementById("general_ledger_data"));
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //   XLSX.writeFile(workbook, "General Ledger.xlsx");
  // }
  // const downloadList = () => {
  //   // Generate the worksheet from the HTML table
  //   const worksheet = XLSX.utils.table_to_sheet(document.getElementById("general_ledger_data"));
  
  //   // Create a new workbook and append the worksheet
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  
  //   // Set custom column widths
  //   const columnWidths = [
  //     { wch: 30 },
  //     { wch: 20 },
  //     { wch: 20 },
  //     { wch: 20 } 
  //   ];
  //   worksheet['!cols'] = columnWidths;
  
  //   // Write the workbook to a file
  //   XLSX.writeFile(workbook, "General Ledger.xlsx");
  // };
  

  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );
  const handleDataFromChild = (data) => {
    setFormDataInfo(data);
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch);
  };
  // console.log(companyProfileData);
  const handleClickRow = (row) => {
    localStorage.setItem("singleViewById", row?._id);
    localStorage.setItem("fromDate", fromDate);
    localStorage.setItem("toDate", toDate);
    localStorage.setItem("accName", row?.account);

    // setSelectedHeading("Ledger Balance");
    store.dispatch( setFilterActive(window.location.pathname))
    navigate(
      "/userdashboard/accounts/report/financialReport/trialbalance/ledgerBalance"
    );
  };

  // const getFromDate = (e) => {
  //   setFromDate(e.target.value);
  // };
  // const getToDate = (e) => {
  //   setToDate(e.target.value);
  // };
  useEffect(() => {
    if (configurationList !== undefined) {
      setToDate(configurationList?.yearEndDate);
      setFromDate(configurationList?.yearStartDate);
    }
  }, [configurationList]);
  
  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    // console.log(currencyObj);
    setSymbol(currencyObj?.symbol);
  }, [companyProfileData, currencyListUpdated]);
  // const changeColor = (heading) => {
  //   setSelectedHeading(heading);
  // };

  // useEffect(() => {
  //   if (toDate !== undefined && fromDate !== undefined) {
  //     const startDate = fromDate === "" ? "" : `${fromDate} 00:00:00`;
  //     const endDate = toDate === "" ? today : `${toDate} 23:59:59`;

  //     GeneralLedgerListAPI({
  //       startDate: startDate,
  //       endDate: endDate,
  //     });
  //   }
  // }, [fromDate, toDate]);
  useEffect(() => {
    // const source = axios.CancelToken.source();
    if (FormDataInfo.length !== 0) {
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
    !loadData && GeneralLedgerListAPI({
        startDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
        endDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
        employeeId: FormDataInfo?.createdBy?.length===0?"": FormDataInfo?.createdBy[0],
        branchId:FormDataInfo?.branchList?.length===0?"": FormDataInfo?.branchList[0],
        partner: FormDataInfo?.partner,
        type: FormDataInfo?.accountType,
       
      },setIsLoading);
    }
    // return () => {
    //   source.cancel('Request canceled by cleanup');
    // };
  }, [FormDataInfo]);

  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, []);

  return (
    <div className="top-main-container" style={{height:"93vh",backgroundColor:"unset"}}>
      {/* <div className="first-main-container-div">
      <div className="first-main-heading-div" id="headingDiv">
          <p
            style={{
              marginLeft: "14px",
              color: selectedHeading === "General Ledger Report" ? "#0e2493" : "#c2cbf9",
            }}
            onClick={() => changeColor("General Ledger Report")}
          >
            General Ledger Report
          </p>
          <p
            style={{
              color: selectedHeading === "Ledger Balance" ? "#0e2493" : "#c2cbf9",
            }}
           // onClick={() => changeColor("Ledger Balance")}
          >
            Ledger Balance
          </p>
          <p
            style={{
              color: selectedHeading === "General Entry" ? "#0e2493" : "#c2cbf9",
            }}
            onClick={() => changeColor("General Entry)}
          >"
            General Entry
          </p>
        </div>
      </div> */}
      <div className="secound-main-container-div" style={{paddingTop:"6px"}}>
       
        <div style={{width:"auto"}}>
        <p style={{ fontWeight: 600, fontSize: "1em" }}>
          General Ledger Report
        </p>
        </div>
         <div style={{display:"flex",width:"auto"}}>
          <div style={{width:"auto",display:"flex"}}>
            <Tooltip title="Share">
              <IconButton>
                <i
                  class="bi bi-share"
                  style={{
                    backgroundColor: "#9797e9",
                    color: "white",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                ></i>
              </IconButton>
            </Tooltip>
         
            <ReactToPrint
              trigger={() => (
                <Tooltip title="Print">
                  <IconButton
                    className="printer-div"
                    style={{ padding: "1px" }}
                  >
                    <i
                      style={{
                        color: "white",
                        backgroundColor: " #d787e7",
                        fontSize: "medium",
                        padding: "8px",
                        borderRadius: "2px",
                      }}
                      class="bi bi-printer"
                    ></i>
                  </IconButton>
                </Tooltip>
              )}
              content={() => componentRef}
            />
          
            <Tooltip title="Download">
              <IconButton onClick={downloadList}>
                <i
                  class="bi bi-arrow-down-circle"
                  style={{
                    backgroundColor: "rgb(255 118 133 / 69%)",
                    color: "white",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                ></i>
              </IconButton>
            </Tooltip>
          </div>
          <div >
           <CategorySearchandFilter
           page="generalLedger"
              isLeft={true}
              statusOptions={["PURCHASE ORDER", "DONE", "CANCEL"]}
              onData={handleDataFromChild}
              onBranchSelect={handleBranchSelect}
              isAccountType={true}
              isCreatedBy={true}
              isPartner={true}
              onFilterChange={handleFilterChange}
            />
        </div>  
        </div> 
       
       
       
      </div>
     
       

        <div className="gl-table-div" style={{height:"84vh",marginTop:"3px",backgroundColor:"white",maxHeight:"84vh"}}>
          <table id="general_ledger_data" style={{paddingTop:"9px"}}>
            <thead>
              <tr>
                <th
                  style={{
                    width: "55%",
                    textAlign: "start",
                    paddingLeft: "12px",
                  }}
                >
                  Particular
                </th>
                <th style={{ width: "15%" }}>Debit</th>
                <th style={{ width: "15%" }}>Credit</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody>
          {isLoading ? (
            // Skeleton loader while loading
            [...Array(10)].map((r, i) => (
              <tr key={i}>
                <td colSpan={4}>
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width={"100%"}
                    height={40}
                  />
                </td>
              </tr>
            ))
          ) : trailBalanceList?.journalEntryList?.map((item, index) => (
            <tr key={index} onClick={() => handleClickRow(item)}>
              <td style={{ textAlign: "start", paddingLeft: "12px" }}>
                {item.account}
              </td>
              <td>
                {symbol}&nbsp;{item.debitTotal.toFixed(2)}
              </td>
              <td>
                {symbol}&nbsp;{item.creditTotal.toFixed(2)}
              </td>
              <td
                style={{
                  color: item.balance < 0 ? "#e31212" : "black",
                }}
              >
                {symbol}&nbsp;{item.balance.toFixed(2)}
              </td>
            </tr>
          ))}
        </tbody>
            <tr style={{ textAlign: "end", position: "sticky", bottom: "0" }}>
              <th
                style={{
                  width: "55%",
                  paddingLeft: "12px",
                  textAlign: "start",
                  fontSize: "initial",
                }}
              >
                Total
              </th>
              <th>
                {symbol}&nbsp;{trailBalanceList?.debitSubtotal.toFixed(2)}
              </th>
              <th>
                {symbol}&nbsp;{trailBalanceList?.creditSubtotal.toFixed(2)}
              </th>
              <th>
                {symbol}&nbsp;
                {trailBalanceList?.openingBalanceSubtotal.toFixed(2)}
              </th>
            </tr>
          </table>
        </div>
     

      {/* print container */}
      <div style={{ display: "none" }}>
        <div
          ref={(el) => (componentRef = el)}
          className="print-container-trail-balance"
        >
          <div className="trailbalnce-print-header">
            <div className="company-logo-container">
              <img
                height={100}
                width={100}
                src="http://placehold.it/100x100"
                alt=""
              />
            </div>
            <div className="trail-balance-print-title">
              <span>General Ledger Report</span>
              <span style={{ fontSize: "13px" }}> As Off {toDate}</span>
            </div>
            <div className="company-details-container">
              <span>{userInfo?.data?.profile?.companyName} </span>
              <span>{userInfo?.data?.profile?.companyAddress} </span>
              <span>{userInfo?.data?.profile?.companyEmail}</span>
            </div>
          </div>
          <div className="print-general-ledger-container">
            <div className="gl-table-div ">
              <table className="general-ledger-table">
                <thead>
                  <tr>
                    <th>Particular</th>
                    <th>Debit</th>
                    <th>Credit</th>
                    <th>Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {trailBalanceList?.journalEntryList?.map((item, index) => (
                    <tr key={index} onClick={() => handleClickRow(item)}>
                      <td style={{ textAlign: "start", paddingLeft: "12px" }}>
                        {item.account}
                      </td>
                      <td>
                        {symbol}&nbsp;{item.debitTotal.toFixed(2)}
                      </td>
                      <td>
                        {symbol}&nbsp;{item.creditTotal.toFixed(2)}
                      </td>
                      <td
                        style={{
                          color: item.openingBalance < 0 ? "#e31212" : "black",
                        }}
                      >
                        {symbol}&nbsp;{item.openingBalance.toFixed(2)}
                      </td>
                    </tr>
                  ))}
                  <tr className="total-row-footer">
                    <td>Total</td>
                    <td>
                      {symbol}&nbsp;{trailBalanceList?.debitSubtotal.toFixed(2)}
                    </td>
                    <td>
                      {symbol}&nbsp;
                      {trailBalanceList?.creditSubtotal.toFixed(2)}
                    </td>
                    <td>
                      {symbol}&nbsp;
                      {trailBalanceList?.openingBalanceSubtotal.toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <LoadingForm loading={isLoading} />
    </div>
  );
};
