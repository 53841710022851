import { Button, Menu } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import {SpeedOutlined,DescriptionOutlined,AccountTreeOutlined,ManageAccountsOutlined,SettingsOutlined} from "@mui/icons-material";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import RemoveFromQueueIcon from '@mui/icons-material/RemoveFromQueue';
import ShopTwoOutlinedIcon from "@mui/icons-material/ShopTwoOutlined";
import InventoryIcon from "@mui/icons-material/Inventory";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import FoodBankIcon from '@mui/icons-material/FoodBank';
import SettingsBrightnessIcon from "@mui/icons-material/SettingsBrightness";
import QrCodeScannerOutlinedIcon from "@mui/icons-material/QrCodeScannerOutlined";

import { useSelector } from 'react-redux';
const SideMenuUpdated = () => {

    const sideMenuRef=useRef(null)
    const userRole = useSelector((state) => state.userRoleSlice.value);
    const loginResponse = useSelector((state) => state.loginResponseSlice.value);
    const permission=useSelector((state)=>state.viewRolesAndPermissionSlice.employeePermission)
    // const {permission}=loginResponse
    const inventorySettingsConfigurationList = useSelector((state) => state.inventoryConfigSettingSlice.apiResList);
  
    const [collapsed, setCollapsed] = useState(true);

    const items = [
        {
          key: '/userdashboard/dashboardhome',
          icon: <SpeedOutlined/>,
          label: 'Dashboard',
          path: '/userdashboard/dashboardhome',
          disabled:!permission?.dashboard
        },
        {
          key: '/userdashboard/productList',
          icon: <RemoveFromQueueIcon/>,
          label: 'Products',
          path: '/userdashboard/productList',
          disabled:!permission?.products
        },
        {
          key: 'pointOfSale',
          label: 'Point Of Sales',
          icon: <PointOfSaleIcon/>,
          children: [
            {
              key: '/userdashboard/pos/billingnew',
              label: 'Billing',
              path: '/userdashboard/pos/billingnew',
              disabled: (!permission?.pointOfSales?.billing || userRole==="admin")
            },
            {
              key: '/userdashboard/pos/specialItems',
              label: 'Special Items',
              path: '/userdashboard/pos/specialItems',
              disabled: (!permission?.pointOfSales?.specialItems)
            },
            {
              key: 'posGeneral',
              label: 'General',
              children:[
                {
                  key: '/userdashboard/pointOfSales/general/shift',
                  label: 'Shift',
                  path: '/userdashboard/pointOfSales/general/shift',
                  disabled: (!permission?.pointOfSales?.general?.shift)
                },
                {
                  key: '/userdashboard/pointOfSales/general/orders',
                  label: 'Orders',
                  path: '/userdashboard/pointOfSales/general/orders',
                  disabled: (!permission?.pointOfSales?.general?.orders)
                },
                {
                  key: '/userdashboard/pointOfSales/general/payments',
                  label: 'Payments',
                  path: '/userdashboard/pointOfSales/general/payments',
                  disabled: (!permission?.pointOfSales?.general?.payments)
                },
                {
                  key: '/userdashboard/pointOfSales/general/wallet',
                  label: 'Wallet',
                  path: '/userdashboard/pointOfSales/general/wallet',
                  disabled: (!permission?.pointOfSales?.general?.wallet)
                },
                {
                  key: '/userdashboard/pointOfSales/general/RewardPoints',
                  label: 'Reward Points',
                  path: '/userdashboard/pointOfSales/general/RewardPoints'
                },
                {
                  key: '/userdashboard/pointOfSales/general/promotions&offers',
                  label: 'Promotions & Offers',
                  path: '/userdashboard/pointOfSales/general/promotions&offers'
                },
                {
                  key: '/userdashboard/pointOfSales/general/Rewards',
                  label: 'Rewards',
                  path: '/userdashboard/pointOfSales/general/Rewards'
                },
              ]
            },
            {
              key: 'posReport',
              label: 'Report',
              children:[
                {
                  key: '/userdashboard/pointOfSales/report/shiftReport',
                  label: 'Shift Report',
                  path: '/userdashboard/pointOfSales/report/shiftReport',
                  disabled: (!permission?.pointOfSales?.report?.shiftReport)
                },
                {
                  key: '/userdashboard/pointOfSales/report/shiftSummaryReport',
                  label: 'Shift Summary Report',
                  path: '/userdashboard/pointOfSales/report/shiftSummaryReport',
                   disabled: (!permission?.pointOfSales?.report?.shiftSummaryReport)
                },
                {
                  key: '/userdashboard/pointOfSales/report/salesDetails',
                  label: 'Sales Details',
                  path: '/userdashboard/pointOfSales/report/salesDetails',
                  disabled: (!permission?.pointOfSales?.report?.salesDetails)
                },
                {
                  key: '/userdashboard/pointOfSales/report/aggregatorReport',
                  label: 'Aggregator Report',
                  path: '/userdashboard/pointOfSales/report/aggregatorReport',
                  disabled: (!permission?.pointOfSales?.report?.aggregatorReport)
                },
                {
                  key: '/userdashboard/pointOfSales/report/DailyCashCardSummary',
                  label: 'Daily Cash/Card Summary',
                  path: '/userdashboard/pointOfSales/report/DailyCashCardSummary',
                  disabled: (!permission?.pointOfSales?.report?.cashcardSummary
                  )
                },
              ]
            },
            {
              key:'posExpense',
              label:'Expense',
              children:[
                {
                  key: '/userdashboard/Expense/staffExpense',
                  label: 'Staff Expense',
                  path: '/userdashboard/Expense/staffExpense',
                  disabled: (!permission?.pointOfSales?.expense?.staffExpense)
                },
                {
                  key: '/userdashboard/Expense/outletExpense',
                  label: 'Outlet Expense',
                  path: '/userdashboard/Expense/outletExpense',
                  disabled: (!permission?.pointOfSales?.expense?.outletExpense)
                },
                {
                  key: '/userdashboard/Expense/addExpenseType',
                  label: 'Add Expense Type',
                  path: '/userdashboard/Expense/addExpenseType',
                  disabled: (!permission?.pointOfSales?.expense?.addExpenseType)
                },
                {
                  key: '/userdashboard/Expense/pettyCashReceipt',
                  label: 'Petty Cash Receipt',
                  path: '/userdashboard/Expense/pettyCashReceipt',
                  disabled: (!permission?.pointOfSales?.expense?.pettycashReceipt || !userRole==="admin")
                },
              ]
            },
            {
              key:'posConfiguration',
              label:'Configuration',
              children:[
                {
                  key: '/userdashboard/pointOfSales/configuration/settings',
                  label: 'Settings',
                  path: '/userdashboard/pointOfSales/configuration/settings',
                  disabled:!permission?.pointOfSales?.configuration?.settings
                },
                {
                  key: '/userdashboard/pointOfSales/configuration/BranchSettings',
                  label: 'Branch Settings',
                  path: '/userdashboard/pointOfSales/configuration/BranchSettings',
                  disabled:!permission?.pointOfSales?.configuration?.branchSettings
                },
                {
                  key: '/userdashboard/pointOfSales/configuration/Aggregator',
                  label: 'Aggregator',
                  path: '/userdashboard/pointOfSales/configuration/Aggregator',
                  disabled:!permission?.pointOfSales?.configuration?.aggregator
                }
              ]
            }
          ],
        },
        {
          key: 'purchaseManager',
          icon: <ShopTwoOutlinedIcon/>,
          label: 'Purchase',
          children:[
            {
              key: '/userdashboard/purchaseManager/RequestForQuatation',
              label: 'Request For Quotation',
              path: '/userdashboard/purchaseManager/RequestForQuatation',
              disabled: !permission?.purchase?.rfq

            },
            {
              key: '/userdashboard/purchaseManager/NewPurchaseOrder',
              label: 'Purchase Order',
              path: '/userdashboard/purchaseManager/NewPurchaseOrder',
              disabled: !permission?.purchase?.purchaseOrder
            },
            {
              key: '/userdashboard/purchaseManager/GoodsReceivedNote',
              label: 'Goods Received Note',
              path: '/userdashboard/purchaseManager/GoodsReceivedNote',
              disabled: !permission?.purchase?.grn
            },
            {
              key: '/userdashboard/purchaseManager/unitOfMeasurment',
              label: 'Unit Of Measurement',
              path: '/userdashboard/purchaseManager/unitOfMeasurment',
              disabled: !permission?.purchase?.uom
            },
            {
              key: '/userdashboard/purchaseManager/vendor/vendors',
              label: 'Vendors',
              path: '/userdashboard/purchaseManager/vendor/vendors',
              disabled: !permission?.purchase?.vendors
            },
            {
              key: '/userdashboard/purchaseManager/configuration',
              label: 'Configuration',
              path: '/userdashboard/purchaseManager/configuration',
              disabled: !permission?.purchase?.configuration
            },
            {
              key: '/userdashboard/purchaseManager/PurchaseReport/PurchaseReportNew',
              label: 'Purchase Report',
              path: '/userdashboard/purchaseManager/PurchaseReport/PurchaseReportNew',
              disabled: !permission?.purchase?.purchaseReport 
            }
          ]
        },
        {
          key: 'inventory',
          icon: <InventoryIcon/>,
          label: 'Inventory',
          children:[
            {
              key: 'inventoryProduct',
              label: 'Products',
              children:[
                {
                  key: '/userdashboard/inventory/products/productmaster',
                  label: 'Product Master',
                  path: '/userdashboard/inventory/products/productmaster',
                  disabled:(!permission?.inventory?.products?.productMaster
                    || !inventorySettingsConfigurationList?.productVarient)
                },
                {
                  key: '/userdashboard/inventory/products/product',
                  label: 'Product',
                  path: '/userdashboard/inventory/products/product',
                  disabled:(!permission?.inventory?.products?.product)
                },
                {
                  key: '/userdashboard/inventory/batch',
                  label: 'Batch',
                  path: '/userdashboard/inventory/batch',
                  disabled:(!inventorySettingsConfigurationList?.enableBatch)
                }
              ]
            },
            {
              key:'inventoryOperations',
              label:'Operations',
              children:[
                {
                  key: '/userdashboard/inventory/operation/Interanltransfer',
                  label: 'Internal Transfer',
                  path: '/userdashboard/inventory/operation/Interanltransfer',
                  disabled:(!permission?.inventory?.operations?.internalTransfer|| !inventorySettingsConfigurationList?.multiLocation)
                },
                {
                  key: '/userdashboard/inventory/operation/branchtransfer',
                  label: 'Branch Transfer',
                  path: '/userdashboard/inventory/operation/branchtransfer',
                  disabled:(!permission?.inventory?.operations?.branchTransfer)
                },
                {
                  key: '/userdashboard/inventory/operation/branchreceipt',
                  label: 'Branch Receipt',
                  path: '/userdashboard/inventory/operation/branchreceipt',
                  disabled:(!permission?.inventory?.operations?.branchReciept                  )
                },
                {
                  key: '/userdashboard/inventory/operation/stockmoves',
                  label: 'Stock Moves',
                  path: '/userdashboard/inventory/operation/stockmoves',

                  disabled:(!permission?.inventory?.operations?.stockMoves)

                },
                {
                  key: '/userdashboard/inventory/operation/inventoryAdjustment',
                  label: 'Inventory Adjustment',
                  path: '/userdashboard/inventory/operation/inventoryAdjustment',
                  disabled:(!permission?.inventory?.operations?.inventoryAdjustments)
                }
              ]
            },
            {
              key:'inventoryReport',
              label:'Reports',
              children:[
                {
                  key: '/userdashboard/inventory/reports/stockMove',
                  label: 'Stock Move',
                  path: '/userdashboard/inventory/reports/stockMove',
                  disabled:(!permission?.inventory?.reports?.stockMoveReport)
                }
              ]
            },
            {
              key:'inventoryConfiguration',
              label:'Configuration',
              children:[
                {
                  key: '/userdashboard/inventory/configuration/warehouse',
                  label: 'Ware House',
                  path: '/userdashboard/inventory/configuration/warehouse',
                  disabled:(!permission?.inventory?.configuration?.warehouse)
                },
                {
                  key: '/userdashboard/inventory/configuration/location',
                  label: 'Location',
                  path: '/userdashboard/inventory/configuration/location',
                  disabled:(!permission?.inventory?.configuration?.location)
                },{
                  key: '/userdashboard/inventory/configuration/attribute',
                  label: 'Attribute',
                  path: '/userdashboard/inventory/configuration/attribute',
                  disabled:(!permission?.inventory?.configuration?.attribute)
                },{
                  key: '/userdashboard/inventory/configuration/category',
                  label: 'Category',
                  path: '/userdashboard/inventory/configuration/category',
                  disabled:(!permission?.inventory?.configuration?.category)
                },{
                  key: '/userdashboard/inventory/configuration/poscategory',
                  label: 'POS Category',
                  path: '/userdashboard/inventory/configuration/poscategory',
                  disabled:(!permission?.inventory?.configuration?.poscategory)
                },
                {
                  key: '/userdashboard/inventory/configuration/settings',
                  label: 'Settings',
                  path: '/userdashboard/inventory/configuration/settings',
                  disabled:(!permission?.inventory?.configuration?.settings)
                }
              ]
            }
          ]
        },
        {
          key: 'sales',
          icon: <ShoppingCartIcon/>,
          label: 'Sales',
          children:[
            {
              key:'/userdashboard/sales/orders/quotation',
              label: 'Quotation',
              path: '/userdashboard/sales/orders/quotation',
              disabled:!permission?.sales?.quotation},
            {
              key: '/userdashboard/sales/orders/salesOrder',
              label: 'Sales Order',
              path: '/userdashboard/sales/orders/salesOrder',
              disabled:!permission?.sales?.salesOrder
            },
            {
              key: '/userdashboard/sales/orders/deliveryNote',
              label: 'Delivery Note',
              path: '/userdashboard/sales/orders/deliveryNote',
              disabled:!permission?.sales?.deliveryNote
            },
            {
              key: '/userdashboard/sales/orders/customers',
              label: 'Customers',
              path: '/userdashboard/sales/orders/customers',
              disabled:!permission?.sales?.
              customers
              
            },
            {
              key:`salesReport`,
              label:'Report',
              children:[
                {
                  key: '/userdashboard/sales/Report/salesReport',
                  label: 'Sales Report',
                  path: '/userdashboard/sales/Report/salesReport',
                  disabled:!permission?.sales?.report?.salesReport
                },
                {
                  key: '/userdashboard/sales/Report/salesReportBySalesPerson',
                  label: 'Sales Report By Sales Person',
                  path: '/userdashboard/sales/Report/salesReportBySalesPerson',
                  disabled:!permission?.sales?.report?.salesReportBySalesPerson

                }
              ]

            }
          ]
        },
        {
          key:'report',
          label:'Report',
          icon:<DescriptionOutlined/>,
          children:[
            {
              key: '/userdashboard/report/stockReport',
              label: 'Stock Report',
              path: '/userdashboard/report/stockReport',
              disabled:!permission?.report?.stockreport
             },
            {
              key: '/userdashboard/report/expenseReport',
              label: 'Expense Report',
              path: '/userdashboard/report/expenseReport',
              disabled:!permission?.report?.expenseReport
            }
          ]
        },
        {
          key:'accounts',
          label:'Accounts',
          icon:<AccountTreeOutlined/>,
          children:[
            {
              key: '/userdashboard/accounts/',
              label: 'Dashboard',
              path: '/userdashboard/accounts/',
              disabled:!permission?.accounts?.dashboard
              
            },
            {
              key: 'accountsCustomer',
              label: 'Customers',
              children:[
                {
                  key: '/userdashboard/accounts/customers/customerInvoices',
                  label: 'Customer Invoices',
                  path: '/userdashboard/accounts/customers/customerInvoices',
                  disabled:!permission?.accounts?.customers?.customerInvoice
                  
                },
                {
                  key: '/userdashboard/accounts/customers/saleswso',
                  label: 'Sales W/SO',
                  path: '/userdashboard/accounts/customers/saleswso',
                  disabled:!permission?.accounts?.customers?.saleswoso
                },
                {
                  key: '/userdashboard/accounts/customer/payments',
                  label: 'Payments',
                  path: '/userdashboard/accounts/customer/payments',
                  disabled:!permission?.accounts?.customers?.payments

                },
                {
                  key: '/userdashboard/accounts/customers/creditNotes',
                  label: 'Credit Notes',
                  path: '/userdashboard/accounts/customers/creditNotes',
                  disabled:!permission?.accounts?.customers?.creditNotes
                },
                {
                  key: '/userdashboard/accounts/customers/customers',
                  label: 'Customers',
                  path: '/userdashboard/accounts/customers/customers',
                  disabled:!permission?.accounts?.customers?.customers
                }
              ]
            },
            {
              key: 'accountsVendor',
              label: 'Vendor',
              children:[
                {
                  key: '/userdashboard/accounts/vendor/vendorBills',
                  label: 'Vendor Bills',
                  path: '/userdashboard/accounts/vendor/vendorBills',
                  disabled:!permission?.accounts?.vendor?.vendorBills
                },
                {
                  key: '/userdashboard/accounts/vendor/purchasewopo',
                  label: 'Purchase W/PO',
                  path: '/userdashboard/accounts/vendor/purchasewopo',
                  disabled:!permission?.accounts?.vendor?.purchasewpo  
                },
                {
                  key: '/userdashboard/accounts/vendor/payments',
                  label: 'Payments',
                  path: '/userdashboard/accounts/vendor/payments',
                  disabled:!permission?.accounts?.vendor?.payments
                  
                },
                {
                  key: '/userdashboard/accounts/vendor/DebitNotes',
                  label: 'Debit Notes',
                  path: '/userdashboard/accounts/vendor/DebitNotes',
                  disabled:!permission?.accounts?.vendor?.debitNotes
                },
                {
                  key: '/userdashboard/accounts/vendor/vendors',
                  label: 'Vendors',
                  path: '/userdashboard/accounts/vendor/vendors',
                  disabled:!permission?.accounts?.vendor?.vendors
                }
              ]
            },
            {
              key:'accountsAccounting',
              label: 'Accounting',
              children:[
                {
                  key:'/userdashboard/accounts/accounting/journal',
                  label: 'Journal',
                  path: '/userdashboard/accounts/accounting/journal',
                  disabled:!permission?.accounts?.accounting?.journal
                  
                },
                {
                  key: '/userdashboard/accounts/accounting/chartofaccounts',
                  label: 'Chart Of Account',
                  path: '/userdashboard/accounts/accounting/chartofaccounts',
                  disabled:!permission?.accounts?.accounting?.chartofaccounts
                },
                {
                  key: '/userdashboard/accounts/accounting/openingBalance',
                  label: 'Opening Balance',
                  path: '/userdashboard/accounts/accounting/openingBalance',
                  disabled:!permission?.accounts?.accounting?.openingBalance
                },
                {
                  key: '/userdashboard/accounts/accounting/journalEntries',
                  label: 'Journal Entries',
                  path: '/userdashboard/accounts/accounting/journalEntries',
                  disabled:!permission?.accounts?.accounting?.journalEntries
                }
              ]
            },
            {
              key:'accountsReconcilation',
              label: 'Reconcilation',
              children:[
                {
                  key: '/userdashboard/accounts/Reconciliation/BankReconciliation',
                  label: 'Bank Reconciliation',
                  path: '/userdashboard/accounts/Reconciliation/BankReconciliation',
                  disabled:!permission?.accounts?.reconcilation?. bankReconcilation
                  
                }
              ]
            },
            {
              key:'accountsVatReport',
              label: 'VAT Report',
              children:[
                {
                  key: '/userdashboard/accounts/vatReport/inputOrOutputReport',
                  label: 'Input / Output Report',
                  path: '/userdashboard/accounts/vatReport/inputOrOutputReport',
                  disabled:!permission?.accounts?.vatreport?.inputoroutputreport
                }
              ]
            },
            {
              key: 'accountsReport',
              label: 'Reports',
              children:[
                {
                  key: 'accountsReportFinancialReports',
                  label: 'Financial Reports',
                  children:[
                    {
                      key: '/userdashboard/accounts/report/financialReport/trialbalance',
                      label: 'Trial Balance',
                      path: '/userdashboard/accounts/report/financialReport/trialbalance',
                      disabled:!permission?.accounts?.report?.financialReport?.trialBalance
                    },
                    {
                      key: '/userdashboard/accounts/report/financialReport/balancesheet',
                      label: 'Balance Sheet',
                      path: '/userdashboard/accounts/report/financialReport/balancesheet',
                      disabled:!permission?.accounts?.report?.financialReport?.balanceSheet
                    },
                    {
                      key: '/userdashboard/accounts/report/financialReport/generalLedger',
                      label: 'General Ledger',
                      path: '/userdashboard/accounts/report/financialReport/generalLedger',
                      disabled:!permission?.accounts?.report?.financialReport?.generalLedger
                    },
                    {
                      key: '/userdashboard/accounts/report/financialReport/Profit&Loss',
                      label: 'Profit and Loss Report',
                      path: '/userdashboard/accounts/report/financialReport/Profit&Loss',
                      disabled:!permission?.accounts?.report?.financialReport?.profitAndLoss
                    }
                  ]
                },
                {
                  key:'accountsReportPartnerReports',
                  label: 'Partner Reports',
                  children:[
                    {
                      key: '/userdashboard/accounts/report/partnerReport/accountPayable',
                      label: 'Account Payable',
                      path: '/userdashboard/accounts/report/partnerReport/accountPayable',
                      disabled:!permission?.accounts?.report?.partnerReports?.accountPayable
                    },
                    {
                      key: '/userdashboard/accounts/report/partnerReport/accountReceivable',
                      label: 'Account Receivable',
                      path: '/userdashboard/accounts/report/partnerReport/accountReceivable',
                      disabled:!permission?.accounts?.report?.partnerReports?.accountReceivable
                    },
                    {
                      key: '/userdashboard/accounts/report/partnerReport/AccountReceivableAgingReport',
                      label: 'Aging Report Receivable ',
                      path: '/userdashboard/accounts/report/partnerReport/AccountReceivableAgingReport',
                      disabled:!permission?.accounts?.report?.partnerReports?.agingReportReceivable

                    },
                    {
                      key: '/userdashboard/accounts/report/partnerReport/AccountPayableAgingReport',
                      label: 'Aging Report Payable ',
                      path: '/userdashboard/accounts/report/partnerReport/AccountPayableAgingReport',
                      disabled:!permission?.accounts?.report?.partnerReports?.agingReportPayable

                    },
                    {
                      key: '/userdashboard/accounts/report/partnerReport/ReceivableDueReport',
                      label: 'Receivable Due Report',
                      path: '/userdashboard/accounts/report/partnerReport/ReceivableDueReport',
                      disabled:!permission?.accounts?.report?.partnerReports?.receivableDueReport
                      
                    },
                    {
                      key: '/userdashboard/accounts/report/partnerReport/PayableDueReport',
                      label: 'Payable Due Report',
                      path: '/userdashboard/accounts/report/partnerReport/PayableDueReport',
                      disabled:!permission?.accounts?.report?.partnerReports?.payableDueReport
                      
                    }
                  ]
                },
                {
                  key:'accountsReportGeneralReports',
                  label: 'General Reports',
                  children:[
                    {
                      key: '/userdashboard/accounts/report/generalReport/bankAndCashReport',
                      label: 'Bank & Cash Report',
                      path: '/userdashboard/accounts/report/generalReport/bankAndCashReport',
                      disabled:!permission?.accounts?.report?.generalReport?.bankAndCash
                    },
                    {
                      key: '/userdashboard/accounts/report/generalReport/ChequeRegisterReport',
                      label: 'Cheque Register Report',
                      path: '/userdashboard/accounts/report/generalReport/ChequeRegisterReport',
                      disabled:!permission?.accounts?.report?.generalReport?.chequeRegister
                    },
                    {
                      key: '/userdashboard/accounts/report/generalReport/InvoiceMarginReport',
                      label: 'Invoice Margin Report',
                      path: '/userdashboard/accounts/report/generalReport/InvoiceMarginReport',
                      disabled:!permission?.accounts?.report?.generalReport?.invoiceMargin
                      
                    },
                    {
                      key: '/userdashboard/accounts/report/generalReport/productMarginReport',
                      label: 'Product Margin Report',
                      path: '/userdashboard/accounts/report/generalReport/productMarginReport',
                      disabled:!permission?.accounts?.report?.generalReport?.productMargin
                    },
                    {
                      key: '/userdashboard/sales/Report/CustomerReceiptReport',
                      label: 'Customer Receipt Report',
                      path: '/userdashboard/sales/Report/CustomerReceiptReport',
                      disabled:!permission?.accounts?.report?.generalReport?.customerReciept

                    }
                  ]
                }
              ]
            },
            {
              key:'/userdashboard/accounts/configuration',
              label: 'Configuration',
              path:'/userdashboard/accounts/configuration',
              disabled: (!permission?.accounts?.configuration || userRole==="user")
              
            }
          ]
        },
        {
          key:'staff',
          label: 'Staff',
          icon:<ManageAccountsOutlined/>,
          children:[
            {
              key:'staffHRM',
              label: 'HRM',
              children:[
                {
                  key: '/userdashboard/staff/hrm/department',
                  label: 'Department',
                  path: '/userdashboard/staff/hrm/department',
                  disabled:!permission?.staff?.hrm?.department
                },
                {
                  key: '/userdashboard/staff/hrm/designation',
                  label: 'Designation',
                  path: '/userdashboard/staff/hrm/designation',
                  disabled:!permission?.staff?.hrm?.designation
                },
                {
                  key: '/userdashboard/staff/hrm/manageEmployee',
                  label: 'Manage Employee',
                  path: '/userdashboard/staff/hrm/manageEmployee',
                  disabled:!permission?.staff?.hrm?.manageEmployee
                },
                {
                  key: '/userdashboard/staff/hrm/addEmployee',
                  label: 'Add Employee',
                  path: '/userdashboard/staff/hrm/addEmployee',
                  disabled:!permission?.staff?.hrm?.addEmployee
                  
                },
                {
                  key: '/userdashboard/staff/hrm/addDocument',
                  label: 'Add Document',
                  path: '/userdashboard/staff/hrm/addDocument',
                  disabled:!permission?.staff?.hrm?.addDocument
                }
              ]
            }
          ]
        },
        {
          key:'/userdashboard/Devicesettings',
          label: 'Device Settings',
          icon:<SettingsBrightnessIcon/>,
          path:'/userdashboard/Devicesettings',
          disabled:!permission?.deviceSettings
        
        },
        {
          key:'/userdashboard/qr/barcode',
          label: 'Generate QR/Barcode',
          icon:<QrCodeScannerOutlinedIcon/>,
          path:'/userdashboard/qr/barcode',
          disabled:!permission?.generateQrBarcode
        },
        {
          key:'/userdashboard/settings',
          label: 'Settings',
          icon:<SettingsOutlined/>,
          path:'/userdashboard/settings',
          hideMenu:userRole==="user"
        }
      ];
    const toggleCollapsed = () => {
        setCollapsed(!collapsed);


    };
    const navigate = useNavigate();
   
    const handleClick = (e) => {
     
        navigate(e.key);
    
       

    };
    const location = useLocation();
    const [selectedKeys, setSelectedKeys] = useState([location.pathname]);


    const removeHiddenMenus = (items) => {
      return items.reduce((filteredItems, item) => {
        if (item.hideMenu) {
          return filteredItems; // Skip items with hideMenu: true
        }
        
        // If the item has children, apply the function recursively
        if (item.children) {
          item.children = removeHiddenMenus(item.children);
        }
    
        // Push the current item to the result array
        filteredItems.push(item);
        return filteredItems;
      }, []);
    };
    
    const finalMenu = removeHiddenMenus(items);
    useEffect(() => {

        setSelectedKeys([location.pathname]);

    }, [location.pathname]);


    useEffect(() => {
      const handleClickOutside = (event) => {
          if (
              sideMenuRef.current &&
              !sideMenuRef.current.contains(event.target)
          ) {
              setCollapsed(true)
          }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
          document.removeEventListener("mousedown", handleClickOutside);
      };
  }, []);

  return (
    <div className='sidemenu-ant-ctn' ref={sideMenuRef}>
        <div style={{position:"relative"}}>

        <Button
        type="primary"
        onClick={toggleCollapsed}
        className='menu-expand-btn'
      >
        {collapsed ? ">" : "<"}
      </Button>
      <Menu
        onClick={handleClick}
        // defaultOpenKeys={['sub3','sub2']}
        selectedKeys={selectedKeys}
        mode="inline"
        theme="light"
        inlineCollapsed={collapsed}
        items={finalMenu}
        className='ant-menu'
        style={{
          width:collapsed?"60px":"fit-content",
          boxShadow:collapsed?"none":"0 8px 16px rgba(0,0,0,0.3)",

        }}
      />

    </div>

    </div>
  )
}

export default SideMenuUpdated