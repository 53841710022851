import React, { useEffect, useState } from 'react'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { addCustomerFromReservation, editCustomerFromReservation } from '../newBillingAPI';
import { useDispatch, useSelector } from 'react-redux';
import countryList from "../../../../../Assets/JSON/countryStates.json"
import { Autocomplete, TextField } from '@mui/material';
import { setDialogBox } from '../Slice/billingScreenSlice';
import { findObjFromList } from '../../../../../Js/generalFunctions';
function AddCustomerFromReservation(props) {

    const{setCustomerPopup}=props
    const countryWithState=countryList.countries
    const dispatch=useDispatch()
    const userInfo = useSelector((state) => state.loginResponseSlice.value);
    const {customerSingleInfo} = useSelector((state) => state.topBarSliceBilling);

    const formValueInitial={
        name:"",
        mobile:"",
        country:null,
        state:null,
        city:"",
        street:"",
        buildingNo:"",
        roomNo:""
    }
    const [formValues,setFormValues]=useState(formValueInitial)
    const [validationAlert,setValidationAlert]=useState({})
    const [addMoreInfo,setAddMoreInfo]=useState(false)
    const formOnChange=(key)=>(e,newValue)=>{
        setFormValues({...formValues,[key]:newValue!==undefined?newValue:e.target.value})
        setValidationAlert({})
    }

    const clearFn=()=>{
        setCustomerPopup(false)
        setFormValues(formValueInitial)
        dispatch(setDialogBox(null))
    }

    const saveCustomer=()=>{
        let customerFormData=new FormData()

        customerFormData.append("name", formValues?.name)
        customerFormData.append("mobileNo", formValues?.mobile)
        formValues.country!==null && customerFormData.append("country",formValues.country?.country)
        formValues.state!==null && customerFormData.append("state",formValues.state)
        formValues.city!=="null" && customerFormData.append("city",formValues.city)
        formValues.street!=="" && customerFormData.append("streetName",formValues.street)
        formValues.buildingNo!=="" && customerFormData.append("buildingNo",formValues.buildingNo)
        formValues.roomNo!=="" && customerFormData.append("roomNo",formValues.roomNo)
        
        // default values
        customerFormData.append("branchId", userInfo?.branchPk)
        customerFormData.append("status", true)
        customerFormData.append("isCustomer", true)
        customerFormData.append("isVendor", false)
        
        customerSingleInfo!==null && customerFormData.append("_id", customerSingleInfo?._id)

        if(formValues?.name===""){
            setValidationAlert({...validationAlert,name:"fill this field !!"})
          }
          else if(formValues?.mobile===""){
            setValidationAlert({...validationAlert,mobile:"fill this field !!"})
          }
          else{
            let postFn=()=>{
                setCustomerPopup("customerAdded")
                setFormValues(formValueInitial)
            }
           customerSingleInfo?editCustomerFromReservation(customerFormData,postFn): addCustomerFromReservation(customerFormData,postFn)
          }
    }


    useEffect(()=>{
        if(customerSingleInfo){
          setFormValues({...formValues,
            name: customerSingleInfo?.name,
            mobile: customerSingleInfo?.mobileNo,
            country: findObjFromList("country", countryWithState, "country", customerSingleInfo),
            state: customerSingleInfo?.state || null,
            city: customerSingleInfo?.city,
            street: customerSingleInfo?.streetName,
            buildingNo: customerSingleInfo?.buildingNo,
            roomNo: customerSingleInfo?.roomNo
          })
          setAddMoreInfo(true)
        }else{
          setFormValues(formValueInitial)
        }
    },[customerSingleInfo])
    return (
            <div className='wallet-change-dialog'>
                  <div className="head justify-center">
                    <div className="icon justify-center">
                        <PeopleAltIcon/>
                    </div>
                       {customerSingleInfo?"Edit":"Create New"} Customer
                  </div>
                  <div className="input-ctn">
                    <button disabled className="btn">Name</button>
                    <input type="text" 
                    value={formValues?.name}
                    onChange={formOnChange("name")}
                    style={{textAlign:"start",paddingLeft:"10px"}}
                    />
                    <p className="doc-validation-alert">{validationAlert?.name}</p>
                  </div>
                  <div className="input-ctn">
                    <button disabled className="btn">Mobile</button>
                    <input type="text" 
                    value={formValues?.mobile}
                    onChange={formOnChange("mobile")}
                    style={{textAlign:"start",paddingLeft:"10px"}}
                    />
                    <p className="doc-validation-alert">{validationAlert?.mobile}</p>
                  </div>
                  {
                    addMoreInfo &&
                    <>
                    <div className="input-ctn">
                      <button disabled className="btn">Country</button>
                      <Autocomplete
                        className='customer-popup-autocomplete'
                        id="combo-box-demo"
                        options={countryWithState || []}
                        getOptionLabel={(option) => option?.country}
                        renderInput={(params) => (
                          <TextField {...params}/>
                        )}
                        value={formValues?.country}
                        onChange={formOnChange("country")}
                      />
                    </div>
                    <div className="input-ctn">
                      <button disabled className="btn">State</button>
                      <Autocomplete
                        className='customer-popup-autocomplete'
                        id="combo-box-demo"
                        options={formValues?.country?.states || []}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField {...params}/>
                        )}
                        value={formValues?.state}
                        onChange={formOnChange("state")}
                      />
                    </div>
                    <div className="input-ctn">
                      <button disabled className="btn">City</button>
                      <input type="text" 
                      value={formValues?.city}
                      onChange={formOnChange("city")}
                      style={{textAlign:"start",paddingLeft:"10px"}}
                      />                   
                    </div>
                    <div className="input-ctn">
                      <button disabled className="btn">Street</button>
                      <input type="text" 
                      value={formValues?.street}
                      onChange={formOnChange("street")}
                      style={{textAlign:"start",paddingLeft:"10px"}}
                      />                   
                    </div>
                    <div className="input-ctn">
                      <button disabled className="btn">Building No</button>
                      <input type="text" 
                      value={formValues?.buildingNo}
                      onChange={formOnChange("buildingNo")}
                      style={{textAlign:"start",paddingLeft:"10px"}}
                      />                   
                    </div>
                    <div className="input-ctn">
                      <button disabled className="btn">Room No</button>
                      <input type="text" 
                      value={formValues?.roomNo}
                      onChange={formOnChange("roomNo")}
                      style={{textAlign:"start",paddingLeft:"10px"}}
                      />                   
                    </div>
                    </>
                  }
                  <div className="wallet-change-btn">
                    <button
                    onClick={clearFn}
                    className="btn-secondary-outlined btn">Cancel</button>
                    <button
                    onClick={saveCustomer}
                    className="btn-primary btn">{customerSingleInfo?"Edit":"Save"}</button>

                    {!addMoreInfo &&
                      <p 
                      onClick={()=>setAddMoreInfo(true)}
                      className="doc-validation-alert">Add more fields</p>
                    }
                  </div>
            </div>
  )
}

export default AddCustomerFromReservation