import axios from "axios"
import { HEADERS } from "../../../../API/UrlAndPaths"
import store from "../../../../Redux/store"
import { get_aggregator_report, get_cash_card_report, get_sales_details_list, get_shift_report_list, get_shift_report_payment, get_shift_report_payment_summary, get_shift_report_sale, get_shift_report_wallet_pay } from "./ReportSlice"
import { setSubmitLoading } from "../../../../Redux/Dashboard/mainDashBoardSlice"

//list of shift//
export const ListPosShifReportAPI = async (body) => {
    await axios.post("pos/shiftReportProducts", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {

                store.dispatch(get_shift_report_list(res.data))

            }
        })
        .catch((err) => {
            store.dispatch(get_shift_report_list(undefined))


        })
}
//list of shiftSales//
export const ListPosShifReportSalesAPI = async (body,setShiftDetails) => {
    await axios.post("pos/shiftReportSale", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {

                store.dispatch(get_shift_report_sale(res.data))
                setShiftDetails(res.data)
                

            }
        })
        .catch((err) => {
            store.dispatch(get_shift_report_sale(undefined))


        })
}
//list of shiftWallet and payment//
export const ListPosShifReportWalletAPI = async (body) => {
    try {
        const response = await axios.post("pos/shiftReportPaymentWallet", body, HEADERS);
        if (body.isWallet === true) {
            store.dispatch(get_shift_report_wallet_pay(response.data));
        } else {
            store.dispatch(get_shift_report_payment(response.data));
        }
    } catch (error) {
        if (body.isWallet === true) {
            store.dispatch(get_shift_report_wallet_pay(undefined));
        } else {
            store.dispatch(get_shift_report_payment(undefined));
        }
    }
}

//list of payment summary//
export const ListPosPaymentSummaryAPI = async (body) => {
    await axios.post("pos/shiftReportPaymentSummary", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {

                store.dispatch( get_shift_report_payment_summary(res.data))

            }
        })
        .catch((err) => {
            store.dispatch( get_shift_report_payment_summary(undefined))


        })
}
//list of sales details//
export const ListPosSalesDetailsAPI = async (body) => {
    store.dispatch(setSubmitLoading(true))

    await axios.post("foodManagement/viewsalesdetailsreport", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(setSubmitLoading(false))

                store.dispatch(get_sales_details_list(res.data))

            }
        })
        .catch((err) => {
            store.dispatch(setSubmitLoading(false))

            store.dispatch(get_sales_details_list(undefined))


        })
}
//list of cash & card Summary//
export const ListCashAndCardSummaryAPI = async (body) => {
    store.dispatch(setSubmitLoading(true))

    await axios.post("foodManagement/cashcardreport", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(setSubmitLoading(false))

                store.dispatch( get_cash_card_report(res.data))

            }
        })
        .catch((err) => {
            store.dispatch(setSubmitLoading(false))

            store.dispatch( get_cash_card_report(undefined))


        })
}
//list of cash & card Summary//
export const ListAggregatorAPI = async (body) => {
    store.dispatch(setSubmitLoading(true))

    await axios.post("pos/aggregatorReport", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(setSubmitLoading(false))

                store.dispatch( get_aggregator_report(res.data))

            }
        })
        .catch((err) => {
            store.dispatch(setSubmitLoading(false))

            store.dispatch( get_aggregator_report(undefined))


        })
}