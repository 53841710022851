import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import { Skeleton } from '@mui/material';
import { setFilterActive } from '../../../../Single Components/listSlice/filterCategorySlice';
import { store_batch_single_data } from './inventoryBatchSlice';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import CustomPagination from '../../../../Single Components/CustomPagination';
import { getInventoryBatchAPICall } from './inventoryBatchAPI';

 const InventoryBatch = () => {

  const filtereDataOptions = useSelector((state) => state.listCategory);

  const dispatch=useDispatch();

  const {tableLoading}=useSelector((state)=>state.mainDashBoardSlice)
  const {tableList}=useSelector((state)=>state.inventoryBatchSlice)
  let navigate = useNavigate();


  const [FormDataInfo, setFormDataInfo] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = tableList?.pages;


  const handleClickRow = (row) => {
    dispatch(setFilterActive(window.location.pathname))
    localStorage.setItem('singleViewById', row?._id)
    navigate("singleInfo")
  }
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };


  useEffect(() => {
    localStorage.removeItem("singleViewById");
    dispatch(store_batch_single_data(null))
  }, [])
  useEffect(() => {
   
    if (FormDataInfo.length !== 0) {
    let payload={
        index: currentPage - 1
    }
    let loadData=window.location.pathname===filtereDataOptions?.filterActive
    !loadData && getInventoryBatchAPICall(payload)
    };
   
  }, [FormDataInfo, currentPage]);



  return (
    <div className='global-page-parent-container'>
      {/* table */}
      <div className='global-white-bg-container'>
        <div className="justify-space-between"
          style={{ gap: "0 12px",margin:"0 1%", display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}
        >
          <h3>Batch</h3>

          <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '9px',
          }}>
            <CategorySearchandFilter
              statusOptions={['DRAFT', 'POSTED']}
              onData={handleDataFromChild}
              isStatus={true}
              onBranchSelect={()=>{}}
            />
          </div>
        </div>

        <div className='create-button-blue-container' style={{ margin: "0 1%",justifyContent:"flex-end" }}>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setFormDataInfo={setFormDataInfo}
          />
        </div>
        <div className='new-global-table-container'>
          <table>
            <thead>
              <tr>
                <th>Batch No</th>
                <th>Product</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody >
              {

                tableLoading ? (
                  [...Array(10)].map((r, i) => (
                    <tr key={i}>
                      <td colSpan={9}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                  ))
                ) :
                tableList?.length !== 0 && tableList?.list?.length !== 0 ? tableList?.list?.map((r, i) => (
                    <tr key={i} onClick={() => handleClickRow(r)}>
                      <td style={{width:"30%",maxWidth:"250px"}}>{r?.batchNo}</td>
                      <td>{r?.productName}</td>
                      <td>{r?.onHandQty}</td>
                    </tr>
                  ))
                    :
                    <tr>
                      <td colSpan={3}>No Data</td>
                    </tr>
              }
            </tbody>

          </table>
        </div>
      </div>

    </div>
  )
}


export default InventoryBatch