import axios from "axios";
import { HEADERS, HEADER_FORM_DATA } from "../../../../../API/UrlAndPaths";
import store from "../../../../../Redux/store";
import { get_delivery_noteres_list, get_delivery_noterow_data_list } from "./deliveryNoteSlice";
import { customerInvoiceSingleViewAPICall } from "../../../Accounts/Customers/CustomerInvoice/customerInvoiceAPI";
import { setErrorSnack, setSnackMsg, setSuccessSnack } from "../../../Sales/Billing/Slice/billingScreenSlice";
import { setSubmitLoading } from "../../../../../Redux/Dashboard/mainDashBoardSlice";
import { setBatchPopup } from "../../../Inventory/Products/Batch/inventoryBatchSlice";
// delivery note listing
export const viewDeliveryNotesAPICall=async(body,setIsLoading)=>{
  setIsLoading !== undefined && setIsLoading(true)
  await axios
    .post(`sales/viewdeliverynotes`,body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(get_delivery_noteres_list({deliveryNoteData:res.data}))
        setIsLoading !== undefined && setIsLoading(false)
      }
    })
    .catch((err) => {
        setIsLoading !== undefined && setIsLoading(false)
        store.dispatch(get_delivery_noteres_list({deliveryNoteData:undefined}))
    });
}
export const DelivaryNoteAPICall =(body,setIsLoading)=>{
  setIsLoading!==undefined&&setIsLoading(true)
  axios.post("sales/deliveryNoteSearch",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_delivery_noteres_list({deliveryNoteData:res.data}))
      setIsLoading!==undefined&&setIsLoading(false)
    }
  })
  .catch((err)=>{
    store.dispatch(get_delivery_noteres_list({deliveryNoteData:undefined}))
    setIsLoading!==undefined&&setIsLoading(false)

  })

}

// post delivery note api call

export const postDeliveryNotesAPICall=async(body,postFn,setState,state,setLoadingForm)=>{
  setLoadingForm!==undefined && setLoadingForm(true)
  await axios.post("sales/postDeliveryNote",body,HEADERS)
   .then((res)=>{
      if(res.status===200){
      //  store.dispatch(get_customer_invoice_row_data_list({singleCustomerInvoiceData:res.data}))
      setLoadingForm!==undefined && setLoadingForm(false)
      setState({...state,message:"Delivery note posted",success:true})
      postFn()
     
      deliveryNoteSingleViewAPICall({ _id: res.data?._id });
      
      }
   })
   .catch((err)=>{
    setLoadingForm!==undefined && setLoadingForm(false)
    if (err.response.status === 500) {
      setState({ ...state, message: "Internal Server error !", error: true })
    } else {
      setState({ ...state, message: err?.response?.data, error: true })
    }
   })
}
// // edit  delivery note api call

export const editDeliveryNotesAPICall=async(body,postFn,setState,state,setLoadingForm)=>{
  setLoadingForm!==undefined && setLoadingForm(true)
  await axios.put("sales/editdeliverynote",body,HEADER_FORM_DATA)
   .then((res)=>{
      if(res.status===200){
        setLoadingForm!==undefined && setLoadingForm(false)
        setState({...state,message:"Delivery note edited",success:true})
          postFn()
      }
   })
   .catch((err)=>{
    setLoadingForm!==undefined && setLoadingForm(false)
    if (err.response.status === 500) {
      setState({ ...state, message: "Internal Server error !", error: true })
    } else {
      setState({ ...state, message: err?.response?.data, error: true })
    }
   })
}


export const deliveryNoteBatchEditAPICAll = async (
  body
) => {
  store.dispatch(setSubmitLoading(true))
  await axios
  .put("sales/editdeliverynote", body, HEADERS)
  .then((res) => {
    if (res.status === 200) {
      
      localStorage.setItem("singleViewById",res.data._id)
      store.dispatch(setSubmitLoading(false))
      store.dispatch(setSnackMsg("Batch Info updated"))
      store.dispatch(setSuccessSnack(true))
      store.dispatch(setBatchPopup(false))
      }
    })
    .catch((err) => {
      store.dispatch(setSubmitLoading(false))
      store.dispatch(setSnackMsg(err.response.status===500?"Internal Server error !":err?.response?.data || "Network connection failed"))
      store.dispatch(setErrorSnack(true))
    });
};
// delivery note single view
export const deliveryNoteSingleViewAPICall=(body)=>{
  axios.post("sales/viewdeliverynoteById",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_delivery_noterow_data_list({ deliveryNoteSingleData:res.data }))
     }
  })
  .catch((err)=>{
    store.dispatch(get_delivery_noterow_data_list({ deliveryNoteSingleData:undefined }))
    
  })
}

// generate customer invoice 
export const generateCustomerInvoiceDraftFromDeliveryNote=(body,postFn,setState,state)=>{
  axios.post("account/createCustomerInvoiceDraftFromDeliveryNote",body,HEADERS)
  .then((res)=>{
    if (res.status===200) {
      customerInvoiceSingleViewAPICall({id:res.data._id})
      localStorage.setItem("singleViewById",res.data._id)
      postFn()
      setState({...state,message:"Delivery note posted",success:true})
    }
  })
  .catch((err)=>{
    setState({...state,message:err?.response?.data,error:true})

  })
}

//post return product 
export const postReturnDeliveryNoteAPICall = async (
  body,
  setSnackBarStates,
  setReturnProduct,
  id,setLoadingForm
  
) => {
  setLoadingForm!==undefined && setLoadingForm(true)
  await axios
    .post("sales/postDeliveryNoteReturn", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setLoadingForm!==undefined && setLoadingForm(false)
        deliveryNoteSingleViewAPICall({_id:id})
        setReturnProduct(false)
        setSnackBarStates((prev)=> ({
          ...prev,
          message: "Return product Posted Successfully",
          success: true,
        }));
    
      }
    })
    .catch((err) => {
      setLoadingForm!==undefined && setLoadingForm(false)
      if (err.response.status === 500) {
        setSnackBarStates((prev) => ({
          ...prev,
          message:"Internal Server error !", error: true
        }));
      } else {
        setSnackBarStates((prev) => ({
          ...prev,
          message: err?.response?.data, error: true
        }));
      }
    });
};
