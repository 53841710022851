import axios from "axios"
import { HEADERS, HEADER_FORM_DATA, HEADER_QUERY } from "../../../../API/UrlAndPaths"
import { get_Promotion_single_view, get_Rewards_list, get_Rewards_single_view, get_order_list, get_payment_wallet_list, get_promotion_offer_list, get_reward_list, get_shift_list, single_view_pos_order, single_view_pos_shift } from "./GeneralSlice"
import store from "../../../../Redux/store"
import { setSubmitLoading, setTableLoadingGlobal } from "../../../../Redux/Dashboard/mainDashBoardSlice"
import { setErrorSnack, setSnackMsg, setSuccessSnack } from "../../Sales/Billing/Slice/billingScreenSlice"

//list of order//
export const ListPosOrdersAPI = async (body) => {
    await axios.post("foodManagement/orderList", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {

                store.dispatch( get_order_list(res.data))

            }
        })
        .catch((err) => {
            store.dispatch( get_order_list(undefined))


        })
}
//pos order single view
export const SingleViewPosOrderAPI = async (body) => {
    await axios.post("foodManagement/viewposordersingle", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(single_view_pos_order(res.data))

            }
        })
        .catch((err) => {
            store.dispatch(single_view_pos_order(undefined))


        })
}
//list of payment and  wallet 

export const ListPosWalletAndPaymentAPI = async (body) => {
    await axios.post("foodManagement/lispaymentandwalletbyshiftid",body, HEADERS)
        .then((res) => {
            if (res.status === 200) {

                store.dispatch(get_payment_wallet_list(res.data))

            }
        })
        .catch((err) => {
            store.dispatch(get_payment_wallet_list(undefined))


        })
}

//list of shift//
export const ListPosShiftAPI = async (body) => {
    await axios.post("foodManagement/viewshiftlist",body, HEADERS)
        .then((res) => {
            if (res.status === 200) {

                store.dispatch(get_shift_list(res.data))

            }
        })
        .catch((err) => {
            store.dispatch(get_shift_list(undefined))


        })
}
//pos shift single view
export const SingleViewPosShiftAPI = async (body) => {
    await axios.post("foodManagement/viewshiftsingle", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(single_view_pos_shift(res.data))

            }
        })
        .catch((err) => {
            store.dispatch(single_view_pos_shift(undefined))


        })
}
//list of promotion & offers//
export const ListPromotionAndOffersAPI = async () => {
    await axios.post("pos/viewPromotionAndOffer", HEADERS)
        .then((res) => {
            if (res.status === 200) {

                store.dispatch(get_promotion_offer_list(res.data))

            }
        })
        .catch((err) => {
            store.dispatch(get_promotion_offer_list(undefined))


        })
}


// create promotion and offers
export const createPromotionAndOffersAPI= async (body) => {
    store.dispatch(setTableLoadingGlobal(true))
    await axios.post("pos/addPromotionAndOffer", body, HEADER_FORM_DATA)
        .then((res) => {
            store.dispatch(setTableLoadingGlobal(false))
            if (res.status === 201) {
                SingleViewPromotionAPI({_id:res.data._id})
                localStorage.setItem("rowId",res.data._id)
            }
        })
        .catch((err) => {
            store.dispatch(setTableLoadingGlobal(false))
            if(err.response.status===500){
            }
        })
}

//delete promotion and offer
export const deleteApiCall = (id) => {
    axios
      .delete("pos/deletePromotionAndOfferById", { headers: HEADER_QUERY,
        data:{_id:id}
      } )
      .then((res) => {
        ListPromotionAndOffersAPI();
      })
      .catch((err) => {
        window.alert("SOMETHING WRONG >>>");
      });
  };

  //singleview promotion //
  export const SingleViewPromotionAPI = async (body) => {
    await axios.post("pos/viewPromotionAndOfferById", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch( get_Promotion_single_view(res.data))
            }
        })
        .catch((err) => {
            store.dispatch( get_Promotion_single_view(undefined))
        })
}

//edit Reward
export const editedPromotionAndofferAPI = async (body, draftFn) => {
    store.dispatch(setSubmitLoading(true))
    await axios.put("pos/editPromotionAndOffer", body, HEADERS)
        .then((res) => {
            store.dispatch(setSubmitLoading(false))
            if (res.status === 201) {
                store.dispatch(setSnackMsg("Edited"))
                store.dispatch(setSuccessSnack(true))
                draftFn()
            }
        })
        .catch((err) => {
            store.dispatch(setSubmitLoading(false))
            if (err.response.status === 500) {
                store.dispatch(setSnackMsg("Internal Server Error !!"))
            } else {
                store.dispatch(setSnackMsg(err.response.data))
            }
            store.dispatch(setErrorSnack(true))

        })
}


// create Reward
  export const createRewardAPI= async (body) => {
    store.dispatch(setTableLoadingGlobal(true))
    await axios.post("pos/addReward", body, HEADER_FORM_DATA)
        .then((res) => {
            store.dispatch(setTableLoadingGlobal(false))
            if (res.status === 201) {
               SingleViewRewardAPI({_id:res.data._id})
                localStorage.setItem("row_id",res.data._id)
            }
        })
        .catch((err) => {
            store.dispatch(setTableLoadingGlobal(false))
            if(err.response.status===500){
            }
        })
}
//list of Rewards//
export const ListRewardsAPI = async () => {
    await axios.post("pos/viewRewards", HEADERS)
        .then((res) => {
            if (res.status === 200) {

                store.dispatch(get_Rewards_list(res.data))

            }
        })
        .catch((err) => {
            store.dispatch(get_Rewards_list(undefined))


        })
}

//Delete of Rewards//
export const deleteRewardApiCall = (id) => {
    axios
      .delete("pos/deleteRewardById", { headers: HEADER_QUERY,
        data:{_id:id}
      } )
      .then((res) => {
        ListRewardsAPI();
      })
      .catch((err) => {
        window.alert("SOMETHING WRONG >>>");
      });
  };

  //singleview//
  export const SingleViewRewardAPI = async (body) => {
    await axios.post("pos/viewRewardById", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch( get_Rewards_single_view(res.data))
            }
        })
        .catch((err) => {
            store.dispatch( get_Rewards_single_view(undefined))
        })
}
//edit Reward
export const editedRewardAPI = async (body, draftFn) => {
    store.dispatch(setSubmitLoading(true))
    await axios.put("pos/editReward", body, HEADERS)
        .then((res) => {
            store.dispatch(setSubmitLoading(false))
            if (res.status === 201) {
                store.dispatch(setSnackMsg("Edited"))
                store.dispatch(setSuccessSnack(true))
                draftFn()
            }
        })
        .catch((err) => {
            store.dispatch(setSubmitLoading(false))
            if (err.response.status === 500) {
                store.dispatch(setSnackMsg("Internal Server Error !!"))
            } else {
                store.dispatch(setSnackMsg(err.response.data))
            }
            store.dispatch(setErrorSnack(true))

        })
}
// list of reward points
export const ListRewardPointAPI = async (body) => {
    await axios.post("pos/customerRewardPointList", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {

                store.dispatch( get_reward_list(res.data))

            }
        })
        .catch((err) => {
            store.dispatch( get_reward_list(undefined))


        })
}