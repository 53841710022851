import React from "react";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const FlowChartAccounts = () => {
  const theme = createTheme({
    palette: {
      green: {
        main: "#92c05c",
        light: "#a3cb76",
        dark: "#657e49",
        contrastText: "#242105",
      },
      yellow: {
        main: "#cfbf72",
        light: "#ffeb8c",
        dark: "#b2a462",
        contrastText: "#242105",
      },
      report: {
        main: "#45bcb9",
        light: "#45bcb9",
        dark: "#286f6d",
        contrastText: "#242105",
      },
      darkBlue: {
        main: "#303a82",
        light: "#4c5cce",
        dark: "#2f3981",
        contrastText: "#242105",
      },
      product: {
        main: "#c56ac1",
        light: "#ff89f9",
        dark: "#b25fae",
        contrastText: "#242105",
      },
      inventory: {
        main: "#a2284b",
        light: "#ee3a6e",
        dark: "#a1274a",
        contrastText: "#242105",
      },
      reportChr: {
        main: "#ffb165",
        light: "#ffc564",
        dark: "#a1274a",
        contrastText: "#b28945",
      },
    },
  });
  return (
    <div className="home-dash-temp-cntr" style={{minWidth:400}}>
      <span>Accounts</span>
      <div className="sub-act-container">
        <ThemeProvider theme={theme}>
          <Button variant="outlined" color="green">
            Journal
          </Button>
          <Button variant="outlined" color="yellow">
            Chart Of Account
          </Button>
          <Button variant="outlined" color="report">
            Reports
          </Button>
          <Button variant="outlined" color="darkBlue">
            Reconciliation
          </Button>
        </ThemeProvider>
      </div>
      <span>Inventory</span>
      <div className="sub-act-container">
        <ThemeProvider theme={theme}>
          <Button variant="outlined" color="product">
            Product
          </Button>
          <Button variant="outlined" color="inventory">
            Inventory Adjustment
          </Button>
          <Button variant="outlined" color="reportChr">
            Stock Report
          </Button>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default FlowChartAccounts;
