import React from 'react'
import { batchListOfProductAPI, openBatchDialog } from '../../Inventory/Products/Batch/inventoryBatchAPI'
import { IconButton } from '@mui/material'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import store from '../../../../Redux/store';
import { storeBatchListOfProduct } from '../../Inventory/Products/Batch/inventoryBatchSlice';

const BatchIconButton = (props) => {
    const {disabled,rowData,index,sales}=props

    const btnClick=()=>{
        store.dispatch(storeBatchListOfProduct([]))
        sales && batchListOfProductAPI({productId:rowData?.product?._id})
        openBatchDialog(rowData,index)
    }
  return (
      <IconButton
          disabled={disabled}
          onClick={btnClick}>
          <FormatListBulletedIcon color={!disabled ? "primary" : "disabled"} />
      </IconButton>
  )
}

export default BatchIconButton