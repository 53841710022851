import axios from "axios";
import store from "../../../../../Redux/store";
import {
  PoVendorBill,
  generate_vendor_bill_name,
  get_vendor_bill_api_res_list,
  get_vendor_bill_row_data_list,
  updateNewProductList,
  update_all_products_list_accounts,
} from "../Slice/vendorBillSlice";
import { HEADERS } from "../../../../../API/UrlAndPaths";
import { setSubmitLoading } from "../../../../../Redux/Dashboard/mainDashBoardSlice";
import { setErrorSnack, setSnackMsg, setSuccessSnack } from "../../../Sales/Billing/Slice/billingScreenSlice";

// let singleViewId = localStorage.getItem("singleViewById");

//getAllNewProductList
export const getAllNewProductList = (loading) => {
  loading!==undefined && loading(true)
  axios
    .post("readymade/viewnewproductlistforothersections", {}, HEADERS)
    .then((res) => {
      if (res.status === 200) {
      loading!==undefined && loading(false)

        store.dispatch(
          updateNewProductList({ NewProductForAccountsData: res.data })
        );
      }
    })
    .catch((err) => {
  loading!==undefined && loading(false)

      store.dispatch(
        updateNewProductList({ NewProductForAccountsData: undefined })
      );
    });
};

// products list for accounting
export const getAllProductsForAccountsAPICall = async () => {
  await axios
    .post(`readymade/listProdsForAccounting`, {}, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        console.log(res.data);
        store.dispatch(
          update_all_products_list_accounts({
            productForAccountsData: res.data,
          })
        );
        // store.dispatch(update_all_products_list_accounts({productListAccounts:res.data}))
      }
    })
    .catch((err) => {
      store.dispatch(
        update_all_products_list_accounts({ productForAccountsData: undefined })
      );
    });
};

// list vendor bill apicall
export const viewVendorBillAPICall = async (setIsLoading) => {
  setIsLoading !== undefined && setIsLoading(true);
  await axios
    .post(`account/viewVendorBill`, {}, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          get_vendor_bill_api_res_list({ vendorBillData: res.data })
        );
        setIsLoading !== undefined && setIsLoading(false);
      }
    })
    .catch((err) => {
      store.dispatch(
        get_vendor_bill_api_res_list({ vendorBillData: undefined })
      );
      setIsLoading !== undefined && setIsLoading(false);
    });
};
// generate vendor bill name
export const generateVendorBillNameAPICall = async (body) => {
  await axios
    .post(`account/generateVendorBillNo`, body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(generate_vendor_bill_name({ name: res.data }));
      }
    })
    .catch((err) => {
      store.dispatch(generate_vendor_bill_name({ name: undefined }));
    });
};
// add vendor bill api call

export const addVendorBillAPICall = async (
  body,
  postFn,
  setState,
  state,
  navigate
) => {
  await axios
    .post("account/createVendorBillDraft", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        let billData = { ...res.data };
        billData.grnBill = navigate !== undefined ? true : false;
        setState({ ...state, message: "vendor bill drafted", success: true });
        store.dispatch(
          get_vendor_bill_row_data_list({ vendorBillSingleRowData: billData })
        );
        localStorage.setItem("singleViewById",res?.data?._id)

        navigate !== undefined && navigate();
      }
    })
    .catch((err) => {
      store.dispatch(
        get_vendor_bill_row_data_list({ vendorBillSingleRowData: undefined })
      );
      setState({ ...state, message: "Something went wrong !!", error: true });
    });
};
// edit uom api call

export const editVendorBillAPICall = async (body, postFn, setState, state) => {
  await axios
    .put("account/editVendorBillDraft", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setState({ ...state, message: "vendor bill edited", success: true });
        postFn();
      }
    })
    .catch((err) => {
      setState({ ...state, message: "Something went wrong !!", error: true });
    });
};

// post vendor bill api call

export const postVendorBillAPICall = async (body, setState, state) => {
  await axios
    .post("account/postVendorBill", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          get_vendor_bill_row_data_list({ vendorBillSingleRowData: res.data })
        );

        setState({ ...state, message: "vendor bill posted", success: true });
        // postFn()
      }
    })
    .catch((err) => {
      setState({ ...state, message: "Something went wrong !!", error: true });
    });
};

//addVendorBillNewApiCall

export const addVendorBillNewApiCall = (
  body,
  setState,
  state,
  setShowPostBtn,
  setShowEditbtn,
  setDisableInput,
  // setEditClickedId,
  draftFn,
  setLoadingForm
) => {
  setLoadingForm!==undefined && setLoadingForm(true)
  axios
    .post("account/createVendorBillDraft", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setLoadingForm!==undefined && setLoadingForm(false)
        localStorage.setItem("singleViewById", res.data._id);
        setState({ ...state, message: "vendor bill drafted", success: true });
        setShowPostBtn(true);
        setShowEditbtn(true);
        setDisableInput(true);
        // setEditClickedId(res?.data);
        localStorage.setItem("singleViewById",res?.data?._id)
        draftFn()

      }
    })
    .catch((err) => {
      setLoadingForm!==undefined && setLoadingForm(false)
      if(err.response.status === 500){
        setState({ ...state, message: "Internal Server error !", error: true });
      }
      else{
        setState({ ...state, message:err?.response?.data, error: true });

      }
    });
};

//editVendorBillNewAPICall

export const editVendorBillNewAPICall = (
  body,
  setState,
  state,
  setEditClicked,
  setShowPostBtn,
  setDisableInput,editFn,setLoadingForm
) => {
  setLoadingForm!==undefined && setLoadingForm(true)
  axios
    .put("account/editVendorBillDraft", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        setLoadingForm!==undefined && setLoadingForm(false)
        setState({ ...state, message: "vendor bill edited", success: true });
        setEditClicked(false);
        setShowPostBtn(res?.data);
        setDisableInput(true);
        editFn()
      }
    })
    .catch((err) => {
      setLoadingForm!==undefined && setLoadingForm(false)
      if(err.response.status === 500){
        setState({ ...state, message:"Internal Server error !", error: true })
      }
      else{
        setState({ ...state, message:err?.response?.data, error: true })
      }
    });
};

// postVendorBillNewAPICall
export const postVendorBillNewAPICall = async (
  body,
  setState,
  state,
  setRegisterPaymentShow,
  setShowPostBtn,
  setShowEditbtn,singleViewId,postFn,setDisableInput,setLoadingForm
) => {
  setLoadingForm!==undefined && setLoadingForm(true)
  await axios
    .post("account/postVendorBill", body, HEADERS)
    .then((res) => {
      if (res?.status === 200) {
        setLoadingForm!==undefined && setLoadingForm(false)
        // store.dispatch(get_vendor_bill_row_data_list({vendorBillSingleData:res.data}))
        // if (singleViewId) {
        //   vendorBillSingleViewApi({ _id: singleViewId });
        // }

        setState({ ...state, message: "vendor bill posted", success: true });
        // setRegisterPaymentShow(true);
        // setShowEditbtn(false);
        // setShowPostBtn(false);
        vendorBillSingleViewApi({ _id: singleViewId },setDisableInput);
        postFn()
      }
    })
    .catch((err) => {
      setLoadingForm!==undefined && setLoadingForm(false)
      if (err.response.status === 500) {
        setState({ ...state, message:"Internal Server error !", error: true });
      } else {
        setState({ ...state, message:err?.response?.data, error: true });
      }
      
    });
};

export const vendorBillSingleViewApi = async (
  body,
  setDisableInput,
  setShowSaveBtn,

) => {
  store.dispatch(setSubmitLoading(true))
  try {
    const result = await axios.post(
      "account/viewVendorBillById",
      body,
      HEADERS
      );
      if (result.status === 200) {
      store.dispatch(setSubmitLoading(false))
      
      store.dispatch(
        get_vendor_bill_row_data_list({ vendorBillSingleRowData: result.data })
        );
        
        
        // setEditClickedId(result?.data);
        setDisableInput(true);
        setShowSaveBtn(false);
      }
    } catch (error) {
    store.dispatch(setSubmitLoading(false))
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const returnVendorBillAPI = async (
  body,
  setAlertPopup,
  setSnackBarStates
) => {
  try {
    let response = await axios.post(
      "account/returnVendorBillDraft",
      body,
      HEADERS
    );
    if (response.status === 200) {
      vendorBillSingleViewApi({ _id: response.data._id });
      setSnackBarStates({
        success: true,
        message: " vendor bill has been return successfully.",
        alert: false,
      });
      setAlertPopup(false);
    }
  } catch (error) {
    setSnackBarStates({ error: true, message: error.message, alert: false });
  }
};

//createVendorBillDraftFromGRN
export const createVendorBillDraftFromGRN =(body,
  postFn,
  setState,
  state,
  navigate,setLoadingForm) =>{
    setLoadingForm!==undefined && setLoadingForm(true)

  axios.post('account/createVendorBillDraftFromGRN',body,HEADERS).then((res)=>{
if(res.status === 200){
  let billData = { ...res.data };
  billData.grnBill = navigate !== undefined ? true : false;
  setState({ ...state, message: "vendor bill drafted", success: true });
  store.dispatch(
    get_vendor_bill_row_data_list({ vendorBillSingleRowData: billData })
  );
  localStorage.setItem("singleViewById",res?.data?._id)
  navigate !== undefined && navigate();
  setLoadingForm!==undefined && setLoadingForm(false)

}
  }).catch((err)=>{
    store.dispatch(
      get_vendor_bill_row_data_list({ vendorBillSingleRowData: undefined })
    );
    setLoadingForm!==undefined && setLoadingForm(false)

    setState({ ...state, message: err?.response?.data, error: true });
  });
};

export const getVendorpoViewAPICall = async (body,setIsLoading) => {
  setIsLoading !== undefined && setIsLoading(true);

  axios.post('purchase/viewAllVendorBillsOfPurchaseOrder',body,HEADERS)
  .then((res)=>{
    if(res.status===200){
  setIsLoading !== undefined && setIsLoading(false);

      store.dispatch(PoVendorBill({ purchaseVendorList: res.data }));
    }
  })
  .catch((err)=>{
  setIsLoading !== undefined && setIsLoading(false);

    store.dispatch(PoVendorBill({purchaseVendorList:undefined}))
})
}
export const VendorBillsFilterAPICall =(body,setIsLoading)=>{
  setIsLoading!==undefined&&setIsLoading(true)
  axios.post("account/searchVendorBill",body,{
    headers: {
      'Content-Type': 'application/json',
    },
   
  })
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_vendor_bill_api_res_list({vendorBillData:res.data}))
      setIsLoading!==undefined&&setIsLoading(false)
    }
  })
  .catch((err)=>{
    store.dispatch(get_vendor_bill_api_res_list({vendorBillData:undefined}))
    setIsLoading!==undefined&&setIsLoading(false)
  })

}


//  advance payment for vendor

export const vendorAdvancePayAPI=async(body,postFn)=>{
  store.dispatch(setSubmitLoading(true))
  await axios.post("account/redeemVendorPaymentAdvance",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      postFn()
      store.dispatch(setSubmitLoading(false))
      store.dispatch(setSnackMsg("Advance payment redeemed"))
      store.dispatch(setSuccessSnack(true))
    }
  })
  .catch((err)=>{

    store.dispatch(setSubmitLoading(false))
    store.dispatch(setSnackMsg(err.response.status===500?"Internal server error":err.response.data))
    store.dispatch(setErrorSnack(true))
  })
}