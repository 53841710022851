import React from "react";
import './homeDashboardStyle.css'
import SalesGraph from "./components/graph/salesGraph/SalesGraph";
import TopSellingProductGraph from "./components/graph/topSellingProductGraph/TopSellingProductGraph";
import TopCustomersTable from "./components/table/topCustomers/TopCustomersTable";
import FlowChartAccounts from "./components/flowChart/accounts/FlowChartAccounts";

import FlowChart from "./components/flowChart/flowCharts/FlowChart";
import { chartTypes } from "./utils/dashboardEnum";


const HomeDashboard = () => {
  return (
    <div className="home-dashboard-new-container">
      <div className="home-dash-new-wrapper">
        {/* <--------Graph----------> */}
        <>
          <SalesGraph />
          <TopSellingProductGraph />
        </>
        {/* <--------table----------> */}
        <>
          <TopCustomersTable />
        </>
        {/* <--------flow-chart---------> */}
        <>
          <FlowChartAccounts />
          <FlowChart chartType={chartTypes.purchase} />
          <FlowChart chartType={chartTypes.sales} /> 

        </>
      </div>
    </div>
  );
};

export default HomeDashboard;
