import { IconButton, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import ReactToPrint from 'react-to-print'
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter'
import { useNavigate } from 'react-router-dom'
import CustomPagination from '../../../../Single Components/CustomPagination'
import "./PosrewardPoint.css"
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const PosRewardPointsSingleView = () => {
    let navigateTo = useNavigate();
    const [FormDataInfo, setFormDataInfo] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const handlePageChange = (page) => {

        setCurrentPage(page);
    };

    const totalPages = ("");
    const handleDataFromChild = (data) => {
        setFormDataInfo(data)
        console.log(data);
    };
    return (
        <div className='global-page-parent-container'>
            <div
                style={{ padding: "0 1%" }}
                className="justify-space-between global-white-bg-container">
                <h3 style={{ margin: "0",cursor:"pointer" }}
                    onClick={() => navigateTo("/userdashboard/pointOfSales/general/RewardPoints")}
                >Reward Point</h3>

                <div className="justify-center" style={{ gap: "10px", width: "70%", justifyContent: "flex-end" }}>
                    <ReactToPrint
                        trigger={() => (
                            <IconButton className="printer-div" style={{ color: "#fff", backgroundColor: "#002995", borderRadius: "0" }}>
                                <i class="bi bi-printer"></i>
                            </IconButton>
                        )}
                    //   content={() => paymentsPrint}
                    />
                    <Tooltip title="Download">
                        <IconButton style={{ backgroundColor: "#ffd2d2", color: "#002995", borderRadius: "0" }}>
                            <i class="bi bi-arrow-down-circle"></i>
                        </IconButton>
                    </Tooltip>
                    <CategorySearchandFilter
                        statusOptions={['DONE', 'DRAFT']}
                        onData={handleDataFromChild}
                        isStatus={true}
                        isType={true}
                        isPaymentType={true}
                    // isBranch={userRole === "admin"}
                    />
                </div>
            </div>
            <div className="global-white-bg-container" style={{ paddingTop: "0" }}>
                <div className="justify-center" style={{ justifyContent: "flex-end", padding: "12px 14px 12px 0px" }}>
                    <CustomPagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </div>
                <div  style={{ display: "flex",background:"linear-gradient(to right, #0b0952, #09097e)",color:"white",justifyContent:"space-between",padding:"17px 30px 17px 30px" }}>
                    <div>
                        <p>----</p>
                    </div>
                    <div style={{display:"flex",gap:"30px"}}> 
                        <div>
                            <p>point</p>
                            <p>500</p>
                        </div>
                        <p style={{alignContent:"end"}}>=</p>
                        <div>
                            <p>Amount</p>
                            <p>500</p>
                        </div>
                    </div>
                    <div className="head-card-div">
                        <div className='head-card-1'>
                            <div>
                            <p>Earning</p>
                            <p>1500</p>
                            </div>
                            <div>
                                <span className='justify-center' style={{transform:"rotate(300deg)",backgroundColor:"rgb(186 241 186)",borderRadius:"14px",color:"#0a5e0a",padding:"3px"}}>
                           <ArrowRightAltIcon />
                           </span>
                            </div>
                        </div>
                        <div  className='head-card-2'>
                            <div>
                            <p>Earning</p>
                            <p>1500</p>
                            </div>
                            <div>
                            <span className='justify-center' style={{transform:"rotate(45deg)",backgroundColor:"rgb(241 186 186)",borderRadius:"14px",color:"rgb(215 16 16)",padding:"3px"}}>
                           <ArrowRightAltIcon />
                           </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="new-global-table-container" style={{padding:"24px 0 13px 1px"}}>
        <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>sequence</th>
            <th>Branch</th>
            <th>Shift</th>
            <th>Employee</th>
            <th>Order Ref</th>
            <th>Order Amount </th>
            <th>Point</th>
          </tr>
        </thead>
        <tbody>
            <tr>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
            </tr>
        </tbody>
        </table>
        </div>
            </div>
        </div>
    )
}

export default PosRewardPointsSingleView