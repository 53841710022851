import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChartOfAccountFilterAPICall, chartOfAccountListApiCall, deleteChartOfAccountAPICall, exportChartOfAccountListAPICall, importChartOfAccountAPICall } from "../../../../API/AccountUpdated/chartOfAccountAPI";
import { useSelector } from "react-redux";
import { Dialog, IconButton, MenuItem, Select, Skeleton } from "@mui/material";
import store from "../../../../Redux/store";
import { chart_of_accounts_singleview } from "../../../../Redux/AccountUpdated/chartOfAccountsSlice";
import exportIcon from "../../../../Assets/Images/Icon awesome-file-export.png"
import importIcon from "../../../../Assets/Images/Icon awesome-file-import.png"
import uploadFile from "../../../../Assets/Images/Icon feather-upload.png";
import SuccessSnackbar from "../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../Single Components/SnackBars/ErrorSnackbar";
import CategorySearchandFilter from "../../../Single Components/CategorySearchandFilter";
import CustomPagination from "../../../Single Components/CustomPagination";
import { LoadingForm } from "../../../Single Components/LoadingForm";
import axios from "axios";
import { setFilterActive } from "../../../Single Components/listSlice/filterCategorySlice";

export const ChartOfAccounts = () => {
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const chartOfAccountList = useSelector(
    (state) => state.chartOfAccountsSlice.tableList
  );
  const [filteredChartOfAccountList, setFilteredChartOfAccountList] = useState([]);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [importPopup, setImportPopup] = useState(false)
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [fileToExport, setFileToExport] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingForm, setLoadingForm] = useState(false);


  const totalPages = filteredChartOfAccountList?.pages;
  const [validationAlert, setValidationAlert] = useState({});
  const [viewOrderStatus, setViewOrderStatus] = useState("active");
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
    alert: false
  });
  
  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      alert: false,
      message: "",
    });
  };
  const closeImportPopup = (e) => {
    setImportPopup(false)
    setFileToExport([])
  }

  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };

  const changeImage = (e) => {
    setFileToExport([...fileToExport, e.target.files[0]]);
    setValidationAlert({})
  };
  const CreateChartofAccount = () => {
    navigate("/userdashboard/accounts/accounting/createchartofaccounts");
  };
  const submitExportClick = () => {
    let newFormData = new FormData()

    // newFormData.append("_id",singleViewById)
    newFormData.append("file", fileToExport[0])

    if (fileToExport.length === 0) {
      setValidationAlert({ ...validationAlert, fileExport: "select a file !!" })
    }
    else {
      importChartOfAccountAPICall(newFormData, setSnackBarStates, snackBarStates, closeImportPopup,setLoadingForm)
    }
  }

  const singleViewClick = (data) => {
    store.dispatch( setFilterActive(window.location.pathname))
    navigate("/userdashboard/accounts/accounting/createchartofaccounts");
    store.dispatch(chart_of_accounts_singleview({ chartOfAccountsSingle: data }))
  }
  const handlePageChange = (page) => {

    setCurrentPage(page);
  };

  const handleDelete = (id) => {
    let update = () => {
      chartOfAccountListApiCall({
        activeStatus: viewOrderStatus === "active"
      })
    }
    deleteChartOfAccountAPICall({ id }, setSnackBarStates, update,setLoadingForm)

  }
  useEffect(() => {


    // chartOfAccountListApiCall({
    //   activeStatus: viewOrderStatus === "active"
    // });

    store.dispatch(chart_of_accounts_singleview({ chartOfAccountsSingle: undefined }))
  }, [viewOrderStatus]);

  useEffect(() => {


    setFilteredChartOfAccountList(chartOfAccountList);

  }, [chartOfAccountList]);

  useEffect(() => {
   
    if(FormDataInfo.length!==0){ 
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData &&
    ChartOfAccountFilterAPICall({
    type: FormDataInfo.type,
    activeStatus:FormDataInfo.status.length!==0 ? (FormDataInfo.status[0]==="ACTIVE"):null,
    search: FormDataInfo.search,
    index: currentPage-1,
  },setIsLoading);
}

  }, [FormDataInfo, currentPage]);
  return (
    <div className="global-page-parent-container">


      <div className="global-white-bg-container">
        <div className="create-button-blue-container">
          <div className="export-actions-ctn">

            <div
              onClick={() => setImportPopup(true)}
              className="export-actions-section">
              <p>Upload </p>
              <div className="import">
                <img src={importIcon} alt="" />
              </div>
            </div>
            <div
              onClick={() => exportChartOfAccountListAPICall({}, setSnackBarStates, snackBarStates,setLoadingForm)}

              className="export-actions-section">
              <p>Download</p>
              <div className="export">
                <img src={exportIcon} alt="" />
              </div>
            </div>
          </div>

          <div className="justify-space-between"
            style={{ gap: "0 12px", justifyContent: "flex-end" }}
          >
            <CategorySearchandFilter
              statusOptions={['ACTIVE', 'INACTIVE']}
              onData={handleDataFromChild}
              isStatus={true}
              isType={true}
              isDate={false}
              isBranch={false}
              onBranchSelect={()=>{}}
            />
            <button onClick={CreateChartofAccount} className="create-button-blue">
              Create
            </button>
          </div>
        

        </div>
        <div className='create-button-blue-container' style={{ margin: "0 1%" }}>
          <h3>Chart Of Accounts</h3>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setFormDataInfo={setFormDataInfo}
          />
        </div>
        <div className="global-table-container">
          <table className="global-table">
            <thead>
              <tr>
                <th>Code</th>
                <th>Account Name</th>
                <th>Type</th>
                <th>Branch</th>
                <th>Active</th>
                <th>Delete</th>
              </tr>
            </thead>
            {isLoading ? (
              <tbody>
                <tr>
                  <td colSpan={12}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={12}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={12}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={12}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={12}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>

                </tr>
              </tbody>
            ) : (
              <tbody>
                {filteredChartOfAccountList?.list?.length > 0 ? (
                  filteredChartOfAccountList?.list?.slice(0)?.map((r, i) => (
                    <tr>
                      <td onClick={() => singleViewClick(r)}>{r?.code}</td>
                      <td onClick={() => singleViewClick(r)}>{r?.accountName}</td>
                      <td onClick={() => singleViewClick(r)}>{r?.type}</td>
                      <td onClick={() => singleViewClick(r)}>{r?.branchId}</td>
                      <td onClick={() => singleViewClick(r)}>
                        <input
                          className="toggle-checkbox"
                          type="checkbox"
                          checked={r?.status}
                        />
                      </td>
                      <td>
                        <IconButton onClick={() => handleDelete(r?._id)}>
                          <i
                            class="bi bi-trash3 delete-icon"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </IconButton>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={12}>No Records</td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
      </div>
      {/* import clicked popup */}
      <Dialog
        open={importPopup}
        maxWidth="lg"
        onClose={closeImportPopup}
      >
        <div className='actions-select-dropdown-dialog'>
          <h4 style={{ margin: "0" }}>Upload</h4>
          <hr className="global-hr" />
          <div className="file-picker-new" style={{ position: "relative", margin: "10px 0" }}>
            <input
              // disabled={formValues?.isEdit} 
              type="file"
              id="fileUpload"
              accept=".xlsx"
              onChange={changeImage}
              disabled={fileToExport?.length !== 0}
            />
            <label htmlFor="fileUpload"
              style={{ cursor: fileToExport?.length !== 0 && "not-allowed" }}
            >
              <img src={uploadFile} alt="" />
              Upload File
            </label>

            <p style={{ top: "70%" }} className="doc-validation-alert">{validationAlert?.fileExport}
            </p>
          </div>

          {fileToExport.length !== 0 &&
            <div style={{ width: "100%" }} className="global-single-input">
              <p>{fileToExport[0]?.name}
                <sup
                  onClick={() => setFileToExport([])}
                  style={{ fontSize: "1rem", color: "red", fontWeight: "600", cursor: "pointer" }}>X</sup>
              </p>
            </div>}
          <div className='justify-center'>
            <button
              onClick={submitExportClick}
              className="btn btn-primary">Submit</button>
            <button className="btn btn-secondary-outlined"
              onClick={closeImportPopup}
            >Cancel</button>
          </div>
        </div>
      </Dialog>

      <SuccessSnackbar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <LoadingForm loading={loadingForm} />

    </div>
  );
};
