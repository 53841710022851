import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { companyProfileInfoForUser } from '../../../../API/Settings/Company Settings/companySettingsAPI';

const PosSalesDetailsPrint = (props) => {
    const {branch}=props;
    const companyProfileData = useSelector(
        (state) => state?.companyProfileSlice?.userValue
      );
      const salesDetails = useSelector(
        (state) => state.posReportshiftSlice.salesReport);
        const decimalPosition = localStorage.getItem("decimalPosition");

      useEffect(()=>{
        companyProfileInfoForUser();
      },[])
  return (
    <div className="print-main-div" style={{backgroundColor:"white"}}>
    <div className='header-div' style={{display:"flex",justifyContent:"space-between"}}>
    <div>
      <img src={companyProfileData?.companyLogo} alt="" style={{height:"62px"}}/>
    </div>
    <div style={{textAlignLast:"center"}}>
      <h1 style={{ fontSize: "medium", margin: "0" }}>Sales Details</h1>
   
      <p style={{margin:"0" ,fontSize: "small"}}>{branch?.branchName}</p>
      
      
    </div>
    <div>
      <h1 style={{ fontSize: "small", margin: "0" }}>companyName</h1>
      <p style={{ margin: "0", fontSize: "smaller" }}>{companyProfileData?.companyName}</p>
      <p style={{ margin: "0", fontSize: "smaller" }}>{companyProfileData?.companyAddress}</p>
      <p style={{ margin: "0", fontSize: "smaller" }}>{companyProfileData?.companyContact}</p>
    </div>
  </div>
  <div className="new-global-table-container" style={{ paddingTop: "20px",overflow:"visible" }}>
                <div style={{ width: "100%", padding: "7px 0 7px 17px", backgroundColor: "#f9f0f0" }}>
                    <p style={{ margin: "0" }}>Product Details</p>
                </div>
                {salesDetails?.productDetails?.list?.map((item, index) => (
                    <div key={index}>
                        <p style={{ color: "#4343fd", fontWeight: "bold", padding: "6px 0 6px 19px", margin: "0" }}>{item?.categoryName}</p>
                        <table>
                            {index === 0 && <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Qty</th>
                                    <th>Amount</th>

                                </tr>
                            </thead>}
                            <tbody>
                                {item?.list?.map((item, i) => (
                                    <tr key={i} style={{ backgroundColor: i % 2 === 0 ? "white" : "rgb(214 212 212)" }}>

                                        <td style={{ border: "none", height: "29px", width: "50%" }}>{item.productName}</td>
                                        <td style={{ border: "none", height: "29px", width: "25%" }}>{item.qty}</td>
                                        <td style={{ border: "none", height: "29px", width: "25%" }}>{item.amount}</td>

                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr style={{ backgroundColor: "#e9e7e7" }}>
                                    <td>&nbsp;</td>
                                    <td style={{ textAlignLast: "center" }}>{item?.list?.reduce((acc, current) => acc + Number(current?.qty || 0), 0)?.toFixed(decimalPosition)}</td>
                                    <td style={{ textAlignLast: "center" }}>{item?.list?.reduce((acc, current) => acc + Number(current?.amount || 0), 0)?.toFixed(decimalPosition)}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                ))}
                <div style={{ backgroundColor: "#b6b6b6", display: "flex", width: "100%" }}>
                    <p style={{ textAlignLast: "center", width: "50%" }}>Grand Total</p>
                    <p style={{ textAlignLast: "center", width: "25%" }}>{salesDetails?.productDetails?.qtyTotal}</p>
                    <p style={{ textAlignLast: "center", width: "25%" }}>{salesDetails?.productDetails?.amountTotal}</p>
                </div>
                <div style={{ paddingTop: '50px' }}>
                    <div style={{ width: "100%", padding: "7px 0 7px 17px", backgroundColor: "#f9f0f0" }}>
                        <p style={{ margin: "0" }}>Payment Details</p>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Payment Method</th>
                                <th>Amount</th>

                            </tr>
                        </thead>
                        <tbody>
                            {salesDetails?.paymentSummary?.list?.map((item, i) => (
                                <tr key={i} style={{ backgroundColor: i % 2 === 0 ? "white" : "rgb(214 212 212)" }}>
                                    <td style={{ border: "none", height: "29px" }}>{item._id}</td>
                                    <td style={{ border: "none", height: "29px" }}>{item.amount}</td>

                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr style={{ backgroundColor: " rgb(233, 231, 231)" }}>
                                <td style={{ textAlignLast: "center" }}>&nbsp;</td>
                                <td style={{ textAlignLast: "center" }}>{parseFloat(salesDetails?.paymentSummary?.total)?.toFixed(decimalPosition)}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            
  
  </div>
  )
}

export default PosSalesDetailsPrint