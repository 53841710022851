import axios from "axios"
import { HEADERS, HEADER_FORM_DATA } from "../../../../../API/UrlAndPaths"
import { generate_customer_invoice_name, get_all_invoice_customer_list, get_customer_invoice_list, get_customer_invoice_row_data_list, get_single_customer_invoice_list } from "./customerInvoiceSlice"
import store from "../../../../../Redux/store"
import { get_customer_credit_note_row_data_list } from "../CreditNotes/customerCreditNotesSlice"
import { setSubmitLoading } from "../../../../../Redux/Dashboard/mainDashBoardSlice"
import { setErrorSnack, setSnackMsg, setSuccessSnack } from "../../../Sales/Billing/Slice/billingScreenSlice"


//getInvoicesList
export const getInvoicesListOfCustomerAPI =(body)=>{
    axios.post('account/customerInvoicesById',body,HEADERS).then((res)=>{
      if(res.status === 200){
        store.dispatch(get_all_invoice_customer_list({customerInvoiceData:res.data}))
      }
  
    }).catch((err)=>{
      store.dispatch(get_all_invoice_customer_list({customerInvoiceData:undefined}))
    })
  }
//getsingleInvoicetList
export const getSingleInvoiceListOfCustomerAPI =(body)=>{
    axios.post('account/customerInvoicesSingleView',body,HEADERS).then((res)=>{
      if(res.status === 200){
        store.dispatch(get_single_customer_invoice_list({singleInvoiceData:res.data}))
      }
  
    }).catch((err)=>{
      store.dispatch(get_single_customer_invoice_list({singleInvoiceData:undefined}))
    })
  }
// view all invoices
  export const viewAllCustomerInvoicesAPICall = async (body, setIsLoading) => {
    setIsLoading !== undefined && setIsLoading(true)
    await axios.post("account/listCustomerInvoice", body, HEADERS)
        .then((res) => {
            if (res.status === 200) {
                store.dispatch(get_customer_invoice_list({ customerInvoiceData: res.data }))
                setIsLoading !== undefined && setIsLoading(false)
            }
        })
        .catch((err) => {
            store.dispatch(get_customer_invoice_list({ customerInvoiceData: undefined }))
            setIsLoading !== undefined && setIsLoading(false)
})
}
export const CustomerInvoiceFilterAPICall =(body,setIsLoading)=>{

  setIsLoading!==undefined&&setIsLoading(true)
    axios.post("account/searchCustomerInvoice",body,{
      headers: {
        'Content-Type': 'application/json',
      },
     
    })
    .then((res)=>{
      if(res.status===200){
        store.dispatch(get_customer_invoice_list({customerInvoiceData:res.data}))
        setIsLoading!==undefined&&setIsLoading(false)
      }
    })
    .catch((err)=>{
      store.dispatch(get_customer_invoice_list({customerInvoiceData:undefined}))
      setIsLoading!==undefined&&setIsLoading(false)
    })
  
  }
// generate sales wso draft name 

export const generateCustomerInvoiceNameAPICall=(body,setName)=>{
    axios.post("account/generatecustomerinvoicenumber",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                setName(res.data?.prefix)
                store.dispatch(generate_customer_invoice_name({customerInvoiceName:res.data}))
            }
        })
        .catch((err)=>{
                setName("###")
                store.dispatch(generate_customer_invoice_name({customerInvoiceName:undefined}))
        })
}

// draft sales wso api call

export const draftCustomerInvoiceAPICall = async (body,postFn,setState,state,setEditDataId,setLoadingForm) => {
    setLoadingForm!==undefined && setLoadingForm(true)
    await axios.post("account/addCustomerInvoice", body, HEADER_FORM_DATA)
        .then((res) => {
            if (res.status === 200) {
                setState({...state,message:"Customer Invoice Drafted",success:true})
                postFn()
                setEditDataId(res.data?._id)
                setLoadingForm!==undefined && setLoadingForm(false)
                localStorage.setItem("singleViewById",res.data._id)
                customerInvoiceSingleViewAPICall({_id:res.data._id})
            }
        })
        .catch((err) => {
            setLoadingForm!==undefined && setLoadingForm(false)
            if(err.response.status === 500){
            setState({...state,message:"Internal Server error !",error:true})
            }
            else{
              setState({...state,message:err?.response?.data,error:true})
            }
           
           
          })
}
// edit sales wso api call

export const editCustomerInvoiceAPICall = async (body,postFn,setState,state,setLoadingForm) => {
    setLoadingForm!==undefined && setLoadingForm(true)
    await axios.post("account/updateCustomerInvoice", body, HEADER_FORM_DATA)
        .then((res) => {
            if (res.status === 200) {
                setState({...state,message:"Customer Invoice Edited",success:true})
                postFn()
                setLoadingForm!==undefined && setLoadingForm(false)
                customerInvoiceSingleViewAPICall({_id:res.data._id})
            }
        })
        .catch((err) => {
            setLoadingForm!==undefined && setLoadingForm(false)
            if(err.response.status === 500){
              setState({...state,message:"Internal Server error !",error:true})
            }
            else{
              setState({...state,message:err?.response?.data,error:true})
            }
          
        })
}
// post sales wso api call

export const postCustomerInvoiceAPICall = async (body,postFn,setState,state,setLoadingForm) => {
    setLoadingForm!==undefined && setLoadingForm(true)
    await axios.post("account/postcustomerinvoice", body, HEADER_FORM_DATA)
        .then((res) => {
            if (res.status === 200) {
                setState({...state,message:"Customer Invoice Posted",success:true})
                postFn()
                localStorage.setItem("singleViewById",res.data._id)
                customerInvoiceSingleViewAPICall({_id:res.data._id})
                setLoadingForm!==undefined && setLoadingForm(false)
            }
        })
        .catch((err) => {
            setLoadingForm!==undefined && setLoadingForm(false)
            if(err.response.status === 500){
              setState({...state,message:"Internal Server error !",error:true})
            }
            else{
              setState({...state,message:err?.response?.data,error:true})
            }
            
        })
}
export const returnCustomerInvoiceAPI = async (
  body,
  setAlertPopup,
  setSnackBarStates
) => {
  try {
    let response = await axios.post(
      "account/returnCustomerInvoiceDraft",
      body,
      HEADERS
    );
    if (response.status === 200) {
      customerInvoiceSingleViewAPICall({ _id: response.data._id });
      setSnackBarStates({
        success: true,
        message: " customer invoice has been return successfully.",
        alert: false,
      });
      setAlertPopup(false);
    }
  } catch (error) {
    setSnackBarStates({ error: true, message: error.message, alert: false });
  }
};

// customer invoice single view 
 export const customerInvoiceSingleViewAPICall=(body)=>{
  store.dispatch(setSubmitLoading(true))
  axios.post("account/viewCustomerInvoiceById",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_customer_invoice_row_data_list({singleCustomerInvoiceData:res.data}))
      store.dispatch(setSubmitLoading(false))
      
    }
  })
  .catch((err)=>{
    store.dispatch(setSubmitLoading(false))
    store.dispatch(get_customer_invoice_row_data_list({singleCustomerInvoiceData:undefined}))
        console.log(err.response);
    })
 }
 // customer invoice register payment api call
 export const customerInvoiceRegisterPaymentAPICall=(body,payment,setState,state,setLoadingForm)=>{
    setLoadingForm!==undefined && setLoadingForm(true)
    axios.post("account/registerPaymentFromCustomerInvoice",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            const singleRowId = localStorage.getItem("singleViewById");
            setState({...state,message:"Payment Success",success:true})
            payment(false)
            customerInvoiceSingleViewAPICall({_id:singleRowId})
            setLoadingForm!==undefined && setLoadingForm(false)
        }
    })
    .catch((err)=>{
          setLoadingForm!==undefined && setLoadingForm(false)
          if(err.response.status === 500){
            setState({...state,message:"Internal Server error !",error:true})
          }
         else{
          setState({...state,message:err?.response?.data,error:true})
         }
    })
 }


//  advance payment for customer

export const customerAdvancePayAPI=async(body,postFn)=>{
    store.dispatch(setSubmitLoading(true))
    await axios.post("account/redeemCustomerPaymentAdvance",body,HEADERS)
    .then((res)=>{
      if(res.status===200){
        postFn()
        store.dispatch(setSubmitLoading(false))
        store.dispatch(setSnackMsg("Advance payment redeemed"))
        store.dispatch(setSuccessSnack(true))
      }
    })
    .catch((err)=>{

      store.dispatch(setSubmitLoading(false))
      store.dispatch(setSnackMsg(err.response.status===500?"Internal server error":err.response.data))
      store.dispatch(setErrorSnack(true))
    })
}