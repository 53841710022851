import { Autocomplete, IconButton, TextField, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import ReactToPrint from 'react-to-print'
import { viewAllAllowedBranchesAPICall, viewAllBranchesAPICall } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import { viewShiftAPICall } from '../../../../API/Settings/Shift/viewShiftAPI';
import { ListCashAndCardSummaryAPI } from './ReportAPI';
import store from '../../../../Redux/store';
import { get_cash_card_report } from './ReportSlice';

// cash/card
const SubTableCash = (props) => {

  const { CashRowData } = props;
  return (
    <div className="new-global-table-container" style={{ paddingTop: "0 42px", maxHeight: "19vh" }}>
      <table style={{ width: "96%", float: "inline-end" }}>
        <thead style={{ height: "34px", backgroundColor: "#e5e5e5" }}>
          <tr>
            <th>ORDER NO</th>
            <th>AMOUNT</th>
            <th>COMMISSION</th>
            <th>BALANCE</th>
          </tr>
        </thead>
        <tbody>
          {CashRowData?.orderList.length > 0 ? (CashRowData?.orderList?.map((item, index) => (
            <tr key={index}>
              <td style={{ borderTop: index !== 0 ? "1px solid gray" : "none", borderBottom: "none", height: "32px", color: "gray" }}>{item.orderNo}</td>
              <td style={{ borderTop: index !== 0 ? "1px solid gray" : "none", borderBottom: "none", height: "32px", color: "gray" }}>{item.amount}</td>
              <td style={{ borderTop: index !== 0 ? "1px solid gray" : "none", borderBottom: "none", height: "32px", color: "gray" }}>{item.commission}</td>
              <td style={{ borderTop: index !== 0 ? "1px solid gray" : "none", borderBottom: "none", height: "32px", color: "gray" }}>{item.balance}</td>
            </tr>
          ))) : (
            <tr>
              <td colSpan={4}></td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};


//cheque subtable
const SubTableCheque = (props) => {
  const { ChequeRowData } = props;
  return (
    <div className="new-global-table-container" style={{ paddingTop: "0 42px", maxHeight: "19vh" }}>
      <table style={{ width: "96%", float: "inline-end" }}>
        <thead style={{ height: "34px", backgroundColor: "#e5e5e5" }}>
          <tr>
            <th>ORDER NO</th>
            <th>AMOUNT</th>
            <th>COMMISSION</th>
            <th>BALANCE</th>
          </tr>
        </thead>
        <tbody>
          {ChequeRowData?.orderList.length > 0 ? (ChequeRowData?.orderList?.map((item, index) => (
            <tr key={index}>
              <td style={{ borderTop: index !== 0 ? "1px solid gray" : "none", borderBottom: "none", height: "32px", color: "gray" }}>{item.orderNo}</td>
              <td style={{ borderTop: index !== 0 ? "1px solid gray" : "none", borderBottom: "none", height: "32px", color: "gray" }}>{item.amount}</td>
              <td style={{ borderTop: index !== 0 ? "1px solid gray" : "none", borderBottom: "none", height: "32px", color: "gray" }}>{item.commission}</td>
              <td style={{ borderTop: index !== 0 ? "1px solid gray" : "none", borderBottom: "none", height: "32px", color: "gray" }}>{item.balance}</td>
            </tr>
          ))) : (
            <tr>
              <td colSpan={4}></td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

const SubTableReturn = (props) => {
  const { returnRowData } = props;
  return (
    <div className="new-global-table-container" style={{ paddingTop: "0 42px", maxHeight: "19vh" }}>
      <table style={{ width: "96%", float: "inline-end" }}>
        <thead style={{ height: "34px", backgroundColor: "#e5e5e5" }}>
          <tr>
            <th>ORDER NO</th>
            <th>AMOUNT</th>

          </tr>

        </thead>
        <tbody>
          {returnRowData?.orderList.length > 0 ? (returnRowData?.orderList?.map((item, index) => (

            <tr key={index}>
              <td style={{ borderTop: index !== 0 ? "1px solid gray" : "none", borderBottom: "none", height: "32px", color: "gray" }}>{item.orderNo}</td>
              <td style={{ borderTop: index !== 0 ? "1px solid gray" : "none", borderBottom: "none", height: "32px", color: "gray" }}>{item.amount}</td>

            </tr>
          ))) : (
            <tr>
              <td colSpan={2}></td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

const DailyCashCardSummary = () => {
  const userRole = useSelector((state) => state.userRoleSlice.value)
  const userInfo = useSelector((state) => state.loginResponseSlice.value);
  const allowedBranchList = useSelector((state) => state.allAllowedBranchesSlice.value);
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  const allShiftList = useSelector((state) => state.viewShiftSlice.value);
  const cashcardSummary = useSelector((state) => state.posReportshiftSlice.cashCardReport);
  console.log(cashcardSummary);

  const [branch, setBranch] = useState(null);
  const [shift, setShift] = useState(null);
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [clickedCashRowIndex, setClickedCashRowIndex] = useState(null);
  const [clickedChequeRowIndex, setClickedChequeRowIndex] = useState(null);
  const [clickedReturnRowIndex, setClickedReturnRowIndex] = useState(null);

  const [expandDetails, setExpandDetails] = useState(false);



  const toggleExpandDetails = () => {
    setExpandDetails(!expandDetails);
  };




  //Cash/Card onclick
  const handleRowClick = (index, rowData) => {
    if (clickedCashRowIndex === index) {
      setClickedCashRowIndex(null); // Hide sub-table if the same row is clicked again

    } else {
      setClickedCashRowIndex(index);

    }
  };

  //cheque onclick
  const handleChequeRowClick = (index, rowData) => {
    if (clickedChequeRowIndex === index) {
      setClickedChequeRowIndex(null); // Hide sub-table if the same row is clicked again

    } else {
      setClickedChequeRowIndex(index);

    }
  };

  //cheque onclick
  const handleReturnRowClick = (index, rowData) => {
    if (clickedReturnRowIndex === index) {
      setClickedReturnRowIndex(null); // Hide sub-table if the same row is clicked again

    } else {
      setClickedReturnRowIndex(index);

    }
  };
  const branchOnchange = (e, newValue) => {
    setBranch(newValue)
    setShift(null)
    viewShiftAPICall({ branchId: newValue?._id });
  }

  const fromOnChange = (e) => {
    setFrom(e.target.value);
    setShift(null);
  }

  // Event handler for selecting a shift
  const shiftOnChange = (e, newValue) => {
    setShift(newValue);
    setFrom('');
    setTo('');
  }
  const submitButtonClick = () => {
    let body = {
      branchId: branch?._id || null,
      shiftId: shift?._id || null,
      startDate: from === "" ? undefined : `${from} 00:00:00`,
      endDate: to === "" ? undefined : `${to} 23:59:59`
    }
    ListCashAndCardSummaryAPI(body)
  }


  useEffect(() => {
    userRole === "user" && viewAllAllowedBranchesAPICall({ employeeId: userInfo?.data?._id });

    viewAllBranchesAPICall();
    store.dispatch(get_cash_card_report(undefined))

  }, [])


  return (
    <div className="global-page-parent-container" >
      <div className="global-white-bg-container Header" style={{ display: "flex", justifyContent: "space-between", padding: "14px 30px 14px 25px", alignItems: "center" }}>
        <p>Daily Cash/Card Summary</p>
        <div style={{ display: "flex", alignItems: "center" }}>
          <p>Expand </p>
          <input
            style={{ marginRight: "50px" }}
            className="toggle-checkbox"
            type="checkbox"
            checked={expandDetails}
            onChange={toggleExpandDetails}
          />

          <ReactToPrint
            trigger={() => (
              <IconButton className="printer-div" style={{ padding: "1px" }}>
                <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
              </IconButton>
            )}
          // content={() => paymentsPrint}
          />

          <Tooltip title="Download">
            <IconButton>
              <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div className="global-white-bg-container " style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <div style={{ display: "flex", width: "68%", justifyContent: "space-between" }}>
          <div className="new-global-single-input auto-complete-new" style={{ width: "20%" }}>
            <Autocomplete
              disablePortal

              options={userRole === "admin" ? allBranchesList : allowedBranchList || []}


              getOptionLabel={(option) => option?.branchName}
              placeholder='Branch'
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Branch" focused placeholder='' />
              )}
              value={branch}
              onChange={branchOnchange}
            //   disabled={formValues?.isEdit}
            />
          </div>
          <div className="new-global-single-input auto-complete-new" style={{ width: "20%" }}>
            <Autocomplete
              disablePortal
              options={(allShiftList?.filter(obj => obj.branchId === branch?._id) || []).reverse()}
              getOptionLabel={(option) => option?.SHIFID}
              placeholder='Shift'
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Shift" focused placeholder='' />
              )}
              value={shift}
              onChange={shiftOnChange}
            //   disabled={formValues?.isEdit}
            />
          </div>


          <div className="new-global-single-input" style={{ width: '20%' }}>
            <TextField
              id="outlined-basic"
              placeholder="From"
              onChange={fromOnChange}
              value={from}
              variant="outlined"
              type="date"
              focused
              label="From"
            />
          </div>



          <div className="new-global-single-input" style={{ width: '20%' }}>
            <TextField
              id="outlined-basic"
              placeholder="From"
              onChange={(e, newValue) => setTo(e.target.value)}
              value={to}
              variant="outlined"
              type="date"
              focused
              label="To"
            />
          </div>
        </div>
        <div style={{ paddingRight: "40px" }}>
          <button className='create-button-blue' onClick={submitButtonClick}>Submit</button>
        </div>
      </div>
      {cashcardSummary !== undefined && (
        <div style={{ backgroundColor: "white" }}>
          {/* Cash /Card Summary */}
          {cashcardSummary?.cashbanklist?.list.length > 0 && (
            <>
          <h1 style={{ fontSize: "larger", padding: "16px 0px 0px 25px", margin: "0" }}>Cash & Bank</h1>
          <div className="new-global-table-container" style={{ paddingTop: "0 42px", maxHeight: expandDetails === true ? "50vh" : "39vh" }}>
            <table>
              <thead >
                <tr>
                  <th>PAYMENT METHOD</th>
                  <th>AMOUNT</th>
                  <th>COMMISSION</th>
                  <th>BALANCE</th>

                </tr>
              </thead>
              <tbody>
                {cashcardSummary?.cashbanklist?.list.length > 0 ? (cashcardSummary?.cashbanklist?.list?.map((item, index) => (
                  <React.Fragment key={index}>
                    <tr onClick={() => handleRowClick(index, item)} style={{ backgroundColor: "#f5f5f5" }}>
                      <td style={{ borderTop: index !== 0 ? "1px solid #252627" : "none", borderBottom: "none", height: "32px" }}>{item.type}</td>
                      <td style={{ borderTop: index !== 0 ? "1px solid #252627" : "none", borderBottom: "none", height: "32px" }}>{item.amount}</td>
                      <td style={{ borderTop: index !== 0 ? "1px solid #252627" : "none", borderBottom: "none", height: "32px" }}>{item.commission}</td>
                      <td style={{ borderTop: index !== 0 ? "1px solid #252627" : "none", borderBottom: "none", height: "32px" }}>{item.balance}</td>
                    </tr>

                    {expandDetails && (
                      <tr>
                        <td colSpan="4">
                          <SubTableCash CashRowData={item} />

                        </td>
                      </tr>
                    )}
                  </React.Fragment>

                ))) : (
                  <tr>
                    <td colSpan={4}>NO DATA</td>
                  </tr>
                )}
              </tbody>
              <tfoot style={{ position: "sticky", bottom: "-4px" }}>
                <tr style={{ backgroundColor: "#b7b6b6" }}>
                  <td style={{ textAlign: "center" }}>
                    TOTAL
                  </td>
                  <td style={{ textAlign: "center" }}>{cashcardSummary?.cashbanklist?.amountTotal}</td>
                  <td style={{ textAlign: "center" }}>{cashcardSummary?.cashbanklist?.commissionTotal}</td>
                  <td style={{ textAlign: "center" }}>{cashcardSummary?.cashbanklist?.balanceTotal}</td>
                </tr>
              </tfoot>
            </table>
          </div>
          </>
          )}

          {/* Cheque Table */}
          {cashcardSummary?.chequeList?.list.length > 0 && (
            <>
              <h1 style={{ fontSize: "larger", padding: "16px 0px 0px 25px", margin: "0" }}>Cheque</h1>
              <div className="new-global-table-container" style={{ paddingTop: "0 42px", maxHeight: expandDetails === true ? "50vh" : "39vh" }}>
                <table>
                  <thead >
                    <tr>
                      <th>PAYMENT METHOD</th>
                      <th>AMOUNT</th>
                      <th>COMMISSION</th>
                      <th>BALANCE</th>

                    </tr>
                  </thead>
                  <tbody>
                    {cashcardSummary?.chequeList?.list.length > 0 ? (cashcardSummary?.chequeList?.list?.map((item, index) => (
                      <React.Fragment key={index}>
                        <tr onClick={() => handleChequeRowClick(index, item)} style={{ backgroundColor: "#f5f5f5" }}>
                          <td style={{ borderTop: index !== 0 ? "1px solid #252627" : "none", borderBottom: "none", height: "32px" }}>{item.type}</td>
                          <td style={{ borderTop: index !== 0 ? "1px solid #252627" : "none", borderBottom: "none", height: "32px" }}>{item.amount}</td>
                          <td style={{ borderTop: index !== 0 ? "1px solid #252627" : "none", borderBottom: "none", height: "32px" }}>{item.commission}</td>
                          <td style={{ borderTop: index !== 0 ? "1px solid #252627" : "none", borderBottom: "none", height: "32px" }}>{item.balance}</td>
                        </tr>
                        {/* clickedChequeRowIndex === index  && */}
                        {expandDetails && (
                          <tr>
                            <td colSpan="4">
                              <SubTableCheque ChequeRowData={item} />
                            </td>
                          </tr>
                        )}
                      </React.Fragment>

                    ))) : (
                      <tr>
                        <td colSpan={4}>NO DATA</td>
                      </tr>
                    )}
                  </tbody>
                  <tfoot style={{ position: "sticky", bottom: "-4px" }}>
                    <tr style={{ backgroundColor: "#b7b6b6" }}>
                      <td style={{ textAlign: "center" }}>
                        TOTAL
                      </td>
                      <td style={{ textAlign: "center" }}>{cashcardSummary?.chequeList?.amountTotal}</td>
                      <td style={{ textAlign: "center" }}>{cashcardSummary?.chequeList?.commissionTotal}</td>
                      <td style={{ textAlign: "center" }}>{cashcardSummary?.chequeList?.balanceTotal}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </>
          )}
          {/* Return Table */}
          {cashcardSummary?.returnList?.list.length > 0 && (
            <>
              <h1 style={{ fontSize: "larger", padding: "16px 0px 0px 25px", margin: "0" }}>Return</h1>
              <div className="new-global-table-container" style={{ paddingTop: "0 42px", maxHeight: expandDetails === true ? "50vh" : "39vh" }}>
                <table>
                  <thead >
                    <tr>
                      <th>PAYMENT METHOD</th>
                      <th>AMOUNT</th>


                    </tr>
                  </thead>
                  <tbody>
                    {cashcardSummary?.returnList?.list.length > 0 ? (cashcardSummary?.returnList?.list?.map((item, index) => (
                      <React.Fragment key={index}>
                        <tr onClick={() => handleReturnRowClick(index, item)} style={{ backgroundColor: "#f5f5f5" }}>
                          <td style={{ borderTop: index !== 0 ? "1px solid #252627" : "none", borderBottom: "none", height: "32px" }}>{item.type}</td>
                          <td style={{ borderTop: index !== 0 ? "1px solid #252627" : "none", borderBottom: "none", height: "32px" }}>{item.amount}</td>

                        </tr>

                        {expandDetails && (
                          <tr>
                            <td colSpan="4">
                              <SubTableReturn returnRowData={item} />
                            </td>
                          </tr>
                        )}
                      </React.Fragment>

                    ))) : (
                      <tr>
                        <td colSpan={2}>NO DATA</td>
                      </tr>
                    )}
                  </tbody>
                  <tfoot style={{ position: "sticky", bottom: "-4px" }}>
                    <tr style={{ backgroundColor: "#b7b6b6" }}>
                      <td style={{ textAlign: "center" }}>
                        TOTAL
                      </td>
                      <td style={{ textAlign: "center" }}>{cashcardSummary?.returnList?.amountTotal}</td>

                    </tr>
                  </tfoot>
                </table>
              </div>
            </>
          )}

          {/* Expense Table */}
          {cashcardSummary?.expenseList?.list?.length > 0 && (
            <>
              <h1 style={{ fontSize: "larger", padding: "16px 0px 0px 25px", margin: "0" }}>Expense</h1>
              <div className="new-global-table-container" style={{ paddingTop: "0 42px", maxHeight: expandDetails === true ? "50vh" : "39vh" }}>
                <table>
                  <thead >
                    <tr>
                      <th>PAYMENT METHOD</th>
                      <th>TYPE</th>
                      <th>EXPENSE TYPE</th>
                      <th>NOTE</th>
                      <th>AMOUNT</th>

                    </tr>
                  </thead>
                  <tbody>
                    {cashcardSummary?.expenseList?.list?.length > 0 ? (cashcardSummary?.expenseList?.list?.map((item, index) => (

                      <tr key={index} style={{ backgroundColor: "#f5f5f5" }}>
                        <td style={{ borderTop: index !== 0 ? "1px solid #252627" : "none", borderBottom: "none", height: "32px" }}>{item.payment}</td>
                        <td style={{ borderTop: index !== 0 ? "1px solid #252627" : "none", borderBottom: "none", height: "32px" }}>{item.type}</td>
                        <td style={{ borderTop: index !== 0 ? "1px solid #252627" : "none", borderBottom: "none", height: "32px" }}>{item.expenseName}</td>
                        <td style={{ borderTop: index !== 0 ? "1px solid #252627" : "none", borderBottom: "none", height: "32px" }}>{item.notes}</td>
                        <td style={{ borderTop: index !== 0 ? "1px solid #252627" : "none", borderBottom: "none", height: "32px" }}>{item.amount}</td>
                      </tr>

                    ))) : (
                      <tr>
                        <td colSpan={5}>NO DATA</td>
                      </tr>
                    )}
                  </tbody>
                  <tfoot style={{ position: "sticky", bottom: "-4px" }}>
                    <tr style={{ backgroundColor: "#b7b6b6" }}>
                      <td style={{ textAlign: "start", paddingInline: "12px" }} colSpan={4}>
                        TOTAL
                      </td>

                      <td style={{ textAlign: "center" }}>{cashcardSummary?.expenseList?.amountTotal}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </>
          )}


          {/* Payment Method */}
          {cashcardSummary?.paymentMethod?.list?.length > 0 && (
            <>
          <h1 style={{ fontSize: "larger", padding: "16px 0px 0px 25px", margin: "0" }}>Payment Method</h1>
          <div className="new-global-table-container" style={{ paddingTop: "0 42px", maxHeight: expandDetails === true ? "50vh" : "39vh" }}>
            <table>
              <thead >
                <tr>
                  <th>PAYMENT METHOD</th>
                  <th>IN</th>
                  <th>OUT </th>
                  <th>EXPENSE</th>
                  <th>BALANCE</th>

                </tr>
              </thead>
              <tbody>
                {cashcardSummary?.paymentMethod?.list?.length > 0 ? (cashcardSummary?.paymentMethod?.list?.map((item, index) => (

                  <tr key={index} style={{ backgroundColor: "#f5f5f5" }}>
                    <td style={{ borderTop: index !== 0 ? "1px solid #252627" : "none", borderBottom: "none", height: "32px" }}>{item.type}</td>
                    <td style={{ borderTop: index !== 0 ? "1px solid #252627" : "none", borderBottom: "none", height: "32px" }}>{item.in}</td>
                    <td style={{ borderTop: index !== 0 ? "1px solid #252627" : "none", borderBottom: "none", height: "32px" }}>{item.out}</td>
                    <td style={{ borderTop: index !== 0 ? "1px solid #252627" : "none", borderBottom: "none", height: "32px" }}>{item.expense}</td>
                    <td style={{ borderTop: index !== 0 ? "1px solid #252627" : "none", borderBottom: "none", height: "32px" }}>{item.balance}</td>
                  </tr>

                ))) : (
                  <tr>
                    <td colSpan={5}>NO DATA</td>
                  </tr>
                )}
              </tbody>
              <tfoot style={{ position: "sticky", bottom: "-4px" }}>
                <tr style={{ backgroundColor: "#b7b6b6" }}>
                  <td style={{ textAlign: "center" }}>
                    TOTAL
                  </td>
                  <td style={{ textAlign: "center" }}>{cashcardSummary?.paymentMethod?.inTot}</td>
                  <td style={{ textAlign: "center" }}>{cashcardSummary?.paymentMethod?.outTot}</td>
                  <td style={{ textAlign: "center" }}>{cashcardSummary?.paymentMethod?.expTot}</td>
                  <td style={{ textAlign: "center" }}>{cashcardSummary?.paymentMethod?.balTot}</td>
                </tr>
              </tfoot>
            </table>
          </div>
          </>
          )}

          {/* Total feild */}

          {/* <div style={{ backgroundColor: "rgb(183, 182, 182)", display: "flex", paddingInline: "12px", justifyContent: "space-between" }}>
            <p>Session Total</p>
            <p>{cashcardSummary?.sessionTotal?.amountTotal}</p>
            <p>{cashcardSummary?.sessionTotal?.commissionTotal}</p>
            <p>{cashcardSummary?.sessionTotal?.balanceTotal}</p>
          </div> */}
        </div>
      )}
    </div>
  )
}

export default DailyCashCardSummary