import { Dialog } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setCartArray, setDialogBox} from '../Slice/billingScreenSlice';
import dummyProductItemImg from "../../../../../Assets/BillingImages/emptyFoodImg.png";
import { errorSnackMsg } from '../../../../Custom Hooks/SnackkBarUtilities';
const RewardsPopup = () => {
    const dispatch=useDispatch()
    const decimalPosition=localStorage.getItem('decimalPosition')

    const {dialogOpen,cartInfo} = useSelector((state) => state.billingScreenSlice);
    const {rewardsList}=useSelector((state)=>state.billingRewardsSlice)
    
    const [selectedRewards,setSelectedRewards]=useState([])


      const clickRewards = (product) => {
        setSelectedRewards((prevSelectedRewards) => {
          if (prevSelectedRewards.some(obj => obj?.productId === product?.productId)) {
            return prevSelectedRewards.filter(obj => obj?.productId !== product?.productId);
          } else {
            return [...prevSelectedRewards, product];
          }
        });
      };

      const addRewards=()=>{
        if(selectedRewards.length===0){
            errorSnackMsg("No items selected")
        }else{
            let rewardProducts=selectedRewards.map((product)=>(
                    {
                        _id: product?.productId,
                        barcode: product?.barcode || "---",
                        uom:product?.uom,
                        salesPrice: Number(product?.salesPrice)?.toFixed(decimalPosition),
                        attribute: [],
                        promoData: [],
                        productName: product?.prodName,
                        taxes: [],
                        includedPrice: null,
                        qty: product?.quantity,
                        totalPrice: Number(product?.salesPrice)?.toFixed(decimalPosition)*Number(product?.quantity)?.toFixed(decimalPosition),
                        exclusiveTax: 0,
                        inclusiveTax: 0,
                        subTotalWoTax: 0,
                        lineTotal: 0,
                        isReward:true,
                        imageUrl:product?.imageUrl
                    }
                ))
                
                let duplicatedRewardProducts = rewardProducts.flatMap((product) => [
                  product,
                  {
                    ...product,
                    qty: -product.qty,
                    salesPrice: (-Number(product.salesPrice)).toFixed(decimalPosition),
                    totalPrice:-product?.totalPrice,
                    isCopy:true
                  }
                ]);
                dispatch(setDialogBox(null))
                let cart=cartInfo?.filter((obj)=>!obj?.isReward)
                let updatedCart=[...cart,...duplicatedRewardProducts]

                dispatch(setCartArray(updatedCart))
                setSelectedRewards([])  
        }
      }

// console.log(selectedRewards)
      useEffect(()=>{
        if(dialogOpen==="billingRewards"){
            let selectedItems=cartInfo?.filter((obj)=>obj?.isReward && !obj?.isCopy)?.map((item)=>(
                {
                    productId: item?._id,
                    prodName: item?.productName,
                    salesPrice: item?.salesPrice,
                    uom: item?.uom,
                    barcode: item?.barcode,
                    imageUrl: item?.imageUrl,
                    quantity: item?.qty
                }
            ))
            setSelectedRewards(selectedItems)
        }
      },[dialogOpen])
  return (
      <Dialog open={dialogOpen === "billingRewards"} onClose={() => {
                                                                dispatch(setDialogBox(null))
                                                                setSelectedRewards([])
                                                                }} maxWidth="xl">
          <div className="billing-payment-ctn-left items-table-popup" style={{ width: "35vw", maxWidth: "1400px" }}>
              <h3>Rewards</h3>
              <div className="products-list">
                  <div
                      className="cards cards-rewards flex-column"
                  >
                      {rewardsList?.length !== 0 ? (
                         rewardsList?.map((product, index) => (
                              <div
                                  className="card-content"
                                  key={index}
                                  style={{ maxHeight: "fit-content",border: selectedRewards.some(obj => obj?.productId === product?.productId) ? "2px solid #2fe32f" : "none" }}
                                  onClick={()=>clickRewards(product)}
                              >
                                  <div className="card-details justify-center">
                                  <img
                                          src={
                                              product?.imageUrl || dummyProductItemImg
                                          }
                                          alt="No Img"
                                          className="product-image "
                                      />
                                      <div className="flex-column">

                                      <h1>{product?.prodName}</h1>
                                    <span>
                                        {product?.salesPrice?.toFixed(decimalPosition) || "00.00"}
                                        &nbsp;
                                        X{product?.quantity}
                                    </span>
                                      </div>
                                  </div>
                                          <span style={{padding:"0px 10px",backgroundColor:"#dddddd",fontWeight:"600"}}>
                                              500 g
                                          </span>
                
                              </div>
                          ))
                      ) : (
                          <div className="center-div" style={{ height: "61vh" }}>
                              No Products Available !!
                          </div>
                      )}
                  </div>
                  <div className="bottom-button-ctn">
                      <button
                          onClick={() => {
                            dispatch(setDialogBox(null))
                            setSelectedRewards([])
                            }}
                          className='cancel-btn'>CANCEL</button>
                      <button 
                      onClick={addRewards}
                      className='add-btn'>ADD</button>
                  </div>
              </div>
          </div>
      </Dialog>
  )
}

export default RewardsPopup