import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import BarcodeReader from "react-barcode-reader";
import { setCartArray, setCartModified, setCartObj, setErrorSnack, setSnackMsg, setTotalsInfo } from '../Slice/billingScreenSlice';
import DeleteButton from '../Utilities/DeleteButton';
import emptyCartPng from '../image/supermaket cart-01.png';
import { update_billingRewards_list } from '../Slice/billingRewardsSlice';
import freeIcon from "../image/free (1).png"
import { keySetInputValue, setSelectedInputField } from '../Slice/billingKeypadSlice';
import { errorSnackMsg } from '../../../../Custom Hooks/SnackkBarUtilities';
const BillingCartList = () => {

  const dispatch=useDispatch()
  const decimalPosition=localStorage.getItem("decimalPosition")

  const { cartInfo,cartObj,cartModified,totalsInfo,singleOrderInfo,paymentMode } = useSelector((state) => state.billingScreenSlice);
  const {currencyDetails}=useSelector((state)=>state.mainDashBoardSlice)
  const {productsList}=useSelector((state)=>state.newBillingSlice)
  const {keyPadInputValue,selectedButton,selectedInputField}=useSelector((state)=>state.billingKeypadSlice)
  const [productMap, setProductMap] = useState(new Map());
  const [selectedCartRow,setSelectedCartRow] = useState(null);

  const createProductMap = useCallback((products) => {
    const map = new Map();
    products.forEach((product) => {
      map.set(product.barcode, product);
    });
    return map;
  }, []);
  const findProductByBarcode = (barcode) => {
    return productMap.get(barcode);
  };

  const addToCart=(item)=>{

    let discountObj=item?.promoData?.find((obj)=>obj.type===1)
    let SalesPrice=(price)=>{
      let discountAmt=discountObj?.discount || 0;
      let discount=(price)-(discountAmt/100*price)

      return discount
    }
    const itemIndex = cartInfo.findIndex(cartItem => cartItem._id === item._id);
    let updatedCartInfo;
    
    if (itemIndex !== -1) {
      updatedCartInfo = [...cartInfo];
      updatedCartInfo[itemIndex] = {
        ...updatedCartInfo[itemIndex],
        qty: (Number(updatedCartInfo[itemIndex].qty) + 1)?.toFixed(decimalPosition),
        totalPrice: (Number(updatedCartInfo[itemIndex].qty + 1) * Number(updatedCartInfo[itemIndex].salesPrice))?.toFixed(decimalPosition),
        discountAmt:(Number(updatedCartInfo[itemIndex].qty) + 1) * (updatedCartInfo[itemIndex].discountAmt)
      }; 
      setSelectedCartRow(itemIndex)
      dispatch(setSelectedInputField("cart"))
      dispatch(keySetInputValue(Number(updatedCartInfo[itemIndex].qty)))
    } else {
      let salesPrice=SalesPrice(Number(item?.salesPrice))
      updatedCartInfo = [{ ...item, 
                            qty: 1?.toFixed(decimalPosition),
                            totalPrice:salesPrice?.toFixed(decimalPosition),
                            salesPrice:salesPrice?.toFixed(decimalPosition),
                            discountAmt:Number(item?.salesPrice)-Number(salesPrice)
                          },...cartInfo];
      setSelectedCartRow(0)
      dispatch(setSelectedInputField("cart"))
      dispatch(keySetInputValue(1))
    }

    dispatch(setCartArray(updatedCartInfo))
    dispatch(setCartModified(!cartModified))
    dispatch(setCartObj(null))
  }
  const cartTableOnChange = (index, value, field) => {
    const updatedCart = [...cartInfo];
  
    let totalPrice=(qty,price)=>{
      let total=qty*price;
      return Number(total)
    }
    if (field === 'qty') {
      updatedCart[index] = { ...updatedCart[index], qty: value };
      updatedCart[index].totalPrice =totalPrice(updatedCart[index].qty,updatedCart[index].salesPrice)?.toFixed(decimalPosition);
    } else if (field === 'salesPrice') {
      // Use parseFloat to handle decimal inputs correctly
      updatedCart[index] = { ...updatedCart[index], salesPrice:value};
      updatedCart[index].totalPrice =totalPrice(updatedCart[index].qty,updatedCart[index].salesPrice)?.toFixed(decimalPosition);
    }
  
    dispatch(setCartArray(updatedCart));
    dispatch(setCartModified(!cartModified));
  };
  

  const deleteCartItem = (index, product) => () => {

    let updatedCart = [...cartInfo];
    if (product.isReward) {
      updatedCart = updatedCart.filter(cartItem => cartItem._id !== product._id);
    } else {
      updatedCart.splice(index, 1);
    }
    dispatch(setCartArray(updatedCart));
    dispatch(setCartModified(!cartModified));

  };

  const cartRowClick=(event,index)=>{
    event.stopPropagation();
    if(index===selectedCartRow && selectedInputField==="cart"){
      setSelectedCartRow(null)
    }else{
      setSelectedCartRow(index)
      dispatch(keySetInputValue(Number(cartInfo[index].qty)))
    }
    dispatch(setSelectedInputField("cart"))
  }

  const calculateTotals = (items) => {
    const subTotal = items?.reduce((sum, item) => sum + item?.subTotalWoTax, 0);
    const amtTotal =(paymentMode==="refundPayment" || paymentMode==="balancePayment")?singleOrderInfo?.amountDue :items?.reduce((sum, item) => sum + item?.lineTotal, 0);
    const totalExclusive = items?.reduce(
      (sum, item) => sum + item?.exclusiveTax,
      0
    );
    const totalInclusive = items?.reduce(
      (sum, item) => sum + item?.inclusiveTax,
      0
    );
    return { subTotal, amtTotal, totalExclusive, totalInclusive };
  };

  const findPromoAndOffers = (row) => {
    let promoDataArray = [];
  
    promoDataArray = [
      ...promoDataArray,
      ...row.promoData.map((promo) => {
        if (promo.type === 2 && promo.qty <= Number(row?.qty)) {
          return promo;
        }
      })
    ].filter((promo) => promo);
  
    let rewardProductListArray = promoDataArray.reduce((acc, item) => {
      const multipleFactor = Math.floor(Number(row?.qty) / item.qty);
  
      item.rewardProductList.forEach((product) => {
        const existingProduct = acc.find((p) => p.productId === product.productId);
        if (existingProduct) {
          existingProduct.quantity += item.rewardQty * multipleFactor;
        } else {
          acc.push({
            ...product,
            quantity: item.rewardQty * multipleFactor
          });
        }
      });
      return acc;
    }, []);
  
    return rewardProductListArray;
  };
  
  const calculateAndSetCalculatedValues = () => {
    let promoDataArray = [];
    const calculatedValues = cartInfo?.map((row) => {
      let totalAmt = Number(row?.qty) * Number(row?.salesPrice);
      let subtotalBeforeTax = totalAmt?.toFixed(decimalPosition);
      let totalTax = row?.taxes?.reduce((sum, item) => sum + item?.amount, 0);
      let totalExclTax = (Number(subtotalBeforeTax) * totalTax) / 100;


      if (row?.promoData && row.promoData?.length > 0) {
        promoDataArray=findPromoAndOffers(row)
      }

      if (row?.includedPrice === "true" || row?.includedPrice === true) {

        let taxesAmount=row?.taxes?.map((obj)=>obj?.amount)
        let totalInclArr=taxesAmount?.map((item,index)=>{
          let total=(Number(subtotalBeforeTax) * item) / (100+item)
          return total
        },0)

        let totalIncl=totalInclArr?.reduce((sum,item)=>sum+item,0)
        return {
          ...row,
          inclusiveTax: totalIncl,
          exclusiveTax: 0,
          subTotalWoTax: totalAmt,
          lineTotal: totalAmt,
        };
      } else if (
        row?.includedPrice === "false" ||
        row?.includedPrice === false
      ) {
        return {
          ...row,
          exclusiveTax: totalExclTax,
          inclusiveTax: 0,
          subTotalWoTax: totalAmt,
          lineTotal: totalAmt + totalExclTax,
        };
      } else {
        return {
          ...row,
          exclusiveTax: 0,
          inclusiveTax: 0,
          subTotalWoTax: totalAmt,
          lineTotal: totalAmt,
        };
      }
    });
    const promoProductIds = promoDataArray.map(promo => promo.productId);
    const filteredCartInfo = calculatedValues.filter(cartItem => {
        return !(cartItem.isReward && !promoProductIds.includes(cartItem._id));
    });

    let totalInfo=calculateTotals(filteredCartInfo)
    dispatch(setCartArray(filteredCartInfo))
    dispatch(setTotalsInfo(totalInfo))
    dispatch(update_billingRewards_list(promoDataArray))
  };


  const onBarcodeScan = (barcode) => {
    const product = findProductByBarcode(Number(barcode));
    if (product && !(paymentMode==="balancePayment" || paymentMode==="refundPayment")) {
      dispatch(setCartObj(product))
    } else {
      errorSnackMsg((paymentMode==="balancePayment"?"New items can't be added on completed order!!":"Product not found"))
    }
  };

  useEffect(() => {
    if (productsList?.length > 0) {
      const map = createProductMap(productsList);
      setProductMap(map);
    }
  }, [productsList, createProductMap]);


  useEffect(()=>{
    if(cartObj){
      paymentMode===null && addToCart(cartObj)
    }
  },[cartObj])

  useEffect(()=>{
    calculateAndSetCalculatedValues()
  },[cartModified])

  useEffect(()=>{
    if(singleOrderInfo!==null){
      console.log(singleOrderInfo)
      let existingCartinfo=singleOrderInfo?.orderInfo?.map((item)=>(
        {
          _id: item?.productId,
          barcode:item?.barcode,
          uom: item?.uom,
          salesPrice: item?.unitPrice,
          promoData:item?.promoData || [],
          productName:item?.desccription,
          taxes: item?.taxes,
          includedPrice:item?.includedPrice === true
            ? true
            : item?.includedPrice === false
            ? false
            : null,
          qty: item?.qty,
          totalPrice: item?.subTotalWoTax,
          exclusiveTax: 0,
          inclusiveTax: 0,
          subTotalWoTax: 0,
          lineTotal: item?.subTotalWoTax,
          isReward: item?.isRewardProd,
          imageUrl: item?.imageUrl,
          viewOnly: (paymentMode==="refundPayment" || paymentMode==="balancePayment"),
        }
      ))
      dispatch(setCartArray(existingCartinfo))

      if(singleOrderInfo?.type==="refund"){
        let totalsInfo={
          subTotal: singleOrderInfo?.subTotal,
          amtTotal: singleOrderInfo?.amtTotal,
          totalExclusive: singleOrderInfo?.totalExclusive,
          totalInclusive: singleOrderInfo?.totalInclusive
        }

      dispatch(setTotalsInfo(totalsInfo))

      }else{
        dispatch(setCartModified(!cartModified))
      }
    }else{
      dispatch(setCartArray([]))
      dispatch(setCartModified(!cartModified))
    }
  },[singleOrderInfo])
  // keypad onChange

  useEffect(()=>{
    if(selectedInputField==="cart" && selectedButton==="qty"){
      cartTableOnChange(selectedCartRow,keyPadInputValue,"qty")
    }
  },[keyPadInputValue])
  return (
      <div className="billing-payment-ctn-left" style={{maxHeight: "calc(100vh - 126px)"}}>
          <div className="payment-table-div" style={{overflowY:"auto"}}>

              <table>
                  <thead>
                      <th className='name' style={{ minWidth:"100px" }}>Name</th>
                      <th>Barcode</th>
                      <th>Qty.</th>

                      <th>Price</th>
                      <th>Total</th>
                      <th>&nbsp;</th>
                  </thead>
                  <tbody>
                      {
                          cartInfo?.map((r, i) => (
                              <tr 
                              key={i} 
                              onClick={(e)=>(!r?.isReward && !r?.viewOnly )&& cartRowClick(e,i)}
                              style={{backgroundColor:r?.isCopy?"#eadcde" :i%2===0?"#e7e7e7":'rgb(239 239 239)',border:(selectedInputField==="cart" && selectedCartRow===i)?"2px dashed #96999b":""}}>
                                  <td className={r?.isCopy ?'justify-center':''} style={{justifyContent:"start",gap:"5px",alignItems:"flex-end"}}>
                                    {r?.isCopy && <img src={freeIcon} alt="" style={{width:"30px"}}/> }
                                    {r?.productName}</td>
                                  <td>{r?.barcode}</td>
                                  <td>
                                      <input
                                          type="number"
                                          class="input-table"
                                          value={r.qty}
                                          onChange={(e) => cartTableOnChange(i, e.target.value,"qty")}
                                          onFocus={(e)=>e.target.select()}
                                          disabled={r?.isReward || r?.viewOnly}

                                          style={{
                                            border:selectedCartRow===i && selectedButton==="qty" && "2px solid green" 
                                          }}
                                      />
                                  </td>
                                  <td>
                                  {r.salesPrice}
                                    {/* <input
                                          type="number"
                                          class="input-table"
                                          value={r.salesPrice}
                                          onChange={(e) => cartTableOnChange(i, e.target.value,"salesPrice")}
                                          onFocus={(e)=>e.target.select()}
                                          disabled={true}
                                      /> */}
                                  </td>
                                  <td>{(r?.totalPrice)}</td>
                                  <td>
                                    {
                                     ( !r?.isCopy && !r?.viewOnly)&&
                                       <DeleteButton onClick={deleteCartItem(i,r)}/>
                                    }
                                  </td>
                              </tr>
                          ))
                      }
                  </tbody>
                  
              </table>
              {
                cartInfo?.length===0 &&
                <div
                  className="justify-center"
                  style={{
                      backgroundColor: "#fff",
                      height: "calc(100% - 43px)",
                      width: "100%",flexDirection:"column"
                  }}
              >
                 <img style={{width:"50%"}} src={emptyCartPng} alt="" />

                 <p>Cart is Empty!!</p>

              </div>}
          </div>

          <div className="bottom">
              <p>Subtotal</p>
              <div>{currencyDetails?.symbol} {(totalsInfo?.subTotal || 0)?.toFixed(decimalPosition)}</div>
          </div>

      <BarcodeReader onScan={onBarcodeScan} />

      </div>
  )
}

export default BillingCartList