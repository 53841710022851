import React, { useEffect, useRef, useState } from "react";
import { getCurrentTime, previousDate, today } from "../../../../../Js/Date";
import { useSelector } from "react-redux";
import {
  convertArrayToExcel,
  filterObjFromList,
} from "../../../../../Js/generalFunctions";
import { viewAllCurrencyUpdated } from "../../../../../API/Settings/Currency/CurrencyAPI";
import { companyProfileInfoForUser } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import { IconButton, TextField, Tooltip } from "@mui/material";
import { AccountPayableAPIcall } from "./partnerReportApi";
import { useNavigate } from "react-router-dom";
import Template from "./AccountPrint";
import ReactToPrint from "react-to-print";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import { setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";
import store from "../../../../../Redux/store";

export const AccountPayable = () => {
  const ExcelJS = require("exceljs");
  let paymentsPrintNew = useRef();
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const configurationList = useSelector(
    (state) => state.glConfigurationSlice.apiResList
  );
  let navigate = useNavigate();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [symbol, setSymbol] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [FormDataInfo, setFormDataInfo] = useState([]);
  const decimalPosition = localStorage.getItem("decimalPosition");
  const [filteredData, setFilteredData] = useState({});
  const accountpayable = useSelector(
    (state) => state?.PartnerReport?.AccountPayable
  );

  // console.log(accountpayable);
  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );
  const handleFilterChange = (updatedItems) => {
    setFilteredData(updatedItems);
  };
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );
  const handleDataFromChild = (data) => {
    setFormDataInfo(data);
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch);
  };

  const initialvalue = {
    label: "Account Payable Report",
    totalBalance: 0,
    totalCredit: 0,
    totalDebit: 0,

    companyLogo: "",
    companyName: "",
    companyAddress: "",
    companyContact: "",
  };

  const [data, setData] = useState([]);
  const [formValues, setFormValues] = useState(initialvalue);

  useEffect(() => {
    setFormValues({
      ...formValues,
      totalBalance: accountpayable?.totalBalance || 0,
      totalCredit: accountpayable?.totalCredit || 0,
      totalDebit: accountpayable?.totalDebit || 0,

      companyLogo: companyProfileData?.logo || "",
      companyName: companyProfileData?.companyName || "",
      companyAddress: companyProfileData?.companyAddress || "",
      companyContact: companyProfileData?.contactNumber || "",
    });
    setData(accountpayable?.vendors);
  }, [accountpayable, companyProfileData]);

  const formatFilterInfo = (filters) => {
    const parts = [];
  
    if (filters?.fromDate && filters?.toDate) {
      parts.push(`As of ${filters?.fromDate} to ${filters?.toDate}`);
    }
    console.log(filters?.fromDate);

    if (filters?.branchList) {
      parts.push(`Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`);
    }
   
    
    return parts; // Return the array directly
  };

  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Account Payable");
    sheet.properties.defaultRowHeight = 20;
  
    // Add header section
    const titleRow = sheet.addRow(["Account Payable"]);
    titleRow.font = { size: 18, bold: true };
    sheet.mergeCells('A1:D1');
    titleRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
  
    // Add additional header information
    const filterParts = formatFilterInfo(filteredData) || [];
  
    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 15, bold: true };
        sheet.mergeCells(`A${index + 2}:D${index + 2}`);
        row.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
      });
    }
  
    // Add an empty row between header and table
    sheet.addRow([]);
  
    // Header row styling
    const headerRow = sheet.addRow(["Vendor", "Debit", "Credit", "Balance"]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF1d1da7" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 15,
      bold: true,
      color: { argb: "FFFFFFFF" }, // White font color
    };
  
    headerRow.eachCell((cell) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Ensure header text is centered
    });
  
    sheet.columns = [
      { header: "Vendor", key: "vendorName", width: 50 },
      { header: "Debit", key: "debit", width: 20 },
      { header: "Credit", key: "credit", width: 20 },
      { header: "Account Payable", key: "balance", width: 20 },
    ];
  
    accountpayable?.vendors?.forEach((item) => {
      const row = sheet.addRow({
        vendorName: item.vendorName,
        debit: `${symbol} ${item.debit?.toFixed(2)}`,
        credit: `${symbol} ${item?.credit?.toFixed(2)}`,
        balance: `${symbol} ${item?.balance?.toFixed(2)}`,
      });
  
      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFC3D4F7" }, // Use full hex code with "FF" prefix for better compatibility
      };
      
      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
          top: { style: "thin", color: { argb: "FFFFFFFF" } },
          left: { style: "thin", color: { argb: "FFFFFFFF" } },
          bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
          right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber === 4) { // Assuming the balance column is the 4th column
          const value = parseFloat(item?.balance);
          if (value < 0) {
            cell.font = { color: { argb: "FFFF0000" } }; // Red color for negative values
          }
        }
      });
    });
  
    // Add row for totals
    const totalRow = sheet.addRow([
      "Total",
      `${symbol} ${accountpayable?.totalDebit.toFixed(2)}`,
      `${symbol} ${accountpayable?.totalCredit?.toFixed(2)}`,
      `${symbol} ${accountpayable?.totalBalance?.toFixed(2)}`,
    ]);
  
    totalRow.eachCell((cell) => {
      cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
      cell.fill = headerFill;
      cell.border = {
        top: { style: "thin", color: { argb: "FFFFFFFF" } },
        left: { style: "thin", color: { argb: "FFFFFFFF" } },
        bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
        right: { style: "thin", color: { argb: "FFFFFFFF" } },
      };
    });
  
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Account Payable.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const handleClickRow = (row) => {
    localStorage.setItem("singleViewById", row?._id);
    localStorage.setItem("fromDate", `${fromDate} 00:00:00`);
    localStorage.setItem("toDate", `${toDate} 23:59:59`);
    localStorage.setItem("vendor", row?.vendorName);

    // setSelectedHeading("Ledger Balance");
    store.dispatch(setFilterActive(window.location.pathname));
    navigate(
      "/userdashboard/accounts/report/partnerReport/accountPayable/singleView"
    );
  };

  const getFromDate = (e) => {
    setFromDate(e.target.value);
  };
  const getToDate = (e) => {
    setToDate(e.target.value);
  };

  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    // console.log(currencyObj);
    setSymbol(currencyObj?.symbol);
  }, [companyProfileData, currencyListUpdated]);

  useEffect(() => {
    if (configurationList !== undefined) {
      setToDate(configurationList?.yearEndDate);
      setFromDate(configurationList?.yearStartDate);
    }
  }, [configurationList]);

  // useEffect(()=>{
  //   AccountPayableAPIcall({startDate: `${fromDate} ${getCurrentTime()}`,
  //   endDate: `${toDate} ${getCurrentTime()}`})
  // },[fromDate, toDate]);

  // useEffect(() => {
  //   if (toDate !== undefined && fromDate !== undefined) {
  //     const startDate = fromDate === '' ? '' : `${fromDate} 00:00:00`;
  //     const endDate = toDate === '' ? '' : `${toDate} 23:59:59`;

  //     AccountPayableAPIcall({
  //       startDate: startDate,
  //       endDate: endDate
  //     });
  //   }
  // }, [fromDate, toDate]);
  useEffect(() => {
    if (FormDataInfo) {
      let body = {};
  
      if (FormDataInfo?.fromDate !== "") {
        body.startDate = `${FormDataInfo.fromDate} 00:00:00`;
      }
  
      if (FormDataInfo?.toDate !== "") {
        body.endDate = `${FormDataInfo.toDate} 23:59:59`;
      }
  
      if (FormDataInfo?.branchList && FormDataInfo?.branchList.length > 0) {
        body.branchId = FormDataInfo?.branchList[0];
      }
  
      if (FormDataInfo?.search) {
        body.search = FormDataInfo.search;
      }
  
      let loadData = window.location.pathname === filtereDataOptions?.filterActive;
      !loadData && AccountPayableAPIcall(body);
    }
  }, [FormDataInfo]);
  

  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, []);

  return (
    <div className="global-page-parent-container" style={{ margin: "0" }}>
      <div className="global-white-bg-container" style={{backgroundColor:"unset",padding:"4px"}}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            className="justify-space-between"
            style={{ display: "flex",width:"-webkit-fill-available", justifyContent:"space-between",backgroundColor:"white" }}
          >
            <div className="sharp-arrow">
              <p style={{margin:"0"}}>Account Payable Report</p>
            </div>
            {/* <h4>Account Payable Report</h4> */}
            <div style={{display:"flex"}}>
            <div style={{ marginLeft: "19px" }}>
              <Tooltip title="Share">
                <IconButton>
                  <i
                    class="bi bi-share"
                    style={{
                      backgroundColor: "#9797e9",
                      color: "white",
                      fontSize: "medium",
                      padding: "8px",
                      borderRadius: "2px",
                    }}
                  ></i>
                </IconButton>
              </Tooltip>

              <ReactToPrint
                trigger={() => (
                  <IconButton
                    className="printer-div"
                    style={{ padding: "1px" }}
                  >
                    <i
                      style={{
                        color: "white",
                        backgroundColor: " #d787e7",
                        fontSize: "medium",
                        padding: "8px",
                        borderRadius: "2px",
                      }}
                      class="bi bi-printer"
                    ></i>
                  </IconButton>
                )}
                content={() => paymentsPrintNew}
              />

              <Tooltip title="Download">
                <IconButton onClick={downloadList}>
                  <i
                    class="bi bi-arrow-down-circle"
                    style={{
                      backgroundColor: "rgb(255 118 133 / 69%)",
                      color: "white",
                      fontSize: "medium",
                      padding: "8px",
                      borderRadius: "2px",
                    }}
                  ></i>
                </IconButton>
              </Tooltip>
            </div>
          
          <div>
            <CategorySearchandFilter
              statusOptions={["PURCHASE ORDER", "DONE", "CANCEL"]}
              onData={handleDataFromChild}
              onBranchSelect={handleBranchSelect}
              onFilterChange={handleFilterChange}
            />
          </div>
          </div>
          </div>
          {/*       
         <div className="date-con-div" style={{marginRight:"-19px"}}>
         <div className='new-global-single-input' style={{width:"41%"}}>
            <TextField
           value={fromDate}
           onChange={getFromDate}
            id="outlined-basic" 
            label="From" 
            variant="outlined" 
            type="date" 
            focused
            disabled={""}
            InputProps={{
              inputProps: {
                max: today,  // Assuming you want to set a max date
              },
              inputProps: { 
                style: { textAlign: "center" },  // Adjust the text alignment
              },
            }} />
            <p className="doc-validation-alert">{""}</p>

          </div>

         <div className='new-global-single-input' style={{width:"41%"}}>
            <TextField
            value={toDate}
            onChange={getToDate}
            id="outlined-basic" 
            label="To" 
            variant="outlined" 
            type="date" 
            focused
            disabled={""}
            InputProps={{
              inputProps: {
                max: today,  // Assuming you want to set a max date
              },
              inputProps: { 
                style: { textAlign: "center" },  // Adjust the text alignment
              },
            }} />
            <p className="doc-validation-alert">{""}</p>

          </div>
        
         </div> */}
        </div>
        <div
          className="global-report-table-container"
          style={{ maxHeight: "84vh",height:"84vh",backgroundColor:"white",marginTop:"3px" }}
        >
          <table style={{padding:"6px"}}>
            <thead>
              <tr>
                <th>Vendor</th>
                <th style={{ width: "22%" }}>Debit</th>
                <th style={{ width: "22%" }}>Credit</th>
                <th style={{ width: "22%" }}>Balance</th>
              </tr>
            </thead>
            <tbody>
              {accountpayable?.vendors?.map((item, index) => (
                <tr key={index} onClick={() => handleClickRow(item)}>
                  <td style={{ textAlignLast: "start", paddingLeft: "10px" }}>
                    {item.vendorName}
                  </td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>
                    {symbol}
                    {item.debit?.toFixed(decimalPosition)}
                  </td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>
                    {symbol}
                    {item.credit?.toFixed(decimalPosition)}
                  </td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>
                    {symbol}
                    {item.balance?.toFixed(decimalPosition)}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th>Total</th>
                <th>
                  {symbol}
                  {accountpayable?.totalDebit?.toFixed(decimalPosition)}
                </th>
                <th>
                  {symbol}
                  {accountpayable?.totalCredit?.toFixed(decimalPosition)}
                </th>
                <th>
                  {symbol}
                  {accountpayable?.totalBalance?.toFixed(decimalPosition)}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div ref={(el) => (paymentsPrintNew = el)}>
          <Template data={data} formValues={formValues} />
        </div>
      </div>
    </div>
  );
};
