import React, { PureComponent } from "react";
import { useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";

const PositiveAndNegativeBarChart = () => {
  const { accountDashBoardData } = useSelector(
    (res) => res.accountDashboardSlice
  );

  const formatYAxisTick = (tick) => {
    const SI_SYMBOL = ["", "K", "M", "B", "T"];
    const tier = (Math.log10(Math.abs(tick)) / 3) | 0;

    if (tier === 0) return tick;

    const suffix = SI_SYMBOL[tier];
    const scale = Math.pow(10, tier * 3);

    const scaledValue = tick / scale;

    // Formatting with one decimal place
    const formattedValue = scaledValue.toFixed(1);

    return formattedValue + suffix;
  };
  return (
    <div
      style={{
        background: "white",
        padding: "2em 0",
        borderRadius: "10px",
        flex: 1,
      }}
    >
      <div style={{ padding: "0.5em", textAlign: "center" }}>
        <span>Operating Net Profit</span>
      </div>
      <ResponsiveContainer height={400}>
        <BarChart
          width={1000}
          height={500}
          data={accountDashBoardData?.report[0]?.graph}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis tickFormatter={formatYAxisTick} />
          <Tooltip />
          <Legend />
          <ReferenceLine y={0} stroke="#000" />
          <Bar dataKey="netProfit" fill="#00866b" name="Net Profit">
            {accountDashBoardData?.report[0]?.graph?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry.netProfit < 0 ? "#e41503" : "#00866b"}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PositiveAndNegativeBarChart;
