import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import CategorySearchandFilter from '../../../Single Components/CategorySearchandFilter';
import { useSelector } from 'react-redux';
import { StockMoveAPIcall } from './ReportAPI';
import { getCurrentTime } from '../../../../Js/Date';
import { IconButton, Skeleton, Tooltip } from '@mui/material';
import ReactToPrint from 'react-to-print';
import Template from "./StockMovePrint"
import { setStockMoveSigleView } from './ReportSlice';
import store from '../../../../Redux/store';
import { setFilterActive, set_Back_button_action } from '../../../Single Components/listSlice/filterCategorySlice';

export const StockMove = () => {
  let navigate = useNavigate();
  const [symbol, setSymbol] = useState(null);
  const [FormDataInfo, setFormDataInfo] = useState([])
  let paymentsPrint = useRef();
  const [selectedBranch, setSelectedBranch] = useState(null);
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };
  const decimalPosition = localStorage.getItem("decimalPosition");
  const decimalPositionStock = localStorage.getItem("decimalPositionStock");

  const stockMove = useSelector((state) => state?.ReportSlice?.StockMove)
  const {currencyDetails,tableLoading}=useSelector((state)=>state.mainDashBoardSlice)
  const filtereDataOptions = useSelector((state) => state.listCategory);

  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };

  const handleClickRow = (row) => {
    localStorage.setItem("vendor", row?.productName)
    localStorage.setItem("stock_id", row?._id);
    store.dispatch( setStockMoveSigleView({stockMoveSingleList:[]}))
    store.dispatch( setFilterActive(window.location.pathname))
    navigate("/userdashboard/inventory/reports/stockMove/singleView")
  }


  useEffect(() => {
    setSymbol(currencyDetails?.symbol);
  }, [currencyDetails]);

  useEffect(() => {
    if (FormDataInfo?.length !== 0) {
      localStorage.setItem("branch", (FormDataInfo?.branchList?.length === 0 ? '' : FormDataInfo?.branchList[0]))
      localStorage.setItem("location", FormDataInfo?.location?.length === 0 ? '' : FormDataInfo?.location[0])
      localStorage.setItem("fromDate", FormDataInfo?.fromDate)
      localStorage.setItem("ToDate", FormDataInfo?.toDate)

      let body = {
        fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
        endDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
        location: FormDataInfo?.location?.length === 0 ? '' : FormDataInfo?.location[0],
        branchId: FormDataInfo?.branchList?.length === 0 ? '' : FormDataInfo?.branchList[0],

        search: FormDataInfo?.search,
      }

    let loadData=window.location.pathname===filtereDataOptions?.filterActive
    !loadData && StockMoveAPIcall(body)
    }
  }, [FormDataInfo])


  return (
    <div className="global-page-parent-container" style={{ margin: "0" }}>
      <div className="global-white-bg-container">
        <div className="justify-space-between" >
          <div className="sharp-arrow" style={{ width: "147Px" }}> <p>Stock Move Report</p></div>
          <div style={{ display: "flex" }}>
                        <Tooltip title="Share">
                            <IconButton>
                                <i
                                    class="bi bi-share"
                                    style={{
                                        backgroundColor: "#9797e9",
                                        color: "white",
                                        fontSize: "medium",
                                        padding: "8px",
                                        borderRadius: "2px",
                                    }}
                                ></i>
                            </IconButton>
                        </Tooltip>

                        <ReactToPrint
                            trigger={() => (
                                <IconButton className="printer-div" style={{ padding: "1px" }}>
                                    <i
                                        style={{
                                            color: "white",
                                            backgroundColor: " #d787e7",
                                            fontSize: "medium",
                                            padding: "8px",
                                            borderRadius: "2px",
                                        }}
                                        class="bi bi-printer"
                                    ></i>
                                </IconButton>
                            )}
                            content={() => paymentsPrint}
                        />

                        <Tooltip title="Download">
                            <IconButton>
                                <i
                                    class="bi bi-arrow-down-circle"
                                    style={{
                                        backgroundColor: "rgb(255 118 133 / 69%)",
                                        color: "white",
                                        fontSize: "medium",
                                        padding: "8px",
                                        borderRadius: "2px",
                                    }}
                                ></i>
                            </IconButton>
                        </Tooltip>
          <div>
            <CategorySearchandFilter
              statusOptions={['PURCHASE ORDER', 'DONE', 'CANCEL']}
              onData={handleDataFromChild}
              isLocation={true}
              onBranchSelect={handleBranchSelect}
            />

          </div>
          </div>
        </div>
        <h1 style={{ margin: "1px", fontSize: "large" }}>Stock Move Report</h1>

        <div className="global-report-table-container">
          <table>
            <thead>
              <tr >
                <th>Item</th>
                <th>UOM</th>
                <th>Opening</th>
                <th>In</th>
                <th>Out</th>
                <th>Closing</th>
                <th>Cost</th>
                <th>Values</th>
              </tr>
            </thead>
            <tbody>
              {
              tableLoading?
              ([...Array(10)].map((r, i) => (
                <tr key={i}>
                  <td colSpan={10}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
              ))
            ):stockMove!==undefined?
            stockMove?.map((item, index) => (
                <tr
                style={{cursor:"pointer"}}
                key={index} onClick={() => handleClickRow(item)}>
                  <td style={{ textAlignLast: "start", paddingLeft: "10px" }}>{item.productName}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.uom}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.openingBalance}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.totalInDate?.toFixed(decimalPositionStock)}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.totalOutDate?.toFixed(decimalPositionStock)}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.closingBalance?.toFixed(decimalPosition)}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{symbol}&nbsp;{item.cost?.toFixed(decimalPosition)}</td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{symbol}&nbsp;{item.value?.toFixed(decimalPosition)}</td>
                </tr>
              ))
            :
            <tr>
              <td colSpan={8}>No Records !!</td>
            </tr>
            }
            </tbody>
            {
            !tableLoading&& stockMove!==undefined&& 
            <tfoot>
              <tr>
                <th colSpan={3}>Total</th>
                <th >{symbol}&nbsp;{(stockMove?.reduce((sum,item)=>sum+item?.totalInDate,0)?.toFixed(decimalPosition))}</th>
                <th >{symbol}&nbsp;{(stockMove?.reduce((sum,item)=>sum+item?.totalOutDate,0)?.toFixed(decimalPosition))}</th>
                <th >{symbol}&nbsp;{(stockMove?.reduce((sum,item)=>sum+item?.closingBalance,0)?.toFixed(decimalPosition))}</th>
                <th >{symbol}&nbsp;{(stockMove?.reduce((sum,item)=>sum+item?.cost,0)?.toFixed(decimalPosition))}</th>
                <th >{symbol}&nbsp;{(stockMove?.reduce((sum,item)=>sum+item?.value,0)?.toFixed(decimalPosition))}</th>
              </tr>
            </tfoot>}
          </table>
        </div>
      </div>
      <div style={{ display: "none" }}>
                <div ref={(el) => (paymentsPrint = el)}>
                    <Template stockMove={stockMove} symbol={symbol} />
                </div>
            </div>
    </div>
  )
}