import { createSlice } from "@reduxjs/toolkit";



export const billingRewardsSlice=createSlice({
    name:'billingRewardsSlice',
    initialState:{
        rewardsList:[]
    },
    reducers:{
        update_billingRewards_list:(state,action)=>{
            state.rewardsList=action.payload
        }
    }
})

export const {update_billingRewards_list}=billingRewardsSlice.actions;

export default billingRewardsSlice.reducer;