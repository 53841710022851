import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, IconButton, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { getAllDesignationAPICall } from '../../../../API/Staff/HRM/departmentAndDesignationAPI';
import { useSelector } from 'react-redux';
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ErrorSnackbar from '../../../Single Components/SnackBars/ErrorSnackbar';
import { addEmployeeRolesAndPermissionAPICall, getRolesAndPermissionAPICall } from '../../../../API/Settings/Roles and permission/rolesAndPermissionAPI';
import SuccessSnackbar from '../../../Single Components/SnackBars/SuccessSnackbar';
export const RolesAndPermissionNew = () => {

   
   const [purchaseSection,setPurchaseSection]=useState([
    {label:"All",isChecked:false,key:"all"},
    {label:"Request For Quotation",isChecked:false,key:"rfq"},
    {label:"Purchase Order",isChecked:false,key:"purchaseOrder"},
    {label:"Goods Received Note",isChecked:false,key:"grn"},
    {label:"Unit Of Measurment",isChecked:false,key:"uom"},
    {label:"Vendors",isChecked:false,key:"vendors"},
    {label:"Configuration",isChecked:false,key:"configuration"},
    {label:"Purchase Report",isChecked:false,key:"purchaseReport"},

   ])
   const [inventorySection,setInventortSection]=useState([
  
     {label:"All",isChecked:false,key:"all"},
      {
      name:"Product",
      nameKey:"products",
      values:[
          {label:"Product Master",isChecked:false,key:"productMaster"},
          {label:"Product",isChecked:false,key:"product"},
         ]
      },
      {
        name:"Operations",
        nameKey:"operations",
        values:[
            {label:"Internal Transfer",isChecked:false,key:"internalTransfer"},
            {label:"Branch Transfer",isChecked:false,key:"branchTransfer"},
            {label:"Branch Receipts",isChecked:false,key:"branchReciept"},
            {label:"Stock Moves",isChecked:false,key:"stockMoves"},
            {label:"Inventory Adjustment",isChecked:false,key:"inventoryAdjustments"},
           ]
       },
       {
        name:"Reports",
        nameKey:"reports",
        values:[
            {label:"Stock Move",isChecked:false,key:"stockMoveReport"},
           ]
       },
       {
        name:"Configuration",
        nameKey:"configuration",
        values:[
            {label:"Ware House",isChecked:false,key:"warehouse"},
            {label:"Location",isChecked:false,key:"location"},
            {label:"Attribute",isChecked:false,key:"attribute"},
            {label:"Category",isChecked:false,key:"category"},
            {label:"POS Category",isChecked:false,key:"poscategory"},
            {label:"Settings",isChecked:false,key:"settings"},
           ]
       },
   
   ])
   const [salesSection,setSalesSection]=useState([
    {label:"All",isChecked:false,key:"all"},
    {label:"Quotation",isChecked:false,key:"quotation"},
    {label:"Sales Order",isChecked:false,key:"salesOrder"},
    {label:"Delivery Note",isChecked:false,key:"deliveryNote"},
    {label:"Customers",isChecked:false,key:"customers"},
    {
      name:"Report",
      nameKey:"report",
      values:[
        {label:"Sales Report",isChecked:false,key:"salesReport"},
        {label:"Sales Report By Sales Person",isChecked:false,key:"salesReportBySalesPerson"},
      
       
    ]
    }
   ])
   const [posSection,setPosSection]=useState([

    {label:"All",isChecked:false,key:'all'},
    {label:"Billing",isChecked:false,key:"billing"},
    {label:"Special Items",isChecked:false,key:"specialItems"},
    {
      name:"General",
      nameKey:"general",
      values:[
          // {label:"All",isChecked:false,key:"all"},
          {label:"Shift",isChecked:false,key:"shift"},
          {label:"Orders",isChecked:false,key:"orders"},
          {label:"Payments",isChecked:false,key:"payments"},
          {label:"Wallet",isChecked:false,key:"wallet"},
         
      ]
    },
    {
    name:"Report",
    nameKey:"report",
    values:[
        {label:"Shift Report",isChecked:false,key:"shiftReport"},
        {label:"Shift Summary Report",isChecked:false,key:"shiftSummaryReport"},
        {label:"Sales Details",isChecked:false,key:"salesDetails"},
        {label:"Aggregator Report",isChecked:false,key:"aggregatorReport"},
        {label:"Daily Cash/Card Summary ",isChecked:false,key:"cashcardSummary"},
       
    ]
    },
    {
        name:"Expense",
        nameKey:"expense",
        values:[
            {label:"Add Expense Type",isChecked:false,key:"addExpenseType"},
            {label:"Staff Expense",isChecked:false,key:"staffExpense"},
            {label:"Outlet Expense",isChecked:false,key:"outletExpense"},
            {label:"Petty Cash Receipts",isChecked:false,key:"pettyCashReciept"},
           
        ]
    },
    {
        name:"Configuration",
        nameKey:"configuration",
        values:[
           
            {label:"Settings",isChecked:false,key:'settings'},
            {label:"Branch Settings",isChecked:false,key:'branchSettings'},
            {label:"Aggregator",isChecked:false,key:'aggregator'},
           
        ]
    }
   ])
 
   const [reportSection,setReportSection]=useState([
    {label:"All",isChecked:false,key:'all'},
    {label:"Stock Report",isChecked:false,key:'stockreport'},
    {label:"Expense Report",isChecked:false,key:'expenseReport'},
   ])
   const [accountSection,setAccountSection]=useState([
    {label:"All",isChecked:false,key:'all'},
    {label:"Dashboard",isChecked:false,key:'dashboard'},
    {
      name:"Customers",
      nameKey:"customers",
      values:[
          {label:"Customer Invoice",isChecked:false,key:"customerInvoice"},
          {label:"Sales W/SO",isChecked:false,key:"saleswoso"},
          {label:"Payments",isChecked:false,key:"payments"},
          {label:"Credit Notes",isChecked:false,key:"creditNotes"},
          {label:"Customers",isChecked:false,key:"customers"},
      ]
    },
    {
      name:"Vendor",
      nameKey:"vendor",
      values:[
          {label:"Vendor Bills",isChecked:false,key:"vendorBills"},
          {label:"Purchase W/PO",isChecked:false,key:"purchasewpo"},
          {label:"Payments",isChecked:false,key:"payments"},
          {label:"Debit Notes",isChecked:false,key:"debitNotes"},
          {label:"Vendors",isChecked:false,key:"vendors"},
      ]
     },
    {
      name:"Accounting",
      nameKey:"accounting",
      values:[
          {label:"Journal",isChecked:false,key:"journal"},
          {label:"Chart Of Accounts",isChecked:false,key:"chartofaccounts"},
          {label:"Opening Balance",isChecked:false,key:"openingBalance"},
          {label:"Journal Entries",isChecked:false,key:"journalEntries"},
         
         
      ]
    },
    {
      name:"Reconcilation",
      nameKey:"reconcilation",
      values:[
          {label:"Bank Reconcilation",isChecked:false,key:"bankReconcilation"},
         
         
      ]
      },
      {
        name:"VAT Report",
        nameKey:"vatreport",
        values:[
            {label:"input/Output Report",isChecked:false,key:"inputoroutputreport"},
           
           
        ]
        },
   
    {
      name:"Reports",
      nameKey:"report",
      values:[
         {
           subName:"Financial Report",
           subNameKey:"financialReport",
           subValues:[
          
            {label:"Trial Balance",isChecked:false,key:"trialBalance"},
            {label:"Balance Sheet",isChecked:false,key:"balanceSheet"},
            {label:"General Ledger",isChecked:false,key:"generalLedger"},
            {label:"Profit And Loss",isChecked:false,key:"profitAndLoss"},
           ]
         },
         {
          subName:"Partner Report",
          subNameKey:"partnerReports",
          subValues:[
           {label:"Account Payable",isChecked:false,key:"accountPayable"},
           {label:"Account Receivable",isChecked:false,key:"accountReceivable"},
           {label:"Aging Report Receivable",isChecked:false,key:"agingReportReceivable"},
           {label:"Aging Report Payable",isChecked:false,key:"agingReportPayable"},
           {label:"Receivable Due Report",isChecked:false,key:"receivableDueReport"},
           {label:"Payable Due Report",isChecked:false,key:"payableDueReport"},
          ]
        },
        {
          subName:"General Report",
          subNameKey:"generalReport",
          subValues:[
           {label:"Back & Cash Report",isChecked:false,key:"bankAndCash"},
           {label:"Cheque Register Report",isChecked:false,key:"chequeRegister"},
           {label:"Invoice Margin Report",isChecked:false,key:"invoiceMargin"},
           {label:"Product Margin Report",isChecked:false,key:"productMargin"},
           {label:"Customer Receipt Report",isChecked:false,key:"customerReciept"},
           
          ]
        },
       
      ]
    },
    {label:"Configuration",isChecked:false,key:'configuration'},
   
 
   ])
   const [staffSection,setStaffSection]=useState([
    
  {
      name:"HRM",
      nameKey:"hrm",
      values:[
          {label:"All",isChecked:false,key:"all"},
          {label:"Department",isChecked:false,key:"department"},
          {label:"Designation",isChecked:false,key:"designation"},
          {label:"Manage Employee",isChecked:false,key:"manageEmployee"},
          {label:"Add Employee",isChecked:false,key:"addEmployee"},
          {label:"Add Document",isChecked:false,key:"addDocument"},
        
      ]
  },
 

   ])
   const [singleSections,setSingleSections]=useState({
      //  dashboard:false,
       products:false,
       deviceSettings:false,
       generateQrBarcode:false
   })





    const designationList = useSelector((state) => state.allDesignationListSlice.value);
    const allActiveEmployeeList = useSelector(
        (state) => state.allActiveEmployeeSlice.value
      );
      //Roles and permission given for an employee or a designation
    const rolesAndPermissionsList = useSelector(
        (state) => state.viewRolesAndPermissionSlice.value
      );
    const [rolesAndPermissionType, setRolesAndPermissionType] =useState("designation");
    const [designation, setDesignation] = useState(null);
    //Filtered Employees by designation
    const [filteredEmployees, setFilteredEmployees] = useState([]);
      //Characters entered for search employee
    const [searchKeyword, setSearchKeyword] = useState("");
     //Searched Employees by characters
     const [empSearchResult, setEmpSearchResult] = useState([]);
     //Selected Employee Id
     const [selectedEmpId, setSelectedEmpId] = useState(null);
     const [snackBarStates, setSnackBarStates] = useState({
      alert: false,
      message: "",
      success: false,
      error: false,
    });

    const closeSnackbar = () => {
      setSnackBarStates({
          ...snackBarStates,
          alert: false,
          message: "",
          success: false,
          error: false,
      });
  };
    
     const changeRadioFn = (e) => {
        setRolesAndPermissionType(e.target.value);
      };

        //Get Search Keyword
     const getSearchKeyword = (e) => {
     setSearchKeyword(e.target.value);
      };

       //Filter Employees By characters
      const filterEmployeesByCharacters = (chars, empList) => {
    let searchResult = [];
    let filteredArray = [];
    if (chars !== "") {
      searchResult = empList?.filter((obj) =>
        obj?.staff_name?.toLowerCase()?.includes(chars?.toLowerCase())
      );
      filteredArray = searchResult;
    } else {
      filteredArray = empList;
    }
    return filteredArray;
      };


      //Get Checked Employee Id
      const getCheckedEmpId = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedEmpId(value);
    }
      };

      // purchase change

      const changeCheckBox=(index)=>{
          purchaseSection[index].isChecked=! purchaseSection[index].isChecked
            if (!purchaseSection[index].isChecked) {
              purchaseSection[0].isChecked = false;
            }
            if (purchaseSection.slice(1).every((item) => item.isChecked)) {
                purchaseSection[0].isChecked = true;
            }
        setPurchaseSection([...purchaseSection])
      }
      const changeAllCheckBox=()=>{
        setPurchaseSection((prevPurchaseSection) => {
            const newPurchaseSection = prevPurchaseSection.map((item) => ({
              ...item,
              isChecked: !prevPurchaseSection[0].isChecked,
            }));
            return newPurchaseSection;
          });
      }

      // inventory change
      const changeAllCheckBoxInventory=()=>{

        const allChecked = inventorySection[0].isChecked;
        const newInvSection = inventorySection.map(section => {
          if (section.values) {
            return {
              ...section,
              values: section.values.map(obj => ({ ...obj, isChecked: !allChecked })),
              isChecked: !allChecked
            };
          } else {
            return { ...section, isChecked: !allChecked };
          }
        });
          setInventortSection(newInvSection)
      }
      const changeCheckBoxInventory=(sectionIndex,objIndex=null)=>{

      const newInvSection = [...inventorySection];
      if(objIndex===null){
        newInvSection[sectionIndex].isChecked = !newInvSection[sectionIndex].isChecked;
       }
      else {
        newInvSection[sectionIndex].values[objIndex].isChecked = !newInvSection[sectionIndex].values[objIndex].isChecked;
        const allChecked = newInvSection[sectionIndex].values.every(obj => obj.isChecked);
        newInvSection[sectionIndex].isChecked = allChecked;
        newInvSection[0].isChecked = newInvSection.every(section => section.isChecked);
      }
      setInventortSection(newInvSection)
    
    }

      // sales change
      const changeAllCheckBoxSales=()=>{

        const allChecked = salesSection[0].isChecked;
        const newSalesSection = salesSection.map(section => {
          if (section.values) {
            return {
              ...section,
              values: section.values.map(obj => ({ ...obj, isChecked: !allChecked })),
              isChecked: !allChecked
            };
          } else {
            return { ...section, isChecked: !allChecked };
          }
        });
          setSalesSection(newSalesSection);
      }
      const changeCheckBoxSales=(sectionIndex, objIndex=null)=>{
     
      const newSaleSection = [...salesSection];
      if(objIndex===null){
        newSaleSection[sectionIndex].isChecked = !newSaleSection[sectionIndex].isChecked;
       }
      else {
        newSaleSection[sectionIndex].values[objIndex].isChecked = !newSaleSection[sectionIndex].values[objIndex].isChecked;
        const allChecked = newSaleSection[sectionIndex].values.every(obj => obj.isChecked);
        newSaleSection[sectionIndex].isChecked = allChecked;
        newSaleSection[0].isChecked = newSaleSection.every(section => section.isChecked);
      }
        setSalesSection(newSaleSection);
      }

      // pos change
    

    const changeCheckBoxPos = (sectionIndex, objIndex = null) => {
      const newPosSection = [...posSection];
    if (objIndex === null) {
      newPosSection[sectionIndex].isChecked = !newPosSection[sectionIndex].isChecked;
    } else {
      newPosSection[sectionIndex].values[objIndex].isChecked = !newPosSection[sectionIndex].values[objIndex].isChecked;
      const allChecked = newPosSection[sectionIndex].values.every(obj => obj.isChecked);
      newPosSection[sectionIndex].isChecked = allChecked;
      newPosSection[0].isChecked = newPosSection.every(section => section.isChecked);
    }
    setPosSection(newPosSection);

    
    };
  
    const changeAllCheckBoxPos = () => {
      const allChecked = posSection[0].isChecked;
      const newPosSection = posSection.map(section => {
        if (section.values) {
          return {
            ...section,
            values: section.values.map(obj => ({ ...obj, isChecked: !allChecked })),
            isChecked: !allChecked
          };
        } else {
          return { ...section, isChecked: !allChecked };
        }
      });
      setPosSection(newPosSection);
    };
  
  
  
    

   


 
    // report change
    const changeAllCheckBoxReport=()=>{
      setReportSection((prevPurchaseSection) => {
          const newPurchaseSection = prevPurchaseSection.map((item) => ({
            ...item,
            isChecked: !prevPurchaseSection[0].isChecked,
          }));
          return newPurchaseSection;
        });
    }
    const changeCheckBoxReport=(index)=>{
      reportSection[index].isChecked=! reportSection[index].isChecked
      if (!reportSection[index].isChecked) {
        reportSection[0].isChecked = false;
      }
      if (reportSection.slice(1).every((item) => item.isChecked)) {
        reportSection[0].isChecked = true;
      }
      setReportSection([...reportSection])
    }
    // accounts change
    const changeAllCheckAccounts = () => {
      const allChecked = accountSection[0].isChecked;
      const updatedAccountSection = accountSection.map(section => {
      if (section.values) {
        return {
          ...section,
          isChecked: !allChecked,
          values: section.values.map(value => {
            if (value.subValues) {
              return {
                ...value,
                isChecked: !allChecked,
                subValues: value.subValues.map(subValue => ({ ...subValue, isChecked: !allChecked }))
              };
            }
            return { ...value, isChecked: !allChecked };
          })
        };
      }
      return { ...section, isChecked: !allChecked };
    });

    setAccountSection(updatedAccountSection);
    };
    
    const changeCheckBoxAccounts = (sectionIndex, itemIndex = null, subItemIndex = null) => {
      const newAccountSection = [...accountSection];

      if (itemIndex === null) {
        // Toggle the section checkbox
        newAccountSection[sectionIndex].isChecked = !newAccountSection[sectionIndex].isChecked;
        if (newAccountSection[sectionIndex].values) {
          newAccountSection[sectionIndex].values = newAccountSection[sectionIndex].values.map(value => {
            if (value.subValues) {
              return {
                ...value,
                isChecked: newAccountSection[sectionIndex].isChecked,
                subValues: value.subValues.map(subValue => ({
                  ...subValue,
                  isChecked: newAccountSection[sectionIndex].isChecked,
                })),
              };
            }
            return { ...value, isChecked: newAccountSection[sectionIndex].isChecked };
          });
        }
      } else if (subItemIndex === null) {
        // Toggle the item checkbox
        newAccountSection[sectionIndex].values[itemIndex].isChecked = !newAccountSection[sectionIndex].values[itemIndex].isChecked;
        if (newAccountSection[sectionIndex].values[itemIndex].subValues) {
          newAccountSection[sectionIndex].values[itemIndex].subValues = newAccountSection[sectionIndex].values[itemIndex].subValues.map(subValue => ({
            ...subValue,
            isChecked: newAccountSection[sectionIndex].values[itemIndex].isChecked,
          }));
        }
        // Check if all items are checked to update the section checkbox
        const allItemsChecked = newAccountSection[sectionIndex].values.every(item => item.isChecked);
        newAccountSection[sectionIndex].isChecked = allItemsChecked;
      } else {
        // Toggle the sub-item checkbox
        newAccountSection[sectionIndex].values[itemIndex].subValues[subItemIndex].isChecked = !newAccountSection[sectionIndex].values[itemIndex].subValues[subItemIndex].isChecked;
        // Check if all sub-items are checked to update the item checkbox
        const allSubItemsChecked = newAccountSection[sectionIndex].values[itemIndex].subValues.every(subValue => subValue.isChecked);
        newAccountSection[sectionIndex].values[itemIndex].isChecked = allSubItemsChecked;
        // Check if all items are checked to update the section checkbox
        const allItemsChecked = newAccountSection[sectionIndex].values.every(item => item.isChecked);
        newAccountSection[sectionIndex].isChecked = allItemsChecked;
      }
    
      // Check if all sections are checked to update the "All" checkbox
      newAccountSection[0].isChecked = newAccountSection.every(section => section.isChecked);
    
      setAccountSection(newAccountSection);
    };
  // staff change
  const changeAllCheckStaff=(sectionIndex)=>{
    const updatedPosSection = [...staffSection];
    const allChecked = updatedPosSection[sectionIndex].values.every(option => option.isChecked);
    
    updatedPosSection[sectionIndex].values.forEach(option => {
      option.isChecked = !allChecked;
    });

    setStaffSection(updatedPosSection);
  }

  const changeCheckBoxStaff=(sectionIndex,objIndex)=>{
    staffSection[sectionIndex].values[objIndex].isChecked=!staffSection[sectionIndex].values[objIndex]?.isChecked
    
    if (!staffSection[sectionIndex].values[objIndex].isChecked) {
      staffSection[sectionIndex].values[0].isChecked = false;
    }
    if (staffSection[sectionIndex].values.slice(1).every((item) => item.isChecked)) {
      staffSection[sectionIndex].values[0].isChecked = true;
    }
    setStaffSection([...staffSection]) 
}
// single section change
const checkboxChangeSingleSection=(key)=>(e)=>{
     setSingleSections({...singleSections,[key]:e.target.checked})
}



    


      const purchaseSectionObject = {};
      purchaseSection.forEach(section => {
          purchaseSectionObject[section.key] = section.isChecked;
      });

      const inventorySectionObject={};
      inventorySection.forEach(section => {
        if (section.values) {
          const sectionObjectInv = {};
          section.values.forEach(value => {
            sectionObjectInv[value.key] = value.isChecked;
          });
          inventorySectionObject[section.nameKey] = sectionObjectInv;
        } else {
          inventorySectionObject[section.key] = section.isChecked;
        }
      });

      const salesSectionObject={};
      salesSection.forEach(section => {
        if (section.values) {
          const sectionObjectsale = {};
          section.values.forEach(value => {
            sectionObjectsale[value.key] = value.isChecked;
          });
          salesSectionObject[section.nameKey] = sectionObjectsale;
        } else {
          salesSectionObject[section.key] = section.isChecked;
        }
      });

      
      const posSectionObject = {};

      posSection.forEach(section => {
        if (section.values) {
          const sectionObject = {};
          section.values.forEach(value => {
            sectionObject[value.key] = value.isChecked;
          });
          posSectionObject[section.nameKey] = sectionObject;
        } else {
          posSectionObject[section.key] = section.isChecked;
        }
      });

    
      const reportSectionObject={};
      reportSection?.forEach(section=>{
        reportSectionObject[section.key]=section.isChecked;
      })

      const accountSectionObject = {};


      accountSection.forEach(section => {
        if (section.values) {
          const sectionObject = {};
          section.values.forEach(value => {
            if (value.subValues) {
              const subValueObject = {};
              value.subValues.forEach(subValue => {
                if (subValue.key) {
                  subValueObject[subValue.key] = subValue.isChecked;
                }
              });
              sectionObject[value.subNameKey] = subValueObject;
            } else if (value.key) {
              sectionObject[value.key] = value.isChecked;
            }
          });
          accountSectionObject[section.nameKey] = sectionObject;
        } else if (section.key) {
          accountSectionObject[section.key] = section.isChecked;
        }
      });
      

      const staffSectionObject = {};

      staffSection.forEach(section => {
        const sectionObjectStaf = {};
        section.values.forEach(value => {
          sectionObjectStaf[value.key] = value.isChecked;
        });
        staffSectionObject[section.nameKey] = sectionObjectStaf;
      });



      const body={
        type:rolesAndPermissionType==="designation"?1:0,
        empId:rolesAndPermissionType==="designation"?designation?._id:selectedEmpId,
        dashboard:singleSections?.dashboard,
        products:singleSections?.products,
        purchase:purchaseSectionObject,
        inventory:inventorySectionObject,
        sales:salesSectionObject,
        pointOfSales:posSectionObject,
        report:reportSectionObject,
        accounts:accountSectionObject,
        staff:staffSectionObject,
        deviceSettings:singleSections?.deviceSettings,
        generateQrBarcode:singleSections?.generateQrBarcode
      }

      const clickRolesAndPermissionUpdateBtn=()=>{
        
        if(designation === null&&selectedEmpId===null){
          setSnackBarStates({...snackBarStates,message:"Select a Designation or Employee",alert:true})
        }
        else{
          addEmployeeRolesAndPermissionAPICall(
            body,
            setSnackBarStates,
            snackBarStates
            )
        }
      }
    

      useEffect(()=>{
        getAllDesignationAPICall();
       
      },[])

      // filter Employees by designation id
      useEffect(()=>{
        let filteredEmployees = [];
        if (allActiveEmployeeList !== undefined) {
          filteredEmployees = allActiveEmployeeList?.filter(
            (obj) => obj?.designation === designation?._id
          );
          setFilteredEmployees(filteredEmployees)
        }
        // return filteredEmployees;
      
      },[designation])

      //Search Employees by characters
      useEffect(() => {
    if (allActiveEmployeeList !== undefined) {
      setEmpSearchResult(allActiveEmployeeList);
      let filteredArray = filterEmployeesByCharacters(
        searchKeyword,
        allActiveEmployeeList
      );
      setEmpSearchResult(filteredArray);
    }
     }, [searchKeyword, allActiveEmployeeList]);

     useEffect(()=>{
      if(rolesAndPermissionType==="designation"){
        getRolesAndPermissionAPICall({_id:designation?._id})
      }
      else if(rolesAndPermissionType==="employee"){
        getRolesAndPermissionAPICall({_id:selectedEmpId})
      }
    
     },[designation,selectedEmpId])

     useEffect(()=>{
         if(rolesAndPermissionsList !== undefined){
          setSingleSections({
            ...singleSections,
            dashboard:rolesAndPermissionsList?.dashboard,
            products:rolesAndPermissionsList?.products,
            deviceSettings:rolesAndPermissionsList?.deviceSettings,
            generateQrBarcode:rolesAndPermissionsList?.generateQrBarcode
          })
          // purchase
          const updatedPurchaseSection = purchaseSection.map(section => ({
            ...section,
            isChecked:rolesAndPermissionsList?.purchase !== undefined && rolesAndPermissionsList?.purchase[section.key],
          }));
      
          setPurchaseSection(updatedPurchaseSection);
          // inventory
          // const updatedInventorySection = inventorySection.map(section => ({
          //   ...section,
          //   isChecked: rolesAndPermissionsList?.inventory !== undefined && rolesAndPermissionsList?.inventory[section.key],
          // }));
          const updatedInventorySection = inventorySection.map(section => {
            if (section.values) {
              return {
                ...section,
                values: section.values.map(value => ({
                  ...value,
                  isChecked: rolesAndPermissionsList.inventory !== undefined && rolesAndPermissionsList.inventory[section.nameKey] !== undefined && rolesAndPermissionsList.inventory[section.nameKey][value.key],
                })),
              };
            } else {
              return {
                ...section,
                isChecked: rolesAndPermissionsList.inventory !== undefined && rolesAndPermissionsList.inventory[section.key],
              };
            }
          });
      
          setInventortSection(updatedInventorySection);
          //sales
          const updatedSalesSection = salesSection.map(section => {
            if (section.values) {
              return {
                ...section,
                values: section.values.map(value => ({
                  ...value,
                  isChecked: rolesAndPermissionsList.sales !== undefined && rolesAndPermissionsList.sales[section.nameKey] !== undefined && rolesAndPermissionsList.sales[section.nameKey][value.key],
                })),
              };
            } else {
              return {
                ...section,
                isChecked: rolesAndPermissionsList.sales !== undefined && rolesAndPermissionsList.sales[section.key],
              };
            }
          });
      
          setSalesSection(updatedSalesSection);

          const updatedPosSection = posSection.map(section => {
            if (section.values) {
              return {
                ...section,
                values: section.values.map(value => ({
                  ...value,
                  isChecked: rolesAndPermissionsList.pointOfSales !== undefined && rolesAndPermissionsList.pointOfSales[section.nameKey] !== undefined && rolesAndPermissionsList.pointOfSales[section.nameKey][value.key],
                })),
              };
            } else {
              return {
                ...section,
                isChecked: rolesAndPermissionsList.pointOfSales !== undefined && rolesAndPermissionsList.pointOfSales[section.key],
              };
            }
          });
          
          setPosSection(updatedPosSection);

        
          // reports
          const updatedReportSection = reportSection.map(section => ({
            ...section,
            isChecked: rolesAndPermissionsList?.report !== undefined && rolesAndPermissionsList?.report[section?.key],
          }));
      
          setReportSection(updatedReportSection);
          //accounts
          const updatedAccountSection = accountSection.map(section => {
            if (section.values) {
              return {
                ...section,
                values: section.values.map(value => {
                  if (value.subValues) {
                    return {
                      ...value,
                      subValues: value.subValues.map(subValue => ({
                        ...subValue,
                        isChecked: rolesAndPermissionsList.accounts !== undefined 
                                   && rolesAndPermissionsList.accounts[section.nameKey] !== undefined 
                                   && rolesAndPermissionsList.accounts[section.nameKey][value.subNameKey] !== undefined 
                                   && rolesAndPermissionsList.accounts[section.nameKey][value.subNameKey][subValue.key],
                      })),
                    };
                  } else {
                    return {
                      ...value,
                      isChecked: rolesAndPermissionsList.accounts !== undefined 
                                 && rolesAndPermissionsList.accounts[section.nameKey] !== undefined 
                                 && rolesAndPermissionsList.accounts[section.nameKey][value.key],
                    };
                  }
                }),
              };
            } else {
              return {
                ...section,
                isChecked: rolesAndPermissionsList.accounts !== undefined 
                           && rolesAndPermissionsList.accounts[section.key],
              };
            }
          });
      
          setAccountSection(updatedAccountSection);
          // staff section
          const updatedStaffSection = staffSection?.map(section => ({
            ...section,
            values:section?.values?.map(value => ({
              ...value,
              isChecked:rolesAndPermissionsList?.staff?.[section?.nameKey]?.[value?.key],
            })),
          }));
      
          setStaffSection(updatedStaffSection);

      

         }
     },[rolesAndPermissionsList])

     

  return (
    <div className="roles-and-permission-container">
       <div className="roles-and-permission-left-container">
        <div className="global-radio-button roles-and-permission-radio-btn">
          <input
            style={{ margin: "0 2% 0 0" }}
            defaultChecked
            type="radio"
            id="rolesAndPermissionDesignation"
            name="rolesAndPermission"
            value="designation"
            onChange={changeRadioFn}
          />
          <label htmlFor="rolesAndPermissionDesignation">Designation</label>
          <input
            style={{ margin: "0 2% 0 4%" }}
            type="radio"
            id="rolesAndPermissionEmployee"
            name="rolesAndPermission"
            value="employee"
            onChange={changeRadioFn}
          />
          <label htmlFor="rolesAndPermissionEmployee">Employee</label>
        </div>
        {rolesAndPermissionType === "designation" ? (
          <div className="roles-and-permission-designation-container">
            <div className="roles-and-permission-designation-top-container">
              <div className='global-single-input auto-complete' style={{width:"98%"}}>
                 <Autocomplete
                 sx={{width:"100%"}}
                 options={designationList||[""]}
                 getOptionLabel={(option)=>option?.position}
                 renderInput={(params)=><TextField {...params} className='roles-and-permission-designation' placeholder='Select Designation'   />}
                 value={designation}
                 onChange={(e,newValue)=>setDesignation(newValue)}
                />
       
              </div>
            </div>
            <div className="roles-and-permission-employee-radio-btn-container">
              {(filteredEmployees?.length > 0 &&
                filteredEmployees
                  ?.slice(0)
                  ?.reverse()
                  ?.map((emp, i) => (
                    <p key={i} style={{margin:"2% 2%"}}>{`${emp?.emp_id} - ${emp?.staff_name}`}</p>
                  ))) || <p>No data</p>}
            </div>
          </div>
        ) : rolesAndPermissionType === "employee" ? (
          <div style={{height:"100%"}}>
            <div className="roles-and-permission-employee-top-container">
              <input
                type="text"
                placeholder="Search employee by name..."
                value={searchKeyword}
                onChange={getSearchKeyword}
              />
              <IconButton disabled className="roles-and-permission-search-icon">
                <SearchIcon />
              </IconButton>
            
            </div>
            <div className="roles-and-permission-employee-radio-btn-container">
              {(empSearchResult?.length > 0 &&
                empSearchResult
                  ?.slice(0)
                  ?.reverse()
                  ?.map((emp, i) => (
                    <div
                      className="roles-and-permission-employee-radio-btn"
                      key={i}
                    >
                      <input
                        type="radio"
                        id={emp?._id}
                        name="employee"
                        value={emp?._id}
                        onChange={getCheckedEmpId}
                      />
                      <label
                        htmlFor={emp?._id}
                      >{`${emp?.emp_id} - ${emp?.staff_name}`}</label>
                    </div>
                  ))) || <span>No data</span>}
            </div>
          </div>
        ) : undefined}
       </div>
       <div className="roles-and-permission-right-container">
          <div className="roles-and-permission-heading-container">
              <h3>Module Access</h3>
              <button
                 className="btn btn-primary btn-update-roles-and-permission"
                onClick={clickRolesAndPermissionUpdateBtn}
                >
            Update
          </button>
          </div>
          <hr className="global-hr" />
          <div> 
             {/* <div className="roles-and-permission-single-input">
            <p>Dashboard</p>
            <input
              className="toggle-checkbox"
              type={"checkbox"}
              name="productList"
              checked={singleSections?.dashboard}
              onChange={checkboxChangeSingleSection("dashboard")}
            />
             </div> */}
             <div className="roles-and-permission-single-input">
            <p>Products</p>
            <input
              className="toggle-checkbox"
              type={"checkbox"}
              name="productList"
              checked={singleSections?.products}
              onChange={checkboxChangeSingleSection("products")}
            />
             </div>
             <div className="roles-and-permission-multiple-input">
                <Accordion>
                   <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                     >
                      <p>Point Of Sales</p>
                   </AccordionSummary>
                   <AccordionDetails>
                    
                      {
                        posSection?.map((section,sectionIndex)=>(
                          section?.values?
                          <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <p>{section?.name}</p>
                            </AccordionSummary>
                            <AccordionDetails>
                            {
                              section?.values?.map((obj,objIndex)=>(
                                <div className="roles-and-permission-single-input accordion-multiple-input">
                                 <p>{obj?.label}</p>
                                 <input
                                  className="toggle-checkbox"
                                  type={"checkbox"}
                                  checked={obj?.isChecked}
                                  onChange={()=>changeCheckBoxPos(sectionIndex,objIndex)}
                                   />
                                  </div>
                                  ))
                              }
                            </AccordionDetails>
                          </Accordion>
                          :
                          <div className="roles-and-permission-single-input accordion-multiple-input">
                               <p>{section?.label}</p>
                               <input
                                  className="toggle-checkbox"
                                  type={"checkbox"}
                                  checked={section?.isChecked}
                                  onChange={() =>section?.label === "All"?changeAllCheckBoxPos():changeCheckBoxPos(sectionIndex)}
                                 
                                />
                            </div>
                        ))
                      }
                   </AccordionDetails>
                </Accordion>
              </div>
              <div className="roles-and-permission-multiple-input">
                <Accordion>
                   <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                     >
                      <p>Purchase</p>
                   </AccordionSummary>
                   <AccordionDetails>
                      {
                        purchaseSection?.map((obj,i)=>(
                            <div className="roles-and-permission-single-input accordion-multiple-input">
                               <p>{obj?.label}</p>
                               <input
                                  className="toggle-checkbox"
                                  type={"checkbox"}
                                  checked={obj?.isChecked}
                                  onChange={()=>obj?.label === "All" ? changeAllCheckBox():changeCheckBox(i)}
                                 
                                />
                            </div>
                        ))
                      }

                   </AccordionDetails>
                </Accordion>
              </div>
              <div className="roles-and-permission-multiple-input">
                <Accordion>
                   <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                     >
                      <p>Inventory</p>
                   </AccordionSummary>
                   <AccordionDetails>
                      {
                        inventorySection?.map((section,sectionIndex)=>(
                          section?.values?
                          <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <p>{section?.name}</p>
                            </AccordionSummary>
                            <AccordionDetails>
                            {
                              section?.values?.map((obj,objIndex)=>(
                                <div className="roles-and-permission-single-input accordion-multiple-input">
                                 <p>{obj?.label}</p>
                                 <input
                                  className="toggle-checkbox"
                                  type={"checkbox"}
                                  checked={obj?.isChecked}
                                  onChange={()=>changeCheckBoxInventory(sectionIndex,objIndex)}
                                   />
                                  </div>
                                  ))
                              }
                            </AccordionDetails>
                          </Accordion>
                          :
                          <div className="roles-and-permission-single-input accordion-multiple-input">
                          <p>{section?.label}</p>
                          <input
                           className="toggle-checkbox"
                           type={"checkbox"}
                           checked={section?.isChecked}
                           onChange={()=>section?.label === "All"?changeAllCheckBoxInventory():changeCheckBoxInventory(sectionIndex)}
                            />
                           </div>
                        ))
                      }
                   </AccordionDetails>
                </Accordion>
              </div>
              <div className="roles-and-permission-multiple-input">
                <Accordion>
                   <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                     >
                      <p>Sales</p>
                   </AccordionSummary>
                   <AccordionDetails>
                      {
                        salesSection?.map((section,sectionIndex)=>(
                          section?.values?
                          <Accordion>
                          <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                          >
                              <p>{section?.name}</p>
                          </AccordionSummary>
                          <AccordionDetails>
                          {
                            section?.values?.map((obj,objIndex)=>(
                              <div className="roles-and-permission-single-input accordion-multiple-input">
                               <p>{obj?.label}</p>
                               <input
                                className="toggle-checkbox"
                                type={"checkbox"}
                                checked={obj?.isChecked}
                                onChange={()=>changeCheckBoxSales(sectionIndex,objIndex)}
                                 />
                                </div>
                                ))
                            }
                          </AccordionDetails>
                        </Accordion>
                            :
                            <div className="roles-and-permission-single-input accordion-multiple-input">
                               <p>{section?.label}</p>
                               <input
                                  className="toggle-checkbox"
                                  type={"checkbox"}
                                  checked={section?.isChecked}
                                  onChange={()=>section?.label === "All"?changeAllCheckBoxSales():changeCheckBoxSales(sectionIndex)}
                                 
                                />
                            </div>
                        ))
                      }
                   </AccordionDetails>
                </Accordion>
              </div>
            
              <div className="roles-and-permission-multiple-input">
                <Accordion>
                   <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                     >
                      <p>Reports</p>
                   </AccordionSummary>
                   <AccordionDetails>
                      {
                        reportSection?.map((obj,i)=>(
                            <div className="roles-and-permission-single-input accordion-multiple-input">
                               <p>{obj?.label}</p>
                               <input
                                  className="toggle-checkbox"
                                  type={"checkbox"}
                                  checked={obj?.isChecked}
                                  onChange={()=>obj?.label === "All" ? changeAllCheckBoxReport():changeCheckBoxReport(i)}
                                 
                                />
                            </div>
                        ))
                      }
                   </AccordionDetails>
                </Accordion>
              </div>
              <div className="roles-and-permission-multiple-input">
              <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <p>Accounts</p>
        </AccordionSummary>
        <AccordionDetails>
          {accountSection.map((section, sectionIndex) => (
            section.values ? (
              <Accordion key={section.nameKey}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id={`panel1a-header-${sectionIndex}`}
                >
                  <p>{section.name}</p>
                </AccordionSummary>
                <AccordionDetails>
                  {section.values.map((value, valueIndex) => (
                    value.subValues ? (
                      <Accordion key={value.subNameKey}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id={`panel1a-header-${sectionIndex}-${valueIndex}`}
                        >
                          <p>{value.subName}</p>
                        </AccordionSummary>
                        <AccordionDetails>
                          {value.subValues.map((subValue, subValueIndex) => (
                            <div className="roles-and-permission-single-input accordion-multiple-input" key={subValue.key}>
                              <p>{subValue.label}</p>
                              <input
                                className="toggle-checkbox"
                                type="checkbox"
                                checked={subValue.isChecked}
                                onChange={()=>changeCheckBoxAccounts(sectionIndex,valueIndex,subValueIndex)}

                              />
                            </div>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    ) : (
                      <div className="roles-and-permission-single-input accordion-multiple-input" key={value.key}>
                        <p>{value.label}</p>
                        <input
                          className="toggle-checkbox"
                          type="checkbox"
                          checked={value.isChecked}
                          onChange={()=>changeCheckBoxAccounts(sectionIndex,valueIndex)}
                        />
                      </div>
                    )
                  ))}
                </AccordionDetails>
              </Accordion>
            ) : (
              <div className="roles-and-permission-single-input accordion-multiple-input" key={section.key}>
                <p>{section.label}</p>
                <input
                  className="toggle-checkbox"
                  type="checkbox"
                  checked={section.isChecked}
                  onChange={()=>section?.label === "All"?changeAllCheckAccounts():changeCheckBoxAccounts(sectionIndex)}
                />
              </div>
            )
          ))}
        </AccordionDetails>
      </Accordion>
              </div>
              <div className="roles-and-permission-multiple-input">
                <Accordion>
                   <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                     >
                      <p>Staff</p>
                   </AccordionSummary>
                   <AccordionDetails>
                      {
                        staffSection?.map((section,sectionIndex)=>(
                          <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <p>{section?.name}</p>
                            </AccordionSummary>
                            <AccordionDetails>
                            {
                              section?.values?.map((obj,objIndex)=>(
                                <div className="roles-and-permission-single-input accordion-multiple-input">
                                 <p>{obj?.label}</p>
                                 <input
                                  className="toggle-checkbox"
                                  type={"checkbox"}
                                  checked={obj?.isChecked}
                                  onChange={()=>obj?.label === "All"?changeAllCheckStaff(sectionIndex):changeCheckBoxStaff(sectionIndex,objIndex)}
                                   />
                                  </div>
                                  ))
                              }
                            </AccordionDetails>
                          </Accordion>
                        ))
                      }
                   </AccordionDetails>
                </Accordion>
              </div>
               <div className="roles-and-permission-single-input">
                 <p>Device Settings</p>
                 <input
                     className="toggle-checkbox"
                     type={"checkbox"}
                     name="productList"
                     checked={singleSections?.deviceSettings}
                     onChange={checkboxChangeSingleSection("deviceSettings")}
                   />
               </div>
               <div className="roles-and-permission-single-input">
                  <p>Generate QR/barcode</p>
                   <input
                   className="toggle-checkbox"
                   type={"checkbox"}
                   name="productList"
                   checked={singleSections?.generateQrBarcode}
                   onChange={checkboxChangeSingleSection("generateQrBarcode")}
                 />
               </div>



          </div>
       </div>
       <ErrorSnackbar
                style={{ top: "2%", left: "50%", height: "20px", width: "auto",transform:"translateX(-50%)",position:"absolute" }}
                open={snackBarStates.alert}
                handleClose={closeSnackbar}
                message={snackBarStates.message}
            />

        <SuccessSnackbar
                open={snackBarStates.success}
                handleClose={closeSnackbar}
                message={snackBarStates.message}
            />
            <ErrorSnackbar
                open={snackBarStates.error}
                handleClose={closeSnackbar}
                message={snackBarStates.message}
            />
    </div>
  )
}
