import React, { useEffect, useRef, useState } from 'react'
import { Autocomplete, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setApplyBtnClicked, setFilteredOrderType, setFilteredStatus } from './filterButtonSlice';
function FilterButton() {

    const menuRef=useRef()
    const dispatch=useDispatch()
    const {orderTypeOptions,filteredOrderType,statusOptions,filteredStatus}=useSelector((state)=>state.filterButtonSlice)
    const [openMenu, setOpenMenu] = useState(false)
    const filterIconClick = () => {
        setOpenMenu(!openMenu)
    }

    const disableBtn=filteredOrderType===null&&filteredStatus===null
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                menuRef.current &&
                !menuRef.current.contains(event.target)
            ) {
                setOpenMenu(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const filterCountingFn=() => {
        const states = [filteredOrderType, filteredStatus]; // Add other filter states here
        return states.filter(state => state !== null).length;
      };

    const filterCount=filterCountingFn()
    return (
        <div style={{position:"relative"}}>
            {
                filterCount!==0 &&
            <div className='filter-span-count justify-center'>{filterCount}</div>}
        <div className="filter-icon-ctn justify-center" onClick={filterIconClick}>
            <i className="bi bi-funnel"
                style={{ color: " #5c5ce5", fontSize: "0.9rem", margin: "0" }}
            ></i>
            Filter
        </div>
            {
                openMenu &&
                <div
                    ref={menuRef}
                    className="filter-icon-ctn-drop-down">
                    {
                        orderTypeOptions?.length!==0 &&
                    <div className='filter-body'>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={orderTypeOptions}
                            sx={{ width: "100%",backgroundColor:"#e7e7e7" }}
                            renderInput={(params) => <TextField
                                {...params} label="Order Type" />}
                            onChange={(e,newValue)=>dispatch(setFilteredOrderType(newValue))}
                            value={filteredOrderType}
                        />
                    </div>}
                    <div className='filter-body'>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={statusOptions}
                            sx={{ width: "100%",backgroundColor:"#e7e7e7" }}
                            renderInput={(params) => <TextField {...params} label="Status" />}
                            onChange={(e,newValue)=>dispatch(setFilteredStatus(newValue))}
                            value={filteredStatus}
                        />
                    </div>

                    <button 
                    className="btn btn-secondary"
                    onClick={()=>dispatch(setApplyBtnClicked("clicked"))}
                    // style={{cursor:disableBtn?"not-allowed":"pointer"}}
                    // disabled={disableBtn}
                    >
                        Apply
                    </button>
                </div>}
        </div>
    )
}

export default FilterButton