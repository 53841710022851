import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const TopCustomersTable = () => {
  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }
  const rows = [
    createData("Frozen yoghurt", 159, 6584, 1000, 4.0),
    createData("Ice cream sandwich", 237, 3350, 37, 4.3),
    createData("Eclair", 262, 1689, 2400, 6.0),
    createData("Cupcake", 305, 7586, 4563, 4.3),
    createData("Gingerbread", 356, 2000, 49, 3.9),
  ];
  return (
    <div className="home-dash-temp-cntr">
      <div className="home-dash-graph-head">
        <span>Top Customers</span>
      </div>
      {/* <TableContainer component={Paper}> */}
      <Table sx={{ minWidth: 400 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Orders</TableCell>
            <TableCell align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
      
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* </TableContainer> */}
    </div>
  );
};

export default TopCustomersTable;
