import axios from "axios";
import { update_all_customer_list, update_filtered_customer_list } from "../../Redux/Customer/allCustomerListSlice";
import { get_all_customer_Filter } from "../../Redux/Customer/allCustomerListWithFilterSlice";
import store from "../../Redux/store";
import { HEADERS } from "../UrlAndPaths";

//view all Customers API
export const viewAllCustomersAPICall = async (body,setIsLoading) => {
  setIsLoading!==undefined && setIsLoading(true)
  await axios
    .post("customer/viewCustomer", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_all_customer_list({ customerList: res.data }));
        setIsLoading!==undefined && setIsLoading(false)

      }
    })
    .catch((err) => {
      store.dispatch(update_all_customer_list({ customerList: undefined }));
      setIsLoading!==undefined && setIsLoading(false)

    });
};
export const CustomersFilterAPICall =(body,setIsLoading)=>{
  setIsLoading!==undefined&&setIsLoading(true)
  axios.post("customer/searchCustomer",body,{
    headers: {
      'Content-Type': 'application/json',
    },
    // cancelToken: signal.token,
  })
  .then((res)=>{
    if(res.status===200){
      store.dispatch(update_filtered_customer_list({filterCustomer:res.data}))
      setIsLoading!==undefined&&setIsLoading(false)
    }
  })
  .catch((err)=>{
    store.dispatch(update_filtered_customer_list({filterCustomer:undefined}))
    setIsLoading!==undefined&&setIsLoading(false)
  })

}
//get search all customer 
export const customerListApi = async (body)=>{
  try {
    const response = await axios.post("account/viewCustomerForDropdown", body, HEADERS)
    if(response.status === 200){
      store.dispatch(update_all_customer_list({ customerList: response.data }));
    }
  } catch (error) {
    console.error(error.message)
    store.dispatch(update_all_customer_list({ customerList: undefined }));
  }
}













// view all customer with filter
export const viewAllCustomerWithFilterAPICall = async (
  body,
  setIsLoading,
  setErrorSnack,
  setSnackMsg
) => {
  if (setIsLoading !== undefined) setIsLoading(true);
  await axios
    .post("customer/viewCustomerwithfilter", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(
          get_all_customer_Filter({ allCustomerFilterData: res.data })
        );
        if (setIsLoading !== undefined) setIsLoading(false);
      }
    })
    .catch((err) => {
      store.dispatch(
        get_all_customer_Filter({ allCustomerFilterData: undefined })
      );
      console.log(err.response);
      switch (err.response.status) {
        case 404:
          setSnackMsg("No data available..");
          break;
        case 500:
          setSnackMsg("Server error!!");
          break;
        case 401:
          setSnackMsg("Unauthorized!!");
          break;
        case 403:
          setSnackMsg("Forbidden!!");
          break;

        default:
          setSnackMsg(
            "Unseen error occurred,Please contact to EYET Support!!!!"
          );
          break;
      }
      setErrorSnack(true);
      if (setIsLoading !== undefined) setIsLoading(false);
    });
};








