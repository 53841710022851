import React, { useEffect, useState } from 'react'
import TopBar from './TopBar'
import { Autocomplete, Dialog, TextField} from '@mui/material'

import { useDispatch, useSelector } from 'react-redux';
import { setBillType, setBtnSelected, setCustomer, setCustomerWallet } from './topBarSliceBilling';
import "./billingPayment.css"
import "../../../../../css/Single Components/calculator.css"
import CloseIcon from '@mui/icons-material/Close';
import { getCurrentTime, getTimeDifference, today } from '../../../../../Js/Date';
import { setCartArray, setCartObj, setDialogBox,setOrderIdInPOS,setPaymentMode,setTotalsInfo } from '../Slice/billingScreenSlice';
import { finalPaymentPOSApiCall, refundPaymentApiCall, refundUpdatePaymentApiCall } from '../API/billingPaymentSection';
import { listHeldOrders, listOrdersForBillingAPI } from '../API/viewOrderSection';
import { setInputCalculator } from '../Slice/calculatorSlice';
import walletIcon from "../image/wallet (5).png"
import Swal from 'sweetalert2';
import BillingCartList from './BillingCartList';
import BillingKeypad from './BillingKeypad';
import paymentEmpty from "../image/paytype-01.png"
import { addPOSOrderAPI, holdPOSOrderAPI, postHoldOrderAPI } from '../API/billingScreenSection';
import { keySetInputValue, setSelectedInputField, update_button_submit } from '../Slice/billingKeypadSlice';
import { errorSnackMsg } from '../../../../Custom Hooks/SnackkBarUtilities';
function BillingPayment() {

  const dispatch=useDispatch()

  const decimalPosition=localStorage.getItem("decimalPosition")


  // redux states
  const { customer,defaultCustomer,customerWallet,billType,aggregator,aggregatorRef} = useSelector((state) => state.topBarSliceBilling)
  const { singleOrderInfo,orderIdForPos,branchId,dialogOpen,cartInfo,totalsInfo,paymentMode} = useSelector((state) => state.billingScreenSlice);

  const userInfo = useSelector((state) => state.loginResponseSlice.value);
  const shiftStatus = useSelector((state) => state.shiftStatusSlice.value);
  const posSettings=useSelector((state)=>state.posSettingSlice.apiResList)
  const paymentTypesForBranch=useSelector((state)=>state.newBillingSlice.paymentTypeList)
  const cardManual = useSelector((state) => state.cardManualSlice.value);
  const  {buttonSubmit,keyPadInputValue,selectedInputField}= useSelector((state) => state.billingKeypadSlice);
  
const walletStatus=posSettings?.activeWalletStatus
const activeWalletJournel=posSettings?.activeWallet?.activeWalletJournel
const rewardStatus=posSettings?.activeLoyaltyStatus
const activeRewardJournel=posSettings?.activeLoyalty?.activeLoyaltyJournel

// useStates
  const paymentFormInitialStates={
    deliveryCharge:0,
    rewardPoints:"",
    discount:0,
    amountTotal:null,
    paidAmount:null,
    wallet:"",
    credit:"",
    totalAmt:0,
    totalExcl:0,
    totalIncl:0,
    change:0,
    balance:0,
    changeInWallet:null
  }
  const [paymentForm,setPaymentForm]=useState(paymentFormInitialStates)

  const [paymentsTypeArray,setPaymentsTypeArray]=useState([])
  const [paymentSelectedArray,setPaymentSelectedArray]=useState([])
  const [initialRefresh,setInitialRefresh]=useState(true)
  const [selectedPaymentRow,setSelectedPaymentRow]=useState(null)
  const paymentFormOnChange=(key)=>(e)=>{

    const validInput = e.target.value.replace(/[^0-9.]/g, '');
    let amountTotal=Number(totalsInfo?.amtTotal?.toFixed(decimalPosition))
    if(key==="deliveryCharge"){
        amountTotal=(amountTotal+Number(validInput))-Number(paymentForm.discount)
    }else if(key==="discount"){
        amountTotal=(amountTotal-Number(validInput))+Number(paymentForm.deliveryCharge)
    }
    setPaymentForm({...paymentForm,[key]:Number(validInput),amountTotal})
    
  }

    const cusWalletObjInitial = {
        journalId: activeWalletJournel,
        journalName: "Customer Wallet",
        type: "wallet",
        _id: null,
        walletBalance: 0
    }
    const [cusWalletObj,setCusWalletObj]=useState(cusWalletObjInitial)
    const cusRewardObjInitial = {
        journalId: activeRewardJournel,
        journalName: "Customer Points",
        type: "reward",
        _id: null,
        walletBalance: 0
    }
    const [cusRewardObj,setCusRewardObj]=useState(cusRewardObjInitial)

  // adding payment type in array
  const paymentTypeClick=(obj)=>()=>{
        let paidAmount=0
        if(obj.type==="wallet"){
                paidAmount=(Number(paymentForm.balance)>Number(obj.walletBalance))?(obj?.walletBalance):paymentForm.balance
                let walletBal=cusWalletObj.walletBalance-Number(paidAmount)   
                setCusWalletObj({...cusWalletObj,walletBalance:walletBal})
            
        }else if(obj.type==="reward"){
            paidAmount=(Number(paymentForm.balance)>Number(obj.walletBalance))?(obj?.walletBalance):paymentForm.balance
            let walletBal=cusRewardObj.walletBalance-Number(paidAmount)   
            setCusRewardObj({...cusRewardObj,walletBalance:walletBal})
        }
        else{
            paidAmount=paymentForm.balance
        }

        if((obj.type==="wallet" && paymentSelectedArray.some(obj=>obj.type==="wallet")) || (obj.type==="reward" && paymentSelectedArray.some(obj=>obj.type==="reward")) ){
            errorSnackMsg(`${obj.type==="wallet"?"Wallet":"Reward"} payment type already exist!!`)
        }else{
            const updatedObj={...obj,paidAmt:Number(paidAmount)?.toFixed(decimalPosition) || 0}
            setPaymentSelectedArray([...paymentSelectedArray,updatedObj])
            setSelectedPaymentRow(paymentSelectedArray.length)
            dispatch(setSelectedInputField("payment"))
            dispatch(keySetInputValue(paidAmount))
        }

  }

// card type selecting
  const paymentCardOnchange=(key)=>(e,newValue)=>{
    if(newValue===null){
            // const newArray=paymentSelectedArray?.filter(obj=>obj.type!=="card")
            // setPaymentSelectedArray(newArray)
    }else{
        setPaymentSelectedArray((prevArray) => {
            // const newArray = prevArray?.filter(obj => obj.type !== "card");
            const updatedValue = { ...newValue,
                 type: "card",
                 paidAmt:(Number(paymentForm.balance)?.toFixed(decimalPosition) || 0),
                 journalId:key?.journalId
                };
            return [...prevArray, updatedValue];
          });
          setSelectedPaymentRow(paymentSelectedArray.length)
          dispatch(setSelectedInputField("payment"))

          dispatch(keySetInputValue(paymentForm.balance))


    }
  }

// deleting selected payment type array
const removePaymentType=(index)=>()=>{
    let newArray = [...paymentSelectedArray];
    newArray.splice(index, 1); 
    setSelectedPaymentRow(paymentSelectedArray===index?null:paymentSelectedArray)
    setPaymentSelectedArray(newArray); 
}

const paidAmountChange=(index,value)=>{
    value = String(value || '0');
    let validInput =value.replace(/[^0-9.]/g, '');
    const updatedData = paymentSelectedArray.map((r, i) => {
        if (i === index) {
            if(r.type==="wallet" && Number(validInput)>customerWallet?.walletBalance){
                errorSnackMsg(`available customer wallet amount is ${customerWallet?.walletBalance}`)
                validInput=customerWallet?.walletBalance
            }
            else if(r.type==="reward" && Number(validInput)>customerWallet?.reward?.redeemableAmt){
                errorSnackMsg(`Available customer wallet amount is ${customerWallet?.reward?.redeemableAmt}`)
                validInput=customerWallet?.reward?.redeemableAmt
            }
            return { ...r, paidAmt: validInput};
        }else{
            return r
        }
    })
    

    setPaymentSelectedArray(updatedData); 
}

const approvalNoOnChange=(index)=>(e)=>{
    const updatedData = paymentSelectedArray.map((r, i) => {
        if (i === index) {
            return { ...r, approvalCode: e.target.value};
        }else{
            return r
        }
    })
    setPaymentSelectedArray(updatedData); 
}

const amountAndWalletChange=(key)=>(e)=>{
    if(key==="wallet"){
        let changeAmt=Math.max(Number(paymentForm.change)-Number(e.target.value),0)
        setPaymentForm({...paymentForm,wallet:e.target.value,changeInWallet:changeAmt?.toFixed(decimalPosition)})
    }
}

// calculate change and balance
const calculateCHangeAndBal=()=>{

    let paidAmt=Number(paymentForm.paidAmount)?.toFixed(decimalPosition)
    let totalAmount=Number(paymentForm.amountTotal)?.toFixed(decimalPosition)

    let changeAmt=Math.max(paidAmt-totalAmount,0)
    let balanceAmt=Math.max(totalAmount-paidAmt,0)
    setPaymentForm({...paymentForm,
        change:changeAmt?.toFixed(decimalPosition),
        balance:balanceAmt?.toFixed(decimalPosition)
    })
}

const clearPaymetArrayFunction=()=>{
    let filteredArray=paymentSelectedArray.filter((obj)=>(!obj.change===true))
    setPaymentSelectedArray(filteredArray)
}

    const clearOrderFn=()=>{
            

        
        dispatch(setOrderIdInPOS(null))
        dispatch(setCustomerWallet(null))
        dispatch(setDialogBox(null))
        dispatch(setCustomer(null))
        dispatch(setCartArray([]))
        dispatch(setCartObj(null))
        dispatch(setCustomerWallet(null))
        dispatch(setTotalsInfo(null))
        dispatch(update_button_submit(null))
        dispatch(setBillType("COUNTER"))
        setPaymentForm(paymentFormInitialStates)
        setPaymentSelectedArray([])
        setSelectedPaymentRow(null)
        dispatch(setSelectedInputField(null))
        dispatch(keySetInputValue(""))
        dispatch(setPaymentMode(null))

        listOrdersForBillingAPI({branchId,type:2})
        listHeldOrders({branchId,type:1})
    }
const finalPlaceOrder=(id)=>{
    const paymentMethodBody=paymentSelectedArray?.map((item)=>(
        {
            type: item?.type,
            journalId: item?.journalId || null,
            paidAmt:Number(Number(item?.paidAmt)?.toFixed(decimalPosition)),
            cardId:(cardManual!==undefined && item?.type==="card")?(item?._id|| null):null,
            approvalCode:item?.approvalCode===""?undefined:item?.approvalCode
        }
    ))

    let payload={
        orderId: id,
        branchId: userInfo?.branchPk,
        discountAmt:(paymentForm?.discount==="" || paymentForm?.discount===null || paymentForm?.discount==="NaN")
                        ?0:Number(paymentForm?.discount),
        deliveryCharge:(paymentForm?.deliveryCharge==="" ||paymentForm?.deliveryCharge===null || paymentForm?.deliveryCharge==="NaN")
                        ?0:Number(paymentForm?.deliveryCharge),
        amtTotal:paymentForm?.amountTotal===""?0:Number(paymentForm?.amountTotal?.toFixed(decimalPosition)),
        paymentDate: `${today} ${getCurrentTime()}`,
        paymentMethod:paymentMethodBody,
        cusId:customer?._id || defaultCustomer?._id
    }
    
    
    finalPaymentPOSApiCall(payload,clearOrderFn,clearPaymetArrayFunction)
}


const addOrderFunction=()=>{
    let orderInfo=cartInfo?.map((item)=>(
        {
            itemType: 1,
            productId:item?._id,
            desccription:item?.productName,
            qty: Number(item?.qty),
            uom: item?.uom,
            unitPrice: Number(item?.salesPrice),
            subTotalWoTax: Number(item?.subTotalWoTax),
            lineTotal: Number(item?.lineTotal),
            taxes:item?.taxes,
            includedPrice: (item?.includedPrice === "true" || item?.includedPrice === true)
              ? true
              : (item?.includedPrice === "false" || item?.includedPrice === false)
              ? false
              : null,
            isRewardProd: item?.isReward || false,
            prodDiscount: Number(item?.discountAmt?.toFixed(decimalPosition))
        }
    ))
    let payload={
        orderInfo,
        subTotal: totalsInfo?.subTotal,
        discountAmt: 0,
        amtTotal: totalsInfo?.amtTotal,
        totalInclusive: totalsInfo?.totalInclusive,
        totalExclusive: totalsInfo?.totalExclusive,
        branchId: userInfo?.branchPk,
        cusId: customer?._id || defaultCustomer?._id,
        orderDate: `${getCurrentTime()} ${today}`,
        ordType: billType,
        aggregatorId: billType === "DELIVERY" ? aggregator?._id || null : null,
        aggregatorRef: billType === "DELIVERY" ? aggregatorRef : null,
    }

    if(buttonSubmit==="holdOrder"){

        payload.orderId=orderIdForPos
        holdPOSOrderAPI(payload,clearOrderFn)

    }else{
        if(paymentMode===null){
            addPOSOrderAPI(payload,finalPlaceOrder)
        }else if(paymentMode==="updateOrder"){
            payload.orderId=orderIdForPos
            postHoldOrderAPI(payload,finalPlaceOrder)
        }else if(paymentMode==="balancePayment"){
            finalPlaceOrder(orderIdForPos)
        }
    }
}



const finalPlaceOrderValidations=()=>{
    
    let changeAmt=Number(paymentForm.change)
    if(cartInfo?.length===0){
        errorSnackMsg("No Items added !!")
    }
     else if(shiftStatus===null){
        errorSnackMsg("Shift status not available !!")
    }
    else if(billType==="DELIVERY" && aggregator===null){
        errorSnackMsg("Select an aggregator !!")
    }
    else if(Number(paymentForm.balance)>0){
        if(customer===null){
            errorSnackMsg("Credit not available !!")
        }else{
            Swal.fire({
                text:"Balance amount will be added to customer credit!!",
                icon: 'warning',
                    showCancelButton: true,
                   confirmButtonColor: '#f80000',
                   confirmButtonText: 'Pay',
                   customClass:{
                     cancelButton:'sweet-alert-cancel-button',
                     confirmButton:'sweet-alert-submit-button',
                   }
              }).then((result)=>{
                if(result.isConfirmed){
                    addOrderFunction()
                }
             })
        }
    }else if(paymentSelectedArray.length===0){
        errorSnackMsg("Payment Method Not Selected !!")
    }
    else if(changeAmt>0){
        if(customer!==null &&walletStatus){
            setPaymentForm({...paymentForm,changeInWallet:paymentForm.change,wallet:""})
            dispatch(setDialogBox("walletChange"))
        }else{
            const cashObj = paymentSelectedArray.find(obj => obj.type === "cash");

            if (cashObj) {
                const modifiedCashObj = { ...cashObj,type:"change", paidAmt: -(changeAmt), change: true };
                // Add the modified object back to the array
                paymentSelectedArray.push(modifiedCashObj);
                addOrderFunction()
              }
              else{
                errorSnackMsg("Change not cleared !!")
              }
        }
    }
    else{
        addOrderFunction()
    }
}





const payFromWallet=()=>{

    let changeAmt=Number(paymentForm.changeInWallet)
    let wallet=Number(paymentForm.wallet)
    const cashObj = paymentSelectedArray.find(obj => obj.type === "cash");

    if(changeAmt>0){

        if (cashObj) {
            const modifiedCashObj = { ...cashObj,type:"change", paidAmt: -(changeAmt), change: true };
            paymentSelectedArray.push(modifiedCashObj);
          }
    }

    if(wallet>0){

        const walletObj= {
            type: "wallet",
            journalId: activeWalletJournel,
            paidAmt:-(wallet),
            cardId:null,
            change:true
        }

        paymentSelectedArray.push(walletObj);

    }

    if(changeAmt>0 && cashObj===undefined){
        errorSnackMsg("Change not cleared !!")
    }else if(activeWalletJournel===undefined || activeWalletJournel===null){
        errorSnackMsg("Wallet journal can't found!!")
    }else{
        addOrderFunction()
    }
}
const refundOrderPayment=()=>{
    const paymentMethodBody=paymentSelectedArray?.map((item)=>(
        {
            type: item?.type,
            journalId: item?.journalId || null,
            paidAmt:-(Number(item?.paidAmt)),
            cardId:(cardManual!==undefined && item?.type==="card")?(item?._id|| null):null,
            approvalCode:item?.approvalCode===""?undefined:item?.approvalCode
        }
    ))
    let refundPayload={
        mainOrdId:singleOrderInfo?.mainOrdId,
        branchId:singleOrderInfo?.branchId,
        orderInfo:singleOrderInfo?.orderInfo,
        cusId:singleOrderInfo?.cusId,
        subTotal: -Number(singleOrderInfo?.subTotal?.toFixed(decimalPosition)),
        discountAmt: Number(paymentForm.discount),
        totalExclusive: -Number(paymentForm?.totalExcl?.toFixed(decimalPosition)),
        totalInclusive: Number(paymentForm?.totalIncl?.toFixed(decimalPosition)),
        amtTotal: -Number(paymentForm?.amountTotal?.toFixed(decimalPosition)),
        paymentDate: `${today} ${getCurrentTime()}`,
        paymentMethod:paymentMethodBody
    }
    let refundUpdatePayload = {
        orderId: singleOrderInfo?.mainOrdId,
        branchId: singleOrderInfo?.branchId,
        paymentDate: `${today} ${getCurrentTime()}`,
        paymentMethod:paymentMethodBody
    }
    if(orderIdForPos===null){
        errorSnackMsg("Order Not Found !!")
    }else if(shiftStatus===null){
        errorSnackMsg("Shift status not available !!")
    }else if(Number(paymentForm.balance)>0){
        errorSnackMsg("balance amount pending !!")
    }else if(paymentSelectedArray.length===0){
        errorSnackMsg("Payment Method Not Selected !!")
    }
    else{
        if(singleOrderInfo?.status==="RETURN"){
            refundUpdatePaymentApiCall(refundUpdatePayload,clearOrderFn)
        }else{
            refundPaymentApiCall(refundPayload,clearOrderFn)
        }
    }
}
const handleMouseLeave = () => {

    const deliveryChargeAmt = typeof paymentForm?.deliveryCharge === 'number' ? paymentForm?.deliveryCharge : parseFloat(paymentForm?.deliveryCharge);
    const discountAmount = typeof paymentForm?.discount === 'number' ? paymentForm?.discount : parseFloat(paymentForm?.discount);


    setPaymentForm({...paymentForm,
                deliveryCharge:deliveryChargeAmt?.toFixed(decimalPosition),
            discount:discountAmount?.toFixed(decimalPosition)
        })
    }

const paymentRowClick=(index)=>()=>{
    setSelectedPaymentRow((index===selectedPaymentRow && selectedInputField==="payment")?null:index)
    dispatch(setSelectedInputField("payment"))
    dispatch(keySetInputValue((index===selectedPaymentRow && selectedInputField==="payment")?null:paymentSelectedArray[index]?.paidAmt))

    dispatch(setInputCalculator(""))
    }
    
const handleMouseLeavePayment = () => {

    let roundedArray = paymentSelectedArray.map((item) => 
        {
            const paidAmtNumber = typeof item.paidAmt === 'number' ? item.paidAmt : parseFloat(item.paidAmt);
            return {
                ...item,
                paidAmt: paidAmtNumber.toFixed(decimalPosition),
            };
        }
    )   
    // setInitialRefresh(true)
    setPaymentSelectedArray(roundedArray)
}


  useEffect(()=>{
    if(posSettings?.posStatus){
        setPaymentsTypeArray(paymentTypesForBranch || [])
    }else{
      let paymentArray=[
        {
          journalName:"Cash",
          type:"cash"
        },
        {
          journalName:"Card",
          type:"card"
        },
        {
          journalName:"Cheque",
          type:"cheque"
        },
      ]
      setPaymentsTypeArray(paymentArray)
    }
  },[paymentTypesForBranch,posSettings])

// to ge the sum of selected payment methods input
 useEffect(()=>{
    if(!(paymentSelectedArray.some(obj=>obj.type==="wallet"))){
        setCusWalletObj({...cusWalletObj,walletBalance:customerWallet?.walletBalance || 0})
    }
    if(!(paymentSelectedArray.some(obj=>obj.type==="reward"))){
        setCusRewardObj({...cusRewardObj,walletBalance:customerWallet?.reward?.redeemableAmt || 0})
    }
    if(paymentSelectedArray.length===0 && initialRefresh===false ){
        setPaymentForm({...paymentForm,paidAmount:0})
        setCusWalletObj({...cusWalletObj,walletBalance:customerWallet?.walletBalance || 0})
        setCusRewardObj({...cusRewardObj,walletBalance:customerWallet?.reward?.redeemableAmt || 0})
    }else{
        let total=paymentSelectedArray?.reduce((sum,item)=>sum+Number(item?.paidAmt),0)
        setPaymentForm({...paymentForm,paidAmount:total})
    }
 },[paymentSelectedArray])

 useEffect(() => {
    initialRefresh===false && calculateCHangeAndBal()
    // setInitialRefresh(false)
    
  }, [paymentForm.paidAmount, paymentForm.amountTotal]);

// keypad amount onchange
useEffect(()=>{
    if(selectedInputField==="payment" && selectedPaymentRow!==null){
        paidAmountChange(selectedPaymentRow,keyPadInputValue)
    }
  },[keyPadInputValue])

  useEffect(()=>{
    if(totalsInfo!==null){
        let orderTotal=singleOrderInfo?.amountDue>0?singleOrderInfo?.amountDue: singleOrderInfo?.amtTotal-(singleOrderInfo?.discountAmt || 0)
        setInitialRefresh(false)
        setPaymentForm({...paymentForm,
            paidAmount:0,
            amountTotal:paymentMode==="refundPayment"?orderTotal :totalsInfo?.amtTotal || 0?.toFixed(decimalPosition),
            discount:paymentMode==="refundPayment"?singleOrderInfo?.discountAmt :0?.toFixed(decimalPosition),
            change:0?.toFixed(decimalPosition),
            balance:paymentMode==="refundPayment"?orderTotal :(totalsInfo?.amtTotal || 0)?.toFixed(decimalPosition) 
        })
    }
  },[totalsInfo])
  


  useEffect(()=>{
    if(customerWallet===null){
        setCusWalletObj(cusWalletObjInitial)
        setCusRewardObj(cusRewardObjInitial)
    }else{
        setCusWalletObj({...cusWalletObj,walletBalance:customerWallet?.walletBalance,journalId: activeWalletJournel})
        setCusRewardObj({...cusRewardObj,walletBalance:customerWallet?.reward?.redeemableAmt || 0,journalId: activeRewardJournel})
    }
    setPaymentSelectedArray([])
  },[customerWallet])

  useEffect(()=>{
    if(buttonSubmit==="holdOrder"){
        addOrderFunction()
    }else if(buttonSubmit==="clearOrder"){
        clearOrderFn()
    }
  },[buttonSubmit])

  return (
    <div className='billing-main-container billing-payment'>
        <div className="billing-left-container" style={{height:"auto"}}>

          <div className="flex-row" style={{gap:"5px"}}>
              <TopBar />
          </div>

          <div className="billing-payment-ctn">
            <BillingCartList/>
            <div className="billing-payment-ctn-right">
                <div className="billing-payment-input-rows"
                    onMouseLeave={handleMouseLeave}
                >
                    <div className="billing-input-field">
                        <p>Order Amount</p>
                        <input
                            placeholder="00.00"
                            type="number"
                            value={(totalsInfo?.amtTotal || 0)?.toFixed(decimalPosition)}
                            readOnly
                        />
                    </div>
                    <div className="billing-input-field">
                        <p>Delivery charge</p>
                        <input
                            placeholder="0.00"
                            type="number"
                            onFocus={(e) => e.target.select()}
                            value={paymentForm?.deliveryCharge}
                            onChange={paymentFormOnChange("deliveryCharge")}
                            onBlur={handleMouseLeave}
                            disabled={singleOrderInfo?.type === "refund"}
                        />
                    </div>
                    <div className="billing-input-field">
                        <p>Discount</p>
                        <input
                            placeholder="0.00"
                            type="number"
                            value={paymentForm?.discount}
                            onChange={paymentFormOnChange("discount")}
                            onFocus={(e) => e.target.select()}
                            onBlur={handleMouseLeave}
                            disabled={singleOrderInfo?.type === "refund"}
                        />
                    </div>

                    <hr className="global-hr" />
                </div>
                <div
                    className="total-amount"
                >
                    <div className="total">
                        {totalsInfo && totalsInfo?.totalExclusive !== 0 &&
                            <p>Exclusive Tax</p>}

                        {totalsInfo && totalsInfo?.totalInclusive !== 0 &&
                            <p >Inclusive Tax</p>
                        }
                        <p>Change</p>
                        <p style={{ color: "red" }}>Balance</p>
                        <h1>Total amount </h1>
                    </div>
                    <div className="total-a">
                        {
                            totalsInfo && totalsInfo?.totalExclusive !== 0 &&
                            <p>{totalsInfo?.totalExclusive?.toFixed(decimalPosition)}</p>
                        }
                        {
                            totalsInfo && totalsInfo?.totalInclusive !== 0 &&
                            <p>{totalsInfo?.totalInclusive?.toFixed(decimalPosition)}</p>}
                        <p>{paymentForm?.change}</p>
                        <p style={{ color: "red" }}>{paymentForm?.balance}</p>
                        <h1>{Number(paymentForm?.amountTotal)?.toFixed(decimalPosition) || 0.00}</h1>
                    </div>
                </div>
                <BillingKeypad />
            </div>
          </div>
        </div>
        <div className="flex-column">
            
            <div className="payment-types-ctn">
                <div className="payment-types-ctn-head justify-center">
                    Payment Method
                </div>
                <div className="payment-types-row-ctn">
                    {
                        paymentsTypeArray?.length!==0?
                        <>
                       { paymentsTypeArray.map((r,i)=>(
                            cardManual!==undefined && cardManual?.filter((obj)=>obj?.journalId===r?.journalId).length!==0 && r?.type==="card"?
                            <React.Fragment key={i}>
                            <div className="payment-types-row payment-types-row-auto-complete">
                                <Autocomplete
                                    options={cardManual?.filter((obj)=>obj?.journalId===r?.journalId) || []}
                                    getOptionLabel={(option) => option?.cardName}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={r?.journalName}
                                        />
                                    )}
                                    onChange={paymentCardOnchange(r)}
                                    value={null}
                                />
                            </div>
                            </React.Fragment>
                            :
                            <>
                                <div key={i}
                                onClick={paymentTypeClick(r)}
                                className="payment-types-row">
                                    {r?.journalName}
                                </div>
                            </>
                        ))}
                        {
                            walletStatus && customerWallet && singleOrderInfo?.type!=="refund" && cusWalletObj?.walletBalance>0 &&
                            <div
                                onClick={paymentTypeClick(cusWalletObj)}
                                className="payment-types-row">
                                {cusWalletObj?.journalName}
                                <span style={{color:"#139109",fontWeight:"500"}}>
                                    {cusWalletObj?.walletBalance?.toFixed(decimalPosition)}
                                </span>
                            </div>
                        }
                        {
                            rewardStatus && customerWallet && singleOrderInfo?.type!=="refund" && cusRewardObj?.walletBalance>0 &&
                            <div
                                onClick={paymentTypeClick(cusRewardObj)}
                                className="payment-types-row">
                                {cusRewardObj?.journalName}
                                <span style={{color:"#139109",fontWeight:"500"}}>
                                    {cusRewardObj?.walletBalance?.toFixed(decimalPosition)}
                                </span>
                            </div>
                        }
                        </>
                        :
                        <div className="center-div" style={{height:"8vh"}}>
                            Payment Methods Not Added !!
                        </div>
                    
                    }
                </div>
                <div className="payment-types-ctn-head justify-center">
                    Payment Selected
                </div>
                <div className="payment-types-row-ctn" style={{flex:"1"}}>
                { 
                    paymentSelectedArray.length!==0?
                    paymentSelectedArray.map((r,i)=>(
                    <>
                    {
                    !(r?.change) &&    
                    <div 
                    key={i}
                    onMouseLeave={handleMouseLeavePayment}
                    onClick={paymentRowClick(i)}
                    className="payment-types-row-list">
                        <div className="payment-types-row">
                            <p className='justify-center' style={{gap:"4px"}}>
                                {r?.journalName?.toUpperCase()}
                                </p>
                                {r?.type==="card" &&
                                <input 
                                className='card-aproval-code' 
                                type="text" 
                                placeholder='Approval code'
                                onChange={approvalNoOnChange(i)}
                                />
                                }
                                <div
                                    className='justify-center'
                                    style={{ justifyContent: "end", gap: "10px" }}
                                >
                                    <input
                                        type='number'
                                        className={selectedPaymentRow === i && selectedInputField==="payment" && "editable"}
                                        value={r?.paidAmt}
                                        onChange={(e)=>paidAmountChange(i,e.target.value)}
                                        onFocus={(e) => e.target.select()}
                                        onBlur={handleMouseLeavePayment}
                                    />
                                </div>
                             
                        </div>
                        <button
                            onClick={removePaymentType(i)}
                        >
                                <CloseIcon sx={{color:"#fff"}}/>
                        </button>
                    </div>}
                    </>
                     ))
                     :
                     <div className="center-div" style={{height:"100%",flexDirection:"column"}}>
                         <img src={paymentEmpty} alt="" style={{width:"75%"}}/>
                         <p>Select a Payment Type</p>
                     </div>
                }
                    
                </div>
            </div>
              <div className="payment-button-ctn">
                  <button
                      onClick={singleOrderInfo?.type==="refund"?refundOrderPayment : finalPlaceOrderValidations}
                      className="billing-placeOrder-button">Payment</button>
              </div>
        </div>
        <Dialog open={dialogOpen==="walletChange"} onClose={()=>dispatch(setDialogBox(null))}>
            <div className='wallet-change-dialog'>
                  <div className="head justify-center">
                    <div className="icon justify-center">
                        <img src={walletIcon} alt="" />
                    </div>
                        Amount add wallet
                  </div>
                  <div className="input-ctn">
                    <button disabled className="btn">Change</button>
                    <input type="text" 
                    value={paymentForm?.changeInWallet}
                    readOnly
                    placeholder={0?.toFixed(decimalPosition)}
                    />
                  </div>
                  <div className="input-ctn">
                    <button disabled  className="btn">Wallet</button>
                    <input type="text" 
                    value={paymentForm?.wallet}
                    onChange={amountAndWalletChange("wallet")}
                    placeholder={0?.toFixed(decimalPosition)}
                    onFocus={(e)=>e.target.select()}
                    // onblur={handleMouseLeave}
                    />
                  </div>
                  <div className="wallet-change-btn">
                    <button
                    onClick={()=>dispatch(setDialogBox(null))}
                    className="btn-secondary-outlined btn">Cancel</button>
                    <button
                    onClick={payFromWallet}
                    className="btn-primary btn">Pay</button>
                  </div>
            </div>
        </Dialog>
    </div>
  )
}

export default BillingPayment