import { Autocomplete, IconButton, TextField, Tooltip } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import ReactToPrint from 'react-to-print'
import { viewAllBranchesAPICall } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import { useSelector } from 'react-redux';
import { ListPosSalesDetailsAPI } from './ReportAPI';
import Template from "./PosSalesDetailsPrint"
const PosSalesDetails = () => {
    const [branch, setBranch] = useState(null);
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
  let paymentsPrint = useRef();

    const decimalPosition = localStorage.getItem("decimalPosition");
    const userRole=useSelector((state)=>state.userRoleSlice.value)

    const allBranchesList = useSelector(
        (state) => state.allBranchesSlice.value);
    const salesDetails = useSelector(
        (state) => state.posReportshiftSlice.salesReport);

       
const allowedBranchList = useSelector((state) => state.allAllowedBranchesSlice.value);

    console.log(salesDetails);
    const handleSubmit = () => {
        let body = {
            branchId: branch?._id,
            fromDate: from,
            endDate: to
        }
        ListPosSalesDetailsAPI(body)

    }

    useEffect(() => {
        viewAllBranchesAPICall();
    }, [])
    return (
        <div className="global-page-parent-container">
            <div className="global-white-bg-container Header" style={{ display: "flex", justifyContent: "space-between", padding: "0px 30px 0 25px", alignItems: "center" }}>
                <p>Sales Details</p>
                <div>


                    <ReactToPrint
                        trigger={() => (
                            <IconButton className="printer-div" style={{ padding: "1px" }}>
                                <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
                            </IconButton>
                        )}
                      content={() => paymentsPrint}
                    />

                    <Tooltip title="Download">
                        <IconButton>
                            <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
                        </IconButton>
                    </Tooltip>
                </div>

            </div>

            <div className="global-white-bg-container " style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div style={{ display: "flex", width: "68%", justifyContent: "space-between" }}>
                    <div className="new-global-single-input auto-complete-new" style={{ width: "27%" }}>
                        <Autocomplete
                            disablePortal
                            options={userRole === "admin" ? allBranchesList : allowedBranchList || []}

                            getOptionLabel={(option) => option?.branchName}
                            placeholder='Branch'
                            sx={{ width: 300 }}
                            renderInput={(params) => (
                                <TextField {...params} label="Branch" focused placeholder='' />
                            )}
                            value={branch}
                            onChange={(e, newValue) => setBranch(newValue)}
                        //   disabled={formValues?.isEdit}
                        />
                    </div>


                    <div className="new-global-single-input" style={{ width: '27%' }}>
                        <TextField
                            id="outlined-basic"
                            // placeholder="From"

                            // variant="outlined"
                            type="date"
                            onChange={(e, newValue) => setFrom(e.target.value)}
                            value={from}

                        //   disabled={formValues?.isEdit}
                        />
                    </div>



                    <div className="new-global-single-input" style={{ width: '27%' }}>
                        <TextField
                            id="outlined-basic"
                            // placeholder="From"
                            onChange={(e, newValue) => setTo(e.target.value)}
                            //   value={formValues?.orderDate}
                            // variant="outlined"
                            type="date"

                        //   disabled={formValues?.isEdit}
                        />
                    </div>
                </div>
                <div style={{ paddingRight: "40px" }}>
                    <button className='create-button-blue' onClick={handleSubmit}>Submit</button>
                </div>
            </div>
     {salesDetails!==undefined &&(
            <div className="new-global-table-container" style={{ paddingTop: "0 42px" }}>
                <div style={{ width: "100%", padding: "7px 0 7px 17px", backgroundColor: "#f9f0f0" }}>
                    <p style={{ margin: "0" }}>Product Details</p>
                </div>
                {salesDetails?.productDetails?.list?.map((item, index) => (
                    <div key={index}>
                        <p style={{ color: "#4343fd", fontWeight: "bold", padding: "6px 0 6px 19px", margin: "0" }}>{item?.categoryName}</p>
                        <table>
                            {index === 0 && <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Qty</th>
                                    <th>Amount</th>

                                </tr>
                            </thead>}
                            <tbody>
                                {item?.list?.map((item, i) => (
                                    <tr key={i} style={{ backgroundColor: i % 2 === 0 ? "white" : "rgb(214 212 212)" }}>

                                        <td style={{ border: "none", height: "29px", width: "50%" }}>{item.productName}</td>
                                        <td style={{ border: "none", height: "29px", width: "25%" }}>{item.qty}</td>
                                        <td style={{ border: "none", height: "29px", width: "25%" }}>{item.amount}</td>

                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr style={{ backgroundColor: "#e9e7e7" }}>
                                    <td>&nbsp;</td>
                                    <td style={{ textAlignLast: "center" }}>{item?.list?.reduce((acc, current) => acc + Number(current?.qty || 0), 0)?.toFixed(decimalPosition)}</td>
                                    <td style={{ textAlignLast: "center" }}>{item?.list?.reduce((acc, current) => acc + Number(current?.amount || 0), 0)?.toFixed(decimalPosition)}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                ))}
                <div style={{ backgroundColor: "#b6b6b6", display: "flex", width: "100%" }}>
                    <p style={{ textAlignLast: "center", width: "50%" }}>Grand Total</p>
                    <p style={{ textAlignLast: "center", width: "25%" }}>{salesDetails?.productDetails?.qtyTotal}</p>
                    <p style={{ textAlignLast: "center", width: "25%" }}>{salesDetails?.productDetails?.amountTotal}</p>
                </div>
                <div style={{ paddingTop: '50px' }}>
                    <div style={{ width: "100%", padding: "7px 0 7px 17px", backgroundColor: "#f9f0f0" }}>
                        <p style={{ margin: "0" }}>Payment Details</p>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Payment Method</th>
                                <th>Amount</th>

                            </tr>
                        </thead>
                        <tbody>
                            {salesDetails?.paymentSummary?.list?.map((item, i) => (
                                <tr key={i} style={{ backgroundColor: i % 2 === 0 ? "white" : "rgb(214 212 212)" }}>
                                    <td style={{ border: "none", height: "29px" }}>{item._id}</td>
                                    <td style={{ border: "none", height: "29px" }}>{item.amount}</td>

                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr style={{ backgroundColor: " rgb(233, 231, 231)" }}>
                                <td style={{ textAlignLast: "center" }}>&nbsp;</td>
                                <td style={{ textAlignLast: "center" }}>{parseFloat(salesDetails?.paymentSummary?.total)?.toFixed(decimalPosition)}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
)}
<div style={{display:"none"}}>
  <div ref={(el) => (paymentsPrint = el)}>
    <Template branch={branch}/>
  </div>
</div>
        </div>
    )
}

export default PosSalesDetails