import { createSlice } from "@reduxjs/toolkit";



export const billingKeypadSlice=createSlice({
    name:'billingKeypadSlice',
    initialState:{
        buttonSubmit:null,
        keyPadInputValue: '',
        selectedButton:null,
        selectedInputField:null
    },
    reducers:{
        update_button_submit:(state,action)=>{
            state.buttonSubmit=action.payload
        },
        selectActionButton:(state,action)=>{
            state.selectedButton=action.payload
        },
        setSelectedInputField:(state,action)=>{
            state.selectedInputField=action.payload
        },
        keySetInputValue: (state, action) => {
            state.keyPadInputValue = action.payload;
          },
        keyAppendInputValue: (state, action) => {
            state.keyPadInputValue += action.payload;
        },
        keyClearInputValue: (state) => {
            state.keyPadInputValue = '';
        },
        keyRemoveLastCharacter: (state) => {
            state.keyPadInputValue = state.keyPadInputValue.slice(0, -1);
        },
    }
})

export const {update_button_submit,
              keySetInputValue,
              keyAppendInputValue,
              keyClearInputValue,
              keyRemoveLastCharacter,
              selectActionButton,
              setSelectedInputField
            }=billingKeypadSlice.actions;

export default billingKeypadSlice.reducer;