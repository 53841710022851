import React, { useEffect, useRef } from "react";
import "../../../../css/Sales/Billingnew.css";
import {
  Backdrop,
  Dialog,
  IconButton
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProductsListForBilling,
  paymentTypesForBilling,
  sendMessageToWhatsApp
} from "./newBillingAPI";
import { PrintOutlined } from "@mui/icons-material";
import { viewPosSettingsAPICall } from "../../PointOfSale/Configuration/Settings/posSettingsAPI";
import ReactToPrint from "react-to-print";
import { getDiscountAPICall } from "../../../../API/Settings/Discount/discountAPI";
import "../../../../css/Orders/viewOrder.css";
import "../../../../css/Purchase Manager/purchaseOrder.css";
import { switch_to_billing_screen } from "./newBillingSlice";
import BillingPayment from "./Components/BillingPayment";
import ViewOrders from "./Components/ViewOrders";
import {
  setDialogBox,
  setPOSBranchId,
  setSingleOrderInfo
} from "./Slice/billingScreenSlice";
import {
  singlePOSOrderInfoAPICall,
} from "./API/billingScreenSection";
import { viewCardManualAPICall } from "../../../../API/Settings/CardAndUpiType/cardManualAPI";
import {
  listHeldOrders,
  listOrdersForBillingAPI,
} from "./API/viewOrderSection";
import PosReceiptTemplate from "../../components/template/posReceipt/PosReceiptTemplate";
import { getTemplateInfoAPI } from "../../Settings/posReceipt/posReceiptApi";
import { listAggregatorsAPI } from "../../PointOfSale/Configuration/Aggregator/aggregatorAPI";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import html2pdf from 'html2pdf.js';
import BillingOffersPopup from "./Components/BillingOffersPopup";
import BillingItems from "./Components/BillingItems";
import BillingLoader from "./Utilities/BillingLoader";
import { today } from "../../../../Js/Date";
import RewardsPopup from "./Components/RewardsPopup";
import { viewUOMSubListAPICall } from "../../Purchase Manager/Pages/UnitOfMeasurment/unitOfMeasurmentAPICall";

export const BillingNew = () => {
  const dispatch = useDispatch();
  // to hide the sidemenu
  dispatch(switch_to_billing_screen({ billingBoolean: true }));

  // useref
  let invoicePrint = useRef();

  // store states from topbar
  const {
    btnClicked
  } = useSelector((state) => state.topBarSliceBilling);


  // billingSlice states
  const {
    orderIdForPos,
    dialogOpen,
    billLoading,
  } = useSelector((state) => state.billingScreenSlice);

  const {productsList}=useSelector((state)=>state.newBillingSlice)

  const userInfo = useSelector((state) => state.loginResponseSlice.value);

  const { receiptData } = useSelector((state) => state.viewOrdersBillingSlice);

  // user ID's
  const userBranchId = userInfo?.branchPk;

  // initial API Calls
  useEffect(() => {

    productsList?.length===0 && getAllProductsListForBilling({ branchId: userBranchId,postDate:today })
    paymentTypesForBilling({ branchId: userBranchId });
    viewPosSettingsAPICall();
    getDiscountAPICall();
    viewUOMSubListAPICall();
    viewCardManualAPICall();
    dispatch(setPOSBranchId(userBranchId));
    listOrdersForBillingAPI({ branchId: userBranchId, type: 2 });
    listHeldOrders({ branchId: userBranchId, type: 1 });
    getTemplateInfoAPI();
    listAggregatorsAPI({ branchId: userBranchId });
  }, []);

  // api call to get the single order info
  useEffect(() => {
    orderIdForPos !== null
      ? singlePOSOrderInfoAPICall({ id: orderIdForPos,postDate: today })
      : dispatch(setSingleOrderInfo(null));
  }, [orderIdForPos]);

  // <--------send-to-ws--btn-action------>

  const sendMessageToWs = async () => {
    try {
      // Convert to PDF 
      const content = invoicePrint;
  
      const options = {
        filename: 'sample.pdf',
        image: { type: 'jpeg', quality: 1 }, 
        html2canvas: { scale: 2, logging: false }, 
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      };
  
      // Generate PDF
      const pdfBlob = await html2pdf().from(content).set(options).outputPdf('blob');
  
      // Create FormData object and append the PDF file
      const formData = new FormData();
      formData.append('pdfFile', pdfBlob, 'sample.pdf');
      formData.append('_id',receiptData?._id );
      formData.append('type',"POS" );
  
      // Now you can pass formData to your API
    
     


      const response = await sendMessageToWhatsApp(formData) 

    
  
     
      
  
    } catch (error) {
      console.log("Error sending message:", error);
      // Handle error if required
    }
  }
  return (
    <>
      <div className="global-page-parent-container billing-parent-container">
        { btnClicked === "viewOrder" || btnClicked === "heldOrder" ? (
          <ViewOrders />
        ) :<BillingPayment />
        }
      </div>
      
      {/* offer billing */}
      <BillingOffersPopup/>
      <RewardsPopup/>
      <Dialog open={dialogOpen==="items"} onClose={()=>dispatch(setDialogBox(null))} maxWidth="xl">
            <BillingItems/>
        </Dialog>
      {/* invoice bill dialog */}
      <Dialog
        open={dialogOpen === "invoicePrint"}
        maxWidth="lg"
        onClose={() => dispatch(setDialogBox(null))}
        onKeyDown={(e) => e.key === "Escape" && dispatch(setDialogBox(null))}
      >
        {/* <Invoice data={invoiceData} /> */}
        <div className="billing-parent-wrapper">
          <div ref={(el) => (invoicePrint = el)}>
            <PosReceiptTemplate />
          </div>
          <div className="invoice-print-icon-container" id="invoicePrintIcon">
            <ReactToPrint
              trigger={() => (
                <IconButton
                  className="invoice-print-btn"
                  sx={{
                    backgroundColor: "#d3e3ec",
                  }}
                >
                  <PrintOutlined
                    className="print-icon"
                    sx={{ color: "#000" }}
                  />
                </IconButton>
              )}
              content={() => invoicePrint}
            />
            <IconButton
              sx={{
                backgroundColor: "#d3e3ec",
              }}
              onClick={() => sendMessageToWs()}
            >
              <WhatsAppIcon color="success" />
            </IconButton>
          </div>
        </div>
      </Dialog>

      {/* Backdrop on loading  */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 500 }}
        open={billLoading}
        // onClick={handleClose}
      >
        <BillingLoader/>
        {/* <CircularProgress color="inherit" /> */}
      </Backdrop>

    </>
  );
};
