import { createSlice } from "@reduxjs/toolkit";

export const GeneralSlice=createSlice({
    name:'generalSlice',
    initialState:{
        orderList:undefined,
        orderListSingleView:undefined,
        PaymentAndWalletList:undefined,
        shiftList:undefined,
        shiftListSingleView:undefined,
        promotionAndOffer:undefined,
        listRewards:undefined,
        singleViewRewards:undefined,
        singleViewPromotion:undefined,
        listOfReward:undefined
    },
    reducers:{
        get_order_list:(state,action)=>{
            state.orderList=action.payload
        },
        single_view_pos_order:(state,action)=>{
            state.orderListSingleView=action.payload
        },
        get_payment_wallet_list:(state,action)=>{
            state.PaymentAndWalletList=action.payload
        },
        get_shift_list:(state,action)=>{
            state.shiftList=action.payload
        },
        single_view_pos_shift:(state,action)=>{
            state.shiftListSingleView=action.payload
        },
        get_promotion_offer_list:(state,action)=>{
            state.promotionAndOffer=action.payload
        },
        get_Promotion_single_view:(state,action)=>{
            state.singleViewPromotion=action.payload
        },
        get_Rewards_list:(state,action)=>{
            state.listRewards=action.payload
        },
        get_Rewards_single_view:(state,action)=>{
            state.singleViewRewards=action.payload
        },
        get_reward_list:(state,action)=>{
            state.listOfReward=action.payload
        },
       
       
    }
})
export const {get_order_list,
    single_view_pos_order,
    get_payment_wallet_list,
    get_shift_list,
    single_view_pos_shift,
    get_Rewards_list,
    get_Rewards_single_view,
    get_Promotion_single_view,
    get_promotion_offer_list,
    get_reward_list}=GeneralSlice.actions
export default GeneralSlice.reducer