import { createSlice } from "@reduxjs/toolkit";
import store from "../../../../../Redux/store";

export const inventoryBatchSlice = createSlice({
  name: "inventoryBatchSlice",
  initialState: {
    tableList: [],
    singleResList:null,
    batchDataPopup:false,
    batchTableRowData:null,
    batchTableRowIndex:null,
    batchListOfProduct:[]
  },
  reducers: {
    store_batch_list: (state, action) => {
        state.tableList = action.payload;
    },
    store_batch_single_data: (state, action) => {
        state.singleResList = action.payload;
    },
    store_table_batch_row_data:(state,action) =>{    
      state.batchTableRowData = action.payload;
    },
    store_table_batch_row_index:(state,action) =>{    
      state.batchTableRowIndex = action.payload;
    },
    setBatchPopup:(state,action) =>{    
      state.batchDataPopup = action.payload;
    },
    storeBatchListOfProduct:(state,action) =>{    
      state.batchListOfProduct = action.payload;
    }
  },
});

export const {store_batch_list,
              store_batch_single_data,
              setBatchPopup,
              store_table_batch_row_data,
              store_table_batch_row_index,storeBatchListOfProduct
            } = inventoryBatchSlice.actions;

export default inventoryBatchSlice.reducer;
