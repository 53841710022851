import React, { useEffect, useState } from "react";
import "./accountDashboardStyle.css";
import DashboardCard from "./components/card/DashboardCard";
import DashboardGraph from "./components/graph/DashboardGraph";
import { amountConvertor, cardTypes } from "./util/AccountDashboardUtils";
import { getAccountDashboardDataApi } from "./accountDashboardApis";
import { useSelector } from "react-redux";

const AccountDashboard = () => {
  // get data from redux store
  const { accountDashBoardData } = useSelector(
    (res) => res.accountDashboardSlice
  );
  useEffect(() => {
    if (!accountDashBoardData) {
      //get the data from the api
      getAccountDashboardDataApi();
    }
  }, [accountDashBoardData]);

  return (
    <div className="account-dashboard-container">
      <div className="account-dashboard-wrapper">
        <div className="act-dash-head">
          <DashboardCard
            showData={amountConvertor(
              accountDashBoardData?.report[0]?.data[0]?.revenue
            )}
            type={cardTypes.SMALL}
            label={"revenue"}
          />
          <DashboardCard
            showData={amountConvertor(
              accountDashBoardData?.report[0]?.data[0]?.cogs
            )}
            type={cardTypes.SMALL}
            label={"total cogs"}
          />
          <DashboardCard
            showData={amountConvertor(
              accountDashBoardData?.report[0]?.data[0]?.grossProfit
            )}
            type={cardTypes.SMALL}
            label={"gross profit"}
          />
          <DashboardCard
            showData={amountConvertor(
              accountDashBoardData?.report[0]?.data[0]?.GPPercentage
            )}
            type={cardTypes.SMALL}
            label={"gross profit %"}
          />
          <DashboardCard
            showData={amountConvertor(
              accountDashBoardData?.report[0]?.data[0]?.netProfit
            )}
            type={cardTypes.SMALL}
            label={"net profit"}
          />
          <DashboardCard
            showData={amountConvertor(
              accountDashBoardData?.report[0]?.data[0]?.NPPercentage
            )}
            type={cardTypes.SMALL}
            label={"net profit %"}
          />
        </div>
        {/* graph */}
        <DashboardGraph />
        <div className="act-dash-dtls">
          <div>
            <DashboardCard
              showData={{
                amount: accountDashBoardData?.report[0]?.data[0]?.receivable,
              }}
              type={cardTypes.MEDIUM}
              label={"receivables"}
              currency={accountDashBoardData?.currency?.currencyCode}
            />
            <DashboardCard
              showData={{
                amount: accountDashBoardData?.report[0]?.data[0]?.payable,
              }}
              type={cardTypes.MEDIUM}
              label={"payables"}
              currency={accountDashBoardData?.currency?.currencyCode}
            />
          </div>
          <div>
            <DashboardCard type={cardTypes.LARGE} label={"cash balance"}   currency={accountDashBoardData?.currency?.currencyCode} />
          </div>
          <div>
            <DashboardCard type={cardTypes.LARGE} label={"bank balance"}   currency={accountDashBoardData?.currency?.currencyCode}/>
          </div>
          <div>
            <DashboardCard
              showData={{
                amount: accountDashBoardData?.report[0]?.data[0]?.asset,
              }}
              type={cardTypes.MEDIUM}
              label={"asset"}
              currency={accountDashBoardData?.currency?.currencyCode}
            />
            <DashboardCard
              showData={{
                amount: accountDashBoardData?.report[0]?.data[0]?.liability,
              }}
              type={cardTypes.MEDIUM}
              label={"liability"}
              currency={accountDashBoardData?.currency?.currencyCode}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountDashboard;
