import React, { useEffect, useState } from 'react'
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useDispatch, useSelector } from 'react-redux';
import {  setDialogBox } from '../Slice/billingScreenSlice';
import { keyAppendInputValue, keyRemoveLastCharacter, selectActionButton, update_button_submit } from '../Slice/billingKeypadSlice';

const BillingKeypad = () => {

  const dispatch=useDispatch()
  const {cartInfo}=useSelector((state)=>state.billingScreenSlice)
  const {rewardsList}=useSelector((state)=>state.billingRewardsSlice)
  const {selectedButton,selectedInputField}=useSelector((state)=>state.billingKeypadSlice)

  const handleButtonClick=(value)=>{
    dispatch(keyAppendInputValue(value));
  }

  const holdOrderClick=()=>{
    dispatch(update_button_submit("holdOrder"))
  }

  const handleBackspaceClick = () => {
    dispatch(keyRemoveLastCharacter());
  };

  const keyPadTypeSelection = (key) => {
    dispatch(selectActionButton(key===selectedButton?null:key));
  }

  useEffect(()=>{
    if(selectedInputField!=="cart"){
        dispatch(selectActionButton(null))
    }
  },[selectedInputField])


  return (
    <div className="flex-column" style={{width:"100%"}}>
      <div className="billing-keypad-section" style={{borderBottom:"none"}}>
          <div className="billing-input-section">
              <div className="row">
                  <button onClick={() => handleButtonClick("7")}>
                      7
                  </button>
                  <button onClick={() => handleButtonClick("8")}>
                      8
                  </button>
                  <button onClick={() => handleButtonClick("9")}>
                      9
                  </button>
              </div>
              <div className="row">
                  <button onClick={() => handleButtonClick("4")}>
                      4
                  </button>
                  <button onClick={() => handleButtonClick("5")}>
                      5
                  </button>
                  <button onClick={() => handleButtonClick("6")}>
                      6
                  </button>
              </div>
              <div className="row">
                  <button onClick={() => handleButtonClick("1")}>
                      1
                  </button>
                  <button onClick={() => handleButtonClick("2")}>
                      2
                  </button>
                  <button onClick={() => handleButtonClick("3")}>
                      3
                  </button>
              </div>
              <div className="row">
                  <button onClick={() => handleButtonClick(".")}>
                      .
                  </button>
                  <button onClick={() => handleButtonClick("0")}>
                      0
                  </button>
                  <button
                      className="billing-Hold-button"
                      disabled={cartInfo?.length===0}
                      onClick={holdOrderClick}
                  >
                      Hold
                  </button>
              </div>
              
          </div>
          <div className="billing-keypad-buttons">
              <button
                  className="billing-payment-button buttons-disabled"
                  style={{height:"90px"}}
                  onClick={()=>dispatch(setDialogBox("billingOffer"))}
                  disabled
              >
                  Offers
              </button>
              <button
                  className={`billing-clear-button  ${rewardsList?.length===0?"buttons-disabled":"buttons-rewarded"}`}
                  onClick={()=>dispatch(setDialogBox("billingRewards"))}
                  disabled={rewardsList?.length===0}
             >
                  Rewards
              </button>
              <button
                  className="billing-clear-button"
                  onClick={()=>{
                    dispatch(update_button_submit("clearOrder"))
                  }}
              >
                  Clear
              </button>
          </div>
      </div>
      <div className="billing-keypad-section" style={{borderTop:"none"}}>
        <div className="billing-input-section" style={{width:"100%"}}>
        <div className="row">

    {selectedButton === "qty" ? (
        <button
            onClick={() => keyPadTypeSelection(null)}
            className="keypad-qty-btn closekey-pad-btn"
            style={{ backgroundColor: "#478045" }}
            disabled={selectedInputField !== "cart"}
    
        >
            <DoneOutlineIcon />
        </button>
    ) : (
        <button
            onClick={() => keyPadTypeSelection("qty")}
            className={`${selectedInputField !== "cart"
                    ? "keypad-qty-btn-enable"
                    : ""
                    } keypad-qty-btn`}
            disabled={selectedInputField !== "cart"}
        >
            Qty
        </button>
    )}
    {selectedButton === "price" ? (
        <button
            onClick={() => keyPadTypeSelection("price")}
            className="keypad-qty-btn closekey-pad-btn"
            style={{ backgroundColor: "#478045" }}
        >
            <DoneOutlineIcon />
        </button>
    ) : (
        <button
            disabled={selectedInputField !== "cart"}
            onClick={() => keyPadTypeSelection("price")}
            className={`${
                // selectedCartRow === null?
                 "keypad-qty-btn-enable"
                    // : ""
                } keypad-qty-btn`}
        >
            Price
        </button>
    )}
  

        <button
            className={"keypad-back-btn"}
            onClick={handleBackspaceClick}
        >
            <KeyboardBackspaceIcon/>
        </button>
</div>
        </div>
      </div>
      </div>
  )
}

export default BillingKeypad