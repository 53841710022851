import { Dialog, Skeleton } from '@mui/material';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setDialogBox, setPageIndex, setPagination } from '../Slice/billingScreenSlice';
import dummyProductItemImg from "../../../../../Assets/BillingImages/emptyFoodImg.png";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const BillingOffersPopup = () => {
    
    const decimalPosition=localStorage.getItem("decimalPosition")
    const {dialogOpen,offerMenuLoading,offerListCategory,pagination,pageIndex,offerProductsList} = useSelector((state) => state.billingScreenSlice);
    const {tableLoading} = useSelector((state) => state.mainDashBoardSlice);
    
    const [offerMenuColors,setOfferMenuColors]=useState({})
    const [offerSelected,setOfferSelected]=useState(null)

    const dispatch=useDispatch()

    const handleScroll = (e) => {
        const { scrollTop, clientHeight, scrollHeight } = e.target;
        if (scrollTop + clientHeight+100 >= scrollHeight) {
          // Call your function when scrolled to the bottom
          if (offerProductsList?.length >= 30 && !tableLoading && pagination !== "max") {
            dispatch(setPageIndex(pageIndex + 1));
            dispatch(setPagination(true));
          }
        }
      };

      const getRandomColor = () => {
        const randomChannel = () => Math.floor(Math.random() * 100) + 50; // Generate a random channel (dark color with some variation)
        return `rgb(${randomChannel()}, ${randomChannel()}, ${randomChannel()})`;
      };



  return (
    <Dialog open={dialogOpen==="billingOffer"} onClose={()=>dispatch(setDialogBox(null))} maxWidth="xl">
    <div className="billing-payment-ctn-left items-table-popup" style={{width:"75vw",maxWidth:"1400px"}}>
        <h3>Offers</h3>
        <div className="products-list">
                <div className="special-items">
                  <div className="category-list-menu">
                    {offerMenuLoading ? (
                      [...Array(10)].map((r) => (
                        <div key={r} className="tab">
                          <Skeleton
                            width={"100%"}
                            height={"40px"}
                            sx={{ backgroundColor: "#fff" }}
                          />
                        </div>
                      ))
                    ) :offerListCategory.length === 0 ? (
                        [...Array(10)]?.map((r, i) => {
                            let color="#000";
                            if(offerMenuColors[i]){
                                color=offerMenuColors[i]
                            }else{
                                color=getRandomColor()
                                setOfferMenuColors((prevValues)=>({...prevValues,[i]:color}))
                            }
                        return(
                            <div
                                key={i}
                                className="tab"
                                style={{ color: color, borderColor: color,backgroundColor:offerSelected === i && `rgba(${color.substring(5, color.length - 1)}, 0.2)` }}
                                onClick={() => setOfferSelected(offerSelected === i?null:i)}
                            >
                                {r?.name || "Sthreni 50%"}
                                {offerSelected === i && <ArrowRightIcon fontSize='large'/>}
                            </div>
                        )
                        })
                    ) : (
                      <div className="tab" style={{ justifyContent: "center" }}>
                        Offer categories not found
                      </div>
                    )}
                  </div>
                </div>
                <div
                  onScroll={handleScroll}
                  className="cards"
                  style={{ width: "75%", marginTop: "0" }}
                >
                  {tableLoading ? (
                    [...Array(10)].map((r, i) => (
                      <div key={i} className="billing-single-product-container">
                        <Skeleton width={"100%"} height="100px" />
                        <Skeleton width={"100%"} height="20px" />
                        <div className="billing-product-list-select-container">
                          <Skeleton width={"60%"} height="20px" />
                          <Skeleton width={"30%"} height="30px" />
                        </div>
                      </div>
                    ))
                  ) : offerProductsList.length === 0 ? (
                    [...Array(100)]?.map((product, index) => (
                      <div
                        className="card-content"
                        key={index}
                        style={{ maxHeight: "fit-content" }}
                      >
                        {
                          <img
                            src={
                              product?.imageUrl || dummyProductItemImg
                            }
                            alt="No Img"
                            className="product-image "
                          />
                        }
                        <div className="card-details">
                          <h1>{product?.productName || "Paste"}</h1>
                            <p className='justify-space-between'>
                                <span>
                                    {product?.salesPrice?.toFixed(decimalPosition) || "120.00"}
                                </span>
                                <span>
                                    500 g
                                </span>
                            </p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="center-div" style={{ height: "61vh" }}>
                      No Products Available !!
                    </div>
                  )}
                    {
                    tableLoading &&
                    [...Array(10)].map((r, i) => (
                      <div key={i} className="billing-single-product-container">
                        <Skeleton width={"100%"} height="60px" />
                        <Skeleton width={"100%"} height="20px" />
                        <div className="billing-product-list-select-container">
                          <Skeleton width={"60%"} height="20px" />
                          <Skeleton width={"30%"} height="30px" />
                        </div>
                      </div>
                    ))}
                </div>
                <div className="bottom-button-ctn">
                    <button 
                    onClick={()=>dispatch(setDialogBox(null))}
                    className='cancel-btn'>CANCEL</button>
                    <button className='add-btn'>ADD</button>
                </div>
        </div>
    </div>
</Dialog>
  )
}

export default BillingOffersPopup