import React, { useEffect, useState } from 'react'
import CustomPagination from '../../../../Single Components/CustomPagination'
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter'
import { IconButton, Tooltip } from '@mui/material'
import ReactToPrint from 'react-to-print'
import { ListPosWalletAndPaymentAPI } from '../GeneralAPI'
import { useSelector } from 'react-redux'
import { getCurrentTime } from '../../../../../Js/Date'

const PosPayment = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [FormDataInfo, setFormDataInfo] = useState([])
  const userRole = useSelector((state) => state.userRoleSlice.value);

  const handleDataFromChild = (data) => {
    setFormDataInfo(data)

};
const handlePageChange = (page) => {
    
  setCurrentPage(page);
};
const paymentList=useSelector((state)=>state.generalSlice.PaymentAndWalletList)
const totalPages = paymentList?.pages; 
useEffect(()=>{
  if (FormDataInfo.length !== 0) {
  ListPosWalletAndPaymentAPI({
    fromDate: FormDataInfo.fromDate === '' ? undefined : `${FormDataInfo.fromDate} ${getCurrentTime()}`,
    endDate: FormDataInfo.toDate === '' ? undefined : `${FormDataInfo.toDate} ${getCurrentTime()}`,
    branchId: FormDataInfo?.branchList?.length === 0 ? undefined:FormDataInfo?.branchList,
    status: FormDataInfo?.status?.length === 0 ? undefined : FormDataInfo?.status[0],
    search: FormDataInfo?.search===''? undefined : FormDataInfo?.search,
    paymentMethod:FormDataInfo?.PaymentType ==='' ? undefined : FormDataInfo?.PaymentType,
        isWallet:false,
     index:currentPage-1})
  }
},[FormDataInfo,currentPage])

  return (
    <div className='global-page-parent-container'>
        <div
        style={{padding:"0 1%"}}
        className="justify-space-between global-white-bg-container">
            <h3 style={{margin:"0"}}>Payment</h3>
        
        <div className="justify-center" style={{gap:"10px",width:"70%",justifyContent:"flex-end"}}>
        <ReactToPrint
          trigger={() => (
            <IconButton className="printer-div" style={{color: "#fff",backgroundColor:"#002995",borderRadius:"0"}}>
              <i class="bi bi-printer"></i>
            </IconButton>
          )}
        //   content={() => paymentsPrint}
        />
        <Tooltip title="Download">
          <IconButton style={{backgroundColor: "#ffd2d2", color: "#002995",borderRadius:"0"}}>
            <i class="bi bi-arrow-down-circle"></i>
          </IconButton>
        </Tooltip>
        <CategorySearchandFilter
            statusOptions={['DONE', 'DRAFT']}
            onData={handleDataFromChild}
            isStatus={true}
            isType={true}
            isPaymentType={true}
            
            isBranch={userRole==="admin"}

        />
        </div>
        </div>

        
        <div className="global-white-bg-container" style={{paddingTop:"0"}}>
            <div className="justify-center" style={{justifyContent:"flex-end"}}>
                <CustomPagination
                 currentPage={currentPage}
                 totalPages={totalPages}
                 onPageChange={handlePageChange}
                />
            </div>
            <div className="new-global-table-container">
            <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Sequence</th>
                <th>Order Ref</th>
                <th>Customer</th>
                <th>Branch</th>
                <th>Shift</th>
                <th>Employee</th>
                <th>Payment Methord</th>
                <th>Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
                { paymentList?.list!==undefined ?
                    paymentList?.list?.map((r,i)=>(
                        <tr key={i} >
                            <td>{r.paymentDate}</td>
                            <td>{r.sequence}</td>
                            <td>{r.orderReference}</td>
                            <td>{r.customerName}</td>
                            <td>{r.branchName}</td>
                            <td>{r.shiftId}</td>
                            <td>{r.employeeName}</td>
                            <td>{r.paymentMethod}</td>
                            <td>{r.paidAmount}</td>
                            <td className="table-data-box">
                                {r?.status === "DRAFT" ? (
                                    <span className="draft"> DRAFT</span>
                                ) : r?.status === "DONE" ? (
                                    <span className="post"> DONE</span>
                                ) : r?.status === "RETURN" ? (
                                    <span className="cancel">RETURN</span>
                                ) : (
                                    <span>{r?.status}</span>
                                )}
                            </td>
                        </tr>
                    )):
                    <tr>
                      <td colSpan={9}>NO DATA</td>
                    </tr>
                }
            </tbody>
            </table>
            </div>
        </div>
    </div>
  )
}

export default PosPayment