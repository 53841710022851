import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { findObjFromList } from '../../../../../Js/generalFunctions';
import { PurchaseReportNewSingleAPI } from './purchaseReportNewAPI';
import { getCurrentTime } from '../../../../../Js/Date';
import Template from "./purchaseReportSinglePrint"
import { IconButton, Tooltip } from '@mui/material';
import ReactToPrint from 'react-to-print';

export const PurchaseReportSingleView = () => {
    let navigate = useNavigate();
    let paymentsPrint = useRef();

    const [symbol, setSymbol] = useState(null);
    const name = localStorage.getItem("name")
    const id = localStorage.getItem("singleViewId")
    const fromDate = localStorage.getItem("fromDate")
    const toDate = localStorage.getItem("toDate")
    const branch = localStorage.getItem("branch")

    const singleView = useSelector((state) => state?.purchaseRport?.purhaseReportNwSingleList)

    const decimalPosition = localStorage.getItem("decimalPosition");

    const currencyListUpdated = useSelector(
        (state) => state?.viewUpdatedCurrency?.value
    );
    const companyProfileData = useSelector(
        (state) => state?.companyProfileSlice?.userValue
    );
    const handleback = () => {
        navigate("/userdashboard/purchasemanager/PurchaseReport/PurchaseReportNew")
    }

    useEffect(() => {
        let currencyObj = findObjFromList(
            "_id",
            currencyListUpdated,
            "currency",
            companyProfileData
        );
        setSymbol(currencyObj?.symbol);
    }, [companyProfileData, currencyListUpdated]);

    useEffect(() => {
        PurchaseReportNewSingleAPI({
            branchId: branch,
            fromDate: fromDate === '' ? '' : `${fromDate} ${getCurrentTime()}`,
            endDate: toDate === '' ? '' : `${toDate} ${getCurrentTime()}`,
            productId: id
        })
    }, [])

    return (
        <div className="global-page-parent-container" style={{ margin: "0" }}>
            <div className="global-white-bg-container">
                <div style={{ paddingBottom: "25px", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                    <div>
                        <div className="sharp-arrow" onClick={() => handleback()}> <p>Purchase Report</p></div>
                        <div className="sharp-arrow" style={{ marginLeft: "18px", width: "auto" }}> <p>{name}</p></div>
                    </div>
                    <div>
                        <Tooltip title="Share">
                            <IconButton>
                                <i
                                    class="bi bi-share"
                                    style={{
                                        backgroundColor: "#9797e9",
                                        color: "white",
                                        fontSize: "medium",
                                        padding: "8px",
                                        borderRadius: "2px",
                                    }}
                                ></i>
                            </IconButton>
                        </Tooltip>

                        <ReactToPrint
                            trigger={() => (
                                <IconButton className="printer-div" style={{ padding: "1px" }}>
                                    <i
                                        style={{
                                            color: "white",
                                            backgroundColor: " #d787e7",
                                            fontSize: "medium",
                                            padding: "8px",
                                            borderRadius: "2px",
                                        }}
                                        class="bi bi-printer"
                                    ></i>
                                </IconButton>
                            )}
                            content={() => paymentsPrint}
                        />

                        <Tooltip title="Download">
                            <IconButton>
                                <i
                                    class="bi bi-arrow-down-circle"
                                    style={{
                                        backgroundColor: "rgb(255 118 133 / 69%)",
                                        color: "white",
                                        fontSize: "medium",
                                        padding: "8px",
                                        borderRadius: "2px",
                                    }}
                                ></i>
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <div className='global-report-table-container' style={{ maxHeight: "80vh" }}>
                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Vendor</th>
                                <th>Bill No.</th>
                                <th>Qty</th>
                                <th>Unit Price</th>
                                <th>Total</th>
                            </tr>

                        </thead>
                        <tbody>

                            {singleView?.table?.map((item, index) => (
                                <tr key={index} style={{ height: "38px" }}>
                                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.date}</td>
                                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.vendorName}</td>
                                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.invoiceNo}</td>
                                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{item.qty}</td>
                                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{symbol}&nbsp;{item.unitPrice}</td>
                                    <td style={{ backgroundColor: "white", border: "0.8px solid #c3d4f7" }}>{symbol}&nbsp;{item.totalAmount}</td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan="5">Total</th>
                                <th>{symbol}&nbsp;{singleView?.amountTotal}</th>

                            </tr>
                        </tfoot>
                    </table>

                </div>
            </div>
            <div style={{ display: "none" }}>
                <div ref={(el) => (paymentsPrint = el)}>

                    <Template singleView={singleView} symbol={symbol} />

                </div>
            </div>
        </div>

    )
}



