import React, { useEffect, useState } from 'react'
import { IconButton, Skeleton, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CustomPagination from '../../../../Single Components/CustomPagination';
import { filterObjFromList } from '../../../../../Js/generalFunctions';
import { viewAllCurrencyUpdated } from '../../../../../API/Settings/Currency/CurrencyAPI';
import { companyProfileInfoForUser } from '../../../../../API/Settings/Company Settings/companySettingsAPI';
import { set_Back_button_action } from '../../../../Single Components/listSlice/filterCategorySlice';
import { PayableDueReportSingleViewAPIcall } from './partnerReportApi';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import { convertDateFormat, today } from '../../../../../Js/Date';
import ReactToPrint from 'react-to-print';

function PayableDueReportSingleView() {
    let navigate = useNavigate();
    let location = useLocation()
    const dispatch = useDispatch();

    const currencyListUpdated = useSelector(
        (state) => state.viewUpdatedCurrency.value
    );
    const companyProfileData = useSelector(
        (state) => state?.companyProfileSlice?.userValue)

        const SingleListData = useSelector((state) => state?.PartnerReport?.PayableDueReportSingleView)


    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [symbol, setSymbol] = useState(null);
    const [FormDataInfo, setFormDataInfo] = useState([])
    const singleViewId = localStorage.getItem("payableSingleViewId")

    const totalPages = 10;
    const decimalPosition = localStorage.getItem("decimalPosition");


    const handlePageChange = (page) => {

        setCurrentPage(page);
    };
    const handleDataFromChild = (data) => {
        setFormDataInfo(data)
    };

    const handleClick = () => {
        localStorage.removeItem("payableSingleViewId")
        navigate('/userdashboard/accounts/report/partnerReport/PayableDueReport');
        dispatch(set_Back_button_action({ backButtonClickAction: true }));
    };


    useEffect(() => {

        PayableDueReportSingleViewAPIcall({
        customerId: singleViewId,
        todayDate: FormDataInfo?.toDate === '' ? `${today} 23:59:59` : `${FormDataInfo?.toDate} 23:59:59`,
        search: FormDataInfo?.search,
        index: currentPage-1,

      })
    }, [FormDataInfo,singleViewId])

    useEffect(() => {
        let currencyObj = filterObjFromList("_id", currencyListUpdated, "currency", companyProfileData)
        setSymbol(currencyObj?.symbol)
    }, [companyProfileData, currencyListUpdated])

    useEffect(() => {
        viewAllCurrencyUpdated({ status: 0 });
        companyProfileInfoForUser();
    }, [])
  return (
    <div className="global-page-parent-container"  style={{ margin: "0" }}>

    <div className="global-white-bg-container" style={{ backgroundColor: "unset", padding: "5px" }}>
        <div className='create-button-blue-container' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end', backgroundColor: "white", paddingInline: "5px", margin: "0" }}>
            <div style={{ alignSelf: "center" }}>
                <h3 style={{ display: 'inline-block' }}>
                    <span
                        style={{ color: 'gray', cursor: 'pointer' }}
                        onClick={handleClick}
                    >
                        Payable Due Report
                    </span>
                    <ArrowForwardIosIcon style={{ fontSize: '0.9rem' }} />
                    {SingleListData?.customerName}
               
                </h3>
            </div>

            <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '50%'
            }}>


                  <CategorySearchandFilter
                    onData={handleDataFromChild}
                    style={{ width: "100%" }}
                    isFromDate={false}

                />
                 <Tooltip title="Share">
                            <IconButton>
                                <i class="bi bi-share" style={{ backgroundColor: "#9797e9", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
                            </IconButton>
                        </Tooltip>

                        <ReactToPrint
                            trigger={() => (
                                <IconButton className="printer-div" style={{ padding: "1px" }}>
                                    <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
                                </IconButton>
                            )}
                        //   content={() => paymentsPrintNew}
                        />

                        <Tooltip title="Download">
                            <IconButton  >
                                <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
                            </IconButton>
                        </Tooltip>
            </div>

        </div>

        
        <div className="customer-receipt-table-container" style={{ marginTop: "4px" }}>
        <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        }}>
            <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}

            />
        </div>
            <table>
                <thead>
                    <tr>

                        <th>Reference</th>
                        <th>Invoice Date</th>
                        <th>Due Date</th>
                        <th>Due Days</th>
                        <th>Not Due Amount</th>
                        <th>Over Due 0-30</th>
                        <th>Over Due 31-60</th>
                        <th>Over Due 61-90</th>
                        <th>Over Due 91-120</th>
                        <th>Over Due 120 Above</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                  
                    {isLoading ? (
        [...Array(10)].map((r, i) => (
          <tr key={i}>
            <td colSpan={8}>
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={"100%"}
                height={40}
              />
            </td>
          </tr>
        ))
      ) : SingleListData?.list !== undefined && SingleListData?.list?.length !== 0 ? (
        SingleListData?.list
          ?.slice(0)
          ?.map((r, i) => (
            <tr key={i}>
              
              <td>{r?.name}</td>
              <td>{convertDateFormat(r?.billDate)}</td>
              <td>{convertDateFormat(r?.dueDate)}</td>
              <td>{r?.days}</td>
              <td>{symbol}&nbsp;{r?.categories?.current?.toFixed(decimalPosition)}</td>
              <td>{symbol}&nbsp;{r?.categories?.upto30days?.toFixed(decimalPosition)}</td>
              <td>{symbol}&nbsp;{r?.categories?.upto60days?.toFixed(decimalPosition)}</td>
              <td>{symbol}&nbsp;{r?.categories?.upto90days?.toFixed(decimalPosition)}</td>
              <td>{symbol}&nbsp;{r?.categories?.upto120days?.toFixed(decimalPosition)}</td>
              <td>{symbol}&nbsp;{r?.categories?.morethan120days?.toFixed(decimalPosition)}</td>
              <td>{symbol}&nbsp;{r?.total?.toFixed(decimalPosition)}</td>

            </tr>
          ))
      ) : (
        <tr>
          <td style={{ textAlign: 'center' }} colSpan={9}>No Data</td>
        </tr>
      )}

                </tbody>
                <tfoot >
                    <tr>
                        <th style={{ textAlign: 'start', fontSize: '1rem', paddingLeft: '3%' }} colSpan={4}>TOTAL</th>
                        <th >{symbol}&nbsp;{SingleListData !== undefined && SingleListData?.sumOfCurrent?.toFixed(decimalPosition)}</th>
                        <th >{symbol}&nbsp;{SingleListData !== undefined && SingleListData?.sumOf30?.toFixed(decimalPosition)}</th>
                        <th >{symbol}&nbsp;{SingleListData !== undefined && SingleListData?.sumOf60?.toFixed(decimalPosition)}</th>
                        <th >{symbol}&nbsp;{SingleListData !== undefined && SingleListData?.sumOf90?.toFixed(decimalPosition)}</th>
                        <th >{symbol}&nbsp;{SingleListData !== undefined && SingleListData?.sumOf120?.toFixed(decimalPosition)}</th>
                        <th >{symbol}&nbsp;{SingleListData !== undefined && SingleListData?.sumOfAbove120?.toFixed(decimalPosition)}</th>
                      
                        <th >{symbol}&nbsp;{SingleListData !== undefined && SingleListData?.sumOfTotal?.toFixed(decimalPosition)}</th>
                    </tr>

                </tfoot>
            </table>
        </div>
    </div>
</div>
  )
}

export default PayableDueReportSingleView