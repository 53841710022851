import { createSlice } from "@reduxjs/toolkit";

export const ReportSlice=createSlice({
    name:'posReportshiftSlice',
    initialState:{
        shiftReport:undefined,
        shiftReportSale:undefined,
        shiftReportWalletPay:undefined,
        shiftReporPaymentSummary:undefined,
        salesReport:undefined,
        shiftReporPayment:undefined,
        cashCardReport:undefined,
        aggregatorReport:undefined

    },
    reducers:{
        get_shift_report_list:(state,action)=>{
            state.shiftReport=action.payload
        },
        get_shift_report_sale:(state,action)=>{
            state.shiftReportSale=action.payload
        },
        get_shift_report_wallet_pay:(state,action)=>{
            state.shiftReportWalletPay=action.payload
        },
        get_shift_report_payment:(state,action)=>{
            state.shiftReporPayment=action.payload
        },
        get_shift_report_payment_summary:(state,action)=>{
            state.shiftReporPaymentSummary=action.payload
        },
        get_sales_details_list:(state,action)=>{
            state.salesReport=action.payload
        },
       //cash card summary
        get_cash_card_report:(state,action)=>{
             state.cashCardReport=action.payload
         },
         //aggregator
        get_aggregator_report:(state,action)=>{
             state.aggregatorReport=action.payload
         },

    }

})
export const {get_shift_report_list,
    get_shift_report_sale,
    get_shift_report_wallet_pay,
     get_shift_report_payment_summary,
     get_sales_details_list,
     get_shift_report_payment,
     get_aggregator_report,
     get_cash_card_report}=ReportSlice.actions
export default ReportSlice.reducer