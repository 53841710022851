import axios from "axios"
import store from "../../../../../Redux/store"
import { setBillLoading, setDialogBox, setErrorSnack, setOrderIdInPOS, setSingleOrderInfo, setSnackMsg, setSuccessSnack, setUpdateOrder } from "../Slice/billingScreenSlice"
import { HEADERS } from "../../../../../API/UrlAndPaths"
import { setAggregator, setAggregatorRef, setBtnSelected, setDefaultCustomer, setSingleCusInfo } from "../Components/topBarSliceBilling"
import { setCartLoading } from "../Slice/viewOrdersBillingSlice"



// add pos order without chair selected
export const addPOSOrderAPI=async(body,finalPlaceOrder)=>{
   
    store.dispatch(setBillLoading(true))
    await axios.post("pos/addPosOrder",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(setBillLoading(false))                
                store.dispatch(setDialogBox(false))                
                finalPlaceOrder(res.data?._id)
            }
        })
        .catch((err)=>{
            let message=err.response.status===500?"Internal Server Error !!":err.response.status===400?"Wrong data passed!!":err.response.data
                store.dispatch(setSnackMsg(message))
                store.dispatch(setBillLoading(false))                
                store.dispatch(setErrorSnack(true))
        })
}


// hold order
export const holdPOSOrderAPI=async(body,clearFn)=>{
    store.dispatch(setBillLoading(true))
    await axios.post("pos/addPosOrderHold",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(setBillLoading(false))                
                store.dispatch(setDialogBox(false))                
                store.dispatch(setSnackMsg("Order Holded"))
                store.dispatch(setSuccessSnack(true))
                clearFn()
                store.dispatch(setAggregator(null))
                store.dispatch(setAggregatorRef(null))
            }
        })
        .catch((err)=>{
            let message=err.response.status===500?"Internal Server Error !!":err.response.status===400?"Wrong data passed!!":err.response.data
                store.dispatch(setSnackMsg(message))
                store.dispatch(setBillLoading(false))                
                store.dispatch(setErrorSnack(true))
        })
}
// convertt an hold order into an order

// add pos order without chair selected
export const postHoldOrderAPI=async(body,finalPlaceOrder)=>{
   
    store.dispatch(setBillLoading(true))
    await axios.post("pos/postOrderFromFloorPlan",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(setOrderIdInPOS(res.data?._id))  
                store.dispatch(setBillLoading(false))                
                store.dispatch(setDialogBox(false))                
                finalPlaceOrder(res.data?._id)
            }
        })
        .catch((err)=>{
            let message=err.response.status===500?"Internal Server Error !!":err.response.status===400?"Wrong data passed!!":err.response.data
                store.dispatch(setSnackMsg(message))
                store.dispatch(setBillLoading(false))                
                store.dispatch(setErrorSnack(true))
        })
}

export const updatePosOrderAPICall=async(body,clearFn,orderType)=>{
    store.dispatch(setBillLoading(true))
    await axios.post("pos/addItemsToExistingOrders",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(setOrderIdInPOS(null))   
                store.dispatch(setSnackMsg("Order Updated 👍"))
                store.dispatch(setBillLoading(false))                
                store.dispatch(setDialogBox(false))                
                store.dispatch(setSuccessSnack(true))   
                store.dispatch(setUpdateOrder(false))   
                if(orderType==="paymentOrder"){
                    store.dispatch(setBtnSelected("payment"))   

                    setTimeout(() => {
                        store.dispatch(setOrderIdInPOS(res.data?._id))   
                    }, 0);
                }   
                store.dispatch(setAggregator(null))
                store.dispatch(setAggregatorRef(null))

                clearFn()
            }
        })
        .catch((err)=>{
            let message=err.response.status===500?"Internal Server Error !!":err.response.data
                store.dispatch(setSnackMsg(message))
                store.dispatch(setBillLoading(false))                
                store.dispatch(setErrorSnack(true))
        })
}

// get single order info

export const singlePOSOrderInfoAPICall=async(body)=>{
    store.dispatch(setCartLoading(true))
    await axios.post("pos/viewPosOrderById",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(setSingleOrderInfo(res.data))
                store.dispatch(setCartLoading(false))
            }
        })
        .catch((err)=>{
            store.dispatch(setSingleOrderInfo(null))
                store.dispatch(setCartLoading(false))
        })
}



// default customer api
export const defaultCustomerForPOSAPI=async(body)=>{
    await axios.post("customer/viewCashCustomer",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(setDefaultCustomer(res.data))
        }
    })
    .catch((err)=>{
        store.dispatch(setDefaultCustomer(null))
    })

}
// get customer single info

export const getSingleCustomerInfoAPICall=async(body)=>{
    await axios.post("customer/getSingleVendorDetails",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(setSingleCusInfo(res.data))
            }
            store.dispatch(setSingleCusInfo(res.data))
        })
        .catch((err)=>{
            store.dispatch(setSingleCusInfo(null))
        })
}