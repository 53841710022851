import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import { PurchaseReportNewAPI } from './purchaseReportNewAPI';
import { IconButton, Skeleton, Tooltip } from '@mui/material';
import ReactToPrint from 'react-to-print';
import Template from "./PurchaseReportPrint"
import { setFilterActive } from '../../../../Single Components/listSlice/filterCategorySlice';
import store from '../../../../../Redux/store';
import { get_purchase_report_single_list } from './purchaseReportNewSlice';

const PurchaseReportNew = () => {
    let navigate = useNavigate();
    const [symbol, setSymbol] = useState(null);
    const [FormDataInfo, setFormDataInfo] = useState([])
    let paymentsPrint = useRef();

    const {currencyDetails,tableLoading} = useSelector((state) => state.mainDashBoardSlice)
    const filtereDataOptions = useSelector((state) => state.listCategory);


    const decimalPosition = localStorage.getItem("decimalPosition");


    const purchaseList = useSelector((state => state?.purchaseRport?.purhaseReportNwList))



    const handleDataFromChild = (data) => {
        setFormDataInfo(data)
    };
    const handleRow = (row) => {
        store.dispatch(get_purchase_report_single_list({purchaseReportNwSingleData:undefined}))

        store.dispatch( setFilterActive(window.location.pathname))
        localStorage.setItem("singleViewId", row._id)
        localStorage.setItem("name", row.itemName)
        localStorage.setItem("fromDate", FormDataInfo?.fromDate);
        localStorage.setItem("toDate", FormDataInfo?.toDate);
        localStorage.setItem("branch", FormDataInfo?.branchList?.length === 0 ? '' : FormDataInfo?.branchList[0])
        navigate("/userdashboard/purchasemanager/PurchaseReport/PurchaseReportNew/SingleView")
    }

    useEffect(() => {
        setSymbol(currencyDetails?.symbol);
    }, [currencyDetails]);

    useEffect(() => {
        if (FormDataInfo?.length !== 0) {
            let body = {
                fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
                endDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
                branchId: FormDataInfo?.branchList?.length === 0 ? '' : FormDataInfo?.branchList[0],
                search: FormDataInfo?.search,
            }

            let loadData=window.location.pathname===filtereDataOptions?.filterActive
            !loadData && PurchaseReportNewAPI(body);
        }
    }, [FormDataInfo]);


    return (
        <div className="global-page-parent-container" style={{ margin: "0" }}>
            <div className="global-white-bg-container">
                <div className="justify-space-between" >
                    <div className="sharp-arrow" style={{ width: "125Px" }}> <p>Purchase Report</p></div>

                    <div style={{ display: "flex" }}>
                        <Tooltip title="Share">
                            <IconButton>
                                <i
                                    class="bi bi-share"
                                    style={{
                                        backgroundColor: "#9797e9",
                                        color: "white",
                                        fontSize: "medium",
                                        padding: "8px",
                                        borderRadius: "2px",
                                    }}
                                ></i>
                            </IconButton>
                        </Tooltip>

                        <ReactToPrint
                            trigger={() => (
                                <IconButton className="printer-div" style={{ padding: "1px" }}>
                                    <i
                                        style={{
                                            color: "white",
                                            backgroundColor: " #d787e7",
                                            fontSize: "medium",
                                            padding: "8px",
                                            borderRadius: "2px",
                                        }}
                                        class="bi bi-printer"
                                    ></i>
                                </IconButton>
                            )}
                            content={() => paymentsPrint}
                        />

                        <Tooltip title="Download">
                            <IconButton>
                                <i
                                    class="bi bi-arrow-down-circle"
                                    style={{
                                        backgroundColor: "rgb(255 118 133 / 69%)",
                                        color: "white",
                                        fontSize: "medium",
                                        padding: "8px",
                                        borderRadius: "2px",
                                    }}
                                ></i>
                            </IconButton>
                        </Tooltip>
                        <div>
                            <CategorySearchandFilter
                                statusOptions={['PURCHASE ORDER', 'DONE', 'CANCEL']}
                                onData={handleDataFromChild}
                                onBranchSelect={()=>{}}
                            />

                        </div>
                    </div>
                </div>
                <h1 style={{ margin: "1px", fontSize: "large" }}>Purchase Report</h1>

                <div className="global-report-table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th >UOM</th>

                                <th >Purchase Qty</th>
                                <th >Purchase Return Qty</th>
                                <th >Total Quatity</th>
                                <th >Average Unit Cost</th>
                                <th >Average Total Value</th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                            tableLoading?
                            ([...Array(10)].map((_,i)=>(
                                <tr key={i}>
                                    <td colSpan={10}>
                                        <Skeleton
                                            variant="rectangular"
                                            animation="wave"
                                            width={"100%"}
                                            height={30}
                                        />
                                    </td>
                                </tr>
                            )))
                            :purchaseList!==undefined? purchaseList.map((item, index) => (
                                <tr style={{cursor:"pointer"}} key={index} onClick={() => handleRow(item)}>
                                    <td style={{ textAlignLast: "start", paddingLeft: "10px" }}>{item.itemName}</td>
                                    <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.uom}</td>

                                    <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.purchaseQty?.toFixed(decimalPosition)}</td>
                                    <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.purchaseRet?.toFixed(decimalPosition)}</td>
                                    <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{item.totalQuantity?.toFixed(decimalPosition)}</td>
                                    <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{symbol}&nbsp;{item.cost?.toFixed(decimalPosition)}</td>
                                    <td style={{ textAlignLast: "end", paddingRight: "10px" }}>{symbol}&nbsp;{item.totalValue?.toFixed(decimalPosition)}</td>
                                </tr>
                            ))
                            :
                            <tr>
                                <td colSpan={7}>No data</td>
                            </tr>
                            }
                        </tbody>
                        {
                        purchaseList &&    
                        <tfoot>
                            <tr>
                                <th colSpan={2}>Total</th>
                                <th >{symbol}&nbsp;{purchaseList?.reduce((acc, row) => acc + row.purchaseQty, 0)?.toFixed(decimalPosition)}</th>
                                <th >{symbol}&nbsp;{purchaseList?.reduce((acc, row) => acc + row.purchaseRet, 0)?.toFixed(decimalPosition)}</th>
                                <th >{symbol}&nbsp;{purchaseList?.reduce((acc, row) => acc + row.totalQuantity, 0)?.toFixed(decimalPosition)}</th>
                                <th >{symbol}&nbsp;{purchaseList?.reduce((acc, row) => acc + row.cost, 0)?.toFixed(decimalPosition)}</th>
                                <th >{symbol}&nbsp;{purchaseList?.reduce((acc, row) => acc + row.totalValue, 0)?.toFixed(decimalPosition)}</th>
                            </tr>
                        </tfoot>}
                    </table>
                </div>
            </div>
            <div style={{ display: "none" }}>
                <div ref={(el) => (paymentsPrint = el)}>

                    <Template purchaseList={purchaseList} symbol={symbol} />

                </div>
            </div>
        </div>

    )
}

export default PurchaseReportNew