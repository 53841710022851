import React, { useEffect, useState } from 'react'
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter'
import CustomPagination from '../../../../Single Components/CustomPagination';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ListRewardsAPI, deleteRewardApiCall } from '../GeneralAPI';
import { get_Rewards_single_view } from '../GeneralSlice';
import store from '../../../../../Redux/store';

const PosReward = () => {
  let navigateTo = useNavigate();

    const [FormDataInfo, setFormDataInfo] = useState([])
    const [currentPage, setCurrentPage] = useState(1);

    const rewardlist=useSelector((state)=>state.generalSlice.listRewards)
    const totalPages = (""); 
    const handleDataFromChild = (data) => {
        setFormDataInfo(data)
        console.log(data);
    };
    const handlePageChange = (page) => {
    
        setCurrentPage(page);
      };
      const createReward=()=>{
        localStorage.removeItem("row_id")
        store.dispatch( get_Rewards_single_view(undefined))
        navigateTo("/userdashboard/pointOfSales/general/Rewards/create")
    }
    const rowClick=(id)=>{
        localStorage.setItem("row_id",id)
        navigateTo("/userdashboard/pointOfSales/general/Rewards/create")

    }

    const deleteDataRow=(id)=>{
        deleteRewardApiCall(id)
    }

    useEffect(()=>{
        ListRewardsAPI()
    },[])
  return (
    <div className='global-page-parent-container'>
    <div
        style={{ padding: "0 1%" }}
        className="justify-space-between global-white-bg-container">
        <h3 style={{ margin: "0" }}>Rewards</h3>

        <div className="justify-center" style={{ gap: "10px", width: "70%", justifyContent: "flex-end" }}>


            <CategorySearchandFilter
                statusOptions={['DONE', 'DRAFT']}
                onData={handleDataFromChild}
                isStatus={true}
                isType={true}
                isPaymentType={true}
                onBranchSelect={() => { }}
            // isBranch={userRole === "admin"}
            />

            <button className='create-button-blue' onClick={createReward}>Create</button>

        </div>
    </div>
<div className="global-white-bg-container" style={{paddingTop:"0"}}>
<div className="justify-center" style={{justifyContent:"flex-end",padding:"12px 14px 12px 0px"}}>
    <CustomPagination
       
       currentPage={currentPage}
       totalPages={totalPages}
       onPageChange={handlePageChange}
      
    />
</div>
    <div className="new-global-table-container">
        <table>
            <thead style={{backgroundColor:"#dbe0fb"}}>
                <tr>
                    <th>Name</th>
                    <th>Branch Type</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {rewardlist?.map((item,index)=>(
                <tr style={{height:"5vh"}} key={index} onClick={()=>rowClick(item._id)}>
                    <td>{item.name}</td>
                    <td>
                        {item?.allowBranches?.map((branch,i)=>(
                            <span key={i}>{branch?.branchName}{i!==item?.branch?.length-1 ? " ":" ,"}</span>
                        ))}
                    </td>
                   
                    
                    <td>   <DeleteIcon color="error" onClick={(e) => {
                            e.stopPropagation(); // Stop event propagation to the parent row
                            deleteDataRow(item?._id)}}/></td>
                </tr>
                ))}
            </tbody>
        </table>
    </div>
    </div>
</div>
  )
}

export default PosReward