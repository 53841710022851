import React, { useRef, useState } from 'react';
import "./billingItems.css";
import SearchIcon from "@mui/icons-material/Search";
import { Input, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import { useDispatch, useSelector } from 'react-redux';
import { setCartObj, setDialogBox } from '../Slice/billingScreenSlice';

const BillingItems = () => {
  const dispatch = useDispatch();

  const decimalPosition = localStorage.getItem("decimalPosition");
  const { productsList } = useSelector((state) => state.newBillingSlice);
  const { cartObj } = useSelector((state) => state.billingScreenSlice);

  const processedData = productsList.map((product, index) => ({
    ...product,
    salesPrice: product?.salesPrice?.toFixed(decimalPosition),
    index: index + 1, 
  }));
  
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleRowClick = (record) => {
    dispatch(setDialogBox(null));
    if (cartObj === null) {
      dispatch(setCartObj(record));
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (dataIndexArray) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{ padding: 8 }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndexArray.join(' & ')}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndexArray)}
          style={{ marginBottom: 8, display: 'block' }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchIcon style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      return dataIndexArray.some(dataIndex => 
        record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase())
      );
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text, record) =>
      dataIndexArray.some(dataIndex => searchedColumn === dataIndex) ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={dataIndexArray.map(dataIndex => record[dataIndex]?.toString() || '').join(' ')}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      key: 'index',
      title: 'S NO',
      className: 'black-th',
      dataIndex: 'index',
      width: '15%',
      sorter: (a, b) => a.index - b.index,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Name & ID',
      dataIndex: 'productName',
      key: 'productName',
      width: '30%',
      className: 'black-th',
      sorter: (a, b) => a.productName.localeCompare(b.productName),
      backgroundColor: '#000',
      ...getColumnSearchProps(['productName', 'barcode']),
    },
    {
      title: 'UOM',
      dataIndex: 'uomName',
      className: 'other-th',
      key: 'uomName',
      width: '25%',
      ...getColumnSearchProps(['uomName']),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Price',
      dataIndex: 'salesPrice',
      className: 'other-th',
      key: 'salesPrice',
      width: '25%',
      ...getColumnSearchProps(['salesPrice']),
      sorter: (a, b) => a.salesPrice - b.salesPrice,
      sortDirections: ['descend', 'ascend'],
    }
  ];

  return (
    <div className="pos-billing-items-container">
      <h2>Product List</h2>
      <Table 
        columns={columns} 
        dataSource={processedData}  
        rowKey="_id"
        scroll={{ y: "60vh" }} 
        onRow={(record, rowIndex) => {
          return {
            onClick: () => handleRowClick(record)
          };
        }}
      />
    </div>
  );
};

export default BillingItems;
