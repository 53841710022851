import { IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { Datepicker } from "../../../../Single Components/Datepicker";
import { ViewBalanceSheetAPIcall } from "./TrailBalanceAPI";
import { LoadingForm } from "../../../../Single Components/LoadingForm";
import { today } from "../../../../../Js/Date";
import { viewAllCurrencyUpdated } from "../../../../../API/Settings/Currency/CurrencyAPI";
import { companyProfileInfoForUser } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import { filterObjFromList } from "../../../../../Js/generalFunctions";
import "./BalanceSheet.css";
import * as XLSX from "xlsx";

const BalanceSheetNew = () => {
  let componentRef = useRef();
  const ExcelJS = require("exceljs");
  const userInfo = useSelector((state) => state.loginResponseSlice.value);
  const decimalPosition = localStorage.getItem("decimalPosition");
  const balanceSheetListData = useSelector(
    (state) => state?.trailbalance?.balanceSheetListData
  );
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );
  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );

  const [loading, setLoading] = useState(false);
  const [symbol, setSymbol] = useState(null);
  const [updatedBalanceSheetArray, setUpdatedBalanceSheetArray] = useState([]);
  const [equityLiabilitySum, setEquityLiabilitySum] = useState(0);


  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Balance Sheet");
    sheet.properties.defaultRowHeight = 20;

    // Add header section
    const titleRow = sheet.addRow(["BALANCE SHEET"]);
    titleRow.font = { size: 18, bold: true };
    sheet.mergeCells("A1:B1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Add date row
    const dateRow = sheet.addRow([
      `Date: ${toDate?.split("-").reverse().join("-")}`,
    ]);
    dateRow.font = { size: 14, italic: true };
    sheet.mergeCells("A2:B2");
    dateRow.getCell(1).alignment = { vertical: "middle", horizontal: "center" };

    // Add an empty row between header and table
    sheet.addRow([]);

    // Header row styling
    const headerRow = sheet.addRow(["Particular", "Account"]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF264a95" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 15,
      bold: true,
      color: { argb: "FFFFFFFF" }, // White font color
    };

    headerRow.eachCell((cell) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: "middle", horizontal: "center" }; // Ensure header text is centered
    });
    sheet.columns = [
      { header: "Particular", key: "account", width: 70 },
      { header: "Balance Sheet", key: "debitTotal", width: 20 },
    ];

    // List of specific headings to apply the background color
    const specificHeadings = [
      "Receivables",
      "Bank and cash",
      "Current asset",
      "Non current asset",
      "Prepayments",
      "Fixed assets",
      "Previous year earnings",
      "Current year earnings",
      "Equity",
      "Payable",
      "Credit card",
      "Current liability",
      "Non current liability"
    ];

    // Process each array type and add rows to the sheet
 

    updatedBalanceSheetArray.forEach((res, i) => {
      // Add subhead
      const subHeadRow = sheet.addRow([res.subHead]);
      subHeadRow.font = { size: 16, bold: true, color: { argb: "FFFFFFFF" } };
      subHeadRow.getCell(1).alignment = {
        vertical: "middle",
        horizontal: "left",
      };

      // Apply background color to cells in columns A and B for subhead
      const subHeadStartCell = subHeadRow.getCell(1);
      const subHeadEndCell = subHeadRow.getCell(2);
      subHeadStartCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF6980bc" },
      };
      subHeadEndCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF6980bc" },
      };

      // Add body
      res.body.forEach((item, j) => {
        const itemRow = sheet.addRow([
          item.name,
          ` ${item.value?.toFixed(decimalPosition)}`,
        ]);
        itemRow.font = { size: 14 };

        // Check if item name is one of the specific headings to apply a different background color
        if (specificHeadings.includes(item.name)) {
          itemRow.getCell(1).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFeef1f9" },
          };
          itemRow.getCell(2).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFeef1f9" },
          };
        }

        // Add subData
        item.subData.forEach((sub, k) => {
          const subDataRow = sheet.addRow([
            `  ${sub.name}`,
            ` ${sub.value?.toFixed(decimalPosition)}`,
          ]);
          subDataRow.font = { size: 12 };
        });
      });

      // Add total
      if (i !== 1) {
        const totalRow = sheet.addRow([`Total`, `${symbol} ${i===0?res?.total:equityLiabilitySum?.toFixed(decimalPosition)}`]);
        // console.log(res.total,'......totalRow');
        totalRow.font = { size: 14, bold: true, color: { argb: "FFFFFFFF" } };
        const totalStartCell = totalRow.getCell(1);
        const totalEndCell = totalRow.getCell(2);
        totalStartCell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF264a95" },
        };
        totalEndCell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF264a95" },
        };
      }
    });

    // Write the workbook to a file
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Balance Sheet.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  useEffect(() => {
    if (balanceSheetListData) {
      // Define a function to sort accounts by accountName
      function sortAccounts(accounts) {
        return accounts.slice().sort((a, b) => {
          if (a.accountName < b.accountName) return -1;
          if (a.accountName > b.accountName) return 1;
          return 0;
        });
      }

      // Define the order for each subHead FFeef1f9
      const subHeadOrder = {
        Asset: [
          "Receivables",
          "Bank and cash",
          "Current asset",
          "Non current asset",
          "Prepayments",
          "Fixed assets",
        ],
        Equity: ["Previous year earnings", "Current year earnings", "Equity"],
        Liability: [
          "Payable",
          "Credit card",
          "Current liability",
          "Non current liability",
        ],
      };

      // Initialize the final array
      const transformedResult = [];

      // Define a function to calculate the total sum
      function calculateTotal(body) {
        let total = 0;
        body.forEach((item) => {
          total += parseFloat(item.value);
        });
        return total.toFixed(2);
      }

      // Initialize the sum of equity and liability
      let equityLiabilitySum = 0;

      // Define a function to process each array type
      function processArray(arrayType, subHead) {
        const body = [];
        const subHeadItems = subHeadOrder[subHead];
        subHeadItems.forEach((subItem) => {
          const item = balanceSheetListData.currentYearData[arrayType].find(
            (account) => account._id === subItem
          );
          if (item) {
            const sortedAccounts = sortAccounts(item.accounts);
            const subData = [];
            sortedAccounts.forEach((account) => {
              subData.push({
                name: account.accountName,
                value: account.closingBalance,
              });
            });
            body.push({
              name: item._id,
              value: item.totalClosingBalance,
              subData: subData,
            });

            // If the subHead is Equity or Liability, add its totalClosingBalance to the sum
            if (subHead === "Equity" || subHead === "Liability") {
              equityLiabilitySum += parseFloat(item.totalClosingBalance);
            }
          } else {
            body.push({
              name: subItem,
              value: 0,
              subData: [],
            });
          }
        });

        transformedResult.push({
          subHead: subHead,
          body: body,
          total: calculateTotal(body),
        });
      }

      // Process each array type
      processArray("assetArray", "Asset");
      processArray("equityArray", "Equity");
      processArray("liabilityArray", "Liability");

      // Set the state with the sum of equity and liability
      setEquityLiabilitySum(equityLiabilitySum);

      // Set the state with the transformed result
      setUpdatedBalanceSheetArray(transformedResult);
    }
  }, [balanceSheetListData]);

  const [toDate, setToDate] = useState(today);
  const getToDate = (e) => {
    setToDate(e.target.value);
  };

  const [showInsideData, setShowInsideData] = useState({
    0: {
      Asset: {
        0: true,
      },
    },
    1: {
      Equity: {
        0: true,
      },
    },
    2: {
      Liability: {
        0: true,
      },
    },
  });
  //function for switching  to view data
  const toggleInsideData = (index, key, subIndex) => {
    setShowInsideData((prevState) => {
      const newState = { ...prevState };
      const currentState = newState[index]?.[key]?.[subIndex];
      newState[index] = {
        ...newState[index],
        [key]: {
          ...newState[index]?.[key],
          [subIndex]: !currentState,
        },
      };
      return newState;
    });
  };

  //rerender when date change
  useEffect(() => {
    if (toDate) {
      setLoading(true);
      ViewBalanceSheetAPIcall(
        {
          // startDate: toDate,
          endDate: `${toDate} 23:59:59`,
        },
        setLoading
      );
    }
  }, [toDate]);

  // initial data
  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, []);

  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    setSymbol(currencyObj?.symbol);
  }, [companyProfileData, currencyListUpdated]);

  // console.log(React.version);

  return (
    <div className="balance-sheet-main-container" style={{height:"94vh",backgroundColor:"unset"}}>
      {/* header */}
      <div className="header-for-balance-sheet" style={{backgroundColor:"white",padding:"2px"}}>
        <div className="balance-sheet-action-btns">
        <div className="balance-sheet-body-head" style={{fontSize:"large",fontWeight:"500"}}>
          <span>Balance Sheet</span>
          <span>({toDate?.split("-").reverse().join("-")})</span>
          </div>
          
        </div>
        <div style={{display:"flex"}}>
          <div>
            <Tooltip title="Share">
              <IconButton>
                <i
                  className="bi bi-share"
                  style={{
                    backgroundColor: "#9797e9",
                    color: "white",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                ></i>
              </IconButton>
            </Tooltip>
                <ReactToPrint
                  trigger={() => (
                    <IconButton
                      className="printer-div"
                      style={{ padding: "1px" }}
                    >
                      <i
                        style={{
                          color: "white",
                          backgroundColor: " #d787e7",
                          fontSize: "medium",
                          padding: "8px",
                          borderRadius: "2px",
                        }}
                        className="bi bi-printer"
                      ></i>
                    </IconButton>
                  )}
                  content={() => componentRef}
                />
            <Tooltip title="Download">
              <IconButton onClick={downloadList}>
                <i
                  className="bi bi-arrow-down-circle"
                  style={{
                    backgroundColor: "rgb(255 118 133 / 69%)",
                    color: "white",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                ></i>
              </IconButton>
            </Tooltip>
          </div>
          <div style={{alignContent:"center"}}>
          <Datepicker
            type="date"
            color="rgb(38, 74, 149)"
            backgroundColor="#f1f2ff"
            value={toDate}
            onChange={getToDate}
          />
          <p className="doc-validation-alert">{""}</p>
        </div>
        </div>
        
      </div>
      {/* body */}
      <div className="balance-sheet-body" style={{backgroundColor:"white",height:"87vh",margin:"0"}}>
        <div
          id="balance_sheet_list"
          className="balance-sheet-body-table-container"
         style={{margin:"6px 3px 0px 3px",maxHeight:"85vh"}}>
          <table style={{paddingTop:"6px"}}>
            <thead>
              <tr>
                <th>Perticular</th>
                <th style={{ minWidth: "15em" }}>Account</th>
              </tr>
            </thead>
            <tbody>
              {updatedBalanceSheetArray?.length > 0 ? (
                updatedBalanceSheetArray.map((res, i) => {
                  return (
                    <React.Fragment key={i}>
                      {/* Subhead */}
                      <tr
                        className="bs-sub-head"
                        onClick={() => toggleInsideData(i, res?.subHead, 0)}
                      >
                        <td colSpan={2}>{res.subHead}</td>
                      </tr>
                      {/* Inside data */}
                      {showInsideData[i]?.[res.subHead]?.[0] &&
                        res?.body?.map((item, j) => {
                          return (
                            <React.Fragment key={j}>
                              <tr
                                className="bs-main-body"
                                onClick={() =>
                                  toggleInsideData(i, res.subHead, j + 1)
                                }
                              >
                                <td className="bs-bold">{item.name}</td>
                                <td className="bs-txt-end bs-total-amt">
                                  {symbol}&nbsp;{" "}
                                  {item?.value?.toFixed(decimalPosition)}
                                </td>
                              </tr>
                              {/* Sub Data */}
                              {showInsideData[i]?.[res.subHead] &&
                              showInsideData[i][res.subHead][j + 1] &&
                              item?.subData?.length > 0
                                ? item?.subData?.map((sub, k) => {
                                    return (
                                      <React.Fragment key={k}>
                                        <tr
                                          className={`bs-sub-data ${
                                            showInsideData[i]?.[res.subHead]?.[
                                              j + 1
                                            ]
                                              ? ""
                                              : ""
                                          }`}
                                        >
                                          <td>{sub?.name}</td>
                                          <td className="bs-txt-end">
                                            {symbol}&nbsp;
                                            {sub?.value?.toFixed(
                                              decimalPosition
                                            )}
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                    );
                                  })
                                : showInsideData[i][res.subHead][j + 1] && (
                                    <tr>
                                      {" "}
                                      <td colSpan={2} className="bs-no-data">
                                        No Data
                                      </td>
                                    </tr>
                                  )}
                            </React.Fragment>
                          );
                        })}
                      {/* Total */}
                      {i !== 1 && (
                        <tr className="bs-footer-total">
                          <td>Total</td>
                          <td>
                            {symbol}&nbsp;
                            {Number(
                              i !== 0 ? equityLiabilitySum : res?.total
                            )?.toFixed(decimalPosition)}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })
              ) : (
                <tr>
                  <td className="bs-no-data" colSpan="2">
                    No Data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <LoadingForm loading={loading} />
        {/* print balance-sheet  */}
        <div className="balance-sheet-print-container">
          <div style={{ display: "none" }}>
            <div
              ref={(el) => (componentRef = el)}
              className="print-container-trail-balance"
              style={{ "@media print": { "@page": { size: "landscape" } } }}
            >
              <div className="trailbalnce-print-header">
                <div className="company-logo-container">
                  <img
                    height={100}
                    width={100}
                    src="http://placehold.it/100x100"
                    alt=""
                  />
                </div>
                <div className="trail-balance-print-title">
                  <span>Balance Sheet</span>
                  <span style={{ fontSize: "13px" }}> As Off {toDate}</span>
                </div>
                <div className="company-details-container">
                  <span>{userInfo?.data?.profile?.companyName} </span>
                  <span>{userInfo?.data?.profile?.companyAddress} </span>
                  <span>{userInfo?.data?.profile?.companyEmail}</span>
                </div>
              </div>
              <div>
                <div
                  style={{ overflow: "unset" }}
                  className="balance-sheet-body-table-container"
                >
                  <table style={{padding:"7px 3px 0px 3px"}}>
                    <thead>
                      <tr>
                        <th>Perticular</th>
                        <th>Account</th>
                      </tr>
                    </thead>
                    <tbody>
                      {updatedBalanceSheetArray?.length > 0 ? (
                        updatedBalanceSheetArray.map((res, i) => {
                          return (
                            <React.Fragment key={i}>
                              {/* Subhead */}
                              <tr
                                className="bs-sub-head"
                                onClick={() =>
                                  toggleInsideData(i, res?.subHead, 0)
                                }
                              >
                                <td colSpan={2}>{res.subHead}</td>
                              </tr>
                              {/* Inside data */}
                              {showInsideData[i]?.[res.subHead]?.[0] &&
                                res.body?.map((item, j) => {
                                  return (
                                    <React.Fragment key={j}>
                                      <tr
                                        className="bs-main-body"
                                        onClick={() =>
                                          toggleInsideData(
                                            i,
                                            res.subHead,
                                            j + 1
                                          )
                                        }
                                      >
                                        <td className="bs-bold">{item.name}</td>
                                        <td className="bs-txt-end bs-total-amt">
                                          {symbol}&nbsp;{" "}
                                          {item?.value?.toFixed(
                                            decimalPosition
                                          )}
                                        </td>
                                      </tr>
                                      {/* Sub Data */}
                                      {showInsideData[i]?.[res.subHead] &&
                                      showInsideData[i][res.subHead][j + 1] &&
                                      item?.subData?.length > 0
                                        ? item?.subData?.map((sub, k) => {
                                            return (
                                              <React.Fragment key={k}>
                                                <tr
                                                  className={`bs-sub-data ${
                                                    showInsideData[i]?.[
                                                      res.subHead
                                                    ]?.[j + 1]
                                                      ? ""
                                                      : ""
                                                  }`}
                                                >
                                                  <td>{sub?.name}</td>
                                                  <td className="bs-txt-end">
                                                    {symbol}&nbsp;
                                                    {sub?.value?.toFixed(
                                                      decimalPosition
                                                    )}
                                                  </td>
                                                </tr>
                                              </React.Fragment>
                                            );
                                          })
                                        : showInsideData[i][res.subHead][
                                            j + 1
                                          ] && (
                                            <tr>
                                              {" "}
                                              <td
                                                colSpan={2}
                                                className="bs-no-data"
                                              >
                                                No Data
                                              </td>
                                            </tr>
                                          )}
                                    </React.Fragment>
                                  );
                                })}
                              {/* Total */}
                              {i !== 1 && (
                                <tr className="bs-footer-total">
                                  <td>Total</td>
                                  <td>
                                    {symbol}&nbsp;
                                    {Number(
                                      i !== 0 ? equityLiabilitySum : res?.total
                                    )?.toFixed(decimalPosition)}
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        })
                      ) : (
                        <tr>
                          <td className="bs-no-data" colSpan="2">
                            No Data
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BalanceSheetNew;
