import { update_login_response } from "../Redux/Login/loginResponseSlice";
import store from "../Redux/store";
import Swal from "sweetalert2";
import * as XLSX from 'xlsx';
import CountryWithStates from "../Assets/JSON/countryStates.json";
//Search by word Fn
export const searchByWord = (
  originalArray,
  filterArray,
  keyword,
  stringKeyOfObj
) => {
  let searchResult = [];
  if (keyword !== "") {
    searchResult = originalArray?.filter((obj) =>
      obj?.stringKeyOfObj?.toLowerCase()?.includes(keyword?.toLowerCase())
    );

    filterArray = searchResult;
  } else {
    filterArray = originalArray;
  }

  return filterArray;
};

//*Logout User
export const logOutUser =
  (isBtnClick, userRole, shiftStatus, confirmPasswordDialog) => () => {
    //   const history = useHistory();
    if (userRole === "user") {
      if (shiftStatus === true) {
        Swal.fire({
          // title: 'Are you sure?',
          text: 'Do you want to end shift or log out?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Log Out',
          cancelButtonText: 'Shift End',
          reverseButtons: true, // To swap the positions of the buttons
        }).then((result) => {
          if (result.isConfirmed) {
            // Handle save action
            localStorage.clear();
            window.location.replace("/");
            if (isBtnClick) {
              setTimeout(() => {
                alert("Successfully Logged Out");
              }, 400);
            }
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            
            confirmPasswordDialog();
            
          }
        });
      } else {
        localStorage.clear();
        window.location.replace("/");
        if (isBtnClick) {
          setTimeout(() => {
            alert("Successfully Logged Out");
          }, 400);
        }
      }
    } else {
      localStorage.clear();
      window.location.replace("/");
      if (isBtnClick) {
        setTimeout(() => {
          alert("Successfully Logged Out");
        }, 400);
      }
    }

    // localStorage.clear();
    // localStorage.setItem('shitBtnState',true)
    // Store.dispatch(login_status_update({ loginData: false }));
    // console.log(isBtnClick);
  };

//* update UserName and Image from local Storage
const userInfo = localStorage.getItem("userInfo");
store.dispatch(
  update_login_response({
    loginResponse: userInfo !== null && JSON.parse(userInfo),
  })
);

//*Success Alert Message
export const successAlertMessage = (title) => () => {
  Swal.fire({
    position: "center",
    icon: "success",
    title: title,
    showConfirmButton: false,
    showCancelButton: false,
    // timer: 2500,
    confirmButtonText: "Submit",
    customClass: {
      confirmButton: "success-alert-message-submit-btn",
      cancelButton: "success-alert-message-cancel-btn",
    },
  });
};
export const deleteAlertMessage = (id, deleteFunction) => () => {
  Swal.fire({
    position: "center",
    icon: "warning",
    title: "Are you sure want to delete this data",

    // text: "You won't be able to revert this!",
    showCancelButton: true,
    confirmButtonColor: "#f80000",
    confirmButtonText: "Delete",
    customClass: {
      cancelButton: "sweet-alert-cancel-button",
      confirmButton: "sweet-alert-submit-button",
      icon: "icon-color",
    },
  }).then((result) => {
    if (result.isConfirmed) {
      deleteFunction(id);
    }
    // else if (result.isDenied) {
    //    cancelFunction()
    // }
  });
};

//*Calculate CalculatedPrice(tax included)
export const calculatePrice = (price, tax) => {
  let calculatedPrice = 0;
  let taxAmount = 0;

  taxAmount = (parseFloat(price) * parseFloat(tax)) / 100;
  calculatedPrice = parseFloat(price) + parseFloat(taxAmount);

  return calculatedPrice;
};

//*Extract States of selected country
export const extractStatesFromCountry = (selectedCountry) => {
  let extractedStates = CountryWithStates.countries?.filter(
    (obj, i) => obj?.country.toLowerCase() === selectedCountry
  );

  return extractedStates;
};

//*Calculate Total Cost (unit Cost * adjust qty)
export const calculateTotalCost = (unitCost, adjQty) => {
  if (unitCost === "") {
    unitCost = 0;
  } else {
    unitCost = unitCost;
  }
  if (adjQty === "") {
    adjQty = 0;
  } else {
    adjQty = adjQty;
  }
  let totalCost = parseFloat(unitCost) * parseFloat(adjQty);

  return totalCost;
};

//* Extract single object from Object List for Single view
//extract Selected  object for single view [invoke on single view icon button click]
export const extractObjectForSingleView = (selectedId, completeList) => {
  let extractedObj = [];
  if (completeList !== undefined) {
    extractedObj = completeList?.filter((obj) => obj?._id === selectedId);
  }
  return extractedObj;
};

//Extract Single Object General Fn
// fullList--[]
//key--string
//compareWith---string||number

export const extractSingleObjectGeneral = (fullList, key, compareWith) => {
  let result = null;
  if (fullList !== undefined) {
    result = fullList?.filter((obj) => obj[key] === compareWith);
  }
  return result;
};

//*calculate size of a file and validate
export const validateFileSize = (file, minSize, maxSize) => {
  let actualFileSize = null;
  let fileSizeInKb = null;
  let returnObject = {
    actualFileSize,
    minSize,
    maxSize,
    isValid: false,
  };
  //Get Size of file
  actualFileSize = file?.size;

  //convert file size from bytes to kb
  if (actualFileSize !== null) {
    fileSizeInKb = parseFloat(actualFileSize) / 1024;
  }

  //Check file size
  if (fileSizeInKb <= maxSize && fileSizeInKb >= minSize) {
    returnObject.actualFileSize = fileSizeInKb;
    returnObject.isValid = true;
  } else {
    returnObject.actualFileSize = fileSizeInKb;
    returnObject.isValid = false;
  }

  return returnObject;
};

//*Calculate work order total amount [itemRate*stitchRate*qty]
export const calculateWorkOrderTotalAmount = (itemRate, stitchRate, qty) => {
  let totalAmount = 0;
  // if (itemRate !== "" && stitchRate !== "" && qty !== "") {
    totalAmount =
      (parseFloat(itemRate===""?0:itemRate) + parseFloat(stitchRate===""?0:stitchRate)) * parseFloat(qty===""?0:qty);
  // }
  return totalAmount;
};

//Sorting By String value, Date Value, Integer value(7/jan/23)
export const sortGeneral = (list, type, keyName, isAscending) => {
  let sortedArray = null;
  if (list !== undefined) {
    if (type === "String") {
      sortedArray = [...list].sort((a, b) => {
        const key1 = a[keyName]?.toUpperCase();
        const key2 = b[keyName]?.toUpperCase();
        if (isAscending) {
          if (key1 < key2) {
            return -1;
          }
          if (key1 > key2) {
            return 1;
          }
          //2 strings must be equal
          return 0;
        } else {
          if (key1 < key2) {
            return 1;
          }
          if (key1 > key2) {
            return -1;
          }
          //2 strings must be equal
          return 0;
        }
      });
    }
    if (type === "Number" || type === "Date") {
      sortedArray = [...list].sort((a, b) => {
        const key1 = a[keyName];
        const key2 = b[keyName];
        if (isAscending) {
          if (key1 < key2) {
            return -1;
          }
          if (key1 > key2) {
            return 1;
          }
          //2 strings must be equal
          return 0;
        } else {
          if (key1 < key2) {
            return 1;
          }
          if (key1 > key2) {
            return -1;
          }
          //2 strings must be equal
          return 0;
        }
      });
    }
  }

  return sortedArray;
};

//Remove Duplicate from Array of Objects
export const removeDuplicateFromAoO = (list) => {
  if (list !== undefined) {
    let unique = [...new Set(list?.map((obj) => JSON.stringify(obj)))].map(
      (obj) => JSON.parse(obj)
    );
    return unique;
  }
};

//Convert percentage to amount
export const convertPercentageToAmount = (total = 0, percentage = 0) => {
  let amount = null;

  amount = (total * percentage) / 100;

  return amount;
};

// filter obj from array

export const filterObjFromList=(key,list,keyToMatch,listToFilter)=>{
// console.log(key,list,keyToMatch,listToFilter);
if(Array.isArray(list)){
  if (listToFilter && listToFilter[keyToMatch]) {
    let filteredArray = list?.filter((obj) => obj[key] === listToFilter[keyToMatch]);
    return filteredArray !== undefined ? (filteredArray?.length !== 0 ? filteredArray[0] : null) : null;
    
  } else {
    return null;
  }
}
 

}
export const filterArrayFromList=(key,list,keyToMatch,listToFilter)=>{
if(Array.isArray(list)){
  if (listToFilter && listToFilter[keyToMatch]) {
    let filteredArray = list?.filter((obj) => obj[key] === listToFilter[keyToMatch]);
    return filteredArray;
    
  } else {
    return null;
  }
}
 

}


// set decimal position when input


export const roundNumericFields = (array, keysToRound) => {

  let decimalPosition=localStorage.getItem("decimalPosition")
  const newArray = array.map((item) => {
    keysToRound.forEach((key) => {
      if (item[key]) {
        item[key] = parseFloat(item[key]).toFixed(decimalPosition);
      }
    });
    return item;
  });
  return newArray;
};
//function to convert json to excel
export const convertExcelToJson = (file) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject(new Error('No file provided'));
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

      resolve(sheetData);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsBinaryString(file);
  });
};

export const convertArrayToExcel = (dataArray, filename) => {
  const dataArrayLength = dataArray.length;
  const worksheet = XLSX.utils.json_to_sheet(dataArray);
  XLSX.utils.sheet_add_aoa(worksheet, [[filename]], { origin: "A1" });
  worksheet["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: dataArrayLength } }];
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  XLSX.writeFile(workbook, `${filename}.xlsx`);
};
// filter an obj from the list

export const findObjFromList = (key, list, keyToMatch, listToFilter) => {
  if (Array.isArray(list)) {
    if (listToFilter && listToFilter[keyToMatch]) {
      return list.find(obj => obj[key] === listToFilter[keyToMatch]) || null;
    } else {
      return null;
    }
  }
  return null;
};
// check arrays are equal

export const areArraysEqual=(array1, array2)=> {
  return array1.length === array2.length && array1.every((value, index) => value === array2[index]);
}


// dates
export const getTodaysLowerCaseDay=()=> {
  const daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
  const today = new Date();
  const dayIndex = today.getDay();
  const todayLowerCaseDay = daysOfWeek[dayIndex];
  return todayLowerCaseDay;
}

export const convertToArabicNumber = (input) => {
  const numberValue = parseFloat(input);

  if (!isNaN(numberValue)) {
    const formattedValue = new Intl.NumberFormat('ar-EG', { maximumFractionDigits: 2 }).format(numberValue);
    return formattedValue;
  } else {
    return input;
  }
};

export const numToWords = (num) => {

  const numToWords = require("num-words");

  let isNegative=num<0
  const [integerPart, decimalPart] = num.toString().split(".");

  let result = numToWords(Math.abs(parseInt(integerPart)));

  if (decimalPart) {
      result += " point ";
      // Convert each digit of the decimal part to words
      for (let digit of decimalPart) {
          result += numToWordsHelper(parseInt(digit)) + " ";
      }
  }

  return result +=" only";
};

const numToWordsHelper = (num) => {
  const ones = [
      "", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine"
  ];
  const tens = [
      "", "", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"
  ];
  const teens = [
      "ten", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen", "seventeen", "eighteen", "nineteen"
  ];

  if (num === 0) return "zero";
  if (num < 10) return ones[num];
  if (num < 20) return teens[num - 10];
  if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? " " + ones[num % 10] : "");
  if (num < 1000) return ones[Math.floor(num / 100)] + " hundred " + (num % 100 !== 0 ? "and " + numToWordsHelper(num % 100) : "");

  return "Number out of range";
};


export const numToArabicWords = (num) => {
  const ones = ['صفر', 'واحد', 'اثنان', 'ثلاثة', 'أربعة', 'خمسة', 'ستة', 'سبعة', 'ثمانية', 'تسعة'];
  const tens = ['', 'عشرة', 'عشرون', 'ثلاثون', 'أربعون', 'خمسون', 'ستون', 'سبعون', 'ثمانون', 'تسعون'];
  const scales = ['', 'ألف', 'مليون', 'مليار', 'تريليون'];

  let words = '';
  let numStr = num.toString();

  if (num === 0) {
    return 'صفر';
  }

  if (num < 0) {
    words += 'سالب ';
    numStr = numStr.substring(1);
  }

  // Split the number into groups of three digits
  const groups = numStr.match(/(\d{1,3})(?=(\d{3})*$)/g);

  for (let i = 0; i < groups.length; i++) {
    let groupNum = parseInt(groups[i], 10);
    if (groupNum !== 0) {
      let groupWords = '';

      // Extract hundreds digit
      let hundreds = Math.floor(groupNum / 100);
      if (hundreds > 0) {
        groupWords += ones[hundreds] + ' مئة ';
        groupNum %= 100;
      }

      // Extract tens and ones digits
      if (groupNum >= 10 && groupNum <= 99) {
        if (groupNum < 20) {
          groupWords += ones[groupNum];
        } else {
          let tensDigit = Math.floor(groupNum / 10);
          groupWords += tens[tensDigit];
          let onesDigit = groupNum % 10;
          if (onesDigit !== 0) {
            groupWords += ' و' + ones[onesDigit];
          }
        }
      } else if (groupNum >= 1 && groupNum <= 9) {
        groupWords += ones[groupNum];
      }

      // Append scale (thousands, millions, etc.)
      if (groupWords !== '') {
        groupWords += ' ' + scales[groups.length - i - 1];
      }

      words += groupWords + ' ';
    }
  }

  return words.trim();
};


export const  convertToArabicMobileNo=(mobileNumber)=> {
  // Define an object mapping each digit to its Arabic numeral equivalent
  const arabicNumerals = {
    '0': '٠',
    '1': '١',
    '2': '٢',
    '3': '٣',
    '4': '٤',
    '5': '٥',
    '6': '٦',
    '7': '٧',
    '8': '٨',
    '9': '٩',
  };

  // Convert each digit character in the mobile number to its Arabic numeral
  const arabicNumber = mobileNumber.replace(/[0-9]/g, (digit) => arabicNumerals[digit]);

  return arabicNumber;
}

// find the sum of given keys of an object

export const findSumOfObjkeys=(array, ...keys)=> {
  const result = {};

  keys.forEach(key => {
    result[key] = array.reduce((acc, obj) => acc + (obj[key] || 0), 0);
  });

  return result;
}

// function to calculate taxes of table list data

export const taxCalculationForLines=(tableData)=>{
  let decimalPosition=localStorage.getItem("decimalPosition")

  const calculatedValues = tableData?.map((row) => {

    const taxIdArray=row?.tax?.map((obj)=>obj?._id)
    const splittedTaxArray = row?.tax?.flatMap(obj => {
        if (obj.taxArray&& obj.taxArray.length !== 0) {
          return [...obj.taxArray, obj];
        } else {
          return obj;
        }
      })
      .filter(obj => obj.taxArray.length === 0) || [];
    if (row?.inclusive === true) {

      let totalAmt = Number(row?.qty) * Number(row?.unitPrice)
      let taxArray = splittedTaxArray?.map((obj) => obj?.amount)

      let totalOfTax = taxArray?.reduce((sum, item) => sum + item, 0)
      let taxableAmount = (totalAmt / (totalOfTax + 100)) * 100

      let discount = ((taxableAmount?.toFixed(decimalPosition)) * row?.discount) / 100

      let subtotalBeforeTax = (taxableAmount)?.toFixed(decimalPosition) - (discount?.toFixed(decimalPosition))


      let totalDiscount = taxArray.reduce((total, taxPercentage) => {
        const discountAmount = (taxPercentage / 100) * subtotalBeforeTax;
        return total + discountAmount;
      }, 0);
      return { ...row, inclusiveTax: totalDiscount, exclusiveTax: 0,taxSplitted:splittedTaxArray,taxIdList:taxIdArray };
    }
    else if (row?.inclusive === false) {
      let totalAmt = Number(row?.qty) * Number(row?.unitPrice)
      let discount = ((totalAmt?.toFixed(decimalPosition)) * row?.discount) / 100
      let subtotalBeforeTax = (totalAmt)?.toFixed(decimalPosition) - (discount?.toFixed(decimalPosition))
      let totalTax = splittedTaxArray?.reduce((sum, item) => sum + item?.amount, 0)
      let totalExclTax = (subtotalBeforeTax * totalTax) / 100
      return { ...row, exclusiveTax: totalExclTax, inclusiveTax: 0,taxSplitted:splittedTaxArray,taxIdList:taxIdArray };
    }

    else {
      return { ...row, exclusiveTax: 0, inclusiveTax: 0,taxSplitted:splittedTaxArray,taxIdList:taxIdArray };
    }
  });
  return calculatedValues
}

