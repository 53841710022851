import React, { useEffect, useState } from 'react'
import "./posOrders.css"
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter'
import { IconButton, Tooltip } from '@mui/material'
import ReactToPrint from 'react-to-print'
import CustomPagination from '../../../../Single Components/CustomPagination'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ListPosOrdersAPI } from '../GeneralAPI'
import { getCurrentTime } from '../../../../../Js/Date'
function PosOrders() {
  const navigate=useNavigate()
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const userRole = useSelector((state) => state.userRoleSlice.value);

  const handlePageChange = (page) => {
    
    setCurrentPage(page);
  };
const orderList=useSelector((state)=>state.generalSlice.orderList)
const totalPages = orderList?.pages; 
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
    
};
const singleRowClick=(row)=>{
  localStorage.setItem("singleId",row._id)
  navigate("/userdashboard/pointOfSales/general/orderSingleView")
}
useEffect(()=>{
  if (FormDataInfo.length !== 0) {
    
    ListPosOrdersAPI({
        fromDate: FormDataInfo.fromDate === '' ? undefined : `${FormDataInfo.fromDate} ${getCurrentTime()}`,
        endDate: FormDataInfo.toDate === '' ? undefined : `${FormDataInfo.toDate} ${getCurrentTime()}`,
        branchId: FormDataInfo?.branchList?.length === 0 ? undefined:FormDataInfo?.branchList,
        status: FormDataInfo?.status?.length === 0 ? undefined : FormDataInfo?.status[0],
        search: FormDataInfo?.search===''? undefined : FormDataInfo?.search,
        orderType: FormDataInfo?.Ordertype ==='' ? undefined : FormDataInfo?.Ordertype,
        index:currentPage-1
    })
  }
},[FormDataInfo,currentPage])
  return (
    <div className='global-page-parent-container'>
    <div
    style={{padding:"0 1%"}}
    className="justify-space-between global-white-bg-container">
        <h3 style={{margin:"0"}}>Orders</h3>
    
    <div className="justify-center" style={{gap:"10px",width:"70%",justifyContent:"flex-end"}}>
    <ReactToPrint
      trigger={() => (
        <IconButton className="printer-div" style={{color: "#fff",backgroundColor:"#002995",borderRadius:"0"}}>
          <i class="bi bi-printer"></i>
        </IconButton>
      )}
    //   content={() => paymentsPrint}
    />
    <Tooltip title="Download">
      <IconButton style={{backgroundColor: "#ffd2d2", color: "#002995",borderRadius:"0"}}>
        <i class="bi bi-arrow-down-circle"></i>
      </IconButton>
    </Tooltip>
    <CategorySearchandFilter
        statusOptions={['DONE', 'DRAFT','RETURN']}
        onData={handleDataFromChild}
        // isType={true}
        isStatus={true}
        isOrderType={true}
        isBranch={userRole==="admin"}
    />
    </div>
    </div>

    
    <div className="global-white-bg-container" style={{paddingTop:"0"}}>
        <div className="justify-center" style={{justifyContent:"flex-end"}}>
            <CustomPagination
               
               currentPage={currentPage}
               totalPages={totalPages}
               onPageChange={handlePageChange}
              
            />
        </div>
        <div className="new-global-table-container">
        <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Order No</th>
            <th>Shift</th>
            <th>Employee</th>
            <th>Customer</th>
            <th>Order Amount</th>
            <th>Amount Paid</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
            { orderList?.list!==undefined? orderList?.list?.map((r,i)=>(
                    <tr key={i} onClick={()=>singleRowClick(r)}>
                        <td>{r.orderDate}</td>
                        <td>{r.orderNo}</td>
                        <td>{r.shiftId}</td>
                        <td>{r.employeeName}</td>
                        <td>{r.customerName}</td>
                        <td>{r.orderAmount}</td>
                        <td>{r.paidAmount}</td>
                        <td className="table-data-box">
                            {r?.status === "DRAFT" ? (
                                <span className="draft"> DRAFT</span>
                            ) : r?.status === "DONE" ? (
                                <span className="post"> DONE</span>
                            ) : r?.status === "CANCEL" ? (
                                <span className="cancel">CANCEL</span>
                            ) :  r?.status==='RETURN'?(
                              <span className="return">RETURN</span>):
                             (
                                <span>{r?.status}</span>
                            )}
                        </td>
                    </tr>
                )):
                <tr>
                  <td colSpan={8}>NO DATA</td>
                </tr>
            }
        </tbody>
        </table>
        </div>
    </div>
</div>
  )
}

export default PosOrders