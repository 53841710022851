import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAggregator, setAggregatorRef, setBillType, setBtnSelected, setCustomer, setCustomerWallet, setOrderWaiter, setSearchBarInput, setSingleCusInfo} from './topBarSliceBilling'
import { Autocomplete, Dialog, TextField, createFilterOptions } from '@mui/material'
import { setDialogBox,setOrderIdInPOS, setPageIndex} from '../Slice/billingScreenSlice'
import { findObjFromList } from '../../../../../Js/generalFunctions'
import { listHeldOrders, listOrdersForBillingAPI } from '../API/viewOrderSection'
import { getCustomerWallet } from '../API/billingPaymentSection'
import { setApplyBtnClicked, setFilteredOrderType, setFilteredStatus } from '../Utilities/filterButtonSlice'
import SearchIcon from "@mui/icons-material/Search";
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import EditIcon from '@mui/icons-material/Edit';
import AddCustomerFromReservation from '../Utilities/AddCustomerFromReservation'
import { defaultCustomerForPOSAPI, getSingleCustomerInfoAPICall } from '../API/billingScreenSection'
import { errorSnackMsg } from '../../../../Custom Hooks/SnackkBarUtilities'
function TopBar() {

const menuRef=useRef()

const userInfo=useSelector((state)=>state.loginResponseSlice.value)

// api responses
const customerList = useSelector((state) => state.allCustomerListSlice.value);

// store states
const {customer,btnClicked,defaultCustomer,aggregator,aggregatorRef,searchBarInput}=useSelector((state)=>state.topBarSliceBilling)
const {heldOrders}=useSelector((state)=>state.viewOrdersBillingSlice)

const {singleOrderInfo,dialogOpen,cartInfo,paymentMode} = useSelector((state) => state.billingScreenSlice);
const {aggregatorList}=useSelector((state)=>state.aggregatorSlice)
const allActiveEmpList = useSelector((state) => state.allActiveEmployeeSlice.value);
const dispatch=useDispatch()
// order types list
const switchTabs=["Counter","Delivery"]

// useStates
const [addCustomerDialog, setAddCustomerDialog] = useState(false);
const [billTypeOption,setBillTypeOption]=useState("Counter")
const [showAggregator,setShowAggregator]=useState(false)
// functions
const onChangeBillType=(key)=>()=>{
    let disable=paymentMode==="refundPayment" || paymentMode==="balancePayment"
    if(!disable){
        let option=key==="Counter"?"COUNTER":"DELIVERY"
        dispatch(setBillType(option))
        setBillTypeOption(key)
        option==="DELIVERY" ? setShowAggregator(!showAggregator):setShowAggregator(false)
    }else{
        errorSnackMsg("Unable to switch order type in this order!!")
    }
}

const closeCustomerDialog=()=>{
    setAddCustomerDialog(false)
    dispatch(setDialogBox(null))
}

const buttonsOnClick=(option)=> {
    dispatch(setSearchBarInput(null))
    dispatch(setOrderIdInPOS(null))
    if(btnClicked===option){
        dispatch(setBtnSelected(null))
    }else{
        dispatch(setBtnSelected(option))
        if(option==="viewOrder"){
            dispatch(setFilteredOrderType(null))
            dispatch(setFilteredStatus(null))
            listOrdersForBillingAPI({branchId:userInfo?.branchPk,type:2})
        }
        if(option==="heldOrder"){
            dispatch(setFilteredStatus(null))
            listHeldOrders({branchId:userInfo?.branchPk,type:1})
        }
    }
}

const aggregatorSelected=(item)=>()=>{
    dispatch(setAggregator((aggregator?._id===item?._id)?null:item))
    dispatch(setAggregatorRef(null))

    !(aggregator?._id===item?._id) && dispatch(setDialogBox("aggregatorRef"))
}

const customerOnChange=(e,newValue)=>{
    if(singleOrderInfo?.type==="refund"){
        errorSnackMsg("Customer can't be changed on refund!!")
    }else{
        dispatch(setCustomer(newValue))
        newValue?getCustomerWallet({cusId:newValue?._id}):dispatch(setCustomerWallet(null))
    }
}

const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option?.name + option.mobileNo,
  });
  const handleKeyDown = (event) => {
    if (event.key === 'F1') {
      event.preventDefault(); // Prevent default browser behavior
      dispatch(setDialogBox("items"))
    }
  };

  const searchInputOnChange=(e)=>{
    dispatch(setSearchBarInput(e.target.value))
    dispatch(setApplyBtnClicked("clicked"))
    dispatch(setPageIndex(0))
}
useEffect(()=>{
    defaultCustomer===null &&   defaultCustomerForPOSAPI({})

    window.addEventListener('keydown', handleKeyDown);

    // Cleanup
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
},[])

useEffect(()=>{
    if(customer!==null){
        getSingleCustomerInfoAPICall({_id:customer?._id})
    }else{
        dispatch(setSingleCusInfo(null))
        dispatch(setCustomerWallet(null))
    }
},[customer])
useEffect(()=>{
    if(singleOrderInfo!==null){
        let cusObj=findObjFromList("_id",customerList,"cusId",singleOrderInfo)
        dispatch(setCustomer(cusObj))

        cusObj!==null &&getCustomerWallet({cusId:singleOrderInfo?.cusId})
        dispatch(setAggregator(findObjFromList("_id",aggregatorList,"aggregatorId",singleOrderInfo)))
        dispatch(setOrderWaiter(findObjFromList("_id",allActiveEmpList,"waiterId",singleOrderInfo)))
        dispatch(setAggregatorRef(singleOrderInfo?.aggregatorRef || null))
        let key=singleOrderInfo?.ordType
        let option=key==="COUNTER"?"Counter":"Delivery"
        dispatch(setBillType(key))
        setBillTypeOption(option)
    }else{
        if(btnClicked!==null){
            dispatch(setCustomer(null))
        }
        dispatch(setSingleCusInfo(null))
        dispatch(setAggregator(null))
        dispatch(setAggregatorRef(null))
        dispatch(setOrderWaiter(null))
        setBillTypeOption("Counter")
        dispatch(setBillType("COUNTER"))
    }
},[singleOrderInfo])

useEffect(() => {
    const handleClickOutside = (event) => {
        if (
            menuRef.current &&
            !menuRef.current.contains(event.target)
        ) {
            setShowAggregator(false);
        }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
}, []);

return (
        <div className="billing-left-first"
        style={{justifyContent:btnClicked &&"space-between"}}
        >
          
          <div
                className="new-billing-order-type-switch"
            >
                {
                    switchTabs?.map((option) => (
                        <div
                            key={option}
                            className={`switch-bill-type ${billTypeOption === option ? 'active' : ''}`}
                            onClick={onChangeBillType(option)}
                            style={{paddingBottom:billTypeOption === option ?"3px":"7px"}}
                        >
                            <p readOnly style={{ margin: "0", fontSize: "0.8rem" }}>
                                {option}
                            </p>
                        </div>
                    ))
                }
                {
                    showAggregator &&
                    <div className="aggregate-ctn" ref={menuRef}>
                        {
                            aggregatorList.length !== 0 ?
                                aggregatorList?.map((item, i) => (
                                    <div
                                        onClick={aggregatorSelected(item)}
                                        key={i}
                                        style={{ backgroundColor: (aggregator?._id === item?._id) ? "rgb(64 19 19)" : "#795547" }}
                                    >
                                        {item?.name}
                                    </div>
                                ))
                                :
                                <div>No Records !!</div>
                        }
                    </div>
                }
                
            </div>
            <div
                className="billing-left-secoundnav-container"
            >
                 <button
                    style={{
                        backgroundColor:(btnClicked==="floorPlan")?"#fff":"rgb(76 77 99)",
                        color:(btnClicked==="floorPlan")?"rgb(6 0 255)":"#fff",
                        position: "relative"
                    }}
                        onClick={() =>dispatch(setDialogBox("items"))}
                        className="top-bar-buttons billing-btn-table">ITEMS (F1)
                    </button>
                <button className="top-bar-buttons billing-btn-2">
                    <span
                        onClick={() =>dispatch(setDialogBox("addCustomer"))}
                        className="circle-button justify-center">{customer===null?"+":<EditIcon/> }</span>
                    <div style={{ flex: "1" }}>
                        <Autocomplete
                            options={customerList || []}
                            getOptionLabel={(option) => option?.name}
                            value={customer}
                            filterOptions={filterOptions}
                            onChange={customerOnChange}
                            renderInput={(params) => (
                                <TextField {...params} placeholder="Customer" />
                            )}
                            renderOption={(props, option) => (
                                <li {...props} key={option?._id}>
                                  <span>{option?.name}</span>
                                </li>
                              )}
                        />

                    </div>
                </button>
                <button
                    onClick={() =>buttonsOnClick("viewOrder")}
                    className="top-bar-buttons billing-btn-3"
                    style={{
                        backgroundColor:(btnClicked==="viewOrder")?"#fff":"rgb(21 128 145)",
                        color:(btnClicked==="viewOrder")?"rgb(4 221 255)":"#fff",
                    }}
                    >View Order</button>
                <button
                    style={{
                        backgroundColor:(btnClicked==="heldOrder")?"#fff":"#cc5200",
                        color:(btnClicked==="heldOrder")?"#cc5200":"#fff",
                        position: "relative"
                    }}
                    onClick={() =>buttonsOnClick("heldOrder")}
                    className="top-bar-buttons billing-btn-4">Held Orders
                        {
                        heldOrders.length!==0&&
                        <span
                            style={{
                                padding: "2px 5px",
                                backgroundColor: "#fff",
                                marginLeft:"4px",
                                color:"#cc5200",
                                border:"1px solid #cc5200"
                            }}
                        >
                        {heldOrders.length}
                        </span>}
                </button>

            </div>
            {
               ( btnClicked==="viewOrder" || btnClicked==="heldOrder") &&
            <div className="billing-search-bar">
                <SearchIcon
                sx={{cursor:"pointer"}}
                className="billing-search-icon" />
                <input
                    type="text"
                    placeholder={`Search ${btnClicked===null?"products":"orders"} by name..`}
                    className="search-bar"
                    value={searchBarInput}
                    onChange={searchInputOnChange}
                    onFocus={(e)=>e.target.select()}
                />
            </div>
            }


            {/* add customer */}
        <Dialog
            open={dialogOpen==="addCustomer"}
            onClose={closeCustomerDialog}
            maxWidth="fit-content"
        >
            <AddCustomerFromReservation setCustomerPopup={setAddCustomerDialog} />
        </Dialog>

        <Dialog 
        open={dialogOpen==="aggregatorRef"} 
        onClose={()=>dispatch(setDialogBox(null))}
        >
            <div className='wallet-change-dialog' >
                <div className="head justify-center">
                    <div className="icon justify-center" style={{backgroundColor:"#ffa50030"}}>
                        <DeliveryDiningIcon sx={{color:"#ff7201"}}/>
                    </div>
                    Add Refference ID
                </div>
                <div className="input-ctn">
                    <button disabled className="btn" style={{width:"80px",textAlign:"start"}}>Aggregator</button>
                    <input type="text"
                        readOnly
                        value={aggregator?.name}
                        style={{ textAlign: "start", paddingLeft: "10px" }}
                    />
                </div>
                <div className="input-ctn">
                    <button disabled className="btn" style={{width:"80px",textAlign:"start"}}>REF ID</button>
                    <input type="text"
                        value={aggregatorRef}
                        onChange={(e)=>dispatch(setAggregatorRef(e.target.value))}
                        style={{ textAlign: "start", paddingLeft: "10px" }}
                    />
                </div>
                <div className="wallet-change-btn">
                    <button
                        onClick={()=>{
                                dispatch(setDialogBox(null))
                                dispatch(setAggregatorRef(null))
                            }}
                        className="btn-secondary-outlined btn">Cancel</button>
                    <button
                        style={{backgroundColor:"#ff7201"}}
                        className="btn-primary btn"
                        onClick={()=>dispatch(setDialogBox(null))}>Submit</button>
                </div>
            </div>
        </Dialog>
        </div>
  )
}

export default TopBar