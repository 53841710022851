import React, { useEffect, useState } from 'react'
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter'
import CustomPagination from '../../../../Single Components/CustomPagination';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { ListPromotionAndOffersAPI, deleteApiCall } from '../GeneralAPI';
import { useSelector } from 'react-redux';
import store from '../../../../../Redux/store';
import { get_Promotion_single_view } from '../GeneralSlice';



const PosPromotionsAndOffers = () => {
    let navigateTo = useNavigate();

    const [FormDataInfo, setFormDataInfo] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const listPromotionAndOffers = useSelector(
        (state) => state.generalSlice.promotionAndOffer
    );
    console.log(listPromotionAndOffers);
    const deleteDataRow = (id) => {
        deleteApiCall(id)
    }
    const totalPages = ("");
    const createPromotion = () => {
        localStorage.removeItem("rowId")
        store.dispatch(get_Promotion_single_view(undefined))
        navigateTo("/userdashboard/pointOfSales/general/promotions&offers/create")

    }
    const handleDataFromChild = (data) => {
        setFormDataInfo(data)
        console.log(data);
    };
    const rowOnclick = (id) => {
        localStorage.setItem("rowId", id)
        navigateTo("/userdashboard/pointOfSales/general/promotions&offers/create")
    }
    const handlePageChange = (page) => {

        setCurrentPage(page);
    };
    useEffect(() => {

        ListPromotionAndOffersAPI()
    }, [])
    return (
        <div className='global-page-parent-container'>
            <div
                style={{ padding: "0 1%" }}
                className="justify-space-between global-white-bg-container">
                <h3 style={{ margin: "0" }}>Promotion & Offers</h3>

                <div className="justify-center" style={{ gap: "10px", width: "70%", justifyContent: "flex-end" }}>


                    <CategorySearchandFilter
                        statusOptions={['DONE', 'DRAFT']}
                        onData={handleDataFromChild}
                        isStatus={true}
                        isType={true}
                        isPaymentType={true}
                        onBranchSelect={() => { }}
                    // isBranch={userRole === "admin"}
                    />

                    <button className='create-button-blue' onClick={createPromotion}>Create</button>

                </div>
            </div>
            <div className="global-white-bg-container" style={{ paddingTop: "0" }}>
                <div className="justify-center" style={{ justifyContent: "flex-end", padding: "12px 14px 12px 0px" }}>
                    <CustomPagination

                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}

                    />
                </div>
                <div className="new-global-table-container">
                    <table>
                        <thead style={{ backgroundColor: "#dbe0fb" }}>
                            <tr>
                                <th>Branch</th>
                                <th>Banner Type</th>
                                <th>Name</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {listPromotionAndOffers?.length > 0 ? (
                                listPromotionAndOffers?.map((item, index) => (
                                    <tr key={index} style={{ height: "5vh", backgroundColor: index % 2 === 0 ? "#fbfbfb" : "#eaeaea" }} onClick={() => rowOnclick(item._id)}>
                                        <td style={{ border: "none" }}>
                                            {item.allowBranches.map((branch, branchIndex) => (
                                                <span key={branchIndex}>{branch.branchName}{branchIndex !== item.allowBranches.length - 1 ? " " : ", "}</span>
                                            ))}
                                        </td>
                                        <td style={{ border: "none" }}>{item.type}</td>
                                        <td style={{ border: "none" }}>{item.name}</td>
                                        <td style={{ border: "none" }}>{item.startDate}</td>
                                        <td style={{ border: "none" }}>{item.endDate}</td>
                                        <td style={{ border: "none" }}>
                                            <DeleteIcon color="error" onClick={(e) => { e.stopPropagation(); deleteDataRow(item?._id) }} />
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6">NO DATA</td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    )
}

export default PosPromotionsAndOffers